import React from 'react'
import { Clear } from '@material-ui/icons'
import { displayFileSize } from '../utils'
import { Button } from 'reactstrap'
import './file-upload-data.scss'

export default function FileUploadData({ file, url, onClear }) {
    return (
        <div className="file-upload-data">
            <table className="file-table">
                <tbody>
                    <tr>
                        <th>File:</th>
                        <td>{file.name}</td>
                    </tr>
                    <tr>
                        <th>Size:</th>
                        <td>{displayFileSize(file.size)}</td>
                    </tr>
                    {url !== '' && 
                        <tr>
                            <th>Thumbnail:</th>
                            <td><img src={url} className="thumbnail" alt="File upload preview" /></td>
                        </tr>
                    }
                    <tr>
                        <td colSpan={2}>
                            <Button
                                type="button"
                                onClick={onClear}
                                color="secondary"
                            >
                                <span className="d-flex align-items-center"><Clear className="me-1" />Clear File Selection</span>
                            </Button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}