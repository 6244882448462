import React from 'react'
import { Loading } from '.'
import './thumbnail.scss'

export default function Thumbnail({ src, height, width, onClick, placeholder, className, ...otherProps }) {
    return (
        <div 
            className={`thumbnail ${className ? className : ''} ${placeholder ? 'placeholder' : ''}`} 
            style={{ 
                backgroundImage: `url(${src})`, 
                height: height || '100px', 
                width: width || '100px' 
            }}
            onClick={onClick ? onClick : () => {}}
            {...otherProps}
        >
            {placeholder
                ? <Loading />
                : null
            }
        </div>
    )
}
