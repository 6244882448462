import React, { useState, useEffect} from 'react'
import { themes, useTheme } from '.'
import { useAuth } from '../auth/AuthProvider'

const ThemeContext = React.createContext()

export default function ThemeProvider({ children }) {
  const { user, userSettings, updateUserSettings } = useAuth()
  const [theme, setTheme] = useState('light')

  useTheme(themes[theme]);

  useEffect(() => {
    if (user && userSettings && userSettings.theme) {
      setTheme(userSettings.theme)
    }
    if (!user) {
      setTheme('light')
    }
  }, [user, userSettings])

  const getThemeColor = key => {
    return themes[theme][key]
  }

  const toggleTheme = () => {
    //setTheme(prev => prev === 'light' ? 'dark' : 'light')
    const updatedSettings = {
      ...userSettings,
      theme: theme === 'light' ? 'dark' : 'light'
    }
    updateUserSettings(updatedSettings)
  }

  const updateTheme = async (theme) => {
    let body = {
        ...userSettings,
        theme: theme
    }
    await updateUserSettings(body)
}

  const context = {
    theme, setTheme, toggleTheme,
    getThemeColor,
    updateTheme
  }

  return (
    <ThemeContext.Provider value={context}>
      {children}
    </ThemeContext.Provider>
  )
}

export const useTheming = () => {
  const context = React.useContext(ThemeContext)
  if (context === undefined) {
    throw new Error(`useTheming must be used within a ThemeProvider`)
  }
  return context
}
