import React from 'react'
import { Link } from '@reach/router'
import { Row, Col, Card, Table, CardBody } from 'reactstrap'
import centers from './centers.png'
import cross1 from './cross1.png'
import cross2 from './cross2.png'
import cross3 from './cross3.png'
import cross4 from './cross4.png'
import cross5 from './cross5.png'
import cross6 from './cross6.png'

export default function LBL1() {
    return (
        <div>
            <h1>3x3 Beginner Tutorial: The White Cross</h1>

            <ul>
                <li><Link to="/cubetools/lbl">Introduction</Link></li>
                <li>The White Cross</li>
                <li><Link to="/cubetools/lbl/2">The White Corners</Link></li>
                <li><Link to="/cubetools/lbl/3">The Second Layer</Link></li>
                <li><Link to="/cubetools/lbl/4">The Yellow Cross</Link></li>
                <li><Link to="/cubetools/lbl/5">The Yellow Top</Link></li>
                <li><Link to="/cubetools/lbl/6">The Final Corners</Link></li>
                <li><Link to="/cubetools/lbl/7">The Final Edges</Link></li>
            </ul>

            <h2>Get Your Bearings</h2>

            <Row>
                <Col>
                    <p>The center squares of the cube never move with respect to each other, so they serve as a reference point. We will think of the side with the yellow center as the top. The side with the white center is the bottom, which is the first layer we will solve. By solving the cube from the bottom up, the unsolved portions of the cube remain clearly visible during the solving process.</p>
                </Col>
                <Col>
                    <Card className="text-center">
                        <CardBody>
                            <img src={centers} alt="" />
                        </CardBody>
                        <CardBody>
                            In this diagram, the blue center is on front, the red center is on the right, and the yellow center is on top.     
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <h2>The Cross</h2>

            <p>The first step is to make a cross on the bottom layer. You can use any color you like, but many people choose white because it is so easy to see. This tutorial will start with white, so we will make a cross by moving the 4 edges with white stickers around the white center square.</p>

            <p>When we make the cross, the four side color stickers should align with the center sticker on each of the four sides (red with red, blue with blue, etc.). The completed cross is shown in the following two diagrams.</p>

            <Row>
                <Col className="text-center">
                    <img src={cross1} alt="" /><br />(front)
                </Col>
                <Col className="text-center">
                    <img src={cross2} alt="" /><br />(back)
                </Col>
            </Row>

            <h2>The Bad News</h2>

            <p>There are no set algorithms used to form the white cross. Here are some situations you may encounter and some ideas which may help you in making the cross.</p>

            <Table>
                <tbody>
                    <tr>
                        <td><img src={cross3} alt="" /></td>
                        <td>Red is already aligned with red. Simply use (R') to move the white sticker in place.</td>
                    </tr>
                    <tr>
                        <td><img src={cross4} alt="" /></td>
                        <td>The red/white edge piece is on the top next to the blue center. Use (U'R2) to move it into place. U’ aligns the red sticker, and R2 aligns the white sticker.</td>
                    </tr>
                    <tr>
                        <td><img src={cross5} alt="" /></td>
                        <td>The white/blue and white/red edge pieces need to switch places. One way to fix this is (D'F'D2F). D'F' aligns the blue/white edge and moves it out of the way to the middle layer. D2 aligns the red/white edge, and F restores the blue/white edge. If other cross pieces are in place, (RDR'D'R) works.</td>
                    </tr>
                    <tr>
                        <td><img src={cross6} alt="" /></td>
                        <td>This is not as bad as it looks. Use (D'FD). D' moves the blue/white edge out of our way. F moves the red/white edge to the bottom layer, aligning the white sticker. D aligns red with the red center while restoring the blue/white edge.</td>
                    </tr>
                </tbody>
            </Table>

            <h2>The Good News</h2>
            <p>Because this step is entirely intuitive (without algorithms to help) it is actually the hardest step of solving the cube. Once you can make the cross, the rest will seem easier with a little practice! Next we will <Link to="/cubetools/lbl/2">solve the white corners</Link> around the cross.</p>

        </div>
    )
}
