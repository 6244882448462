import React, { useEffect, useState, useCallback } from 'react'
import { Card, Loading } from '../../common'
import { useFriends } from '../Friends/FriendsProvider'
import { Done, Clear } from '@material-ui/icons'
import { useAuth } from '../../main/auth/AuthProvider'
import RenderedDashboardElement from './RenderedDashboardElement'
import { useWhisk } from '../Whisk'
import { useBudget } from '../NickelAndDime'
import { Link } from '@reach/router'
import { useHoney } from '../Honey'
import { useTracker } from '../Tracker'
import { Button } from 'reactstrap'
import './dashboard.scss'

export default function Dashboard() {
    const { invitations, acceptInvitation, removeFriend } = useFriends()
    const { userSettings } = useAuth()
    const { getGroups } = useTracker()
    const { getRecipes } = useWhisk()
    // const { getBudgets } = useBudget()
    const { getLists, } = useHoney()
    const [dashboardElements, setDashboardElements] = useState([])
    const [loading, setLoading] = useState(false)
    // const showLessonsLink = user && [20, 21].includes(user.userId)

    const onLoad = useCallback(async () => {
        setLoading(true)
        await Promise.all([
            getLists(),
            getRecipes(),
            getGroups(),
            // getBudgets()
        ])
        setLoading(false)
    }, [getLists, getRecipes, getGroups])

    useEffect(() => {
        onLoad()
    }, [onLoad])

    useEffect(() => {
        if (userSettings && userSettings.dashboard) {
            setDashboardElements(userSettings.dashboard)
        }
    }, [userSettings])

    return (
        <div className="main-container dashboard">
            {invitations.length > 0 &&
                <Card className="dashboard-section" header="Invitations">        
                    <ul className="friends-list">
                        {invitations.map(x => 
                            <li key={x.userId}>
                                {`${x.firstName} ${x.lastName} (${x.email})`}
                                <Button
                                    onClick={() => acceptInvitation(x.userId)}
                                    color="success"
                                    className="ms-1"
                                >
                                    <Done />
                                </Button>
                                <Button
                                    onClick={() => removeFriend(x.userId)}
                                    color="danger"
                                    className="ms-1"
                                >
                                    <Clear />
                                </Button>
                            </li>
                        )}
                    </ul> 
                </Card>
            }
            {/* {!loading && showLessonsLink && 
                <Card header="Lessons" headerLink="/lessons">
                    <Link to="/lessons">View Lessons Hub</Link>
                </Card>} */}
            {loading && dashboardElements.length > 0
                ? <Loading /> 
                : dashboardElements.map((element, i) => 
                    <RenderedDashboardElement element={element} key={`element-${i}-${element.type}-${element.id}`} />
            )}
            {dashboardElements.length < 1 && 
                <p className="text-muted">Customize your Dashboard in <Link to='/settings'>Settings</Link>!</p>
            }
        </div>
    )
}
