import React from 'react'
import { Router } from '@reach/router'
import { CubeToolsMenu, CubeTools, Flashcards, Resources, Scrambler, Timer, Tutorials, Notation, Triggers,
    LayerByLayer, LBL1, LBL2, LBL3, LBL4, LBL5, LBL6, LBL7, CFOP, CFOP1, CFOP2, CFOP3, CFOP4, CFOP5,
    Roux, Roux1, Roux2, Roux3, Roux4, Roux5, Roux6, Roux7, Roux8, Two, Two1, Two2, Two3, Two4 } from '.'

export default function CubeToolsModule() {
    return (
        <div className="module-container">
                <CubeToolsMenu />
                <div className="main-container with-side-menu">
                    <Router>
                        <CubeTools default />
                        <Flashcards path="flashcards" />
                        <Resources path="resources" />
                        <Scrambler path="scrambler" />
                        <Timer path="timer" />
                        <Tutorials path="tutorials" />
                        <Notation path="notation" />
                        <Triggers path="triggers" />
                        <LayerByLayer path="lbl" />
                        <LBL1 path="lbl/1" />
                        <LBL2 path="lbl/2" />
                        <LBL3 path="lbl/3" />
                        <LBL4 path="lbl/4" />
                        <LBL5 path="lbl/5" />
                        <LBL6 path="lbl/6" />
                        <LBL7 path="lbl/7" />
                        <CFOP path="cfop" />
                        <CFOP1 path="cfop/1" />
                        <CFOP2 path="cfop/2" />
                        <CFOP3 path="cfop/3" />
                        <CFOP4 path="cfop/4" />
                        <CFOP5 path="cfop/5" />
                        <Roux path="roux" />
                        <Roux1 path="roux/1" />
                        <Roux2 path="roux/2" />
                        <Roux3 path="roux/3" />
                        <Roux4 path="roux/4" />
                        <Roux5 path="roux/5" />
                        <Roux6 path="roux/6" />
                        <Roux7 path="roux/7" />
                        <Roux8 path="roux/8" />
                        <Two path="2x2" />
                        <Two1 path="2x2/1" />
                        <Two2 path="2x2/2" />
                        <Two3 path="2x2/3" />
                        <Two4 path="2x2/4" />
                    </Router>
                </div>
        </div>
    )
}