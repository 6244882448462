import React from 'react'
import { Router } from '@reach/router'
import { KittyGame, HighScores } from '.'

export default function SaveTheKitty() {
    return (
        <Router>
            <KittyGame path='play' />
            <HighScores path='scores' />
        </Router>
    )
}
