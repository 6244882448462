import React, { useState, useEffect, useCallback } from 'react'
import { Form, FormGroup, Col, Input, Button, Label, FormFeedback, FormText } from 'reactstrap'
import { Link, navigate } from '@reach/router'
import { fetcher, Loading } from '../../common'

export default function PieceDialog({ pieceId, isModal, entry, onClose }) {
    const [piece, setPiece] = useState(null)
    const [nameInput, setNameInput] = useState('')
    const [artistInput, setArtistInput] = useState('')
    const [albumInput, setAlbumInput] = useState('')
    const [pathInput, setPathInput] = useState('')
    const [hasSubmitted, setHasSubmitted] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const maxNameLength = 200
    const maxPathLength = 300
    const maxArtistLength = 100
    const maxAlbumLength = 100
    const returnUrl = '/music/pieces'

    const getPiece = useCallback(async () => {
        if (pieceId && pieceId !== 'new') {
            setIsLoading(true)
            const resp = await fetcher.get(`music/pieces/${pieceId}`)
            if (resp) {
                setPiece(resp)
                setNameInput(resp.name)
                setArtistInput(resp.artist || '')
                setAlbumInput(resp.album || '')
                setPathInput(resp.path)
            }
        }
        setIsLoading(false)
    }, [pieceId])

    useEffect(() => {
        getPiece()
    }, [getPiece])

    useEffect(() => {
        if (isModal && entry) {
            setPathInput(entry.path_lower)
        }
    }, [isModal, entry])

    const handleSave = async (e) => {
        e.preventDefault()
        setHasSubmitted(true)
        if (nameInput.trim() === '' || pathInput.trim() === '' || 
            nameInput.trim().length > maxNameLength ||
            pathInput.trim().length > maxPathLength ||
            artistInput.trim().length > maxArtistLength ||
            albumInput.trim().length > maxAlbumLength) {
            return
        }
        setIsLoading(true)
        if (piece) {
            editPiece()
        } else {
            createPiece()
        }
    }

    const createPiece = async () => {
        const body = {
            name: nameInput.trim(),
            artist: artistInput.trim(),
            album: albumInput.trim(),
            path: pathInput.trim()
        }
        const resp = await fetcher.post('music/pieces', body)
        if (resp && !isModal) {
            navigate(returnUrl)
        } else if (resp && isModal) {
            onClose()
        } else {
            setIsLoading(false)
        }
    }

    const editPiece = async () => {
        const body = {
            name: nameInput.trim(),
            artist: artistInput.trim(),
            album: albumInput.trim(),
            path: pathInput.trim(),
            id: piece.id
        }
        const resp = await fetcher.put(`music/pieces/${piece.id}`, body)
        if (resp && !isModal) {
            navigate(returnUrl)
        } else if (resp && isModal) {
            onClose()
        } else {
            setIsLoading(false)
        }
    }

    const handleDelete = async () => {
        setIsLoading(true)
        const resp = await fetcher.del(`music/pieces/${piece.id}`)
        if (resp) {
            navigate(returnUrl)
        } else {
            setIsLoading(false)
        }
    }

    if (isLoading) return <Loading />

    return (
        <div>
            <h1>Piece</h1>
            <Form onSubmit={handleSave}>
                <Col sm={isModal ? 12 : 4} lg={isModal ? 12 : 3}>
                    <FormGroup row>
                        <Label for="name">Name</Label>
                        <Input 
                            type="text"
                            id="name"
                            value={nameInput}
                            onChange={e => setNameInput(e.target.value)}
                            invalid={(hasSubmitted && nameInput.trim() === '') || nameInput.trim().length > maxNameLength}
                        />
                        <FormText>{maxNameLength - nameInput.trim().length} characters remaining</FormText>
                        {nameInput.trim().length > maxNameLength && 
                            <FormFeedback>Too long!</FormFeedback>}
                        {hasSubmitted && nameInput.trim() === '' && 
                            <FormFeedback>Please enter a name for the piece.</FormFeedback>}
                    </FormGroup>
                    <FormGroup row>
                        <Label for="artist">Artist</Label>
                        <Input 
                            type="text"
                            id="artist"
                            value={artistInput}
                            onChange={e => setArtistInput(e.target.value)}
                            invalid={artistInput.trim().length > maxArtistLength}
                        />
                        <FormText>{maxArtistLength - artistInput.trim().length} characters remaining</FormText>
                        <FormFeedback>Too long!</FormFeedback>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="album">Album</Label>
                        <Input 
                            type="text"
                            id="album"
                            value={albumInput}
                            onChange={e => setAlbumInput(e.target.value)}
                            invalid={albumInput.trim().length > maxAlbumLength}
                        />
                        <FormText>{maxAlbumLength - albumInput.trim().length} characters remaining</FormText>
                        <FormFeedback>Too long!</FormFeedback>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="path">Dropbox Path</Label>
                        <Input 
                            type="text"
                            id="path"
                            value={pathInput}
                            onChange={e => setPathInput(e.target.value)}
                            invalid={(hasSubmitted && pathInput.trim() === '') || pathInput.trim().length > maxPathLength}
                        />
                        <FormText>{maxPathLength - pathInput.trim().length} characters remaining</FormText>
                        {pathInput.trim().length > maxPathLength && <FormFeedback>Too long!</FormFeedback>}
                        {hasSubmitted && pathInput.trim() === '' && <FormFeedback>Please enter the Dropbox path.</FormFeedback>} 
                    </FormGroup>
                    {isModal
                        ? <FormGroup row>
                            <Button color="success" type="submit">Save</Button>
                            <Button color="secondary" onClick={onClose} className="ms-1">Cancel</Button>
                        </FormGroup>
                        : <FormGroup row>
                            <Button color="success" type="submit">Save</Button>
                            <Link to="/music/pieces" className="btn btn-secondary ms-1">Cancel</Link>
                            {piece !== null && 
                                <Button color="danger" type="button" className="ms-1" onClick={handleDelete}>
                                    Delete
                                </Button>}
                        </FormGroup>}
                </Col>
            </Form>
        </div>
    )
}
