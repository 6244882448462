import { getPieceArrayFromFenPieces, getPossibleMoves, coordMap, getSquareContentBySquareName, getKingSquare, getPositionAfterMove, getFenPiecesFromPieceArray } from "./helpers"

export const analyzePosition = (fen, depth = 1) => {
    let result = 0
    const initialScore = getAnalysisForPosition(fen)
    const possibleMoves = getPossibleMoves(fen)
    const possibleMoveScores = {}
    for (let i = 0; i < possibleMoves.length; i++) {
        const possibleMove = possibleMoves[i]
        const possibleMoveOriginSquare = possibleMove.split('-')[0]
        const possibleMoveDestinationSquare = possibleMove.split('-')[1]
        const currentPosition = getPieceArrayFromFenPieces(fen.pieces)
        const possibleMovePiece = getSquareContentBySquareName(possibleMoveOriginSquare, currentPosition)
        const isEnPassantCapture = possibleMovePiece.toLowerCase() === 'p' && possibleMoveDestinationSquare === fen.enPassantSquare
        const positionAfterPossibleMove = getPositionAfterMove(currentPosition, 
            possibleMovePiece, possibleMoveOriginSquare, possibleMoveDestinationSquare, isEnPassantCapture)
        const fenPiecesAfterPossibleMove = getFenPiecesFromPieceArray(positionAfterPossibleMove)
        const newFen = {
            ...fen,
            pieces: fenPiecesAfterPossibleMove,
            turn: fen.turn === 'w' ? 'b' : 'w'
        }
        if (fen.turn === 'b') {
            newFen.fullMoves = fen.fullMoves + 1
        }
        // TODO: update newFen.halfMoveCLock, castlingOptions, and en passant square
        possibleMoveScores[possibleMove] = getAnalysisForPosition(newFen)
    }
    // console.log(possibleMoveScores)
    let sortedScores = Object.values(possibleMoveScores).sort()
    // console.log(sortedScores)
    const bestMoveScore = fen.turn === 'w' ? sortedScores[sortedScores.length - 1] : sortedScores[0]
    const bestMove = getKeyByValue(possibleMoveScores, bestMoveScore)
    // console.log(bestMove)
    result += parseFloat(initialScore)
    // TODO: calculate possible move scores, use recursion?
    return result
}

function getKeyByValue(object, value) {
    return Object.keys(object).find(key => object[key] === value);
  }

export const getAnalysisForPosition = fen => {
    const position = getPieceArrayFromFenPieces(fen.pieces)
    let points = 0
    points += getPointsForMaterial(position)
    points += getPointsForMobility(fen, position)
    return points.toFixed(2)
}

export const getPointsForMaterial = position => {
    let points = 0

    for (let key in coordMap) {
        const piece = getSquareContentBySquareName(key, position)
        switch (piece) {
            case 'p':
                points += -1
                break
            case 'P':
                points += 1
                break
            case 'r':
                points += -5
                break
            case 'R':
                points += 5
                break
            case 'n':
            case 'b':
                points += -3
                break
            case 'N':
            case 'B':
                points += 3
                break
            case 'q':
                points += -9
                break
            case 'Q':
                points += 9
                break
            default:
        }
    }

    // bishop pair = .5
    // passed pawn = .3
    // doubled pawn = -.2
    // isolated pawn = -.2
    return points
}

export const getPointsForMobility = (fen, position) => {
    let points = 0

    const fenWhite = {...fen}
    fenWhite.turn = 'w'
    const whitePossibleMoves = getPossibleMoves(fenWhite)
    points += whitePossibleMoves.length
    if (whitePossibleMoves.length < 1) {
        points -= 999
    }

    const whiteKingSquare = getKingSquare('w', position)
    const whiteKingMoves = whitePossibleMoves.filter(x => x.substring(0, 2) === whiteKingSquare)
    points += whiteKingMoves.length    

    const fenBlack = {...fen}
    fenBlack.turn = 'b'
    const blackPossibleMoves = getPossibleMoves(fenBlack)
    points -= blackPossibleMoves.length
    if (blackPossibleMoves.length < 1) {
        points += 999
    } 

    const blackKingSquare = getKingSquare('b', position)
    const blackKingMoves = blackPossibleMoves.filter(x => x.substring(0, 2) === blackKingSquare)    
    points -= blackKingMoves.length    

    return points / 3
}

