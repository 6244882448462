import React from 'react'
import './tag.scss'

export default function Tag({ text, className, ...otherProps}) {
    return (
        <div className={`tag ${className}`} {...otherProps}>
            {text}
        </div>
    )
}
