import React, { useEffect, useCallback, useMemo } from 'react'
import { navigate } from '@reach/router'
import { useWhisk } from '.'
import { DataTable } from '../../common'
import { useFriends } from '../Friends/FriendsProvider'
import { Input } from 'reactstrap'
import { useAuth } from '../../main/auth/AuthProvider'

export default function Recipes() {
    const { user } = useAuth()
    const { recipes, getRecipes } = useWhisk()
    const { friends } = useFriends()

    useEffect(() => {
        getRecipes()
    }, [getRecipes])

    const getName = useCallback(value => {
        if (user && value === user.userId) {
            return 'Me'
        }
        const friend = friends.find(x => x.userId === value)
        if (friend) {
            return `${friend.firstName} ${friend.lastName}`
        }
        return '?'
    }, [friends, user])

    const friendFilter = useCallback(({ column: { filterValue, setFilter, preFilteredRows, id } }) => {
        
        const options = () => {
            const opts = new Set()
            preFilteredRows.forEach(row => opts.add(getName(row.values[id])))
            return [...opts.values()].sort()
        }
     
        return (
            <Input type="select" value={filterValue} onChange={e => setFilter(e.target.value || undefined)}>
                <option value="">All</option>
                {options().map((option, i) =>
                    <option key={`option-${i}-${option}`} value={option}>
                        {option}
                    </option>
                )}
            </Input>
        )
    }, [getName])

    const columns = useMemo(() => [
        {
            Header: 'Name',
            accessor: 'name'
        },
        {
            Header: 'Owner',
            accessor: 'userId',
            Cell: ({ value }) => getName(value),
            Filter: friendFilter,
            filter: 'friends'
        },
        {
            Header: 'Tags',
            accessor: 'tags'
        }
    ], [friendFilter, getName])

    if (recipes && recipes.length)
        return (
            <DataTable 
                data={recipes}
                columns={columns}
                sortable
                filterable
                paginated={recipes.length > 10}
                onRowClick={e => navigate(`whisk/${e.original.recipeId}`)}
            />
        )
    
    return (
        <p className="text-muted">No recipes found. Add some!</p>
    )
}
