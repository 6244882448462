import React, { useState, useEffect } from "react";
import { Link, useLocation } from "@reach/router";
import { useAuth } from "../auth/AuthProvider";
import {
    Collapse,
    Nav,
    Navbar,
    NavbarBrand,
    NavbarToggler,
    NavLink,
    NavItem,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
} from "reactstrap";
import { useTheming } from "../theme/ThemeProvider";
import { WbSunny, Brightness3 } from "@material-ui/icons";

export default function Navigation() {
    const location = useLocation();
    const { user, logout } = useAuth();
    const { theme, toggleTheme } = useTheming();
    const [isOpen, setIsOpen] = useState(false);
    const isHarris = user && user.userId < 3;

    useEffect(() => {
        if (location) {
            window.scrollTo(0, 0);
            setIsOpen(false);
        }
    }, [location]);

    if (location.pathname.includes("admin/start")) {
        return null;
    }

    return (
        <nav>
            <Navbar expand="md">
                <NavbarBrand tag={Link} to="/">
                    SquareNote
                </NavbarBrand>
                <NavbarToggler onClick={() => setIsOpen((x) => !x)} />
                <Collapse isOpen={isOpen} navbar>
                    <Nav className="ms-auto" navbar>
                        {user !== null && (
                            <div className="clickable nav-link" onClick={toggleTheme} title="Change theme">
                                {theme === "dark" ? <Brightness3 /> : <WbSunny />}
                            </div>
                        )}
                        {user !== null && (
                            <NavItem>
                                <NavLink to="/dashboard" tag={Link}>
                                    Dashboard
                                </NavLink>
                            </NavItem>
                        )}
                        {/* {hasLessonsAccess && 
                            <NavItem>
                                <NavLink to="/lessons" tag={Link}>Lessons</NavLink>
                            </NavItem>
                        } */}
                        <NavItem>
                            <NavLink to="/blog" tag={Link}>
                                Blog
                            </NavLink>
                        </NavItem>
                        {user === null ? (
                            <NavItem>
                                <NavLink to="/productivity" tag={Link}>
                                    Productivity
                                </NavLink>
                            </NavItem>
                        ) : (
                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav caret>
                                    Productivity
                                </DropdownToggle>
                                <DropdownMenu>
                                    {/* <DropdownItem to="/brisk" tag={Link}>Brisk <span className="text-muted">(weather)</span></DropdownItem> */}
                                    <DropdownItem to="/calendar" tag={Link}>
                                        Calendar
                                    </DropdownItem>
                                    {isHarris ? (
                                        <DropdownItem href="https://squarenote.azurewebsites.net/honey">
                                            Honey <span className="text-muted">(lists)</span>
                                        </DropdownItem>
                                    ) : (
                                        <DropdownItem to="/honey" tag={Link}>
                                            Honey <span className="text-muted">(lists)</span>
                                        </DropdownItem>
                                    )}
                                    {/* <DropdownItem to="/routines" tag={Link}>In the Groove <span className="text-muted">(routines)</span></DropdownItem> */}
                                    {isHarris && (
                                        <DropdownItem href="https://squarenote.azurewebsites.net/budget">
                                            Nickel &amp; Dime <span className="text-muted">(budget)</span>
                                        </DropdownItem>
                                    )}
                                    <DropdownItem href="https://squarenote.azurewebsites.net/notes">Notes</DropdownItem>
                                    <DropdownItem to="/tracker" tag={Link}>
                                        Tracker <span className="text-muted">(data)</span>
                                    </DropdownItem>
                                    <DropdownItem to="/whisk" tag={Link}>
                                        Whisk <span className="text-muted">(recipes)</span>
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        )}
                        <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle nav caret>
                                Fun
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem to="/games/chess" tag={Link}>
                                    Chess
                                </DropdownItem>
                                <DropdownItem to="/cubetools" tag={Link}>
                                    CubeTools
                                </DropdownItem>
                                {user && user.userId < 3 && (
                                    <DropdownItem to="/dashboard/garden" tag={Link}>
                                        Garden
                                    </DropdownItem>
                                )}
                                <DropdownItem to="/music" tag={Link}>
                                    Music
                                </DropdownItem>
                                <DropdownItem to="/games/kitty/play" tag={Link} className="mobile-hide">
                                    Save the Kitty
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        {user ? (
                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav caret>
                                    {user.email}
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem to="/friends" tag={Link}>
                                        Friends
                                    </DropdownItem>
                                    <DropdownItem to="/settings" tag={Link}>
                                        Settings
                                    </DropdownItem>
                                    {user && user.isAdmin && (
                                        <>
                                            <DropdownItem to="/admin" tag={Link}>
                                                Admin
                                            </DropdownItem>
                                            <DropdownItem to="/admin/start" tag={Link}>
                                                Home Start
                                            </DropdownItem>
                                            <DropdownItem to="/admin/start/work" tag={Link}>
                                                Work Start
                                            </DropdownItem>
                                        </>
                                    )}
                                    <DropdownItem onClick={logout}>Sign Out</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        ) : (
                            <NavItem>
                                <NavLink to="/login" tag={Link}>
                                    Sign In
                                </NavLink>
                            </NavItem>
                        )}
                    </Nav>
                </Collapse>
            </Navbar>
        </nav>
    );
}
