import React from 'react'
import { useAuth } from '../../main/auth'
import { Router } from '@reach/router'
import { BlogMenu, BlogPost, BlogPostDialog, BlogTagDialog, ManageBlogTags, BlogHome, BlogPosts } from '.'

export default function BlogModule() {
    const { user } = useAuth()

    return (
        <div className="module-container">
            <BlogMenu />
            <div className="main-container with-side-menu">
                <Router>
                    <BlogHome default />
                    <BlogPost path="post/:postKey" />
                    {user && user.isAdmin && <> 
                        <BlogPosts path="posts" />
                        <BlogPostDialog path="posts/edit/:postId" />
                        <BlogPostDialog path="posts/new" />
                        <BlogTagDialog path="tags/edit/:tagId" />
                        <BlogTagDialog path="tags/new" />
                        <ManageBlogTags path="tags" />
                    </>}
                </Router>
            </div>
        </div>
    )
}
