import React, { useMemo } from 'react'
import { useTable, useSortBy, useFilters, usePagination } from 'react-table'
import { ArrowDownward, ArrowUpward } from '@material-ui/icons'
import { TextColumnFilter } from './DataTableFilters'
import { DataTablePagination } from '.'
import { Table } from 'reactstrap'
import './data-table.scss'

export default function DataTableNoAuth({ data, columns, sortable, filterable, paginated, onRowClick }) {

    const defaultColumn = useMemo(() => ({
        Filter: TextColumnFilter,
    }), [])

    const filterTypes = useMemo(() => ({
        boolean: (tableRows, id, filterValue) => {
            return tableRows.filter(row => {
                const rowValue = row.values[id]
                const filterBoolean = filterValue === 'Yes'
                return rowValue === "" || undefined
                    ? true
                    : rowValue === filterBoolean
            })
        },
        exact: (tableRows, id, filterValue) => {
            return tableRows.filter(row => {
                const rowValue = row.values[id]
                return rowValue === '' || undefined
                    ? true
                    : rowValue === filterValue || rowValue === parseInt(filterValue, 10)
            })
        },
        months: (tableRows, id, filterValue) => {
            const months = [
                'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
            ]
            return tableRows.filter(row => {
                const rowValue = row.values[id]
                return rowValue === '' || undefined
                    ? true
                    : months[Number(rowValue) - 1] === filterValue
            })
        }
    }), [])
    
    const tableInstance = useTable({ 
        columns, 
        data, 
        defaultColumn, 
        filterTypes, 
        initialState: { pageIndex: 0 } 
    }, useFilters, useSortBy, usePagination)

    const { 
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page 
    } = tableInstance

    const getTableContent = () => {
        const content = paginated ? page : rows
        return content.map((row, i) => {
            prepareRow(row)
            return (
                <tr 
                    key={`table-body-row-${i}`} 
                    {...row.getRowProps()} 
                    onClick={onRowClick ? () => onRowClick(row) : () => {}}
                    className={onRowClick ? 'clickable' : ''}
                >
                    {row.cells.map((cell, j) =>
                        <td key={`table-body-row-${i}-cell-${j}`} {...cell.getCellProps()}>
                            {cell.render('Cell')}
                        </td>
                    )}
                </tr>
            )
        })
    }

    return (
        <div className="data-table">
            <Table striped hover {...getTableProps()}>
                <thead>
                    {paginated && 
                        <tr className="pagination-row">
                            <td colSpan={columns.length}>
                                <DataTablePagination tableInstance={tableInstance} />
                            </td>
                        </tr>
                    }
                    {headerGroups.map((headerGroup, i) =>
                        <React.Fragment key={`table-head-row-set-${i}`}>
                            <tr className="header-row" {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column, j) => {
                                    if (sortable) {
                                        return (
                                            <th 
                                                key={`column-${j}-header`} 
                                                {...column.getHeaderProps(column.getSortByToggleProps())}
                                                className={column.canSort ? 'sortable' : ''}
                                            >
                                                {column.render('Header')}
                                                <span>
                                                    {column.isSorted
                                                        ? column.isSortedDesc
                                                            ? <ArrowDownward className="sort-icon" />
                                                            : <ArrowUpward className="sort-icon" />
                                                        : ''
                                                    }
                                                </span>
                                            </th>
                                        )
                                    } else {
                                        return (
                                            <th key={`column-${j}-header`} {...column.getHeaderProps()}>
                                                {column.render('Header')}
                                            </th>
                                        )
                                    }
                                })}
                            </tr>
                            {filterable && 
                                <tr className="filter-row">
                                    {headerGroup.headers.map((column, j) =>
                                        <td key={`column-${j}-filter`}>
                                            {column.canFilter ? column.render('Filter') : null}
                                        </td>
                                    )}
                                </tr>}
                        </React.Fragment>
                    )}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {getTableContent()}                        
                </tbody>
            </Table>
        </div>
    )
}
