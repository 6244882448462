import { VolumeUp } from '@material-ui/icons'
import { Button } from 'reactstrap';
import './tuning-sound.scss'

export default function TuningSound({ label, frequencies }) {

    const play = () => {
        if (!frequencies || !frequencies.length) return;
        frequencies.forEach(x => generateNote(x))
    }

    const generateNote = (frequency) => {
        if (!frequency) return;
        const context1 = new AudioContext()
        const o1 = context1.createOscillator()
        o1.type = 'sine'
        const g1 = context1.createGain()
        g1.gain.value = .2;
        // g1.gain.exponentialRampToValueAtTime(0.00001, context1.currentTime + 8);
        o1.frequency.value = frequency;
        o1.connect(g1);
        g1.connect(context1.destination);
        o1.start(0);
        o1.stop(context1.currentTime + 3)
    }
    
    return (
        <Button color="primary" className="tuning-sound" onClick={play}>
            <VolumeUp /> {label}
        </Button>
    )
}
