import React, { useEffect, useState } from 'react'
import { Done, Clear, Delete } from '@material-ui/icons'
import { Card } from '../../common'
import { ListSharing, useHoney } from '.'
import { navigate } from '@reach/router'
import { Input, FormGroup, Label, Button, Form, FormFeedback } from 'reactstrap'

export default function ListDialog({ listId }) {
    const { activeList, addList, setActiveListId, deleteList, renameList, isListCreator } = useHoney()
    const [listNameInput, setListNameInput] = useState('')
    const [hasSubmitted, setHasSubmitted] = useState(false)

    const handleSubmit = event => {
        event.preventDefault()
        setHasSubmitted(true)
        if (listNameInput.trim() === '') {
            return
        } else if (activeList) {
            renameList(activeList, listNameInput)
        } else {
            addList(listNameInput)
        }
    }

    useEffect(() => {
        if (listId && listId !== "new") {
            setActiveListId(parseInt(listId, 10))
        } else {
            setActiveListId(0)
        }
    }, [listId, setActiveListId])

    useEffect(() => {
        if (activeList) {
            setListNameInput(activeList.name)
        } else {
            setListNameInput("")
        }
    }, [activeList])

    if (!listId || isListCreator)
        return (
            <>
                <Card header={listId && activeList ? `Edit List: ${activeList.name}` : 'Add List'}>
                    <Form>
                        <FormGroup>
                            <Label for="listName">List Name</Label>
                            <Input 
                                type="text"
                                id="listName"
                                placeholder="List name"
                                value={listNameInput}
                                onChange={e => setListNameInput(e.target.value)}
                                invalid={hasSubmitted && listNameInput.trim() === ''}
                            />
                            <FormFeedback>Please enter a list name.</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Button 
                                type="submit"
                                color="success"
                                onClick={e => handleSubmit(e)}
                            >
                                <span className="d-flex align-items-center"><Done className="me-1" />Save</span>
                            </Button>
                            <Button 
                                type="button"
                                color="secondary"
                                onClick={() => navigate(activeList ? `/honey/${activeList.listId}` : `/honey`)}
                                className="ms-1"
                            >
                                <span className="d-flex align-items-center"><Clear className="me-1" />Cancel</span>
                            </Button>
                            {listId !== undefined && 
                                <Button 
                                    type="button"
                                    color="danger"
                                    onClick={() => deleteList(listId)}
                                    className="ms-1"
                                >
                                    <span className="d-flex align-items-center"><Delete className="me-1" />Delete</span>
                                </Button>}
                        </FormGroup>
                    </Form>
                </Card>
                {listId !== undefined && <ListSharing />}
            </>
        )

    return null
}
