import React from 'react'
import { Link } from '@reach/router'
import _2x2b from './2x2b.pdf'

export default function _2x2() {
    return (
        <div>
            <h1>2x2 Beginner Tutorial</h1>

            <ul>
                <li>Introduction</li>
                <li><Link to="/cubetools/2x2/1">Bottom Side</Link></li>
                <li><Link to="/cubetools/2x2/2">Bottom Layer</Link></li>
                <li><Link to="/cubetools/2x2/3">Top Side</Link></li>
                <li><Link to="/cubetools/2x2/4">Top Layer</Link></li>
            </ul>

            <p>This tutorial is for beginners. You can <a href={_2x2b} target="_blank" rel="noreferrer">download</a> it as a PDF. More experienced cubers may be interested in the <Link to="/cubetools/ortega">intermediate 2x2 tutorial</Link>.</p>

            <h2>Harder Than It Looks</h2>
            
            <p>The 2x2 cube is a set of 8 corner pieces which move around a central mechanism. These pieces can arranged in 3,674,160 different ways, but the cube is never more than 11 turns away from being solved. Solving the cube that efficiently requires a lot of memorization, so in this tutorial we will learn to solve it using a few more turns with only a little memorization.</p>
            
            <p>The &quot;trick&quot; to solving the cube is the use of algorithms, or specific sequences of turns. Algorithms do certain things to the pieces of the cube without disrupting other parts of the cube. The more algorithms you memorize, the more tools you have to solve the cube efficiently. This basic method of solving the 2x2 cube uses only three algorithms.</p>
            
            <h2>Notation</h2>
            
            <p>Before we dive into the first step, you need to be familiar with basic cube notation. We use capital letters to show which side of the cube to turn: <u>F</u>ront, <u>B</u>ack, <u>R</u>ight, <u>L</u>eft, <u>U</u>p (top), or <u>D</u>own (bottom).
            <ul>
                <li>A lone letter (e.g. R) represents a quarter-turn clockwise (while looking at that face).</li>
                <li>If there is a prime mark following the letter (e.g. R'), it represents a quarter-turn counterclockwise.</li>
                <li>If there is a &quot;2&quot; following the letter (e.g. R2), it represents a half-turn (180 degrees).</li>
            </ul>
            
            See my <Link to="/cubetools/notation">notation page</Link> for more details.</p>
            
            <h2>Spoiler Alert!</h2>
            
            <p>We will use three algorithms to solve the 2x2 cube:
                <ol type="A">
                    <li>(RUR'U')</li>
                    <li>(RU2R'U') (RU2L'U) (R'U'R)</li>
                    <li>(RUR'U) (RU2R') </li>
                </ol>
            </p>
            
            <p>Here are the four steps in this method, along with the algorithm used in each.
                <ol type="1">
                    <li>Bottom side (A)</li>
                    <li>Bottom layer (B)</li>
                    <li>Top side (C)</li>
                    <li>Top layer (B)</li>
                </ol>
            </p>

            <p>Let's begin by <Link to="/cubetools/2x2/1">solving a side</Link>!</p>

        </div>
    )
}
