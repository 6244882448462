import React from 'react'
import { Link } from '@reach/router'
import { Row, Col } from 'reactstrap'
import SB from './SB.png'
import SB1 from './SB1.png'
import SB2 from './SB2.png'
import SB3 from './SB3.png'
import SB4 from './SB4.png'
import SB5 from './SB5.png'
import SB6 from './SB6.png'

export default function Roux2() {
    return (
        <div>
            <h1>Roux Tutorial: The Second Block</h1>

            <ul>
                <li><Link to="/cubetools/roux">Introduction</Link></li>
                <li><Link to="/cubetools/roux/1">The First Block</Link></li>
                <li>The Second Block</li>
                <li><Link to="/cubetools/roux/3">CMLL (Top Corners)</Link></li>
                <li><Link to="/cubetools/roux/4">LSE: Edge Orientation</Link></li>
                <li><Link to="/cubetools/roux/5">LSE: Permute UL/UR</Link></li>
                <li><Link to="/cubetools/roux/6">LSE: Permute M Slice</Link></li>
                <li><Link to="/cubetools/roux/7">Example Solves</Link></li>
                <li><Link to="/cubetools/roux/8">Advanced Roux</Link></li>
            </ul>

            <p>Next, we form another 1x2x3 block on the opposite side of the cube without disrupting the first block.</p>

            <div className="text-center mb-2">
                <img src={SB} alt="" />
            </div>

            <p>As with the first block, there are no set algorithms. F2L techniques can be helpful in forming the second block while preserving the first one, but there may be more efficient ways to form the block by utilizing the M slice (M turns and r turns). Here are some example ideas.</p>

            <Row className="mb-2">
                <Col className="text-center"><img src={SB1} alt="" /><br />(rUr')</Col>
                <Col className="text-center"><img src={SB2} alt="" /><br />(U'MU2)(rU'r')</Col>
                <Col className="text-center"><img src={SB3} alt="" /><br />(MU)(rU'r')</Col>
                <Col className="text-center"><img src={SB4} alt="" /><br />(U'M2U2)(rU'r')</Col>
                <Col className="text-center"><img src={SB5} alt="" /><br />(rU'r') U2 (rUr')</Col>
                <Col className="text-center"><img src={SB6} alt="" /><br />(U'rU')(MUr')</Col>
            </Row>

            <p>Solving the second block in 12-14 moves is a good goal.</p>

            <p>Once the second block is complete, we are ready to solve the top corners using <Link to="/cubetools/roux/3">CMLL</Link>.</p>
        </div>
    )
}
