import React from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

export default function TextEditor({ data, onChange, placeholder }) {
    return (
        <div style={{ width: '100%'}}>
            <CKEditor 
                editor={ClassicEditor}
                data={data}
                config={{ 
                    toolbar: [
                        'heading', '|', 
                        'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 
                        'blockQuote', 'mediaEmbed', 'insertTable', '|', 
                        'undo', 'redo'
                    ],
                    placeholder: placeholder || '',                
                }}
                onChange={(event, editor) => {
                    const data = editor.getData();
                    onChange(data)
                }}
            />
        </div>
    )
}
