import React from 'react'
import { useChess } from '.'
import pw from '../../files/chess/pw.png'
import kw from '../../files/chess/kw.png'
import qw from '../../files/chess/qw.png'
import bw from '../../files/chess/bw.png'
import nw from '../../files/chess/nw.png'
import rw from '../../files/chess/rw.png'
import './prisoners.scss'

export default function WhitePrisoners() {
    const { capturedBlackPieces, capturedWhitePieces } = useChess()
    
    const renderPiece = (piece, index) => {
        let altText = ''
        let source = null

        switch (piece) {
            case 'P':
                altText = 'White pawn'
                source = pw
                break
            case 'K':
                altText = 'White King'
                source = kw
                break
            case 'Q':
                altText = 'White Queen'
                source = qw
                break
            case 'B':
                altText = 'White Bishop'
                source = bw
                break
            case 'N':
                altText = 'White Knight'
                source = nw
                break
            case 'R':
                altText = 'White Rook'
                source = rw
                break
            default:
                break
        }

        return (
            <img src={source} alt={altText} key={`prisoner-${piece}-${index}`} className="prisoner" />
        )
    }

    return (
        <div className="prisoners">
            {capturedWhitePieces.map((piece, index) => renderPiece(piece, index))}
        </div>
    )
}
