import React from 'react'
import { useFriends } from '../Friends/FriendsProvider'
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons'
import { fetcher } from '../../common'
import { useTracker } from '.'
import './tracker-group-sharing.scss'

export default function TrackerGroupSharing({ group }) {
    const { permissions, getPermissions } = useTracker()
    const { friends } = useFriends()
    const checkedBox = <CheckBox className='muted' />
    const uncheckedBox = <CheckBoxOutlineBlank className='muted' />
    const groupPermissions = permissions && group ? permissions.filter(x => x.groupId === group.id) : []
    const friendPermissions = friends.map(friend => {
        let friendPermission = groupPermissions.find(x => x.userId === friend.userId)
        return ({
            id: friendPermission ? friendPermission.id : 0,
            userId: friend.userId,
            firstName: friend.firstName,
            lastName: friend.lastName,
            email: friend.email,
            canRead: friendPermission !== undefined,
            canEdit: friendPermission && friendPermission.canEdit
        })
    })
    
    const handleViewClick = async (id, userId, allowed) => {
        if (allowed === false) {
            let response = await fetcher.del(`tracker/permission/${id}`)
            if (response) {
                getPermissions()
            }
        } else {
            let body = {
                userId: userId,
                groupId: group.id,
                canEdit: false
            }
            let response = await fetcher.post('tracker/permission', body)
            if (response) {
                getPermissions()
            }
        }
    }

    const handleEditClick = async (id, allowed) => {
        let body = {
            id: id,
            canEdit: allowed
        }
        let response = await fetcher.put('tracker/permission', body)
        if (response) {
            getPermissions()
        }
    }

    const rows = friendPermissions.map(x => 
        <tr key={x.userId}>
            <td>{x.firstName} {x.lastName}<br />({x.email})</td>
            <td>
                <div className="clickable" onClick={() => handleViewClick(x.id, x.userId, !x.canRead)}>
                    {x.canRead ? checkedBox : uncheckedBox}                    
                    View
                </div>
            </td>
            <td>
                {x.canRead 
                    ? <div className="clickable" onClick={() => handleEditClick(x.id, !x.canEdit)}>
                        {x.canEdit 
                            ? checkedBox 
                            : uncheckedBox}
                        Edit
                    </div>
                    : null}
            </td>
        </tr>
    )

    return (
        <div className="tracker-group-sharing">
            <table className="sharing-table">
                <tbody>
                    {rows}
                </tbody>
            </table>
        </div>
    )
}
