import React, { useState, useEffect } from 'react'
import { Form, FormGroup, Col, Row, Input, Button, Label, FormFeedback,
    Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, InputGroupText, FormText } from 'reactstrap'
import { dateToString } from '../../utils'
import { Clear } from '@material-ui/icons'
import { fetcher } from '../../common'

export default function DonationDialog({ donation, show, onClose }) {
    const [donorInput, setDonorInput] = useState("")
    const [dateDonatedInput, setDateDonatedInput] = useState("")
    const [dateTransferredInput, setDateTransferredInput] = useState("")
    const [amountInput, setAmountInput] = useState(0)
    const [hasThankYouInput, setHasThankYouInput] = useState(false)
    const [methodInput, setMethodInput] = useState("")
    const [otherMethodInput, setOtherMethodInput] = useState("")
    const [notesInput, setNotesInput] = useState("")
    const [connectionInput, setConnectionInput] = useState("")
    const [hasSubmitted, setHasSubmitted] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const methods = ["PayPal", "Venmo", "Check", "Other"]
    const connections = ["Bradley", "Bekki Work", "Cindy", "Danny", "Hollis", "Jennifer", "Laura"]

    useEffect(() => {
        if (donation) {
            setDonorInput(donation.donor)
            setDateDonatedInput(donation.dateDonated.substring(0,10))
            setDateTransferredInput(donation.dateTransferred ? donation.dateTransferred.substring(0,10) : "")
            setAmountInput(donation.amount)
            setHasThankYouInput(donation.hasThankYou)
            setConnectionInput(donation.connection || "")
            if (["PayPal", "Venmo", "Check"].includes(donation.method)) {
                setMethodInput(donation.method)
            } else {
                setMethodInput("Other")
                setOtherMethodInput(donation.method)
            }
            setNotesInput(donation.notes || "")
        }
    }, [donation])

    const isValid = () => {
        if (!donorInput || !methodInput || !amountInput || !dateDonatedInput) {
            return false
        }
        if (methodInput === "Other" && !otherMethodInput) {
            return false
        }
        return true;
    }

    const handleClose = (triggerRefresh = false) => {
        setHasSubmitted(false)
        onClose(triggerRefresh)
        setDonorInput("")
        setDateDonatedInput("")
        setDateTransferredInput("")
        setAmountInput(0)
        setHasThankYouInput(false)
        setMethodInput("")
        setOtherMethodInput("")
        setNotesInput("")
        setConnectionInput("")
    }

    const createDonation = async () => {
        const request = {
            donor: donorInput,
            amount: parseFloat(amountInput),
            dateDonated: dateDonatedInput,
            dateTransferred: dateTransferredInput || null,
            method: methodInput === "Other" ? otherMethodInput : methodInput,
            hasThankYou: hasThankYouInput,
            notes: notesInput || null,
            connection: connectionInput || null
        }
        const resp = await fetcher.post('trustfund', request)
        if (resp) {
            handleClose(true)
        }
        setIsLoading(false)
    }

    const editDonation = async () => {
        const request = {
            id: donation.id,
            donor: donorInput,
            amount: parseFloat(amountInput),
            dateDonated: dateDonatedInput,
            dateTransferred: dateTransferredInput || null,
            method: methodInput === "Other" ? otherMethodInput : methodInput,
            hasThankYou: hasThankYouInput,
            notes: notesInput || null,
            connection: connectionInput || null
        }
        const resp = await fetcher.put('trustfund', request)
        if (resp) {
            handleClose(true)
        }
        setIsLoading(false)
    }

    const handleSave = (e) => {
        e.preventDefault()
        setHasSubmitted(true)
        if (!isValid()) return;
        setIsLoading(true)
        if (donation) {
            editDonation()
        } else {
            createDonation()
        }

    }

    return (
        <Modal isOpen={show} toggle={handleClose} size="lg">
            <Form onSubmit={handleSave}>
                <ModalHeader toggle={handleClose}>{donation ? "Edit" : "Create"} Donation</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col xs={12} sm={6}>
                            <FormGroup>
                                <Label for="donor">Donor<span className="text-danger">*</span></Label>
                                <Input 
                                    type="text"
                                    id="donor"
                                    placeholder="First and Last Name"
                                    value={donorInput}
                                    onChange={e => setDonorInput(e.target.value)}
                                    maxLength={100}
                                    invalid={hasSubmitted && !donorInput}
                                />
                                <FormFeedback>Donor name is required</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col xs={12} sm={6}>
                            <FormGroup>
                                <Label for="method">Method<span className="text-danger">*</span></Label>
                                <Input
                                    type="select"
                                    id="method"
                                    value={methodInput}
                                    onChange={e => setMethodInput(e.target.value)}
                                    invalid={hasSubmitted && !methodInput}
                                >
                                    <option value={""} disabled>Select a method...</option>
                                    {methods.map(x => <option key={`method-${x}`} value={x}>{x}</option>)}
                                </Input>
                                <FormFeedback>Method is required</FormFeedback>
                            </FormGroup>
                            {methodInput === "Other" && 
                                <FormGroup>
                                    <Label for="otherMethod">Other Method<span className="text-danger">*</span></Label>
                                    <Input 
                                        type="text"
                                        id="otherMethod"
                                        value={otherMethodInput}
                                        onChange={e => setOtherMethodInput(e.target.value)}
                                        invalid={!otherMethodInput && methodInput === "Other"}
                                    />
                                </FormGroup>}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={6}>
                            <FormGroup>
                                <Label for="date">Date Donated<span className="text-danger">*</span></Label>
                                    <Input 
                                        type="date" 
                                        id="date"
                                        value={dateDonatedInput} 
                                        onChange={e => setDateDonatedInput(e.target.value)}
                                        className="mb-1"
                                        invalid={hasSubmitted && !dateDonatedInput}
                                    />
                                    <FormFeedback>Date donated is required</FormFeedback>
                                    <Button
                                        type="button"
                                        color="primary"
                                        outline
                                        onClick={() => setDateDonatedInput(dateToString())}
                                    >
                                        Today
                                    </Button>
                            </FormGroup>
                        </Col>
                        <Col xs={12} sm={6}>
                            <FormGroup>
                                <Label for="amount">Amount<span className="text-danger">*</span></Label>
                                <InputGroup>
                                    <InputGroupText>$</InputGroupText>
                                    <Input 
                                        type="number"
                                        id="amount"
                                        placeholder="0.00"
                                        value={amountInput || ""}
                                        onChange={e => setAmountInput(e.target.value)}
                                        invalid={hasSubmitted && !amountInput}
                                    />
                                    <FormFeedback>Please enter an amount.</FormFeedback>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={6}>
                            <FormGroup>
                                <Label for="connection">Connection</Label>
                                    <Input
                                        type="select"
                                        id="connection"
                                        value={connectionInput}
                                        onChange={e => setConnectionInput(e.target.value)}
                                    >
                                        <option value={""}>Select a connection...</option>
                                        {connections.map(x => <option key={`connection-${x}`} value={x}>{x}</option>)}
                                    </Input>
                                    <FormText>Helpful for managing thank you notes.</FormText>
                            </FormGroup>
                        </Col>
                        <Col xs={12} sm={6}>
                            <FormGroup>
                                <Label check>
                                    <Input 
                                        type="checkbox"
                                        checked={hasThankYouInput}
                                        onChange={() => setHasThankYouInput(x => !x)}
                                    /> Thank you note sent?
                                </Label>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={6}>
                            <FormGroup>
                                <Label for="dateTransferred">Date Transferred</Label>
                                    <Input 
                                        type="date" 
                                        id="dateTransferred"
                                        value={dateTransferredInput} 
                                        onChange={e => setDateTransferredInput(e.target.value)}
                                        className="mb-1"
                                    />
                                    <div>
                                        <Button
                                            type="button"
                                            color="primary"
                                            outline
                                            onClick={() => setDateTransferredInput(dateToString())}
                                        >
                                            Today
                                        </Button>
                                        {dateTransferredInput !== '' && 
                                            <Button
                                                type="button"
                                                color="secondary"
                                                outline
                                                onClick={() => setDateTransferredInput('')}
                                                className="ms-1"
                                            >
                                                <span className="d-flex align-items-center"><Clear className="me-1" />Remove Date</span>
                                            </Button>}
                                    </div>
                            </FormGroup>
                        </Col>
                        <Col xl={6} sm={6}>
                            <FormGroup>
                                <Label for="notes">Notes (optional)</Label>
                                <Input
                                    type="textarea"
                                    id="notes"
                                    placeholder="Notes" 
                                    value={notesInput}
                                    onChange={e => setNotesInput(e.target.value)}
                                    maxLength="200"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="success" type="submit" onClick={handleSave} disabled={isLoading}>{isLoading ? "Saving..." : "Save"}</Button>{' '}
                    <Button color="secondary" onClick={handleClose}>Cancel</Button>
                </ModalFooter>
            </Form>
        </Modal>
    )
}
