import React from 'react'
import { MonthlyControls, useCalendar, CalendarDay } from '.'

export default function Monthly() {
    const { monthArray } = useCalendar()
    const days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']

    return (
        <>
            <MonthlyControls />
            <table>
                <thead>
                    <tr>
                        {days.map(day => <th key={day} className="text-center">{day}</th>)}
                    </tr>
                </thead>
                <tbody>
                    {monthArray.map((week, i) => 
                        <tr key={`week-${i}`}>
                            {week.map((day, j) => 
                                <CalendarDay day={day} key={`week-${i}-${j}`} />
                            )}
                        </tr>
                    )}
                </tbody>
            </table>
        </>
    )
}