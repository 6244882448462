import React from 'react'
import { Link } from '@reach/router'
import { Row, Col } from 'reactstrap'
import dots2a from './2 dots a.png'
import dots2b from './2 dots b.png'
import dots4a from './4 dots a.png'
import dots4b from './4 dots b.png'
import cycle1 from './3c1.png'
import cycle2 from './3c2.png'
import cycle3 from './3c3.png'
import cycle4 from './3c4.png'
import cycle5 from './3c5.png'
import cycle6 from './3c6.png'
import cycle7 from './3c7.png'
import cycle8 from './3c8.png'

export default function Roux6() {
    return (
        <div>
            <h1>Roux Tutorial: Permute M Slice</h1>

            <ul>
                <li><Link to="/cubetools/roux">Introduction</Link></li>
                <li><Link to="/cubetools/roux/1">The First Block</Link></li>
                <li><Link to="/cubetools/roux/2">The Second Block</Link></li>
                <li><Link to="/cubetools/roux/3">CMLL (Top Corners)</Link></li>
                <li><Link to="/cubetools/roux/4">LSE: Edge Orientation</Link></li>
                <li><Link to="/cubetools/roux/5">LSE: Permute UL/UR</Link></li>
                <li>LSE: Permute M Slice</li>
                <li><Link to="/cubetools/roux/7">Example Solves</Link></li>
                <li><Link to="/cubetools/roux/8">Advanced Roux</Link></li>
            </ul>

            <p>There are 3 basic cases for this last step: 2 dots, 4 dots, and 3-edge cycles.</p>

            <h2>2 Dots (Bars)</h2>
            
            <p>These can be solved very quickly.</p>

            <Row>
                <Col className="text-center">
                    <img src={dots2a} alt="" /><br />(U2 M2 U2)
                </Col>
                <Col className="text-center">
                    <img src={dots2b} alt="" /><br />(M2 U2 M2 U2)
                </Col>
            </Row>

            <h2>4 Dots</h2>
            
            <p>This is the awkward case. You can solve it with M and U turns, or you can use the E slice, whichever is faster for you.</p>

            <Row>
                <Col className="text-center">
                    <img src={dots4a} alt="" /><br />(E2 M' E2 M)
                </Col>
                <Col className="text-center">
                    <img src={dots4b} alt="" /><br />(E2 M' E2 M')
                </Col>
            </Row>

            <h2>3-Edge Cycles</h2>

            <p>3-edge cycles are a common case requiring only 4 or 5 turns to solve, but recognizing their best solution takes practice.</p>

            <p>First, find the &quot;unique edge&quot; whose stickers do not align with its neighboring centers in the M slice. Make sure that the unique edge is in the top layer, using M2 if necessary.</p>

            <p>Next, look at the FU and BU stickers. (Actually, to save time, you'll want to track the BU in advance so you already know what it is.) They will be either the same color (e.g. red) or opposite colors (e.g. red and orange).
                <ul>
                    <li>Same color: do (MU2 MU2) or (M'U2 M'U2), whichever keeps the unique edge on top. A final M2 may be required to solve the cube.</li>
                    <li>Opposite color: do (U2 M U2) or (U2 M' U2), whichever keeps the unique edge on top. A final M or M' turn solves the cube.</li>
                </ul>
            </p>

            <p>This can be a challenging step, so here are some cases to help. The solution to 3-edge cycles depends on the color (orange or red) of the BU edge sticker, which I've indicated with O or R.</p>

            <span className="text-primary"><b>FU/BU Same</b></span><br />
            <b>FU/BU Different</b>

            <Row>
                <Col className="text-center">
                    <img src={cycle1} alt="" /><br /><span className="text-primary">O: M'U2M'U2M2</span><br />R: U2MU2M'
                </Col>
                <Col className="text-center">
                    <img src={cycle2} alt="" /><br /><span className="text-primary">O: M'U2MU2M2</span><br />R: M2U2MU2M'
                </Col>
                <Col className="text-center">
                    <img src={cycle7} alt="" /><br />O: U2M'U2M'<br /><span className="text-primary">R: MU2M'U2M2</span>
                </Col>
                <Col className="text-center">
                    <img src={cycle8} alt="" /><br />O: M2U2M'U2M'<br /><span className="text-primary">R: MU2MU2M2</span>
                </Col>
                <Col className="text-center">
                    <img src={cycle3} alt="" /><br />O: U2MU2M<br /><span className="text-primary">R: M'U2M'U2</span>
                </Col>
                <Col className="text-center">
                    <img src={cycle4} alt="" /><br />O: M2U2MU2M<br /><span className="text-primary">R: M'U2MU2</span>
                </Col>
                <Col className="text-center">
                    <img src={cycle5} alt="" /><br /><span className="text-primary">O: MU2M'U2</span><br />R: U2M'U2M
                </Col>
                <Col className="text-center">
                    <img src={cycle6} alt="" /><br /><span className="text-primary">O: MU2MU2</span><br />R: M2U2M'U2M
                </Col>
            </Row>

            <p>Let's bring it all together with some <Link to="/cubetools/roux/7">example solves</Link>.</p>
        </div>
    )
}
