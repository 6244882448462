import React from 'react'
import { SaveTheKitty } from '../modules/Kitty'
import { Router } from '@reach/router'
import GameList from './GameList'
import { ChessModule } from '../modules/Chess'

export default function Games() {
    return (
        <Router>
            <SaveTheKitty path="kitty/*" />
            <ChessModule path="chess/*" />
            <GameList default />
        </Router>
    )
}
