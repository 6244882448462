import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { fetcher, Loading, DataTableNoAuth } from '../../common'
import { Link } from '@reach/router'
import { displayUtcDate } from '../../utils'
import { SelectColumnFilter } from '../../common/DataTableFilters'

export default function BlogPosts() {
    const [posts, setPosts] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const getPosts = useCallback(async () => {
        setIsLoading(true)
        const resp = await fetcher.get('blog/posts/mine')
        if (resp) {
            setPosts(resp)
        }
        setIsLoading(false)
    }, [])

    useEffect(() => {
        getPosts()
    }, [getPosts])

    const columns = useMemo(() => [
        {
            Header: 'Title',
            accessor: 'title'
        },
        {
            Header: 'Date',
            accessor: 'dateCreated',
            disableFilters: true,
            Cell: ({ value }) => displayUtcDate(value)
        },
        {
            Header: 'Tags',
            accessor: 'tags',
            Cell: ({ value }) => value.map(x => x.name).join(', ')
        },
        {
            Header: 'Public?',
            accessor: 'isPublic',
            Cell: ({ value }) => value ? 'Yes' : 'No',
            Filter: SelectColumnFilter,
            filter: 'boolean'
        },
        {
            Header: 'Actions',
            accessor: 'nothingReally',
            disableFilters: true,
            disableSortBy: true,
            Cell: ({ row }) => 
                <div>
                    <Link className="btn btn-primary" to={`/blog/post/${row.original.key}`}>View</Link>
                    <Link className="btn btn-secondary ms-1" to={`/blog/posts/edit/${row.original.id}`}>Edit</Link>                    
                </div>
        }
    ], [])

    if (isLoading) return <Loading />

    return (
        <div>
            <h2>My Blog Posts</h2>
            {posts.length > 0 
                ? <DataTableNoAuth
                    data={posts}
                    columns={columns}
                    sortable
                    filterable
                    paginated={posts.length > 10}
                />
                : <p>No posts found. Write some!</p>
            }
        </div>
    )
}
