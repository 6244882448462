import React, { useState, useCallback, useEffect } from 'react'
import { LineChart, Line, XAxis, YAxis, Tooltip, ReferenceLine, CartesianGrid, ResponsiveContainer } from 'recharts'
import { useTheming } from '../../main/theme/ThemeProvider'
import './tracker-line-graph.scss'

export default function TrackerLineGraph({ data, ylabel, metadata }) {
    const { getThemeColor } = useTheming()
    const [chartData, setChartData] = useState([])

    const getChartData = useCallback(() => {
        if (data && data.length) {
            const updatedChartData = data.map(row => {
                const time = new Date(row.timeUtc)
                return {
                    name: time.getTime(),
                    value: row.value
                }
            })
            setChartData(updatedChartData)
        }
    }, [data])

    useEffect(() => {
        getChartData()
    }, [getChartData])

    if (data === null || !data.length) {
        return null
    }

    const renderTooltip = ({ active, payload, label }) => {
        if (active && payload && payload[0]) {
            let date = new Date(payload[0].payload.name)
            if (!date) return null
            let time = date.toLocaleString('en', {hour: 'numeric', minute: '2-digit'})
            return (
                <div className="custom-tooltip">
                    <table>
                        <tbody>
                            <tr>
                                <th>Date</th>
                                <td>{date.toLocaleDateString()}</td>
                            </tr>
                            <tr>
                                <th>Time</th>
                                <td>{time}</td>
                            </tr>
                            <tr>
                                <th>Amount</th>
                                <td>{payload[0].payload.value} {ylabel}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )
        }
        return null
    }

    return (
        <div className="tracker-line-graph">
            <ResponsiveContainer width='100%' height={300}>
                <LineChart width={400} height={300} data={chartData}>
                    <CartesianGrid strokeDasharray="3 3" stroke={getThemeColor('bs-gray-400')} />
                    <XAxis 
                        dataKey="name"
                        stroke={getThemeColor('bs-body-color')}
                        height={50}
                        scale="time" 
                        type="number"
                        domain={['dataMin', 'dataMax']}
                        angle={-25}
                        textAnchor="end"
                        tickFormatter={unixTime => {
                            let date = new Date(unixTime)
                            return date.toLocaleString('en', {month: 'numeric', day: 'numeric'})
                        }}
                    />
                    <YAxis 
                        width={40}
                        stroke={getThemeColor('bs-body-color')}
                        //label={{ value: ylabel, position: 'insideLeft', fill: getThemeColor('text') }}
                        domain={metadata && metadata.minY ? [metadata.minY, 'auto'] : [0, 'auto']}
                    />
                    <Tooltip content={renderTooltip} />
                    {metadata && metadata.referenceValues ?
                        metadata.referenceValues.map((v, i) =>
                            <ReferenceLine
                                key={`ref-value-line-${i}`}
                                y={v.value}
                                stroke={v.color}
                                strokeDasharray="4 4"
                                label={{ value: v.label, fill: v.color }}
                                ifOverflow={v.alwaysShow ? "extendDomain" : null}
                            />
                        )
                        : null}
                    {metadata && metadata.referenceTimes ?
                        metadata.referenceTimes.map((v, i) =>
                            <ReferenceLine
                                key={`ref-time-line-${i}`}
                                x={new Date(v.value).getTime()}
                                stroke={v.color}
                                strokeDasharray="4 4"
                                label={{ value: v.label, fill: v.color }}
                                ifOverflow={v.alwaysShow ? "extendDomain" : null}
                            />
                        )
                        : null}
                    <Line type="monotone" dataKey="value" stroke={getThemeColor('bs-primary')} />
                </LineChart>
            </ResponsiveContainer>
        </div>
    )
}