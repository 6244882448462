import React from 'react'
import { Link } from '@reach/router'

export default function GameList() {
    return (
        <div>
            <h1>Games</h1>
            <ul>
                <li><Link to="/games/kitty/play">Save the Kitty</Link></li>
                <ul>
                    <li><Link to="/games/kitty/scores">High scores</Link></li>
                </ul>
                <li><Link to="/games/chess">Chess</Link></li>
            </ul>
        </div>
    )
}
