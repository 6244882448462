import React, { useEffect, useState } from 'react'
import { PlayCircleFilled, PauseCircleFilled, ArrowBackIos, ArrowForwardIos } from '@material-ui/icons'
import { useMusic } from '.'
import { Loading } from '../../common'
import { getExtension } from '../../utils'
import { Button } from 'reactstrap'
import './audio-player.scss'

export default function AudioPlayer() {
    const { playBtn, pauseBtn, isPlaying, audioPlayer, activePiece, nextPiece, prevPiece, activePieceCollection, 
        activePieceUrl, isLoadingPiece, mode, activePlaylistTitle, setIsPlaying, activePieceIndex } = useMusic()
    const [currentTime, setCurrentTime] = useState(0)
    const [duration, setDuration] = useState(0)

    useEffect(() => {
        const player = audioPlayer.current
        if (player) {
            audioPlayer.current.addEventListener('timeupdate', e => {
                setCurrentTime(e.target.currentTime)
                setDuration(e.target.duration)
            })
        }
        return () => {
            if (player) {
               player.removeEventListener('timeupdate', () => {})
            }
        }
    }, [audioPlayer])

    function getTime(time) {
        if (!isNaN(time)) {
            return Math.floor(time / 60) + ':' + ('0' + Math.floor(time % 60)).slice(-2)
        }
        return '0:00'
    }

    const play = async () => {
        if (audioPlayer.current) {
            await audioPlayer.current.play()
            setIsPlaying(true)
        } else if (audioPlayer) {
            await audioPlayer.play()
            setIsPlaying(true)
        }
    }

    const pause = () => {
        if (audioPlayer.current) {
            audioPlayer.current.pause()
            setIsPlaying(false)
        }
    }

    const getPieceType = () => {
        if (activePiece && activePiece.path) {
            const extension = getExtension(activePiece.path)
            if (extension === 'ogg') {
                return 'audio/ogg'
            }
        } 
        return 'audio/mpeg'
    }

    useEffect(() => {
        if (mode === 'playlist' && isPlaying && currentTime === duration && currentTime > 0) {
            const player = document.getElementById('appAudioPlayer')
            if (player) {
                player.pause()
                setIsPlaying(false)
                player.currentTime = 0
                const nextPieceBtn = document.getElementById('nextPieceBtn')
                if (nextPieceBtn) {
                    nextPieceBtn.click()
                }
            }
        }
    }, [currentTime, mode, duration, nextPiece, isPlaying, setIsPlaying, audioPlayer])

    return (
        <div className="audio-player">
            {mode === 'playlist' && activePlaylistTitle && 
                <div>
                    {activePlaylistTitle} ({activePieceIndex + 1}/{activePieceCollection.length})
                </div>}
            {!isLoadingPiece && (!activePiece || !activePieceUrl)
                ? null
                : <div>
                    {activePieceCollection.length > 0 && mode === 'playlist' &&
                        <Button outline onClick={prevPiece} color="dark">
                            <ArrowBackIos className="arrow-btn" />
                        </Button>}                    
                    {isPlaying && currentTime !== duration
                        ? <PauseCircleFilled className="play-pause-btn" onClick={pause} ref={pauseBtn} /> 
                        : <PlayCircleFilled className="play-pause-btn" onClick={play} ref={playBtn} />
                    }
                    {activePieceCollection.length > 0 && mode === 'playlist' &&
                        <Button outline onClick={nextPiece} id="nextPieceBtn" color="dark">
                            <ArrowForwardIos className="arrow-btn" />
                        </Button>}
                </div>}
            <audio crossOrigin="anonymous" ref={audioPlayer} preload="metadata" id="appAudioPlayer">
                {activePieceUrl ? <source src={activePieceUrl} type={getPieceType()} /> : null}
                Your browser is lame.
            </audio>
            {activePiece ?
                <div className="title">
                    {activePiece.name}
                </div> : null}
            {!isLoadingPiece && activePiece && <div>{getTime(currentTime)} / {getTime(duration)}</div>}
            {isLoadingPiece ? <Loading /> : null}
        </div>
    )
}
