import React from 'react'
import logo from '../files/squarenote.png'
import './home.scss'

export default function Home() {
    return (
        <div className="main-container home text-center">
            <h1>Welcome to SquareNote!</h1>
            <p>
                <a href="https://squarenote.azurewebsites.net">Check out the new site design in progress.</a>
            </p>
            <img src={logo} alt="SquareNote logo" className="logo" />
        </div>
    )
}
