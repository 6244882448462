import React from 'react'
import { Link } from '@reach/router'
import { Card, CardBody, Col, Row } from 'reactstrap'
import eoExample from './eo example1.png'
import eoExample2 from './eo example2.png'
import arrow from '../lbl/arrow.png'
import lse1 from './lse o1 all bad.png'
import lse2 from './lse o2 bottom pair.png'
import lse3 from './lse o3 arrow f.png'
import lse4 from './lse o4 neighbors.png'
import lse5 from './lse o5 cradle.png'
import lse6 from './lse o6 cross.png'
import lse7 from './lse o7 diagonal b.png'
import lse8 from './lse o8 top pair.png'
import lse9 from './lse o9 stinger.png'
import lse10 from './lse o10 arrow b.png'
import lse11 from './lse o11 diagonal f.png'

export default function Roux4() {
    return (
        <div>
            <h1>Roux Tutorial: Orient Last Six Edges</h1>

            <ul>
                <li><Link to="/cubetools/roux">Introduction</Link></li>
                <li><Link to="/cubetools/roux/1">The First Block</Link></li>
                <li><Link to="/cubetools/roux/2">The Second Block</Link></li>
                <li><Link to="/cubetools/roux/3">CMLL (Top Corners)</Link></li>
                <li>LSE: Edge Orientation</li>
                <li><Link to="/cubetools/roux/5">LSE: Permute UL/UR</Link></li>
                <li><Link to="/cubetools/roux/6">LSE: Permute M Slice</Link></li>
                <li><Link to="/cubetools/roux/7">Example Solves</Link></li>
                <li><Link to="/cubetools/roux/8">Advanced Roux</Link></li>
            </ul>

            <p>All that remains is to solve the last six edges (LSE). We can generally do this turning only the M slice and the U slice, which helps our speed considerably. A common approach is for the left fingers to control U - primarily the index finger but also the middle finger for U2 turns - while the right fingers control M - primarily the ring finger for M', the middle finger for M, and both for M2.</p>

            <p>In this step, we will orient the last six edges (the 4 on top plus the bottom 2 between the blocks).</p>

            <h2>Taking Inventory</h2>

            <Row>
                <Col md={6} sm={12}>
                    <p>First, turn the M slice so that the white or yellow center is facing up. Only then can we easily see if an edge is oriented (good) or disoriented (bad).</p>

                    <p>Second, count the bad edges:
                        <ul>
                            <li><strong>An edge is oriented correctly if its yellow or white sticker is on the top or bottom of the cube.</strong> Otherwise, it is a bad edge that needs to be flipped. In other words, when all the edges are oriented, the top and the bottom of the cube will have only yellow or white stickers. Edges are always flipped in pairs, so there will be 0, 2, 4, or 6 bad edges.</li>
                            <li><strong>Don't look at the DB edge.</strong> Five of the six edges are immediately visible, so the state of the hidden DB edge can be inferred without rotating the cube to look at it. If there is an odd number of bad edges visible, DB is also bad. If there is an even number of bad edges visible, DB is good.</li>
                        </ul>
                    </p>
                </Col>
                <Col md={6} sm={12}>
                    <Card>
                        <CardBody>
                            <div className="text-center">
                                <img src={eoExample} alt="" />
                            </div>
                        </CardBody>
                        <CardBody>
                            In this example, there are 3 bad edges on the top (UL, UF, UR). The UB and DF edges are good. Since the total number of bad edges must be even, then we know that the DB edge is bad even without seeing it.
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <h2>Arrows and the Burden of Freedom</h2>

            <p>Orienting the edges is a flexible process. If you like, you can simply memorize the 11 cases and some algorithms that will solve them (chart below), and move on. However, if you understand how these algorithms work, it will help you solve the LSE more efficiently.</p>

            <p>The case that is easiest to solve is an Arrow case: 3 bad edges on the top and 1 bad edge on the bottom. It is solved in just three moves.</p>

            <Row className="mb-2">
                <Col className="text-center">
                    <img src={lse3} alt="" /><br />Front Arrow<br />(M'U'M')
                </Col>
                <Col className="text-center">
                    <img src={lse10} alt="" /><br />Back Arrow<br />(MU'M')
                </Col>
            </Row>

            <p>When you get an Arrow, the bad bottom edge can be on the front or the back. In either case, you turn the U slice so that the top &quot;arrow&quot; points to the bad bottom edge, bring the bad bottom edge up with M or M', then orient all edges with any U turn move followed by any M turn.
                <ul>
                    <li>A Front Arrow can be solved (M'U'M') or (M'UM') or a similar sequence that begins with M'.</li>
                    <li>A Back Arrow can be solved with (MU'M') or a similar sequence that begins with M.</li>
                </ul>
            </p>

            <h2>Arrow Example</h2>

            <p>In our earlier example, we have a Back Arrow case which can be solved (U2 MU'M'). The result is shown below.</p>

            <Row className="mb-2">
                <Col className="text-end">
                    <img src={eoExample} alt="" />
                </Col>
                <Col className="text-center">
                    <img src={arrow} alt="" /><br />(U2 MU'M')
                </Col>
                <Col className="text-start">
                    <img src={eoExample2} alt="" />
                </Col>
            </Row>

            <p>Our five visible edges are all good, so we know the DB edge is also good. This completes edge orientation.</p>

            <h2>Other Cases</h2>

            <p>We solve the other cases by cycling through the various cases until we get an Arrow case. We use two algorithmic tools to do this:

            <ul>
                <li>(M'UM') flips the orientation of the UL, UF, UR, and DF edges</li>
                <li>(M'U2M) switches the position of the UF and DF edges</li>
            </ul>

            These tools are the building blocks of algorithms used to solve edge orientation. The chart below lists the 11 cases for edge orientation, along with the cycles and algorithms for each case.</p>

            <h2>Edge Orientation Cases</h2>

            <p>After counting the bad edges, turn the U layer until your edges match one of the 11 cases below. The purple edges are bad.</p>

            <Row>
                <Col md={4} sm={6}>
                    <img src={lse5} alt="" /><br />Cradle
                    <ul>
                        <li>M'U2M' cycles to Back Arrow</li>
                        <li>Algorithm: (MU2)(M'U2)(M'U'M')</li>
                    </ul>
                </Col>
                <Col md={4} sm={6}>
                    <img src={lse6} alt="" /><br />Cross
                    <ul>
                        <li>M'U2M' cycles to Front Arrow</li>
                        <li>Algorithm: (M'U2)(M'U2)(M'U'M')</li>
                    </ul>
                </Col>
                <Col md={4} sm={6}>
                    <img src={lse9} alt="" /><br />Stinger
                    <ul>
                        <li>M2 cycles to Front Arrow</li>
                        <li>Algorithm: (M2U')(M'U'M')</li>
                    </ul>
                </Col>
                <Col md={4} sm={6}>
                    <img src={lse4} alt="" /><br />Neighbors
                    <ul>
                        <li>M'UM' cycles to Front Arrow</li>
                        <li>Algorithm: (M'UM') U2 (M'U'M')<br />Alt: U (M'U'M') U2 (M'U'M')</li>
                    </ul>
                </Col>
                <Col md={4} sm={6}>
                    <img src={lse11} alt="" /><br />Front Diagonal
                    <ul>
                        <li>MU'M cycles to Front Arrow</li>
                        <li>Algorithm: (M'U'M) U' (M'U'M')</li>
                    </ul>
                </Col>
                <Col md={4} sm={6}>
                    <img src={lse7} alt="" /><br />Back Diagonal
                    <ul>
                        <li>M'UM' cycles to Back Arrow</li>
                        <li>Algorithm: (M'U'M') U' (MU'M')</li>
                    </ul>
                </Col>
                <Col md={4} sm={6}>
                    <img src={lse8} alt="" /><br />Top Pair
                    <ul>
                        <li>M'UM' cycles to Stinger</li>
                        <li>Algorithm: (M'UM) U' (M'U'M')<br />Alt: (RUR'U') M' (URU'r')</li>
                    </ul>
                </Col>
                <Col md={4} sm={6}>
                    <img src={lse2} alt="" /><br />Bottom Pair
                    <ul>
                        <li>M'UM' cycles to Back Arrow</li>
                        <li>Algorithm: (M'UM') U' (MU'M')<br />Alt: (M'U'M') U (MU'M')</li>
                    </ul>
                </Col>
                <Col md={4} sm={6}>
                    <img src={lse1} alt="" /><br />All Bad
                    <ul>
                        <li>M'UM' cycles to Front Diagonal</li>
                        <li>Algorithm: (M'U'M') U2 (M'U'M) U' (M'U'M')<br />Alt: (RU'r'U') M' (UrUr')</li>
                    </ul>
                </Col>
                <Col md={4} sm={6}>
                    <img src={lse3} alt="" /><br />Front Arrow
                    <ul>
                        <li>M'UM' solves</li>
                        <li>Algorithm: (M'U'M')</li>
                    </ul>
                </Col>
                <Col md={4} sm={6}>
                    <img src={lse10} alt="" /><br />Back Arrow
                    <ul>
                        <li>MU'M' solves</li>
                        <li>Algorithm: (MU'M')</li>
                    </ul>
                </Col>
            </Row>

            <p>Edge orientation is complete when the top and bottom of the cube have only yellow or white stickers. You are then ready to start permuting the last six edges, starting with <Link to="/cubetools/roux/5">UL and UR</Link>.</p>

        </div>
    )
}
