import React from 'react'
import { WhitePrisoners, BlackPrisoners, ChessboardSquare, PawnPromotionDialog, useChess } from '.'
import { Card } from '../../common'
import './chessboard.scss'

export default function Chessboard() {
    const { showCoords, view, pawnPromotionSquare } = useChess()

    const files = view === 'white' 
        ? ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h'] 
        : ['h', 'g', 'f', 'e', 'd', 'c', 'b', 'a'] 

    const getCoord = rank => {
        return view === 'white' ? rank : 9 - rank
    } 

    return (
        <Card>
            {view === 'white' ? <WhitePrisoners /> : <BlackPrisoners />}
            <table className="chessboard">
                <tbody>
                    {showCoords && 
                        <tr>
                            <th />
                            {files.map(file => <td key={`coord-${file}`} className="coord-file">{file}</td>)}
                        </tr>
                    }
                    <tr>
                        {showCoords && <td className="coord-rank">{getCoord(8)}</td>}
                        <ChessboardSquare name={view === 'white' ? 'a8' : 'h1'} />
                        <ChessboardSquare name={view === 'white' ? 'b8' : 'g1'} />
                        <ChessboardSquare name={view === 'white' ? 'c8' : 'f1'} />
                        <ChessboardSquare name={view === 'white' ? 'd8' : 'e1'} />
                        <ChessboardSquare name={view === 'white' ? 'e8' : 'd1'} />
                        <ChessboardSquare name={view === 'white' ? 'f8' : 'c1'} />
                        <ChessboardSquare name={view === 'white' ? 'g8' : 'b1'} />
                        <ChessboardSquare name={view === 'white' ? 'h8' : 'a1'} />
                        {showCoords && <td className="coord-spacer" />}
                    </tr>
                    <tr>
                        {showCoords && <td className="coord-rank">{getCoord(7)}</td>}
                        <ChessboardSquare name={view === 'white' ? 'a7' : 'h2'} />
                        <ChessboardSquare name={view === 'white' ? 'b7' : 'g2'} />
                        <ChessboardSquare name={view === 'white' ? 'c7' : 'f2'} />
                        <ChessboardSquare name={view === 'white' ? 'd7' : 'e2'} />
                        <ChessboardSquare name={view === 'white' ? 'e7' : 'd2'} />
                        <ChessboardSquare name={view === 'white' ? 'f7' : 'c2'} />
                        <ChessboardSquare name={view === 'white' ? 'g7' : 'b2'} />
                        <ChessboardSquare name={view === 'white' ? 'h7' : 'a2'} />
                    </tr>
                    <tr>
                        {showCoords && <td className="coord-rank">{getCoord(6)}</td>}
                        <ChessboardSquare name={view === 'white' ? 'a6' : 'h3'} />
                        <ChessboardSquare name={view === 'white' ? 'b6' : 'g3'} />
                        <ChessboardSquare name={view === 'white' ? 'c6' : 'f3'} />
                        <ChessboardSquare name={view === 'white' ? 'd6' : 'e3'} />
                        <ChessboardSquare name={view === 'white' ? 'e6' : 'd3'} />
                        <ChessboardSquare name={view === 'white' ? 'f6' : 'c3'} />
                        <ChessboardSquare name={view === 'white' ? 'g6' : 'b3'} />
                        <ChessboardSquare name={view === 'white' ? 'h6' : 'a3'} />
                    </tr>
                    <tr>
                        {showCoords && <td className="coord-rank">{getCoord(5)}</td>}
                        <ChessboardSquare name={view === 'white' ? 'a5' : 'h4'} />
                        <ChessboardSquare name={view === 'white' ? 'b5' : 'g4'} />
                        <ChessboardSquare name={view === 'white' ? 'c5' : 'f4'} />
                        <ChessboardSquare name={view === 'white' ? 'd5' : 'e4'} />
                        <ChessboardSquare name={view === 'white' ? 'e5' : 'd4'} />
                        <ChessboardSquare name={view === 'white' ? 'f5' : 'c4'} />
                        <ChessboardSquare name={view === 'white' ? 'g5' : 'b4'} />
                        <ChessboardSquare name={view === 'white' ? 'h5' : 'a4'} />
                    </tr>
                    <tr>
                        {showCoords && <td className="coord-rank">{getCoord(4)}</td>}
                        <ChessboardSquare name={view === 'white' ? 'a4' : 'h5'} />
                        <ChessboardSquare name={view === 'white' ? 'b4' : 'g5'} />
                        <ChessboardSquare name={view === 'white' ? 'c4' : 'f5'} />
                        <ChessboardSquare name={view === 'white' ? 'd4' : 'e5'} />
                        <ChessboardSquare name={view === 'white' ? 'e4' : 'd5'} />
                        <ChessboardSquare name={view === 'white' ? 'f4' : 'c5'} />
                        <ChessboardSquare name={view === 'white' ? 'g4' : 'b5'} />
                        <ChessboardSquare name={view === 'white' ? 'h4' : 'a5'} />
                    </tr>
                    <tr>
                        {showCoords && <td className="coord-rank">{getCoord(3)}</td>}
                        <ChessboardSquare name={view === 'white' ? 'a3' : 'h6'} />
                        <ChessboardSquare name={view === 'white' ? 'b3' : 'g6'} />
                        <ChessboardSquare name={view === 'white' ? 'c3' : 'f6'} />
                        <ChessboardSquare name={view === 'white' ? 'd3' : 'e6'} />
                        <ChessboardSquare name={view === 'white' ? 'e3' : 'd6'} />
                        <ChessboardSquare name={view === 'white' ? 'f3' : 'c6'} />
                        <ChessboardSquare name={view === 'white' ? 'g3' : 'b6'} />
                        <ChessboardSquare name={view === 'white' ? 'h3' : 'a6'} />
                    </tr>
                    <tr>
                        {showCoords && <td className="coord-rank">{getCoord(2)}</td>}
                        <ChessboardSquare name={view === 'white' ? 'a2' : 'h7'} />
                        <ChessboardSquare name={view === 'white' ? 'b2' : 'g7'} />
                        <ChessboardSquare name={view === 'white' ? 'c2' : 'f7'} />
                        <ChessboardSquare name={view === 'white' ? 'd2' : 'e7'} />
                        <ChessboardSquare name={view === 'white' ? 'e2' : 'd7'} />
                        <ChessboardSquare name={view === 'white' ? 'f2' : 'c7'} />
                        <ChessboardSquare name={view === 'white' ? 'g2' : 'b7'} />
                        <ChessboardSquare name={view === 'white' ? 'h2' : 'a7'} />
                    </tr>
                    <tr>
                        {showCoords && <td className="coord-rank">{getCoord(1)}</td>}
                        <ChessboardSquare name={view === 'white' ? 'a1' : 'h8'} />
                        <ChessboardSquare name={view === 'white' ? 'b1' : 'g8'} />
                        <ChessboardSquare name={view === 'white' ? 'c1' : 'f8'} />
                        <ChessboardSquare name={view === 'white' ? 'd1' : 'e8'} />
                        <ChessboardSquare name={view === 'white' ? 'e1' : 'd8'} />
                        <ChessboardSquare name={view === 'white' ? 'f1' : 'c8'} />
                        <ChessboardSquare name={view === 'white' ? 'g1' : 'b8'} />
                        <ChessboardSquare name={view === 'white' ? 'h1' : 'a8'} />
                    </tr>
                    {showCoords && 
                        <tr>
                            <th />
                            {files.map(file => <td key={`coord-${file}-bottom-row`} className="coord-spacer" />)}
                        </tr>
                    }
                </tbody>
            </table>
            {view === 'white' ? <BlackPrisoners /> : <WhitePrisoners />}
            {pawnPromotionSquare !== null && <PawnPromotionDialog />}
        </Card>
    )
}
