import React from 'react'
import { Router } from '@reach/router'
import { Recipes, WhiskMenu, RecipeDialog, RecipeView } from '.'

export default function WhiskModule() {
    return (
        <div className="module-container">
            <WhiskMenu />
            <div className="main-container with-side-menu">
                <Router>
                    <RecipeDialog path="new" />
                    <RecipeView path=":recipeId" />
                    <RecipeDialog path=":recipeId/:mode" />
                    <Recipes default />
                </Router>
            </div>
        </div>
    )
}
