import React from 'react'
import { Table } from 'reactstrap'

export default function Triggers() {
    return (
        <div>
            <h1>Common Triggers</h1>

            <p>Triggers are mini-algorithms that are commonly used. Naming them can make it easier to learn the algorithms that use them.</p>

            <Table striped>
                <thead>
                    <tr>
                        <th>Trigger</th>
                        <th>Name</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>RUR'U'</td>
                        <td>Sexy Move</td>
                    </tr>
                    <tr>
                        <td>L'U'LU</td>
                        <td>Sexy Move mirrored</td>
                    </tr>
                    <tr>
                        <td>RUR'URU2R'</td>
                        <td>Sune (&quot;soo-nay&quot;)</td>
                    </tr>
                    <tr>
                        <td>R'U'RU'R'U2R</td>
                        <td>Anti-Sune</td>
                    </tr>
                    <tr>
                        <td>R'FRF'</td>
                        <td>Sledgehammer</td>
                    </tr>
                    <tr>
                        <td>LF'L'F</td>
                        <td>Sledgehammer mirrored</td>
                    </tr>
                    <tr>
                        <td>FR'F'R</td>
                        <td>Reversed Sledgehammer, a.k.a. Hedgeslammer</td>
                    </tr>
                    <tr>
                        <td>RUR'F'</td>
                        <td>Lasso*</td>
                    </tr>
                    <tr>
                        <td>R'FR2U'</td>
                        <td>Rebound*</td>
                    </tr>
                    <tr>
                        <td>RUR'U</td>
                        <td>Half Sune</td>
                    </tr>
                    <tr>
                        <td>R'U'RU'</td>
                        <td>Half Anti-Sune</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>*My own label.</td>
                    </tr>
                </tbody>
            </Table>

            <p>
                These can help you use a memorization technique called chunking. For example, a Y-Permutation from PLL...
                <blockquote className="mt-2 ms-4">FRU'R'U'RUR'F'RUR'U'R'FRF'</blockquote>
                ...is 17 pieces of information. But when you think in terms of triggers, it becomes just 8 or even 5 pieces of information to remember:
                <blockquote className="mt-2 ms-4">F (RU'R'U') Lasso Sexy Sledgehammer</blockquote>
            </p>
        </div>
    )
}
