import React, { useEffect } from 'react'
import { Link, navigate } from '@reach/router'
import { Loading, Card } from '../../common'
import { useAuth } from './AuthProvider'
import './signup.scss'

export default function Signup() {
    const { user, isLoadingUser, isLoading } = useAuth()

    useEffect(() => {
        if (!isLoadingUser && user)
            navigate('/dashboard')
    }, [user, isLoadingUser])

    if (isLoading || isLoadingUser)
        return <Loading />

    return (
        <div className="main-container signup">
            <div className="signup-container">
                <Card>
                    <p>You can request an account by contacting me on <a href="https://www.linkedin.com/in/bradleykh/" target="_blank" rel="noopener noreferrer">LinkedIn</a>.</p>
                    <p>One day, there will be a fancy form for you to fill out instead. :)</p>
                    <p>Already have an account? <Link to="/login">Sign in!</Link></p>
                </Card>
            </div>
        </div>
    )
}
