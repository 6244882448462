/*
u user
m monster
k kitty
w weapon
* hole
= wall

Constraints:
   Level must be square (same number of rows and columns)
   Level should have one user, one weapon, at least one kitty, and at least one monster

Level Design:
  1-10: Bradley Harris
  everything else: John Culpepper
*/

const level1 = 
[
 'w=      ',
 ' =  k ==',
 '        ',
 '==      ',
 '        ',
 '       =',
 '   =    ',
 'u  =   m'
];

const level2 = 
[
 '        ',
 '  *   k ',
 'w       ',
 '    m * ',
 '        ',
 '   *    ',
 ' =======',
 '       u'
];

const level3 =
[
 '        ',
 ' === == ',
 ' =    = ',
 ' =w   = ',
 'k=    =u',
 ' =m   = ',
 ' == === ',
 '        '
];

const level4 =
[
 'u     =w',
 '    m = ',
 '      = ',
 '      = ',
 '      = ',
 '     k= ',
 ' ====== ',
 '        ',
];

const level5 =
[
 '       u',
 ' =====  ',
 ' =   =  ',
 ' = = =  ',
 ' = =w= m',
 ' = ===  ',
 ' =      ',
 '   k   =',
];

const level6 =
[
 '========',
 '=*u m *=',
 '=  *   =',
 '= ***  =',
 '= **** =',
 '=   *  =',
 '=*k  w*=',
 '========',
];

const level7 =
[
 '========',
 '========',
 '=m    u=',
 '= *=*  =',
 '= =*=  =',
 '=w    k=',
 '========',
 '========',
];

const level8 =
[
 '========',
 '========',
 '==u  *==',
 '== k  ==',
 '==  * ==',
 '==  mw==',
 '========',
 '========',
];

const level9 =
[
 '        ',
 ' ====== ',
 ' =   w= ',
 ' = == = ',
 ' =    = ',
 ' =   m= ',
 ' == === ',
 ' k    u '
];

const level10 =
[
 '  w* *  ',
 ' *    * ',
 ' m  *   ',
 '   *  k ',
 '  *    *',
 '*    *  ',
 ' *  * * ',
 '*   u  *',
];

const level11 =
[
 'u  =  k ',
 ' *   = =',
 '  = *   ',
 ' *  = = ',
 '   *==  ',
 ' = =   =',
 '  m  =  ',
 ' = = = w',
];

const level12 =
[
 '   *  u ',
 ' k      ',
 '    *   ',
 '  *   * ',
 '*   *   ',
 '  m*   *',
 ' *    * ',
 ' w*  *  ',
];

const level13 =
[
 'u       ',
 '***  *  ',
 '    **  ',
 '   k    ',
 ' ** *  *',
 '   *m   ',
 '    * * ',
 '  *    w',
];

const level14 =
[
 '        ',
 ' *===*= ',
 '  k   = ',
 '= *   = ',
 '  *   = ',
 ' =m   =w',
 '  *   ==',
 '*   *  u',
];

const level15 =
[
 '*    *  ',
 ' w=     ',
 '=== *   ',
 '        ',
 ' *  *m *',
 '        ',
 '      * ',
 'k  *   u',
];

const level16 =
[
 '   *   *',
 ' *   *  ',
 '   m  *w',
 '   *   *',
 'k       ',
 ' *   *  ',
 '   u    ',
 '        ',
];

const level17 =
[
 '        ',
 ' ** **  ',
 '  *w*   ',
 '* ***  *',
 '        ',
 ' * m *  ',
 '      k ',
 ' u  *   ',
];

const level18 =
[
 '    =   ',
 ' w=   = ',
 '   =  k ',
 ' =   =  ',
 '  = m  =',
 '=   =   ',
 '  =   = ',
 '    =  u',
];

const level19 =
[
 ' =  u  =',
 'k  = =  ',
 '   m    ',
 '***   * ',
 'w*  *   ',
 ' *      ',
 ' * *    ',
 '       *',
];

const level20 =
[
 '     *   *  ',
 '  = *  *    ',
 ' =*   * *   ',
 '  ======== =',
 '* *         ',
 '  =      m* ',
 ' =      *   ',
 ' =  = =   * ',
 ' =*  k      ',
 ' =   =  =  *',
 ' = =        ',
 'w=u   *     ',
];

const level21 =
[
 '**   w    **',
 '   *****    ',
 ' **     **  ',
 '    =   =  *',
 '  =   =   = ',
 '= m =   =   ',
 '  =   = m = ',
 '=   =   =   ',
 '  =   =   = ',
 '= m =   = k=',
 '  =   =   = ',
 '=   = u =   ',
];

const level22 =
[
 '      =     =w=',
 '= ===   ===    ',
 '    m =   m = =',
 ' == = == ==== =',
 '    =          ',
 '= =   = k == ==',
 '= === =        ',
 '  m   ==== === ',
 ' = ==   =      ',
 ' =  === = == ==',
 '  = =     m    ',
 '= =m  === = == ',
 '  ===   = =    ',
 ' =   ==   === =',
 'u  =    =     =',
];

const level24 =
[
 '====      =    ',
 '     == = m ===',
 'w= =  = ===    ',
 '== ==     == = ',
 '  m   = = =  = ',
 ' ==== = =   ===',
 ' =    =   =    ',
 ' = ===k === == ',
 '      =        ',
 '= === === == ==',
 '  m        m  =',
 '=== === ===== =',
 '    =       = =',
 ' =    === = = =',
 ' m===  u  =    ',
];

const level25 =
[
 '=    = k=  u',
 '  = =     = ',
 ' =m    = =  ',
 '    = =    =',
 ' = =     =  ',
 '=     = =   ',
 '   = =     =',
 '= =m    = = ',
 '     = =    ',
 '  = =   m = ',
 ' =     = =  ',
 'w   = =    =',
];

const level26 =
[
 '=   = =   =k',
 '= = = = = = ',
 '= =   = = = ',
 'm = = = = = ',
 '=w= =   =   ',
 '= = = = = = ',
 '=   = = =m= ',
 '= =m=   = =u',
 '  = = = = = ',
 '= = = =m= = ',
 '= =   = =   ',
 '= = = =   = ',
];

const level27 =
[
 ' =   =   =  ',
 '   = k =   =',
 ' =   =   =  ',
 '   =   =   =',
 ' =   =   =  ',
 ' m =   = m =',
 '= = =u= =   ',
 '  =  =  =  =',
 '   =   =    ',
 ' =  = =  = =',
 '     =      ',
 ' = = w = = =',
];

const level28 =
[
 'k          u    ',
 '======= ====== =',
 '           m    ',
 '== ====== ======',
 '      m         ',
 ' ====== ===== ==',
 '                ',
 '===== == =======',
 '         m      ',
 ' == ========= = ',
 '                ',
 '= ===== ========',
 '                ',
 '==== === ==== ==',
 '                ',
 '=m=== = =====m=w',
];

const level29 =
[
 '==     =u  =    ',
 '   ===   = = == ',
 ' =   = ===    = ',
 ' = =       == = ',
 '   =m===== m    ',
 '== =   =   = = =',
 '=  ===   === =  ',
 '= ==   =      = ',
 '     ===== == = ',
 ' === = m   =    ',
 '   =   ===   ===',
 '== == ==   k   =',
 '  m =    ===== =',
 ' == = ==   m =  ',
 ' =     === =  = ',
 ' w ===     ==   ',
];

const level34 =
[
'              k',
' =========== = ',
' =    m      = ',
' = ========= = ',
' =     m   = = ',
' = = === = = = ',
' = = =   = = = ',
' = = = w = = = ',
' = = =   = = = ',
' = = = === = = ',
' = =    m    = ',
' = ========= = ',
' =           = ',
' = =========== ',
'u     m        '
];

const level37 =
[
'               ',
'  ====   ====  ',
'      = =      ',
'   =   w   =   ',
' ==*==   ==*== ',
'   =   k   =   ',
'       =       ',
'               ',
'    =======    ',
'  =mmmmmmmmm=  ',
'   =mmmmmmm=   ',
'     mmmmm     ',
'     =====     ',
'       u       ',
];

const level44 =
[
'm   =    =    ',
' w  =      m  ',
'         =    ',
'    = m  =    ',
'=========== ==',
'    =    =    ',
'         =    ',
' m  = m  =  m ',
'    =         ',
'=== ==========',
'm   =        m',
'    =    =    ',
'         =    ',
'    = k  =u   '
];

const level49 = 
[
'm      =m      ',
'               ',
'm      =      m',
'       =       ',
'       =       ',
'       =       ',
'      m=m      ',
'= =========== =',
'      m=      m',
'       =       ',
'       =       ',
'       =m      ',
'       =       ',
'       =       ',
'kw     =      u'
]

const level50 =
[
    'k=k=k= u =k=k=k',
    '=k=k=     =k=k=',
    'k=k=       =k=k',
    '=k=    m    =k=',
    'k=           =k',
    '=             =',
    '=      m      =',
    '=   m  *  m   =',
    '=      m      =',
    '=             =',
    'k=           =k',
    '=k=    m    =k=',
    'k=k=       =k=k',
    '=k=k=  m  =k=k=',
    'k=k=k= w =k=k=k'
]

export const levels = [
  level1, level2, level3, level4, level15, 
  level17, level5, level6, level18, level16, 
  level7, level19, level11, level12, level13, 
  level9, level10, level25, level27, level21, 
  level28, level22, level29, level24, level26,
  level14, level20, level34, level37, level49, 
  level44, level8, level50
]
