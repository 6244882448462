import React from 'react'

export default function CubeTools() {
    return (
        <div className="main-container">
            <h1>CubeTools</h1>
            <p>CubeTools lives <a href="https://bradleykh.github.io/CubeTools" target="_blank" rel="noopener noreferrer">somewhere else</a> right now, but I am starting to migrate it here.</p>
        </div>
    )
}
