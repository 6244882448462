import { Check, Clear } from '@material-ui/icons'

export default function BooleanIcon({ value, title }) {
    return (
        <div className="d-flex justify-content-center" title={title || ""}>
            {value 
                ? <Check className="text-success" />
                : <Clear className="text-danger" />
            }
        </div>
    )
}
