import React from 'react'
import { Link } from '@reach/router'

export default function CFOP5() {
    return (
        <div>
            <h1>CFOP Tutorial: Beyond CFOP</h1>

            <ul>
                <li><Link to="/cubetools/cfop">Introduction</Link></li>
                <li><Link to="/cubetools/cfop/1">Cross</Link></li>
                <li><Link to="/cubetools/cfop/2">First Two Layers (F2L)</Link></li>
                <li><Link to="/cubetools/cfop/3">Orient Last Layer (OLL)</Link></li>
                <li><Link to="/cubetools/cfop/4">Permute Last Layer (PLL)</Link></li>
                <li>Beyond CFOP</li>
            </ul>

            <h4>CFOP Variants</h4>

            <p>Because CFOP is so popular, it is very well studied, and many variants have arisen to supplement its core idea.</p>

            <p>Some CFOP solvers start with an <a href="https://www.speedsolving.com/wiki/index.php/Extended_cross" target="_blank" rel="noopener noreferrer">extended cross</a> when it is convenient. The extended cross refers to a cross plus one CE pair. This is essentially a 2x2x2 block plus two edges.</p>

            <p>The <a href="https://www.speedsolving.com/wiki/index.php/ZB_method" target="_blank" rel="noopener noreferrer">ZB method</a>, invented by Zbigniew Zborowski and Ron van Bruchem, uses few moves but relies on a large number of algorithms. After solving the F2L minus the last CE pair, there are two steps: <a href="https://www.speedsolving.com/wiki/index.php/ZBLS" target="_blank" rel="noopener noreferrer">ZBLS</a>, which solves the last CE pair and orients the LL edges, and <a href="https://www.speedsolving.com/wiki/index.php/ZBLL" target="_blank" rel="noopener noreferrer">ZBLL</a>, which finishes the last layer in one algorithm. The full ZB method has 795 algorithms (302 in ZBLS, 493 in ZBLL), so it is not popular despite its efficiency.</p>

            <p>There is a simplified version of ZBLS called <a href="https://www.speedsolving.com/wiki/index.php/VHLS" target="_blank" rel="noopener noreferrer">VHLS</a>, part of the <a href="https://www.speedsolving.com/wiki/index.php/VH_method" target="_blank" rel="noopener noreferrer">VH method</a> by Lars Vandenbergh and Dan Harris. VHLS restricts the conditions for inserting the last CE pair, reducing its algorithms to only 32 compared to 302 in ZBLS.</p>

            <p><a href="https://www.speedsolving.com/wiki/index.php/OLS" target="_blank" rel="noopener noreferrer">OLS</a> (Orientation of the Last Slot) is a set of algorithms that solve OLL when the last F2L slot is filled. It is divided into two groups: <a href="https://www.speedsolving.com/wiki/index.php/VLS" target="_blank" rel="noopener noreferrer">VLS</a> (Valk Last Slot), which uses a connected CE pair, and <a href="https://www.speedsolving.com/wiki/index.php/HLS" target="_blank" rel="noopener noreferrer">HLS</a> (Hessler Last Slot), which uses a CE pair which can be connected in one move. There is a subset of VLS called the <a href="https://www.speedsolving.com/wiki/index.php/Winter_Variation" target="_blank" rel="noopener noreferrer">Winter Variation</a> (WV) which uses 27 algorithms (plus mirrors) to skip OLL when the LL edges are already oriented. There is a corresponding subset of HLS called the <a href="https://www.speedsolving.com/wiki/index.php/Summer_Variation" target="_blank" rel="noopener noreferrer">Summer Variation</a> (SV).</p>

            <p><a href="https://www.speedsolving.com/wiki/index.php/COLL" target="_blank" rel="noopener noreferrer">COLL</a> (Corners of Last Layer, or Corners and Orient Last Layer) is a set of algorithms that solve LL corner permutation and orientation simultaneously. Including mirrored cases, there are 42 COLL cases (24 without). COLL can be useful when the completed F2L ends with oriented LL edges (as in ZBLS or VHLS); in such cases, COLL and a simple edge permutation solve the cube.</p>

            <h4>Other Methods</h4>

            <p>There are also entirely different approaches to solving the cube. The <a href="https://www.speedsolving.com/wiki/index.php/Petrus_method" target="_blank" rel="noopener noreferrer">Petrus method</a> begins by building a 2x2x2 block on one corner, then extending that to a 2x2x3 block, then finishing the first two layers after twisting edges. LL edges are automatically oriented, so the cube is finished after positioning LL corners, orienting LL corners, and positioning LL edges. Petrus is an intuitive approach that uses fewer moves than other methods, but it is not a popular approach for those aiming for speed.</p>

            <p>The <a href="https://www.speedsolving.com/wiki/index.php/ZZ_Method" target="_blank" rel="noopener noreferrer">ZZ method</a>, invented by Zbigniew Zborowski, is designed to make turns very quickly. First, all the edges are oriented. Then the right and left layers are solved, followed by the middle layer. This approach uses roughly the same number of moves as the Petrus method.</p>

            <p>The <a href="https://www.speedsolving.com/wiki/index.php/Roux_method" target="_blank" rel="noopener noreferrer">Roux method</a>, invented by Gilles Roux, begins by building two 1x2x3 blocks on opposite sides of the cube. This leaves the U and M slices free to turn. Next, the top layer corners are solved. The remaining six edges are oriented and positioned using U and M turns. See my <a href='/cubetools/roux'>Rouxtorial</a>.</p>

            <p>...and these only scratch the surface. With such a variety of approaches out there, even the most experienced cubers have opportunities to learn and grow.</p>
        </div>
    )
}
