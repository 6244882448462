import React, { useEffect, useCallback, useMemo } from 'react'
import { Link } from '@reach/router'
import { useMusic } from '.'
import { DataTable } from '../../common'
import { PlayCircleFilled } from '@material-ui/icons'
import { NotificationManager } from 'react-notifications'

export default function Pieces() {
    const { setMode, setActivePieceCollection, setActivePieceUrl, setSelectedPiece, pieces, getPieces, loadAndPlayFile } = useMusic()

    useEffect(() => {
        getPieces()
    }, [getPieces])

    const handleFileClick = useCallback(async (piece) => {
        setActivePieceUrl('')
        setSelectedPiece(piece)
        setMode('piece')
        setActivePieceCollection([])
        try {
            await loadAndPlayFile(piece.path)
        } catch (err) {
            NotificationManager.error(`Caught error on play(): ${err}`)
        }
    }, [setActivePieceUrl, setSelectedPiece, setMode, setActivePieceCollection, loadAndPlayFile])

    const columns = useMemo(() => [
        {
            Header: 'Name',
            accessor: 'name',
            Cell: ({ row, value }) => 
                <span className="d-flex align-items-center">
                    <PlayCircleFilled className="me-1 clickable" onClick={() => handleFileClick(row.original)} />{value}
                </span>
        },
        {
            Header: 'Artist',
            accessor: 'artist'
        },
        {
            Header: 'Album',
            accessor: 'album'
        },
        {
            Header: 'Tags',
            accessor: 'tags'
        },
        {
            Header: '',
            accessor: 'nothingReally',
            disableFilters: true,
            disableSortBy: true,
            Cell: ({ row }) => 
                <div className="text-end">
                    <Link to={`/music/pieces/${row.original.id}`} className="btn btn-info">Edit</Link>
                </div>
        }
    ], [handleFileClick])

    return (
        <div>
            <h1>Pieces</h1>
            {pieces.length > 0 && 
                <DataTable
                    data={pieces}
                    columns={columns}
                    filterable
                    sortable
                    paginated={pieces.length > 10}
                />}
        </div>
    )
}
