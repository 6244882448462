import React from 'react'
import { Link } from '@reach/router'
import threeLook from './3lookll.pdf'
import fourLook from './4lookll.pdf'
import f2l from './f2l.pdf'
import oll from './oll.pdf'

export default function CFOP() {
    return (
        <div>
            <h1>CFOP Tutorial</h1>

            <ul>
                <li>Introduction</li>
                <li><Link to="/cubetools/cfop/1">Cross</Link></li>
                <li><Link to="/cubetools/cfop/2">First Two Layers (F2L)</Link></li>
                <li><Link to="/cubetools/cfop/3">Orient Last Layer (OLL)</Link></li>
                <li><Link to="/cubetools/cfop/4">Permute Last Layer (PLL)</Link></li>
                <li><Link to="/cubetools/cfop/5">Beyond CFOP</Link></li>
            </ul>

            <h2>Overview</h2>

            <p>The most popular speedsolving method, CFOP has 4 steps:
                <ol>
                    <li><strong><u>C</u></strong>ross</li>
                    <li><strong><u>F</u></strong>irst two layers (F2L)</li>
                    <li><strong><u>O</u></strong>rient last layer (OLL)</li>
                    <li><strong><u>P</u></strong>ermute last layer (PLL)</li>
                </ol>
            </p>

            <p>If you are new to solving the cube, I recommend starting with the <Link to='/cubetools/lbl'>layer-by-layer tutorial</Link>. CFOP differs from a layer-by-layer approach in several ways.</p>

            <p>The biggest difference is in the first two layers. In a layer-by-layer approach, after the first layer corners are solved, we must temporarily disrupt each one in order to solve the middle layer edges. It is more efficient to solve the first layer corner and its neighboring second layer edge at the same time. These are called corner-edge (CE) pairs, and solving them is largely intuitive.</p>

            <p>Once all four CE pairs are inserted around the cross, pure CFOP solves the last layer in two steps: orient (57 algorithms) and permute (21 algorithms). This two-look last layer requires 78 algorithms, so I include two less ambitious options: a four-look last layer which requires only 16 algorithms, and a three-look last layer (two-look OLL plus full PLL) which requires 31 algorithms.</p>

            <h2>Study Aids</h2>

            <p>Not sure how to memorize so many algorithms? Check out my <Link to="/cubetools/triggers">list of common triggers</Link> and my <Link to="/cubetools/flashcards">algorithm flashcards</Link>. Learning algorithms will decrease your move count and your solve times. Pure CFOP solves the cube in roughly 56 moves on average. The four-look last layer increases that to 85 moves on average.</p>

            <p>Here are some CFOP guides in PDF format:
                <ul>
                    <li><a href={f2l} target="_blank" rel="noreferrer">F2L</a></li>
                    <li><a href={oll} target="_blank" rel="noreferrer">OLL</a></li>
                    <li><a href={fourLook} target="_blank" rel="noreferrer">4-Look Last Layer</a></li>
                    <li><a href={threeLook} target="_blank" rel="noreferrer">3-Look Last Layer</a></li>
                </ul>
            </p>

            <h2>Onward!</h2>

            <p>If needed, go ahead and brush up on <Link to="/cubetools/notation">cube notation</Link> before we get to know our old friend, the <Link to="/cubetools/cfop/1">cross</Link>, even better!</p>
        </div>
    )
}
