import React, { useState, useEffect, useCallback } from 'react'
import { Done, Clear, CloudUpload, Launch, Delete } from '@material-ui/icons'
import { Loading, fetcher, FileUploadData } from '../../common'
import { useAuth } from '../../main/auth'
import { Tag } from '.'
import { validUploadFileTypes, validUploadImgFileTypes } from '../../utils'
import { Input, Button, Form, FormGroup, FormText, FormFeedback, Label, Col, Alert } from 'reactstrap'
import { navigate } from '@reach/router'
import './recipe-dialog.scss'

export default function RecipeDialog({ recipeId, mode }) {
    const uploadBtn = React.createRef()
    const { user } = useAuth()
    const [recipe, setRecipe] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [alert, setAlert] = useState('')
    const [hasSubmitted, setHasSubmitted] = useState(false)
    const [nameInput, setNameInput] = useState('')
    const [typeInput, setTypeInput] = useState('text')
    const [descriptionInput, setDescriptionInput] = useState('')
    const [urlInput, setUrlInput] = useState('')
    const [fileInput, setFileInput] = useState(null)
    const [file, setFile] = useState(null)
    const [tagInput, setTagInput] = useState('')
    const [tagsArray, setTagsArray] = useState([])
    const [uploadUrl, setUploadUrl] = useState('')
    const maxNameLength = 100

    const getRecipe = useCallback(async () => {
        if (recipeId && recipeId !== 'new') {
            setIsLoading(true)
            const resp = await fetcher.get(`recipes/${recipeId}`)
            if (resp) {
                setRecipe(resp)
                setNameInput(resp.name)
                setTypeInput(resp.type)
                setDescriptionInput(resp.description)
                setTagsArray(resp.tags ? resp.tags.split(',') : [])
                setUrlInput(resp.type === 'url' ? resp.location : '')
                setFile(resp.type === 'file' ? resp.location : null)
            }
        }
        setIsLoading(false)
    }, [recipeId])

    useEffect(() => {
        getRecipe()
    }, [getRecipe])

    const needToUpload = () => {
        if (recipe === null) {
            return true
        }
        if (recipe !== null && typeInput === 'file' && file === null) {
            return true
        }
        return false
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setHasSubmitted(true)
        if (nameInput.trim() === '' || nameInput.trim().length > maxNameLength ||
            (typeInput === 'text' && descriptionInput.trim() === '') ||
            (typeInput === 'url' && urlInput.trim() === '')) {
            return
        }
        setIsLoading(true)
        let location = getLocation()
        if (typeInput === 'file' && needToUpload()) {
            const valid = validateFile()
            if (valid) {
                let formData = new FormData()
                formData.append("file", fileInput)
                let response = await fetcher.postFile('recipes/file', formData)
                if (response) {
                    location = response.name
                }
            } else {
                setIsLoading(false)
                return
            }
        }
        if (recipe && mode === 'edit') {
            editRecipe(location)
        } else {
            createRecipe(location)
        }
    }

    const createRecipe = async (location) => {
        setIsLoading(true)
        let body = {
            userId: user.userId,
            type: typeInput,
            name: nameInput.trim(),
            location: location,
            description: descriptionInput,
            tags: tagsArray.sort().join(',')
        }
        const response = await fetcher.post('recipes', body)
        if (response) {
            navigate(`/whisk/${response.recipeId}`)
        } else {
            setIsLoading(false)
        }
    }

    const editRecipe = async (location, shouldRedirect = true) => {
        const body = {
            userId: user.userId,
            type: typeInput,
            name: nameInput.trim(),
            location: location,
            description: descriptionInput,
            tags: tagsArray.sort().join(','),
            recipeId: recipe.recipeId,
            concurrency: recipe.concurrency
        }
        const response = await fetcher.put('recipes', body)
        if (response && shouldRedirect) {
            navigate(`/whisk/${recipe.recipeId}`)
        } else {
            setIsLoading(false)
        }
    }

    const deleteRecipe = async () => {
        setIsLoading(true)
        if (recipe.type === 'file' && recipe.location) {
            await fetcher.del(`recipes/file/${recipe.location}`)
        }
        const response = await fetcher.del(`recipes/${recipe.recipeId}`)
        if (response) {
            navigate('/whisk')
        } else {
            setIsLoading(false)
        }
    }

    const getLocation = () => {
        if (typeInput === 'url') {
            return urlInput
        }
        if (recipe && typeInput === 'file') {
            return file
        }
        return null
    }

    const addTag = () => {
        const updatedTagsArray = [...tagsArray, tagInput.trim()].sort()
        setTagsArray(updatedTagsArray)
        setTagInput('')
    }

    const removeTag = tag => {
        const updatedTagsArray = [...tagsArray].filter(x => x !== tag)
        setTagsArray(updatedTagsArray)
    }

    const validateFile = useCallback(() => {
        if (!fileInput) {
            setAlert('Please select a file!')
            return false
        }
        if (!validUploadFileTypes.includes(fileInput.type)) {
            setAlert('Invalid file type!')
            return false
        }
        if (fileInput.size > 20000000) {
            setAlert('File exceeds the 20 MB limit.')
            return false
        }
        setAlert('')
        return true
    }, [fileInput, setAlert])

    const handleDeleteFile = async () => {
        if (file) {
            const deleteBlobResp = await fetcher.del(`recipes/file/${file}`)
            if (deleteBlobResp) {
                await editRecipe(null, false)
                setFile(null)
            }
        }
    }

    const openRecipeFile = async () => {
        await fetcher.open(file, recipe.name, 'recipes')
    }

    const handleCancel = () => {
        if (recipe) {
            navigate(`/whisk/${recipe.recipeId}`)
        } else {
            navigate('/whisk')
        }
    }

    useEffect(() => {
        if (fileInput && typeInput === 'file') {
            let valid = validateFile()
            if (valid) {
                if (validUploadImgFileTypes.includes(fileInput.type)) {
                    const reader = new FileReader()
                    reader.onload = () => {
                        setUploadUrl(reader.result)
                    }
                    reader.readAsDataURL(fileInput)
                } else {
                    setUploadUrl('')
                }
            } 
        }
    }, [fileInput, typeInput, validateFile])

    if (isLoading) return <Loading />

    return (
        <Form className="recipe-dialog">
            <FormGroup row>
                <Col sm={6}>
                    <Label for="name">Name</Label>
                    <Input 
                        type="text"
                        id="name"
                        placeholder="Name"
                        value={nameInput}
                        onChange={e => setNameInput(e.target.value)}
                        invalid={nameInput.trim().length > maxNameLength || (hasSubmitted && nameInput.trim() === '')}
                    />
                    <FormText>{maxNameLength - nameInput.trim().length} characters remaining</FormText>
                    {nameInput.trim().length > maxNameLength && <FormFeedback>Too long!</FormFeedback>}
                    {hasSubmitted && nameInput.trim() === '' && <FormFeedback>Please enter a recipe name.</FormFeedback>}
                </Col>
                <Col sm={6}>
                    <Label>Type</Label>
                    <div className="type">
                        <label>
                            <input 
                                type="radio" 
                                name="recipeType" 
                                value="text" 
                                checked={typeInput === 'text'}
                                onChange={e => setTypeInput(e.target.value)}
                            />Text
                        </label>
                        <label>
                            <input 
                                type="radio" 
                                name="recipeType" 
                                value="url" 
                                checked={typeInput === 'url'}
                                onChange={e => setTypeInput(e.target.value)}
                            />Website
                        </label>
                        <label>
                            <input 
                                type="radio" 
                                name="recipeType" 
                                value="file" 
                                checked={typeInput === 'file'}
                                onChange={e => setTypeInput(e.target.value)}
                            />File
                        </label>
                    </div>
                </Col>
            </FormGroup>
            {typeInput === 'text' &&
                <FormGroup row>
                    <Col sm={12}>
                        <Label for="description">Description</Label>
                        <Input
                            type="textarea"
                            id="description"
                            placeholder="Description" 
                            value={descriptionInput}
                            onChange={e => setDescriptionInput(e.target.value)}
                            className="description"
                            invalid={hasSubmitted && descriptionInput.trim() === ''}
                        />
                        <FormFeedback>Please enter a recipe description.</FormFeedback>
                    </Col>
                </FormGroup>}
            {typeInput === 'url' && 
                <FormGroup row>
                    <Col sm={12}>
                        <Label for="url">Link</Label>
                        <Input 
                            type="text"
                            id="url" 
                            placeholder="e.g. http://www.google.com"
                            value={urlInput} 
                            onChange={e => setUrlInput(e.target.value)}
                            invalid={hasSubmitted && urlInput.trim() === ''}
                        />
                        <FormText>Type or paste the complete link (URL).</FormText>
                        <FormFeedback>Please enter the link to the recipe.</FormFeedback>
                    </Col>
                </FormGroup>}
            {typeInput === 'file' && !file &&
                <FormGroup row>
                    <Col sm={12}>
                        <input 
                            ref={uploadBtn}
                            className="d-none"
                            type="file" 
                            onChange={() => setFileInput(Array.from(uploadBtn.current.files)[0])}
                            accept={validUploadFileTypes.join(', ')}
                        />
                        {fileInput
                            ? <FileUploadData 
                                file={fileInput}
                                url={uploadUrl}
                                onClear={() => setFileInput(null)}
                            />
                            : <Button 
                                type="button"
                                color="primary"
                                onClick={() => uploadBtn.current.click()}
                            >
                                <span className="d-flex align-items-center"><CloudUpload className="me-1" />Select File</span>
                            </Button>}
                    </Col>
                </FormGroup>}
            {typeInput === 'file' && file &&
                <FormGroup row>
                    <Col sm={12}>
                        <div className="existing-file">
                            <Button
                                color="primary"
                                onClick={openRecipeFile}
                            >
                                <span className="d-flex align-items-center"><Launch className="me-1" />View File</span>
                            </Button>
                            <Button
                                type="button"
                                color="danger"
                                onClick={handleDeleteFile}
                                className="ms-1"
                                outline
                            >
                                <span className="d-flex align-items-center"><Clear className="me-1" />Delete File</span>
                            </Button>
                        </div>
                    </Col>
                </FormGroup>}
            {alert !== '' && 
                <FormGroup row>
                    <Col sm={12}>
                        <Alert color="danger">{alert}</Alert>
                    </Col>
                </FormGroup>}
            <FormGroup row>
                <Col sm={6}>
                    <Label>Tags</Label>
                    <div className="tag-list">
                        {tagsArray.map(x => 
                            <Tag 
                                key={x} 
                                text={x} 
                                className="clickable"
                                onClick={() => removeTag(x)}
                            />
                        )}
                        {tagsArray.length
                            ? <div>(click to remove)</div>
                            : <div>(none)</div>
                        }
                    </div>
                </Col>
                <Col sm={6}>
                    <Label for="newTag">New Tag</Label>
                    <Input 
                        type="text" 
                        id="newTag"
                        placeholder="New Tag" 
                        value={tagInput}
                        onChange={e => setTagInput(e.target.value)}
                    />
                    {tagInput.trim() !== '' &&
                        <Button type="button" color="primary" onClick={addTag}>
                            Save Tag
                        </Button>}
                </Col>
            </FormGroup>
            <FormGroup row>
                <Col sm={12}>
                    <Button
                        type="submit"
                        color="success"
                        onClick={async (event) => await handleSubmit(event)}
                    >
                        <span className="d-flex align-items-center"><Done className="me-1" />Save</span>
                    </Button>
                    <Button
                        type="button"
                        color="secondary"
                        className="ms-1"
                        onClick={handleCancel}
                    >
                        <span className="d-flex align-items-center"><Clear className="me-1" />Cancel</span>
                    </Button>
                    {recipe !== null && mode === 'edit' &&
                        <Button
                            type="button"
                            color="danger"
                            className="ms-1"
                            onClick={deleteRecipe}
                        >
                            <span className="d-flex align-items-center"><Delete className="me-1" />Delete</span>
                        </Button>}
                </Col>
            </FormGroup>
        </Form>
    )
}
