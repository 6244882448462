import React from 'react'
import { Link } from '@reach/router'
import { Row, Col } from 'reactstrap'
import oll from './oll.png'
import oll1 from './oll1.png'
import oll2 from './oll2.png'
import oll3 from './oll3.png'
import oll4 from './oll4.png'
import oll5 from './oll5.png'
import oll6 from './oll6.png'
import oll7 from './oll7.png'

export default function _2x23() {
    return (
        <div>
            <h1>2x2 Beginner Tutorial: Top Side</h1>

            <ul>
                <li><Link to="/cubetools/2x2">Introduction</Link></li>
                <li><Link to="/cubetools/2x2/1">Bottom Side</Link></li>
                <li><Link to="/cubetools/2x2/2">Bottom Layer</Link></li>
                <li>Top Side</li>
                <li><Link to="/cubetools/2x2/4">Top Layer</Link></li>
            </ul>

            <p>Next we will make the top side all yellow.</p>
 
            <p>With the white side on the bottom, look at where the four yellow stickers are. Other than the solved case, there are seven possible cases, shown below.</p>
            
            <Row className="mb-2">
                <Col className="text-center"><img src={oll1} alt="" /><br />Sune</Col>
                <Col className="text-center"><img src={oll2} alt="" /><br />Anti-Sune</Col>
                <Col className="text-center"><img src={oll3} alt="" /><br />Headlights</Col>
                <Col className="text-center"><img src={oll4} alt="" /><br />Blinkers</Col>
                <Col className="text-center"><img src={oll5} alt="" /><br />Bowtie</Col>
                <Col className="text-center"><img src={oll6} alt="" /><br />Pi</Col>
                <Col className="text-center"><img src={oll7} alt="" /><br />Double Headlights</Col>
            </Row>

            <p>Fortunately, we can use one algorithm to solve all of them: (RUR'U)(RU2R'). This algorithm is called the Sune, and it solves the Sune case.</p>
            
            <p>If you encounter one of the other six cases, face the top in the direction shown, then do the Sune. This will give you a Sune or Anti-Sune case which you can then solve using the Sune.</p>
            
            <p>When the top side is all yellow, we are ready to complete the <Link to="/cubetools/2x2/4">top layer</Link>.</p>
            
            <div className="text-center">
                <img src={oll} style={{ height: '100px' }} alt="" />
            </div>
        </div>
    )
}
