import React, { useState, useEffect, useCallback } from 'react'
import { dateToString } from '../../utils';

export const CalendarContext = React.createContext()

export default function CalendarProvider({ children }) {
    const getInitialActiveDate = () => {
        const d = new Date()
        return d
    }

    const [activeDate, setActiveDate] = useState(getInitialActiveDate());
    const [date, setDate] = useState({})
    const [monthArray, setMonthArray] = useState([])
    const [events, setEvents] = useState([])

    useEffect(() => {
        setDate({
            year: activeDate.getFullYear(),
            month: activeDate.getMonth(),
            date: activeDate.getDate(),
            day: activeDate.getDay()
        })
    }, [activeDate])

    const getFirstDayOfMonth = useCallback(() => {
        let dayOfWeek = date.day
        for (let i = date.date; i > 1; i--) {
            dayOfWeek--
            if (dayOfWeek < 0)
                dayOfWeek = 6
        }
        return dayOfWeek
    }, [date])

    const getMonthLength = (month, year) => {
        return new Date(year, month + 1, 0).getDate()
    }

    const getPreviousMonthLength = useCallback((month, year) => {
        var previousMonth = month === 0 ? 11 : month - 1
        var previousYear = month === 0 ? year - 1 : year
        return getMonthLength(previousMonth, previousYear)
    }, [])

    const resetDate = () => {
        setActiveDate(getInitialActiveDate())
    }

    const getMonthArray = useCallback(() => {
        let array = []
        let monthLength = getMonthLength(date.month, date.year)
        let previousMonthLength = getPreviousMonthLength(date.month, date.year)

        // first week
        let week = []
        for (let i = 0; i < getFirstDayOfMonth(); i++){
            week.unshift(previousMonthLength + 100)
            previousMonthLength--
        }
        let monthDate = 1
        while (week.length < 7) {
            week.push(monthDate)
            monthDate++
        }
        array.push(week)

        // more weeks
        while (monthDate <= monthLength) {
            let week = []
            let i = 0
            while (monthDate <= monthLength && i < 7) {
                week.push(monthDate)
                if (monthDate === monthLength) {
                    let blanks = 7 - week.length
                    let j = 1
                    while (blanks > 0) {
                        week.push(j + 100)
                        j++
                        blanks--
                    }
                }
                i++
                monthDate++
            }
            array.push(week)
        }
        setMonthArray(array)
    }, [date, getFirstDayOfMonth, getPreviousMonthLength])

    const nextMonth = () => {
        let newDate = new Date(activeDate)
        if (newDate.getDate() > 28) {
            newDate.setDate(28)
        }
        newDate.setMonth(newDate.getMonth() + 1)
        setActiveDate(newDate)
    }

    const prevMonth = () => {
        let newDate = new Date(activeDate)
        if (newDate.getDate() > 28) {
            newDate.setDate(28)
        }
        newDate.setMonth(newDate.getMonth() - 1)
        setActiveDate(newDate)
    }
    
    const nextWeek = () => {
        let newDate = new Date(activeDate)
        newDate.setDate(newDate.getDate() + 7)
        setActiveDate(newDate)
    }
    
    const prevWeek = () => {
        let newDate = new Date(activeDate)
        newDate.setDate(newDate.getDate() - 7)
        setActiveDate(newDate)
    }

    const isToday = (day) => {
        let today = new Date()
        return day === today.getDate() && date.month === today.getMonth() && date.year === today.getFullYear()
    }

    const getDateKey = (day) => {
        if (day > 108) { // previous month
            if (date.month === 0) {
                const newDate = day - 100
                const newMonth = 11
                const newYear = date.year - 1
                return dateToString(new Date(newYear, newMonth, newDate))
            }
            else {
                const newDate = day - 100
                const newMonth = date.month - 1
                const newYear = date.year
                return dateToString(new Date(newYear, newMonth, newDate))
            }
        }
        else if (day > 100) { // next month
            if (date.month === 11) {
                const newDate = day - 100
                const newMonth = 0
                const newYear = date.year + 1
                return dateToString(new Date(newYear, newMonth, newDate))
            }
            else {
                const newDate = day - 100
                const newMonth = date.month + 1
                const newYear = date.year
                return dateToString(new Date(newYear, newMonth, newDate))
            }
        }
        else { // current month
            return dateToString(new Date(date.year, date.month, day))
        }
    }
    
    useEffect(() => {
        getMonthArray()
    }, [getMonthArray])

    const context = {
        date,
        events, setEvents,
        monthArray,
        prevMonth, nextMonth,
        prevWeek, nextWeek,
        resetDate,
        isToday,
        getDateKey
    }

    return (
        <CalendarContext.Provider value={context}>
            {children}
        </CalendarContext.Provider>
    )
}

export function useCalendar() {
    const context = React.useContext(CalendarContext)
    if (context === undefined) {
        throw new Error('useCalendar must be used within a CalendarProvider')
    }
    return context
}