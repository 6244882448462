import React, { useState, useEffect, useCallback } from 'react'
import { fetcher } from '../../common'
import { useAuth } from '../../main/auth'
import './high-scores.scss'

export default function HighScores() {
    const { user } = useAuth()
    const [scores, setScores] = useState([])
    const [userScores, setUserScores] = useState([])

    const getScores = useCallback(async () => {
        const response = await fetcher.get('kitty')
        setScores(response)
    }, [])

    const getUserScores = useCallback(async () => {
        const response = await fetcher.get('kitty/user')
        setUserScores(response)
    }, [])

    useEffect(() => {
        getScores()
        if (user) {
            getUserScores()
        }
    }, [getScores, getUserScores, user])

    return (
        <div className="high-scores">
            <div className="column">
                <h2>High Scores</h2>
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Score</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {scores.length 
                            ? scores.map(score =>
                                    <tr key={score.kittyScoreId}>
                                        <td>{score.name}</td>
                                        <td>{score.score}</td>
                                        <td>{new Date(score.date).toLocaleDateString()}</td>
                                    </tr>    
                                )
                            : <tr>
                                <td colSpan={3}>No scores yet.</td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
            {user && <div className="column">
                <h2>Your Scores</h2>
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Score</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {userScores.length 
                            ? userScores.map(score =>
                                    <tr key={score.kittyScoreId}>
                                        <td>{score.name}</td>
                                        <td>{score.score}</td>
                                        <td>{new Date(score.date).toLocaleDateString()}</td>
                                    </tr>    
                                )
                            : <tr>
                                <td colSpan={3}>No scores yet.</td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>}
        </div>
    )
}
