import React, { useState, useEffect, useCallback } from 'react'
import { fetcher, Loading } from '../../common'
import { Form, FormGroup, FormFeedback, Col, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Link, navigate } from '@reach/router'

export default function BlogTagDialog({ tagId }) {
    const [tag, setTag] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [nameInput, setNameInput] = useState('')
    const [hasSubmitted, setHasSubmitted] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const returnUrl = '/blog/tags'

    const getTag = useCallback(async () => {
        if (tagId && tagId !== 'new') {
            setIsLoading(true)
            const resp = await fetcher.get(`blog/tags/${tagId}`)
            if (resp) {
                setTag(resp)
                setNameInput(resp.name)
            }
        }
        setIsLoading(false)
    }, [tagId])

    useEffect(() => {
        getTag()
    }, [getTag])

    const handleSubmit = async (e) => {
        e.preventDefault()
        setHasSubmitted(true)
        if (nameInput.trim() === '') {
            return
        }
        setIsLoading(true)
        if (tag) { // edit
            const body = {
                id: tag.id,
                name: nameInput.trim()
            }
            const resp = await fetcher.put(`blog/tags/${tag.id}`, body)
            if (resp) {
                navigate(returnUrl)
            } else {
                setIsLoading(false)
            }
        } else { // create
            const body = {
                name: nameInput.trim()
            }
            const resp = await fetcher.post(`blog/tags`, body)
            if (resp) {
                navigate(returnUrl)
            } else {
                setIsLoading(false)
            }
        }
    }

    const handleDelete = async () => {
        setIsLoading(true)
        const resp = await fetcher.del(`blog/tags/${tag.id}`)
        if (resp) {
            navigate(returnUrl)
        } else {
            setIsLoading(false)
        }
    }

    if (isLoading) return <Loading />

    return (
        <div>
            <h2>{tag ? 'Edit' : 'New'} Blog Tag</h2>
            <Form onSubmit={handleSubmit}>
                <Col sm={6}>
                    <FormGroup>
                        <Label for="name">Name</Label>
                        <Input 
                            type="text"
                            id="name"
                            value={nameInput}
                            onChange={e => setNameInput(e.target.value)}
                            invalid={hasSubmitted && nameInput.trim() === ''}
                        />
                        <FormFeedback>Please enter a name.</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                        <Button type="submit" color="primary">Save</Button>
                        <Link className="btn btn-secondary ms-1" to={returnUrl}>Cancel</Link>
                        {tag !== null && 
                            <Button 
                                type="button" 
                                color="danger" 
                                onClick={() => setShowDeleteModal(true)} 
                                className="ms-1"
                            >
                                Delete
                            </Button>}
                    </FormGroup>
                </Col>
            </Form>

            <Modal isOpen={showDeleteModal} toggle={() => setShowDeleteModal(x => !x)}>
                <ModalHeader toggle={() => setShowDeleteModal(x => !x)}>Delete Blog Tag</ModalHeader>
                <ModalBody>
                    Are you sure you want to delete this blog tag?
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={handleDelete}>Yes, Kill It!</Button>
                    <Button color="secondary" onClick={() => setShowDeleteModal(false)}>No, I Like It</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}
