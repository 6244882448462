import React, { useState, useEffect, useCallback } from 'react'
import { Link } from '@reach/router'
import { useAuth } from '../../main/auth/AuthProvider'
import { fetcher, Loading, FileViewer, FileLink } from '../../common'
import { displayUtcDate } from '../../utils'
import { BlogTag } from '.'
import { useFiles } from '../../main/FilesProvider'
import { Table } from 'reactstrap'

export default function BlogPost({ postKey }) {
    const { user } = useAuth()
    const { addFilePathsToQueue } = useFiles()
    const [isLoading, setIsLoading] = useState(false)
    const [post, setPost] = useState(null)
    const [activeFile, setActiveFile] = useState(null)
    const canEdit = user && post && user.userId === post.userId

    const getPost = useCallback(async () => {
        if (postKey) {
            setIsLoading(true)
            const resp = await fetcher.get(`blog/posts/key/${postKey}`)
            if (resp) {
                setPost(resp)
                addFilePathsToQueue(resp.files.map(file => file.path), 'blog')
            }
        }
        setIsLoading(false)
    }, [postKey, addFilePathsToQueue])

    useEffect(() => {
        getPost()
    }, [getPost])

    const handleFileClick = index => {
        const file = post.files[index]
        if (file) {
            setActiveFile(file)
        }
    }

    if (isLoading) return <Loading />

    if (post && activeFile) return (
        <FileViewer 
            files={post.files} 
            initialFile={activeFile}
            onClose={() => setActiveFile(null)}
        />
    )

    if (post && (post.isPublic || canEdit)) return (
        <div>
            <h2>{post.title}</h2>
            <div>
                {post.tags.map(tag => <BlogTag tag={tag} key={`tag-${tag.id}`} />)}
            </div>
            <p className="text-muted">{post.author}, {displayUtcDate(post.dateCreated)}</p>
            <div dangerouslySetInnerHTML={{ __html: post.content }} />
            {post.files.length > 0 && 
                <Table>
                    <tbody>
                        {post.files.map((file, index) => 
                            <tr key={`file-${file.id}`}>
                                <td>
                                    <FileLink
                                        file={file}
                                        filePath={file.path}
                                        hideCloseBtn
                                        onClick={() => handleFileClick(index)}
                                    />
                                </td>
                                <td>
                                    {file.name}
                                </td>
                                <td>
                                    {file.description}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>}
            {canEdit && <Link to={`/blog/posts/edit/${post.id}`} className="btn btn-primary">Edit</Link>}
        </div>
    )

    return (
        <div>I can't seem to find that post.</div>
    )
}
