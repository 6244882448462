import React from 'react'
import { Link } from '@reach/router'
import cmll from './cmll.pdf'
import cmll2 from './2lookcmll.pdf'

export default function Roux3() {
    return (
        <div>
            <h1>Roux Tutorial: CMLL</h1>

            <ul>
                <li><Link to="/cubetools/roux">Introduction</Link></li>
                <li><Link to="/cubetools/roux/1">The First Block</Link></li>
                <li><Link to="/cubetools/roux/2">The Second Block</Link></li>
                <li>CMLL (Top Corners)</li>
                <li><Link to="/cubetools/roux/4">LSE: Edge Orientation</Link></li>
                <li><Link to="/cubetools/roux/5">LSE: Permute UL/UR</Link></li>
                <li><Link to="/cubetools/roux/6">LSE: Permute M Slice</Link></li>
                <li><Link to="/cubetools/roux/7">Example Solves</Link></li>
                <li><Link to="/cubetools/roux/8">Advanced Roux</Link></li>
            </ul>

            <p>Once the two 1x2x3 blocks are in place, we solve the top corners. This is the only step in Roux for which algorithms are required.</p>

            <h2>2-Look CMLL</h2>

            <p>I recommend starting with 2-look CMLL, which requires only 9 algorithms, many of which will already be familiar to CFOP solvers. 2-look CMLL first orients and then permutes the corners. Here is a <a href={cmll2} target="_blank" rel="noreferrer">PDF</a> and <Link to="/cubetools/flashcards">flashcards</Link> to help you get started.</p>

            <h2>Full CMLL</h2>

            <p>CMLL solves the top corners in one step using 42 algorithms. Here is a <a href={cmll} target="_blank" rel="noreferrer">PDF</a> and <Link to="/cubetools/flashcards">flashcards</Link> to help you memorize them.</p>

            <p>Once the corners are solved, we can move on to the <Link to="/cubetools/roux/4">last six edges</Link>.</p>
        </div>
    )
}
