import React from "react";
import { Thumbnail } from ".";
import { useFiles } from "../main/FilesProvider";
import { getExtension, getFileNameWithoutTimestamp } from "../utils";
import { Close } from "@material-ui/icons";
import "./file-link.scss";

export default function FileLink({
    file,
    filePath,
    isPreview,
    removed,
    hideCloseBtn,
    folder = "blog",
    onClick = () => {},
    onRemove = () => {},
    onRestore = () => {},
    ...otherProps
}) {
    const { blobs } = useFiles();

    const imgExtensions = ["jpg", "jpeg", "gif", "png"];
    const isImage = imgExtensions.includes(getExtension(filePath));

    if (!filePath) return null;

    if (blobs[`${folder}/${filePath}`]) {
        if (isImage) {
            return (
                <div className={`thumbnail-container`} {...otherProps}>
                    <Thumbnail
                        src={blobs[`${folder}/${filePath}`]}
                        onClick={() => onClick(filePath)}
                        className={removed ? "removed" : ""}
                    />
                    {!hideCloseBtn && (
                        <>
                            {removed ? (
                                <span className="dead" onClick={() => onRestore(filePath)}>
                                    &times;
                                </span>
                            ) : (
                                <Close className="close-btn" onClick={() => onRemove(filePath)} />
                            )}
                        </>
                    )}
                </div>
            );
        } else {
            return (
                <div className={`thumbnail-container`} {...otherProps}>
                    <div className="file-link" onClick={() => onClick(filePath)}>
                        {file ? file.name : getFileNameWithoutTimestamp(filePath)}
                    </div>
                    {!hideCloseBtn && (
                        <>
                            {removed ? (
                                <span className="dead" onClick={() => onRestore(filePath)}>
                                    &times;
                                </span>
                            ) : (
                                <Close className="close-btn" onClick={() => onRemove(filePath)} />
                            )}
                        </>
                    )}
                </div>
            );
        }
    } else if (isPreview) {
        return (
            <div className="file-link" onClick={() => onClick(filePath)} {...otherProps}>
                {filePath}
            </div>
        );
    } else {
        if (isImage) {
            return <Thumbnail placeholder {...otherProps} />;
        } else {
            return (
                <div className="file-link placeholder" {...otherProps}>
                    {file ? file.name : getFileNameWithoutTimestamp(filePath)}
                </div>
            );
        }
    }
}
