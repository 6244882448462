import React from 'react'
import { useChess } from '.'
import pb from '../../files/chess/pb.png'
import kb from '../../files/chess/kb.png'
import qb from '../../files/chess/qb.png'
import bb from '../../files/chess/bb.png'
import nb from '../../files/chess/nb.png'
import rb from '../../files/chess/rb.png'
import './prisoners.scss'

export default function BlackPrisoners() {
    const { capturedBlackPieces, capturedWhitePieces } = useChess()

    const renderPiece = (piece, index) => {
        let altText = ''
        let source = null

        switch (piece) {
            case 'p':
                altText = 'Black pawn'
                source = pb
                break
            case 'k':
                altText = 'Black King'
                source = kb
                break
            case 'q':
                altText = 'Black Queen'
                source = qb
                break
            case 'b':
                altText = 'Black Bishop'
                source = bb
                break
            case 'n':
                altText = 'Dark Knight'
                source = nb
                break
            case 'r':
                altText = 'Black Rook'
                source = rb
                break
            default:
                break
        }

        return (
            <img src={source} alt={altText} key={`prisoner-${piece}-${index}`} className="prisoner" />
        )

    }

    return (
        <div className="prisoners">
            {capturedBlackPieces.map((piece, index) => renderPiece(piece, index))}
        </div>
    )
}
