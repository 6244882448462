import React from 'react'
import { Link } from '@reach/router'
import { Row, Col } from 'reactstrap'
import side1 from './side1.png'
import side2 from './side2.png'
import side3 from './side3.png'
import side4 from './side4.png'
import side5 from './side5.png'
import side6 from './side6.png'
import side7 from './side7.png'

export default function _2x21() {
    return (
        <div>
            <h1>2x2 Beginner Tutorial: Bottom Side</h1>

            <ul>
                <li><Link to="/cubetools/2x2">Introduction</Link></li>
                <li>Bottom Side</li>
                <li><Link to="/cubetools/2x2/2">Bottom Layer</Link></li>
                <li><Link to="/cubetools/2x2/3">Top Side</Link></li>
                <li><Link to="/cubetools/2x2/4">Top Layer</Link></li>
            </ul>

            <p>The first step is the most instinctive and intuitive step: we are going to solve a side.</p>
 
            <p>(For those of you familiar with a layer-by-layer approach, I really do mean a side, not a layer. We will position the layer in the next step.)</p>
            
            <h2>The First Three Pieces</h2>
            
            <p>Let's start with the white pieces because they are easy to see. Solving three of the four white pieces is usually not so bad. The goal is to get something like this:</p>

            <div className="text-center">
                <img src={side1} style={{ height: '100px' }} alt="" />
            </div>

            <h2>Position the Last White Piece</h2>
            
            <p>Once you get three white pieces on a side, turn the cube so that that side is on the bottom.</p>

            <div className="text-center">
                <img src={side2} style={{ height: '100px' }} alt="" />
            </div>

            <p>Look to see where the last white sticker is. If the white sticker is in the top layer, spin the top until the sticker is over the unsolved bottom corner.</p>

            <Row className="mb-2">
                <Col className="text-center">
                    <img src={side3} style={{ height: '100px' }} alt="" /><br />Sticker on front
                </Col>
                <Col className="text-center">
                    <img src={side7} style={{ height: '100px' }} alt="" /><br />Sticker on right
                </Col>
                <Col className="text-center">
                    <img src={side5} style={{ height: '100px' }} alt="" /><br />Sticker on top
                </Col>
            </Row>

            <p>If the white sticker is in the bottom layer but facing sideways, that's OK too. Just turn the cube so that the unsolved corner is in the front right of the cube, as below.</p>

            <Row className="mb-2">
                <Col className="text-center">
                    <img src={side4} style={{ height: '100px' }} alt="" /><br />Sticker on front
                </Col>
                <Col className="text-center">
                    <img src={side6} style={{ height: '100px' }} alt="" /><br />Sticker on right
                </Col>
            </Row>

            <h2>Solve the Last White Piece</h2>
            
            <p>When your cube looks like one of these five pictures, you can solve the white side with this algorithm: (RUR'U'). In the cubing community, this algorithm is called &quot;Sexy Move&quot;. Repeat that algorithm until the white sticker has joined its three friends on the bottom of the cube. You won't need to use it more than five times.</p>
            
            <p>For the more ambitious among you, I am also including a shortcut algorithm for each of these five cases.</p>
            
            <Row className="mb-2">
                <Col className="text-center">
                    <img src={side3} style={{ height: '100px' }} alt="" /><br />(RUR'U')*5<br />Shortcut: (URU'R')
                </Col>
                <Col className="text-center">
                    <img src={side4} style={{ height: '100px' }} alt="" /><br />(RUR'U')*4<br />Shortcut: (RU'R'U)(RU'R')
                </Col>
                <Col className="text-center">
                    <img src={side5} style={{ height: '100px' }} alt="" /><br />(RUR'U')*3<br />Shortcut: (RU2R'U')(RUR')
                </Col>
                <Col className="text-center">
                    <img src={side6} style={{ height: '100px' }} alt="" /><br />(RUR'U')*2<br />Shortcut: (RUR'U')(RUR')
                </Col>
                <Col className="text-center">
                    <img src={side7} style={    { height: '100px' }} alt="" /><br />(RUR'U')<br />Shortcut: (RUR')
                </Col>
            </Row>

            <p>When the bottom of the cube is all white, you are ready to move on to the <Link to="/cubetools/2x2/2">next step, the bottom layer</Link>.</p>

        </div>
    )
}
