import React, { useState, useEffect } from 'react'
import { Link, navigate } from '@reach/router'
import { useAuth } from './AuthProvider'
import { Loading, Card } from '../../common'
import { Button, Input, FormGroup, FormFeedback, Form, Label } from 'reactstrap'
import { NotificationManager } from 'react-notifications'
import './login.scss'

export default function Login() {
    const { login, isLoadingUser, user, isLoading } = useAuth()
    const [emailInput, setEmailInput] = useState('')
    const [passwordInput, setPasswordInput] = useState('')
    const [hasSubmitted, setHasSubmitted] = useState(false)

    useEffect(() => {
        if (!isLoadingUser && user)
            navigate('/dashboard')
    }, [user, isLoadingUser])

    const loginAttempt = async (event) => {
        event.preventDefault()
        setHasSubmitted(true)
        if (emailInput.trim() === '' || passwordInput.trim() === '') {
            return
        }
        const response = await login(emailInput.trim(), passwordInput.trim())
        if (response && response.message) {
            NotificationManager.warning(response.message)
        }
    }

    if (isLoadingUser || isLoading)
        return <Loading />

    return (
        <div className="main-container d-flex">
            <Card header="Sign In" className="login">
                <Form className="login-form">
                    <FormGroup>
                        <Label for="email">Email</Label>
                        <Input
                            type="email"
                            name="email"
                            id="email"
                            onChange={(event) => setEmailInput(event.target.value)}
                            value={emailInput}
                            placeholder="Email"
                            invalid={hasSubmitted && emailInput.trim() === ''}
                        />
                        <FormFeedback>Please enter your email address.</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                        <Label for="password">Password</Label>
                        <Input
                            type="password"
                            name="password"
                            id="password"
                            onChange={(event) => setPasswordInput(event.target.value)}
                            value={passwordInput}
                            placeholder="Password"
                            invalid={hasSubmitted && passwordInput.trim() === ''}
                        />
                        <FormFeedback>Please enter your password.</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                        <Button
                            type="submit"
                            color="success"
                            onClick={e => loginAttempt(e)}
                        >
                            Submit
                        </Button>
                    </FormGroup>
                </Form>
                <p>Don't have an account? <Link to="/signup">Request one!</Link></p>
            </Card>
        </div>
    )
}
