import React from 'react'
import { navigate } from '@reach/router'
import { AddCircle, List } from '@material-ui/icons'
import { Button, ButtonGroup } from 'reactstrap'
import SideMenu from '../../main/nav/SideMenu'

export default function WhiskMenu() {
    return (
        <SideMenu header="Whisk" headerLink="/whisk">
            <ButtonGroup vertical>
                <Button
                    color="primary"
                    outline
                    onClick={() => navigate('/whisk')}
                >
                    <span className="d-flex align-items-center"><List className="me-1"/>View Recipes</span>
                </Button>
                <Button
                    color="primary"
                    outline
                    onClick={() => navigate('/whisk/new')}
                >
                    <span className="d-flex align-items-center"><AddCircle className="me-1"/>Recipe</span>
                </Button>
            </ButtonGroup>
        </SideMenu>
    )
}
