import React from 'react'
import { Link, navigate } from '@reach/router'
import { useCalendar, CalendarEvent } from '.'
import { useHoney } from '../Honey'

export default function CalendarDay({ day }) {
    const { isToday, events, getDateKey } = useCalendar()
    const { changeItemDate, activeListId } = useHoney()

    const generateClassName = () => {
        if (day > 100) {
            return 'day ref' 
        }
        if (isToday(day)) {
            return 'day today'
        }
        return 'day'
    }

    const getTasks = () => {
        let key = getDateKey(day)
        let taskArray = events.find(x => x[0] === key)
        if (taskArray) {
            let tasks = taskArray[1]
            return tasks.map((x, i) => 
                <Link key={`${day}-${i}`} to={`/honey/${x.listId}`}>
                    <CalendarEvent text={x.name} data={x} />
                </Link>)
        }
        return null
    }

    const handleDayClick = e => {
        if (e.target.className.includes('day')) {
            navigate(`/calendar/${getDateKey(day)}/${activeListId}`)
        }
    }

    const allowDrop = e => e.preventDefault()
    
    const drop = async e => {
        e.preventDefault();
        let event = JSON.parse(e.dataTransfer.getData("text"))
        let isValidDate = !isNaN(new Date(e.target.id))
        if (isValidDate) {
            await changeItemDate(event, e.target.id)
        } else {
            let parentValidDate = !isNaN(new Date(e.target.parentNode.parentNode.id))
            if (parentValidDate)
                await changeItemDate(event, e.target.parentNode.parentNode.id)
        }        
    }

    return (
        <td 
            className={generateClassName()}
            onClick={handleDayClick}
            onDrop={e => drop(e)}
            onDragOver={e => allowDrop(e)}
            id={getDateKey(day)}
        >
            {day > 100 ? day - 100 : day}
            {getTasks()}
        </td>
    )
}