export const globals = {
  'red': '#F00',
  'orange': '#F80',
  'gold': '#FFD700',
  'lightGold': '#F6EFD7',
  'green': '#008000',
  'darkGreen': '#002600',
  'lightGreen': '#6A6',
  'lighterGreen': '#CCE6CC',
  'brightGreen': '#73FF7A',
  'blue': '#0080FF',
  'navy': '#0040AA',
  'paleBlue': '#93AEDB',
  'purple': '#6f109c',
  'lightPurple': '#B417FF',
  'palePurple': '#D7B3E8',
  'brown': '#4D4100',

  'white': '#FFF',
  'lighterGray': '#E5E5E5',
  'lightGray': '#CCC',
  'mediumGray': '#AAA',
  'darkGray': '#808080',
  'darkerGray': '#333',
  'black': '#1c1c1c'
}

export const themes = {
  dark: {
    'bs-primary': '#2a9fd6',
    'bs-secondary': '#555',
    'bs-success': '#77b300',
    'bs-info': '#93c',
    'bs-warning': '#f80',
    'bs-danger': '#c00',
    'bs-light': '#222',
    'bs-dark': '#adafae',
    'bs-body-color': '#adafae',
    'bs-body-bg': '#060606',
    'bs-primary-rgb': '42, 159, 214',
    'bs-secondary-rgb': '85, 85, 85',
    'bs-success-rgb': '119, 179, 0',
    'bs-info-rgb': '153, 51, 204',
    'bs-warning-rgb': '255, 136, 0',
    'bs-danger-rgb': '204, 0, 0',
    'bs-light-rgb': '34, 34, 34',
    'bs-dark-rgb': '173, 175, 174',
    'bs-white-rgb': '255, 255, 255',
    'bs-black-rgb': '0, 0, 0',
    'bs-body-color-rgb': '173, 175, 174',
    'bs-body-bg-rgb': '6, 6, 6',
    'bg-1': '#282828',
    'dropdown-text': '#fff'
  },
  light: {
    'bs-primary': '#0d6efd',
    'bs-secondary': '#6c757d',
    'bs-success': '#198754',
    'bs-info': '#0dcaf0',
    'bs-warning': '#ffc107',
    'bs-danger': '#dc3545',
    'bs-light': '#f8f9fa',
    'bs-dark': '#212529',
    'bs-body-color': '#212529',
    'bs-body-bg': '#fff',
    'bs-primary-rgb': '13, 110, 253',
    'bs-secondary-rgb': '108, 117, 125',
    'bs-success-rgb': '25, 135, 84',
    'bs-info-rgb': '13, 202, 240',
    'bs-warning-rgb': '255, 193, 7',
    'bs-danger-rgb': '220, 53, 69',
    'bs-light-rgb': '248, 249, 250',
    'bs-dark-rgb': '33, 37, 41',
    'bs-white-rgb': '255, 255, 255',
    'bs-black-rgb': '0, 0, 0',
    'bs-body-color-rgb': '33, 37, 41',
    'bs-body-bg-rgb': '255, 255, 255',
    'bg-1': '#fff',
    'dropdown-text': '#212529'
  },
  darkOld: {
    'primary': globals.blue,
    'primary-muted': globals.navy,
    'secondary': globals.lightPurple,
    'secondary-muted': globals.purple,
    'accent': globals.gold,
    'accent-muted': globals.brown,
    'background': globals.black,
    'text': globals.white,
    'text-muted': globals.darkGray,
    'text-accented': globals.black,
    'link': globals.blue,
    'menu-background': globals.darkerGray,
    'menu-text': globals.lightGray,
    'header-background': globals.darkerGray,
    'header-text': globals.lightGray,
    'heading-text': globals.lightGray,
    'border': globals.darkGray,
    'hover': globals.darkerGray,
    'section': globals.darkerGray,
    'shadow': 'rgba(0,0,0,.3)',
    'button': globals.darkerGray,
    'success': globals.brightGreen,
    'warning': globals.red,
    'cancel': globals.darkGray
  },
  lightOld: {
    'primary': globals.navy,
    'primary-muted': globals.paleBlue,
    'secondary': globals.purple,
    'secondary-muted': globals.palePurple,
    'accent': globals.gold,
    'accent-muted': globals.lightGold,
    'background': globals.white,
    'text': globals.black,
    'text-muted': globals.darkGray,
    'text-accented': globals.black,
    'link': globals.navy,
    'menu-background': globals.mediumGray,
    'menu-text': globals.darkerGray,
    'header-background': globals.mediumGray,
    'header-text': globals.white,
    'heading-text': globals.darkGray,
    'border': globals.lightGray,
    'hover': globals.lighterGray,
    'section': globals.lighterGray,
    'shadow': 'rgba(0,0,0,.3)',
    'button': globals.lightGray,
    'success': globals.green,
    'warning': globals.red,
    'cancel': globals.darkGray
  }
}
