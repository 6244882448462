import React, { useEffect, useState } from "react";
import { Link, navigate } from "@reach/router";
import { useHoney } from ".";
import { Loading, Card } from "../../common";
import { Clear, Delete, Done } from "@material-ui/icons";
import { Form, FormGroup, Col, Input, Label, Button, FormFeedback, FormText } from "reactstrap";
import { dateToString } from "../../utils";
import "./item-dialog.scss";

export default function ItemDialog({ listId, index, calendarDate }) {
    const { isLoading, items, addItems, editItem, canEdit, lists, setActiveListId, deleteItems } = useHoney();
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [itemInput, setItemInput] = useState("");
    const [dateInput, setDateInput] = useState("");
    const [descriptionInput, setDescriptionInput] = useState("");
    const [mode, setMode] = useState("create");

    useEffect(() => {
        if (listId) {
            setActiveListId(parseInt(listId));
        }
    }, [listId, setActiveListId]);

    useEffect(() => {
        if (index && index !== "new") {
            setMode("edit");
            const item = items[parseInt(index, 10)];
            if (item) {
                setItemInput(item.name);
                if (item.date) {
                    setDateInput(item.date);
                }
                if (item.description) {
                    setDescriptionInput(item.description);
                }
            }
        }
    }, [index, items]);

    useEffect(() => {
        if (calendarDate) {
            setDateInput(calendarDate);
        }
    }, [calendarDate]);

    const handleDeleteItem = async () => {
        await deleteItems(+listId, [+index])
        navigate(calendarDate ? "/calendar" : `/honey/${listId}`);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setHasSubmitted(true);
        if (itemInput.trim() === "") {
            return;
        }
        if (mode === "create") {
            addItems(itemInput.trim(), dateInput ? dateInput : null, descriptionInput ? descriptionInput.trim() : null);
        } else if (mode === "edit") {
            editItem(
                parseInt(index, 10),
                itemInput.trim(),
                dateInput ? dateInput : null,
                descriptionInput ? descriptionInput.trim() : null
            );
        }
        navigate(calendarDate ? "/calendar" : `/honey/${listId}`);
    };

    const generatePlaceholder = () => {
        switch (mode) {
            case "create":
                return "List item(s)";
            case "edit":
                return "Rename list item";
            default:
                return "Add an item";
        }
    };

    if (!lists.length) {
        return (
            <div>
                You don't have any lists yet. <Link to="/honey/new">Add some!</Link>
            </div>
        );
    }

    if (!canEdit) return null;
    if (isLoading) return <Loading />;

    return (
        <Card className="item-dialog" header={index === "new" ? "New Item(s)" : "Edit Item"}>
            <Form>
                <Col>
                    <FormGroup row>
                        <Label for="items">{index === "new" ? "Item(s)" : "Item"}</Label>
                        <Input
                            type="text"
                            id="items"
                            placeholder={generatePlaceholder()}
                            value={itemInput}
                            onChange={(event) => setItemInput(event.target.value)}
                            invalid={hasSubmitted && itemInput.trim() === ""}
                        />
                        {index === "new" && <FormText>Enter one or more items, separated by commas.</FormText>}
                        <FormFeedback>Please enter at least one item.</FormFeedback>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="description">Description (optional)</Label>
                        <Input
                            type="text"
                            id="description"
                            placeholder="Add a description"
                            value={descriptionInput}
                            onChange={(event) => setDescriptionInput(event.target.value)}
                        />
                    </FormGroup>
                    <FormGroup row>
                        <Label for="date">Due Date (optional)</Label>
                        <Input
                            id="date"
                            type="date"
                            value={dateInput}
                            onChange={(event) => setDateInput(event.target.value)}
                        />
                        <div>
                            <Button type="button" color="primary" outline onClick={() => setDateInput(dateToString())}>
                                Today
                            </Button>
                            {dateInput !== "" && (
                                <Button
                                    type="button"
                                    color="secondary"
                                    outline
                                    onClick={() => setDateInput("")}
                                    className="ms-1"
                                >
                                    <span className="d-flex align-items-center">
                                        <Clear className="me-1" />
                                        Remove Date
                                    </span>
                                </Button>
                            )}
                        </div>
                    </FormGroup>
                    <div className="d-flex">
                        <Button type="submit" color="success" onClick={(event) => handleSubmit(event)}>
                            <span className="d-flex align-items-center">
                                <Done className="me-1" />
                                Save
                            </span>
                        </Button>
                        {index !== undefined && index !== "new" && (
                            <Button type="button" color="danger" className="ms-1" onClick={() => handleDeleteItem()}>
                                <span className="d-flex align-items-center">
                                    <Delete className="me-1" />
                                    Delete
                                </span>
                            </Button>
                        )}
                        <Button
                            type="button"
                            color="secondary"
                            onClick={() => navigate(calendarDate ? `/calendar` : `/honey/${listId}`)}
                            className="ms-1"
                        >
                            <span className="d-flex align-items-center">
                                <Clear className="me-1" />
                                Cancel
                            </span>
                        </Button>
                    </div>
                </Col>
            </Form>
        </Card>
    );
}
