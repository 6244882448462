import React, { useState, useCallback } from 'react'
import { fetcher } from '../../common'

export const TrackerContext = React.createContext()

export default function TrackerProvider({ children }) {
    const [groups, setGroups] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [permissions, setPermissions] = useState([])
    const [activeGroup, setActiveGroup] = useState(null)
    const [minDate, setMinDate] = useState('')
    const [maxDate, setMaxDate] = useState('')

    const getGroup = useCallback(async (groupId) => {
        if (groupId) {
            const resp = await fetcher.get(`tracker/groups/${groupId}`)
            if (resp) {
                setActiveGroup(resp)
            }
        }
    }, [])

    const getGroups = useCallback(async () => {
        setIsLoading(true)
        let response = await fetcher.get('tracker/groups')
        setGroups(response)
        setIsLoading(false)
    }, [])

    const getPermissions = useCallback(async () => {
        let response = await fetcher.get('tracker/permission')
        if (response) {
            setPermissions(response)
        }
    }, [])

    const context = {
        groups, setGroups,
        isLoading, setIsLoading,
        permissions, getPermissions,
        activeGroup, setActiveGroup,
        minDate, setMinDate,
        maxDate, setMaxDate,
        getGroups,
        getGroup
    }

    return (
        <TrackerContext.Provider value={context}>
            {children}
        </TrackerContext.Provider>
    )
}

export function useTracker() {
    const context = React.useContext(TrackerContext)
    if (context === undefined) {
        throw new Error(`useTracker must be used within a TrackerProvider`)
    }
    return context
}
