import React, { useState } from 'react'
import { Link } from '@reach/router'
import { ExpandMore, ExpandLess } from '@material-ui/icons'
import './side-menu.scss'

export default function SideMenu({ children, header, headerLink, className, ...otherProps }) {
    const [isOpen, setIsOpen] = useState(true)

    return (
        <div className={`side-menu ${className ? className : ''}`} {...otherProps}>
            <div className="header" onClick={() => setIsOpen(x => !x)}>
                <div className="mobile-only clickable">
                    {isOpen ? <ExpandLess /> : <ExpandMore />}
                </div>
                <span className="mobile-hide">{headerLink ? <Link to={headerLink}>{header}</Link> : header}</span>
                <span className="mobile-only clickable">{header}</span>
            </div>
            <div className={`body ${isOpen ? '' : 'is-collapsed'}`}>
                {children}
            </div>
        </div>
    )
}
