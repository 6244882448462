import React, { useState, useEffect, useCallback } from 'react'
import { Form, FormGroup, Col, Input, Button, Label, FormFeedback, FormText, Table,
    Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap'
import { useMusic } from '.'
import { Link, navigate } from '@reach/router'
import { fetcher, Loading } from '../../common'
import { AddCircle, Clear, ArrowUpward, ArrowDownward } from '@material-ui/icons'
import './playlist-dialog.scss'

export default function PlaylistDialog({ playlistId }) {
    const { pieces } = useMusic()
    const [playlist, setPlaylist] = useState(null)
    const [nameInput, setNameInput] = useState('')
    const [filterInput, setFilterInput] = useState('')
    const [piecesInput, setPiecesInput] = useState([])
    const [checkedPieceIds, setCheckedPieceIds] = useState([])
    const [hasSubmitted, setHasSubmitted] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [showPieceModal, setShowPieceModal] = useState(false)
    const [insertIndex, setInsertIndex] = useState(0)
    const unattachedPieces = pieces.filter(piece => piecesInput.find(attachedPiece => piece.id === attachedPiece.id) === undefined)
    const filteredPieces = filterInput
        ? unattachedPieces.filter(piece => 
            piece.name.toLowerCase().includes(filterInput.toLowerCase()) || 
            piece.artist.toLowerCase().includes(filterInput.toLowerCase()) ||
            piece.album.toLowerCase().includes(filterInput.toLowerCase())) 
        : unattachedPieces
    const maxNameLength = 100
    const hasUnsavedChanges = playlist && playlist.pieceIds.length !== piecesInput.length
    const returnUrl = '/music/playlists'

    const getPlaylist = useCallback(async () => {
        if (playlistId && playlistId !== 'new') {
            setIsLoading(true)
            const resp = await fetcher.get(`music/playlists/${playlistId}`)
            if (resp) {
                setPlaylist(resp)
                setNameInput(resp.name)
                if (resp.pieceIds.length > 0 && pieces.length > 0) {
                    let updatedPieces = []
                    for (let i = 0; i < resp.pieceIds.length; i++) {
                        const piece = pieces.find(piece => piece.id === resp.pieceIds[i])
                        if (piece) {
                            updatedPieces.push(piece)
                        }
                    }
                    setPiecesInput(updatedPieces)
                }
            }
        }
        setIsLoading(false)
    }, [playlistId, pieces])

    useEffect(() => {
        getPlaylist()
    }, [getPlaylist])

    const checkToggle = id => {
        if (checkedPieceIds.includes(id)) {
            uncheckItem(id)
        } else {
            checkItem(id)
        }
    }

    const checkItem = id => {
        setCheckedPieceIds(x => [...x, id])
    }

    const uncheckItem = id => {
        if ([...checkedPieceIds].length === 1) {
            setCheckedPieceIds([])
        } else {
            let updatedCheckedItemIds = [...checkedPieceIds].filter(x => x !== id)
            setCheckedPieceIds(updatedCheckedItemIds)
        }
    }

    const removePiece = index => {
        let updatedPieces = [...piecesInput]
        updatedPieces.splice(index, 1)
        setPiecesInput(updatedPieces)
    }

    const movePiece = () => {

    }

    const moveUp = index => {
        if (index === 0) {
            return
        }
        let updatedPieces = [...piecesInput]
        const pieceToMove = updatedPieces[index]
        updatedPieces[index] = updatedPieces[index - 1]
        updatedPieces[index - 1] = pieceToMove
        setPiecesInput(updatedPieces)
    }

    const moveDown = index => {
        if (index === piecesInput.length - 1) {
            return
        }
        let updatedPieces = [...piecesInput]
        const pieceToMove = updatedPieces[index]
        updatedPieces[index] = updatedPieces[index + 1]
        updatedPieces[index + 1] = pieceToMove        
        setPiecesInput(updatedPieces)
    }

    const selectPieces = () => {
        if (checkedPieceIds.length > 0) {
            let itemsToAdd = []
            for (let i = 0; i < checkedPieceIds.length; i++) {
                const pieceToAdd = pieces.find(x => x.id === parseInt(checkedPieceIds[i], 10))
                if (pieceToAdd) {
                    itemsToAdd.push(pieceToAdd)
                }
            }
            setPiecesInput(x => [...x, ...itemsToAdd])
            closeModal()
        }
    }

    const closeModal = () => {
        setShowPieceModal(false)
        setCheckedPieceIds([])
        setFilterInput('')
    }

    const handleSave = async (e) => {
        e.preventDefault()
        setHasSubmitted(true)
        if (nameInput.trim() === '' || nameInput.trim().length > maxNameLength) {
            return
        }
        setIsLoading(true)
        if (playlist) {
            editPlaylist()
        } else {
            createPlaylist()
        }
    }

    const createPlaylist = async () => {
        const body = {
            name: nameInput.trim(),
            pieceIds: piecesInput.map(piece => piece.id)
        }
        const resp = await fetcher.post('music/playlists', body)
        if (resp) {
            navigate(returnUrl)
        } else {
            setIsLoading(false)
        }
    }

    const editPlaylist = async () => {
        const body = {
            name: nameInput.trim(),
            pieceIds: piecesInput.map(piece => piece.id),
            id: playlist.id
        }
        const resp = await fetcher.put(`music/playlists/${playlist.id}`, body)
        if (resp) {
            navigate(returnUrl)
        } else {
            setIsLoading(false)
        }
    }

    const handleDelete = async () => {
        setIsLoading(true)
        const resp = await fetcher.del(`music/playlists/${playlist.id}`)
        if (resp) {
            navigate(returnUrl)
        } else {
            setIsLoading(false)
        }
    }

    if (isLoading) return <Loading />

    return (
        <div className="playlist-dialog">
            <h1>Playlist</h1>
            <Form onSubmit={handleSave}>
                <FormGroup row>
                    <Col sm={6}>
                        <Label for="name">Name</Label>
                        <Input 
                            type="text"
                            id="name"
                            value={nameInput}
                            onChange={e => setNameInput(e.target.value)}
                            invalid={(hasSubmitted && nameInput.trim() === '') || nameInput.trim().length > maxNameLength}
                        />
                        <FormText>{maxNameLength - nameInput.trim().length} characters remaining</FormText>
                        {nameInput.trim().length > maxNameLength && <FormFeedback>Too long!</FormFeedback>}
                        {hasSubmitted && nameInput.trim() === '' && <FormFeedback>Please enter a name for the playlist.</FormFeedback>}
                    </Col>
                </FormGroup>
                {hasUnsavedChanges && <Alert color="danger">You have unsaved changes!</Alert>}
                <FormGroup>
                    <Label>Pieces</Label>
                </FormGroup>
                <FormGroup>
                    <Button color="info" onClick={() => setShowPieceModal(true)} size="sm">
                        <span className="d-flex align-items-center">
                            <AddCircle className="me-1" />Pieces
                        </span>
                    </Button>
                </FormGroup>
                <FormGroup>
                    {piecesInput.length < 1 
                        ? <FormText>No pieces.</FormText>
                        : <Table>
                            <thead>
                                <tr>
                                    <th>Remove</th>
                                    <th>Name</th>
                                    <th>Artist</th>
                                    <th>Album</th>
                                </tr>
                            </thead>
                            <tbody>
                                {piecesInput.map((piece, index) =>
                                    <tr key={`piece-${piece.id}`}>
                                        <td>
                                            <Clear className="clickable red" onClick={() => removePiece(index)} />
                                            {index > 0 && 
                                                <ArrowUpward 
                                                    className="clickable"
                                                    onClick={() => moveUp(index)}
                                                />}
                                            {index < piecesInput.length - 1 && 
                                                <ArrowDownward 
                                                    className="clickable"
                                                    onClick={() => moveDown(index)}
                                                />}
                                        </td>
                                        <td>{piece.name}</td>
                                        <td>{piece.artist}</td>
                                        <td>{piece.album}</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    }
                </FormGroup>
                <FormGroup>
                    <Button color="success" type="submit">Save</Button>
                    <Link to="/music/playlists" className="btn btn-secondary ms-1">Cancel</Link>
                    {playlist !== null && 
                        <Button color="danger" type="button" className="ms-1" onClick={handleDelete}>Delete</Button>}
                </FormGroup>
            </Form>

            <Modal isOpen={showPieceModal} toggle={() => setShowPieceModal(x => !x)} size="lg">
                <ModalHeader toggle={() => setShowPieceModal(x => !x)}>Add Pieces to Playlist</ModalHeader>
                <ModalBody>
                <FormGroup>
                        <Label for="filter">Filter Pieces by Title/Artist/Album</Label>
                        <Input 
                            type="text"
                            id="filter"
                            placeholder=""
                            value={filterInput}
                            onChange={e => setFilterInput(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>Tracks ({checkedPieceIds.length} selected)</Label>
                        <div className="selectable-piece-list">                            
                            {filteredPieces.map(piece => 
                                <div 
                                    key={`pieceOption-${piece.id}`} 
                                    className={`selectable-piece ${checkedPieceIds.includes(piece.id) ? 'selected' : ''}`} 
                                    onClick={() => checkToggle(piece.id)}
                                >
                                    {piece.artist 
                                        ? `${piece.artist} - ${piece.name} ${piece.album ? `(${piece.album})` : ''}` 
                                        : `${piece.name} ${piece.album ? `(${piece.album})` : ''}`
                                    }
                                </div>
                            )}
                        </div>
                        <FormText>Click on one or more items to select them.</FormText>
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={selectPieces}>Add Selected Pieces</Button>{' '}
                    <Button color="secondary" onClick={closeModal}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}
