import React, { useState, useEffect, useReducer, useCallback } from 'react'
import { Loading, fetcher, Tooltip, Card } from '../../common'
import { Done, Clear, Delete, AddCircle } from '@material-ui/icons'
import { TrackerGroupSharing, ReferenceValueInput, useTracker } from '.'
import { dateToString } from '../../utils'
import { navigate } from '@reach/router'
import { Button, Input, Form, FormGroup, FormFeedback, Col, Label, FormText } from 'reactstrap'
import './tracker-group-dialog.scss'

export default function TrackerGroupDialog({ groupId }) {

    const refValuesReducer = (state, { action, index, updatedFields }) => {
        let newState = [...state]
        switch (action) {
            case 'set':
                return updatedFields
            case 'add':
                const newRefValue = {
                    value: null,
                    color: null,
                    label: null,
                    alwaysShow: null
                }
                newState.push(newRefValue)
                return newState
            case 'update':
                newState[index || 0] = updatedFields
                return newState
            case 'remove':
                if (newState.length > 0) {
                    newState.splice(index, 1)
                }
                return newState
            default:
                return newState
        }
    }

    const { getGroups, isLoading, groups } = useTracker()
    const [group, setGroup] = useState(null)
    const [hasSubmitted, setHasSubmitted] = useState(false)
    const [nameInput, setNameInput] = useState('')
    const [unitInput, setUnitInput] = useState('')
    const [notesInput, setNotesInput] = useState('')
    const [typeInput, setTypeInput] = useState('group')
    const [refValues, valueDispatch] = useReducer(refValuesReducer, [])
    const [refTimes, timeDispatch] = useReducer(refValuesReducer, [])
    const [minYInput, setMinYInput] = useState('')
    const [aggregateOptions, setAggregateOptions] = useState([])
    const [selectedAggOptions, setSelectedAggOptions] = useState([])

    const getAggregateOptions = useCallback(() => {
        if (!groups || !groups.length) return
        let options = groups.filter(x => !x.isAggregate)
        if (group) {
            options = options.filter(x => x.id !== group.id)
        }
        setAggregateOptions(options)
        if (group && group.metadata && group.metadata.aggregateGroups) {
            setSelectedAggOptions(group.metadata.aggregateGroups)
        }
    }, [group, groups])

    const loadInputs = useCallback(() => {
        if (group) {
            setNameInput(group.name)
            setUnitInput(group.unit)
            setNotesInput(group.notes ? group.notes : '')
            setTypeInput(group.isAggregate ? 'aggregate' : 'group')
            if (group.metadata && group.metadata.minY) {
                setMinYInput(group.metadata.minY)
            }
            if (group.metadata && group.metadata.referenceValues) {
                valueDispatch({ action: 'set', updatedFields: group.metadata.referenceValues})
            }
            if (group.metadata && group.metadata.referenceTimes) {
                timeDispatch({ action: 'set', updatedFields: group.metadata.referenceTimes})
            }
        }
    }, [group])

    const generateMetadata = () => {
        let result = {minY: minYInput ? parseFloat(minYInput) : null}
        if (typeInput === 'aggregate') {
            result.aggregateGroups = [...selectedAggOptions]
        }
        if (refValues.length) {
            result.referenceValues = [...refValues]
        }
        if (refTimes.length) {
            result.referenceTimes = [...refTimes]
        }
        return result
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setHasSubmitted(true)
        if (nameInput.trim() === '' || (unitInput.trim() === '' && typeInput === 'group')) {
            return
        }
        else if (!group) {
            let body = {
                name: nameInput.trim(),
                unit: unitInput.trim(),
                notes: notesInput === '' ? null : notesInput.trim(),
                isAggregate: typeInput === 'aggregate',
                metadata: generateMetadata(),
            }
            let response = await fetcher.post('tracker/groups', body)
            if (response) {
                await getGroups()
                navigate('/tracker/groups')
            }
        }
        else if (group) {
            let body = {
                name: nameInput.trim(),
                unit: unitInput.trim(),
                notes: notesInput === '' ? null : notesInput.trim(),
                isAggregate: typeInput === 'aggregate',
                metadata: generateMetadata(),
                id: group.id
            }
            let response = await fetcher.put('tracker/groups', body)
            if (response) {
                await getGroups()
                navigate(`/tracker/groups/${group.id}`)
            }
        }
    }

    const handleDelete = async () => {
        let response = await fetcher.del(`tracker/groups/${group.id}`)
        if (response) {
            await getGroups()
            navigate('/tracker/groups')
        }
    }

    const handleAggSelection = (id, checked) => {
        let updatedSelections = [...selectedAggOptions]
        if (checked) {
            updatedSelections.push(id)
        } else {
            updatedSelections = updatedSelections.filter(x => x !== id)
        }
        setSelectedAggOptions(updatedSelections)
    }

    const getGroup = useCallback(async () => {
        if (groups.length && groupId) {
            const group = groups.find(x => x.id === parseInt(groupId, 10))
            setGroup(group)
        }
    }, [groupId, groups])

    useEffect(() => {
        getGroup()
    }, [getGroup])

    useEffect(() => {
        loadInputs()
    }, [loadInputs])

    useEffect(() => {
        getAggregateOptions()
    }, [getAggregateOptions])

    if (isLoading) {
        return <Loading />
    }

    return (
        <Card header={`${group ? 'Edit' : 'Create'} Data Group`} className="tracker-group-dialog">
            <Form>
                <FormGroup row>
                    <Label for="type" xl={2} lg={3}>Type</Label>
                    <Col sm={9}>
                        <FormGroup check inline>
                            <Label check>
                                <Input 
                                    type="radio"
                                    name="groupType" 
                                    value="group" 
                                    checked={typeInput === 'group'}
                                    onChange={e => setTypeInput(e.target.value)}
                                />Group
                            </Label>
                        </FormGroup>
                        <FormGroup check inline>
                            <Label check>
                                <Input 
                                    type="radio"
                                    name="groupType" 
                                    value="aggregate" 
                                    checked={typeInput === 'aggregate'}
                                    onChange={e => setTypeInput(e.target.value)}
                                />Aggregate
                            </Label>
                        </FormGroup>
                        <Tooltip text="Aggregates show data from multiple groups at once." />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="groupName" xl={2} lg={3}>Group Name</Label>
                    <Col sm={9}>
                        <Input
                            type="text"
                            id="groupName"
                            placeholder="Group Name"
                            value={nameInput}
                            onChange={e => setNameInput(e.target.value)}
                            invalid={hasSubmitted && nameInput.trim() === ''}
                        />
                        <FormFeedback>Please enter a name for the data group.</FormFeedback>
                    </Col>
                </FormGroup>
                {typeInput === 'group' && 
                    <FormGroup row>
                        <Label for="dataUnit" xl={2} lg={3}>Data Unit</Label>
                        <Col sm={9}>
                            <Input 
                                type="text"
                                id="dataUnit"
                                placeholder="e.g. lb, Rating, sec"
                                value={unitInput}
                                onChange={e => setUnitInput(e.target.value)}
                                invalid={hasSubmitted && unitInput.trim() === ''}
                            />
                            <FormFeedback>Please enter a unit.</FormFeedback>
                        </Col>
                    </FormGroup>}
                {typeInput === 'aggregate' && 
                    <FormGroup row>
                        <Label for="groups" xl={2} lg={3}>Included Groups</Label>
                        <Col sm={9}>
                            {aggregateOptions.map((g, i) => 
                                <FormGroup check key={`agg-${g.id}`}>
                                    <Label check>
                                        <Input 
                                            type="checkbox"
                                            checked={selectedAggOptions.includes(g.id)} 
                                            onChange={e => handleAggSelection(g.id, e.target.checked)}
                                        />
                                        {g.name}
                                    </Label>
                                </FormGroup>
                            )}
                        </Col>
                    </FormGroup>}
                <FormGroup row>
                    <Label xl={2} lg={3}>Reference Values</Label>
                    <Col sm={9}>
                        <Tooltip text="Add a reference value to show a horizontal dotted line on the chart." />
                        {refValues.map((v, i) => 
                            <ReferenceValueInput
                                key={`ref-value-input-${i}`}
                                index={i}
                                type="value"
                                value={v.value}
                                color={v.color}
                                label={v.label}
                                alwaysShow={v.alwaysShow}
                                onChange={valueDispatch}
                                onDelete={() => valueDispatch({ action: 'remove', index: i })}
                            />
                        )}
                        <Button 
                            type="button"
                            color="primary"
                            onClick={() => valueDispatch({ action: 'add' })}
                            className="mt-2"
                        >
                            <span className="d-flex align-items-center"><AddCircle className="me-1" />Ref Value</span>
                        </Button>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label xl={2} lg={3}>Reference Times</Label>
                    <Col sm={9}>
                        <Tooltip text="Add a reference time to show a vertical dotted line on the chart." />
                        {refTimes.map((v, i) => 
                            <ReferenceValueInput
                                key={`ref-time-input-${i}`}
                                index={i}
                                type="time"
                                value={dateToString(new Date(v.value))}
                                color={v.color}
                                label={v.label}
                                alwaysShow={v.alwaysShow}
                                onChange={timeDispatch}
                                onDelete={() => timeDispatch({ action: 'remove', index: i })}
                            />
                        )}
                        <Button 
                            type="button"
                            color="primary"
                            onClick={() => timeDispatch({ action: 'add' })}
                            className="mt-2"
                        >
                            <span className="d-flex align-items-center"><AddCircle className="me-1" />Ref Time</span>
                        </Button>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="yMin" xl={2} lg={3}>Y-Axis Minimum Value</Label>
                    <Col sm={9}>
                        <Input 
                            type="number"
                            id="yMin"
                            value={minYInput}
                            onChange={e => setMinYInput(e.target.value)}
                        />
                        <FormText>This is zero by default.</FormText>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="notes" xl={2} lg={3}>Notes</Label>
                    <Col sm={9}>
                        <Input
                            type="textarea"
                            value={notesInput}
                            onChange={e => setNotesInput(e.target.value)}
                        />
                    </Col>
                </FormGroup>
                {group !== null && 
                    <FormGroup row>
                        <Label xl={2} lg={3}>Sharing</Label>
                        <Col sm={9}>
                            <TrackerGroupSharing group={group} />
                        </Col>
                    </FormGroup>}
                <FormGroup row>
                    <Label xl={2} lg={3}></Label>
                    <Col sm={9}>
                        <Button
                            type="submit"
                            color="success"
                            onClick={async (e) => await handleSubmit(e)}
                        >
                            <span className="d-flex align-items-center"><Done className="me-1" />Save</span>
                        </Button>
                        <Button
                            type="button"
                            color="secondary"
                            className="ms-1"
                            onClick={() => navigate(group ? `/tracker/groups/${group.id}` : '/tracker/groups')}
                        >
                            <span className="d-flex align-items-center"><Clear className="me-1" />Cancel</span>
                        </Button>
                        {group !== null &&
                            <Button
                                type="button"
                                color="danger"
                                onClick={handleDelete}
                                className="ms-1"
                            >
                                <span className="d-flex align-items-center"><Delete className="me-1" />Delete</span>
                            </Button>}
                    </Col>
                </FormGroup>
            </Form>
        </Card>
    )
}
