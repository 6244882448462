import React, { useEffect } from 'react'
import { useLocation, navigate } from '@reach/router'

export default function DropboxRedirect() {
    const location = useLocation()

    useEffect(() => {
        if (location.search) {
            const urlParams = new URLSearchParams(location.search)
            const codeParam = urlParams.get('code')
            if (codeParam) {
                localStorage.setItem('dbCode', codeParam)
                navigate('/music/browse')
            }
        }
    }, [location])
    
    return (
        <div>
            <p>Authorizing...</p>
        </div>
    )
}
