import React from 'react'
import './chord.scss'
import { frequencies } from '../frequencies'

export default function Chord({ symbol, bg, notes }) {

    const generateNote = (note) => {
        const frequency = frequencies[note];
        if (!frequency) return;
        const context1 = new AudioContext()
        const o1 = context1.createOscillator()
        o1.type = 'sine'
        const g1 = context1.createGain()
        g1.gain.exponentialRampToValueAtTime(0.00001, context1.currentTime + 1.5);
        o1.frequency.value = frequency;
        o1.connect(g1);
        g1.connect(context1.destination);
        o1.start(0);
    }
    
    const generateNotes = notes => {
        if (!notes) return
        notes.forEach(x => generateNote(x))
    }
    
    const playChordFromString = notesString => {
        if (!notesString) return;
        const notes = notesString
                        .split(',')
                        .map(note => note.trim().toLowerCase());
        generateNotes(notes)
    }

    return (
        <div 
            className="chord" 
            style={{ backgroundColor: bg, color: '#000' }}
            onClick={() => playChordFromString(notes)}
        >
            {symbol}
        </div>
    )
}
