import { NotificationManager } from 'react-notifications'
import { getExtensionFromMimeType } from '../utils'

const basePath = 'api/'

const get = async (path) => {
    try {
        const request = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        }
        const response = await fetch(basePath + path, request)
        return await handleResponse(response)
    } catch (err) {
        NotificationManager.error(err)
        return null
    }
}

const getFile = async (filePath, folder = 'blog') => {
    const request = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    }
    const response = await fetch(`${basePath}files/${folder}/${filePath}`, request)
    if (response.ok) {
        return response
    } else {
        await handleResponse(response)
    }
}

const getFileDirect = async (path) => {
    const request = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    }
    const response = await fetch(basePath + path, request)
    if (response.ok) {
        return response
    } else {
        await handleResponse(response)
    }
}

const post = async (path, body) => {
    try {
        const request = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(body)
        }
        const response = await fetch(basePath + path, request)
        return await handleResponse(response)
    } catch (err) {
        NotificationManager.error(err)
        return null
    }
}

const put = async (path, body) => {
    try {
        const request = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(body)
        }
        const response = await fetch(basePath + path, request)
        return await handleResponse(response)
    } catch (err) {
        NotificationManager.error(err)
        return null
    }
}

const patch = async (path, body = {}) => {
    try {
        const request = {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(body)
        }
        const response = await fetch(basePath + path, request)
        return await handleResponse(response)
    } catch (err) {
        NotificationManager.error(err)
        return null
    }
}

const del = async (path, body = null) => {
    try {
        const request = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(body)
        }
        const response = await fetch(basePath + path, request)
        return await handleResponse(response)
    } catch (err) {
        NotificationManager.error(err)
        return null
    }
}

const postFile = async (path, body) => {
    try {
        const request = {
            method: 'POST',
            body: body
        }
        const response = await fetch(basePath + path, request)
        return await handleResponse(response)
    } catch (err) {
        NotificationManager.error(err)
        return null
    }
}

const open = async (filePath, filename = 'export', folder = 'blog') => {
    try {
        const resp = await getFile(filePath, folder)
        const blob = await resp.blob()
        const extension = getExtensionFromMimeType(blob.type)
        const dateString = new Date().toLocaleDateString("en-US", {year: 'numeric', day: '2-digit', month: '2-digit'})
        const url = URL.createObjectURL(blob)
        const tempLink = document.createElement('a')
        tempLink.style.display = 'none'
        tempLink.href = url
        tempLink.setAttribute('download', `${filename}_${dateString}.${extension}`)
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
    } catch (err) {
        NotificationManager.error(err)
    }
}

const openDirect = async (filePath, filename = 'export') => {
    try {
        const resp = await getFileDirect(filePath)
        const blob = await resp.blob()
        const extension = getExtensionFromMimeType(blob.type)
        const dateString = new Date().toLocaleDateString("en-US", {year: 'numeric', day: '2-digit', month: '2-digit'})
        const url = URL.createObjectURL(blob)
        const tempLink = document.createElement('a')
        tempLink.style.display = 'none'
        tempLink.href = url
        tempLink.setAttribute('download', `${filename}_${dateString}.${extension}`)
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
    } catch (err) {
        NotificationManager.error(err)
    }
}

const getJson = async (response) => {
    const contentType = response.headers.get('content-type')
    if (contentType && contentType.includes('json'))
        return await response.json()
    return response
}

const handleResponse = async (response) => {
    if (!response.ok) {
        let errorMessage = 'Oops! Something went wrong.'
        try {
            const responseJson = await getJson(response)
            if (responseJson.message)
                errorMessage = responseJson.message
            else if (responseJson.title)
                errorMessage = responseJson.title
        } catch {
            throw errorMessage
        }
        throw errorMessage
    }

    try {
        return await getJson(response)
    } catch {
        return response
    }
}

export const fetcher = { get, getFile, post, put, patch, del, postFile, open, openDirect, getFileDirect }