export const frequencies = {
    c1: 32.7,
    c2: 65.41,
    c3: 130.8,
    c4: 261.6,
    c5: 523.3,
    c6: 1047,
    c7: 2093,
    c8: 4186,

    cs1: 34.65,
    cs2: 69.3,
    cs3: 138.6,
    cs4: 277.2,
    cs5: 554.4,
    cs6: 1109,
    cs7: 2217,
    cs8: 4435,

    d1: 36.71,
    d2: 73.42,
    d3: 146.8,
    d4: 293.7,
    d5: 587.3,
    d6: 1175,
    d7: 2349,
    d8: 4699,

    ds1: 38.89,
    ds2: 77.78,
    ds3: 155.6,
    ds4: 311.1,
    ds5: 622.3,
    ds6: 1245,
    ds7: 2489,
    ds8: 4978,

    e1: 41.2,
    e2: 82.41,
    e3: 164.8,
    e4: 329.6,
    e5: 659.3,
    e6: 1319,
    e7: 2637,
    e8: 5274,

    f1: 43.65,
    f2: 87.31,
    f3: 174.6,
    f4: 349.2,
    f5: 698.5,
    f6: 1397,
    f7: 2794,
    f8: 5588,

    fs1: 46.25,
    fs2: 92.5,
    fs3: 185,
    fs4: 370,
    fs5: 740,
    fs6: 1480,
    fs7: 2960,
    fs8: 5920,

    g1: 49,
    g2: 98,
    g3: 196,
    g4: 392,
    g5: 784,
    g6: 1568,
    g7: 3136,
    g8: 6272,

    gs1: 51.91,
    gs2: 103.82,
    gs3: 207.7,
    gs4: 415.3,
    gs5: 830.6,
    gs6: 1661,
    gs7: 3322,
    gs8: 6645,

    a1: 55,
    a2: 110,
    a3: 220,
    a4: 440,
    a5: 880,
    a6: 1760,
    a7: 3520,
    a8: 7040,

    as1: 58.27,
    as2: 116.5,
    as3: 233.1,
    as4: 466.2,
    as5: 932.3,
    as6: 1865,
    as7: 3729,
    as8: 7459,

    b1: 61.74,
    b2: 123.5,
    b3: 246.9,
    b4: 493.9,
    b5: 987.8,
    b6: 1976,
    b7: 3951,
    b8: 7902
}