import React, { useState, useEffect } from 'react'
import { Calendar, CalendarProvider, CalendarItemDialog } from '.'
import {  useHoney } from '../Honey'
import { sortListItemsByDate } from '../../utils'
import { Router } from '@reach/router'
import { useAuth } from '../../main/auth'

export default function CalendarModule() {
    const { userSettings } = useAuth()
    const { lists, getLists, getPermissions } = useHoney()
    const [events, setEvents] = useState([])

    useEffect(() => { 
        getLists()
        getPermissions()
    }, [getLists, getPermissions])

    useEffect(() => {
        if (lists.length && userSettings) {
            let allLists = lists.filter(list => !userSettings.hideLists.includes(list.listId)).map(list => {
                return {
                    listId: list.listId,
                    name: list.name,
                    details: JSON.parse(list.detailsJSON)
                }
            })
            allLists.forEach(list => list.details.items.forEach(item => item.listId = list.listId))
            let allItems = allLists.map(list => list.details.items).flat()
            let sortedItems = sortListItemsByDate(allItems)
            setEvents(sortedItems)
        }            
    }, [lists, userSettings])

    return (
        <CalendarProvider>
            <Router>
                <CalendarItemDialog path=":calendarDate/:listId" />
                <Calendar default events={events} />
            </Router>
        </CalendarProvider>
    )
}