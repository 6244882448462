import React from 'react'

export default function Resources() {
    return (
        <div>
            <h1>Resources</h1>

            <h2>General pages</h2>
            <ul>
                <li><a href="https://www.speedsolving.com" target="_blank" rel="noopener noreferrer">SpeedSolving.com</a></li>
                <li><a href="http://algdb.net" target="_blank" rel="noopener noreferrer">Algorithm Database</a></li>
                <li><a href="https://www.worldcubeassociation.org" target="_blank" rel="noopener noreferrer">World Cube Association (WCA)</a></li>
            </ul>

            <h2>Personal pages</h2>
            <ul>
                <li><a href="https://www.youtube.com/channel/UCqTVfT9JQqhA6_Hi_h_h97Q" target="_blank" rel="noopener noreferrer">J Perm's YouTube Channel</a> (Dylan Wang)</li>
                    <ul>
                        <li><a href="https://drive.google.com/file/d/1nzAXYUWZJ6H2wIOXaHdWXep3W57tArbR/view" target="_blank" rel="noopener noreferrer">J Perm's F2L Document</a></li>
                    </ul>
                <li><a href="https://cubeskills.com/" target="_blank" rel="noopener noreferrer">CubeSkills</a> (Feliks Zemdegs)</li>
                <li><a href="http://www.kungfoomanchu.com/" target="_blank" rel="noopener noreferrer">Rubik's Cube Guides</a> (Andy Klise)</li>
                <li><a href="http://lar5.com/cube/" target="_blank" rel="noopener noreferrer">Petrus Method</a> (Lars Petrus)</li>
                <li><a href="https://sites.google.com/view/kianroux" target="_blank" rel="noopener noreferrer">Roux Method Guide</a> (Kian Mansour)</li>
            </ul>

            <h2>Supplies</h2>
            <ul>
                <li><a href="http://thecubicle.us" target="_blank" rel="noopener noreferrer">The Cubicle</a></li>
                <li><a href="http://speedcubeshop.com" target="_blank" rel="noopener noreferrer">SpeedCubeShop</a></li>
            </ul>

            <h2>Tools</h2>
            <ul>
                <li><a href="http://cstimer.net" target="_blank" rel="noopener noreferrer">csTimer</a></li>
                <li><a href="http://cubegrass.appspot.com/block_trainer/" target="_blank" rel="noopener noreferrer">Block Trainer</a></li>
                <li><a href="http://cube.crider.co.uk/coracle.html" target="_blank" rel="noopener noreferrer">Corner Lookahead Drill</a></li>
            </ul>

        </div>
    )
}
