import React, { useState, useEffect, useCallback } from 'react'
import { BarChart, Bar, ReferenceLine, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useTheming } from '../../main/theme/ThemeProvider';
import './stacked-bar-graph.scss'

export default function StackedBarGraph({ data, groups, metadata }) {
    const { getThemeColor } = useTheming()
    const [chartData, setChartData] = useState([])

    const getChartData = useCallback(() => {
        if (data && data.data) {
            const result = data.data.map(x => {
                const properties = Object.keys(x.properties)
                const time = new Date(x.date)
                let item = {
                    name: time.getTime(),
                    date: x.date
                }
                properties.forEach(p => item[p] = x.properties[p])
                return item
            })
            setChartData(result)
        }
    }, [data])

    useEffect(() => {
        getChartData()
    }, [getChartData])

    if (chartData === null || !chartData.length) {
        return null
    }

    const colors = [
        '#a33540', // vintage red
        '#d0b544', // gold
        '#01aba8', // teal
        '#238257', // moss cottage green, brightened. originally #40544b
        '#314383', // indigo blue
        '#87559c', // cabaret purple
        '#314383',
        '#7b927e',
        '#772c30',
        '#a2af60',
        '#c67469',
        '#57674c',
        '#f0d584',
        '#728492',
        '#364564',
        '#949087',
        '#F00', 
        '#F80', 
        '#FF0', 
        '#080', 
        '#00F', 
        '#08F', 
        '#888',
        '#AAA',
        '#555'
    ]

    const getValue = value => {
        if (!value) {
            return null
        }
        const hasRemainder = value % Math.round(value) > 0
        return hasRemainder ? value.toFixed(2) : value
    }
    
    const renderTooltip = ({ active, payload, label }) => {
        if (active && payload && payload[0]) {
            let date = new Date(payload[0].payload.name)
            if (date) return (
                <div className="custom-tooltip">
                    <table>
                        <tbody>
                            <tr>
                                <th colSpan={2}>{date.toLocaleDateString()}</th>
                            </tr>
                            {payload.map((x,i) =>
                                <tr key={`legend-row-${i}`}>
                                    <td>
                                        <div className="group-key">
                                            <div style={{ color: x.fill }}>
                                                {x.dataKey.substring(0,1).toUpperCase()}{x.dataKey.substring(1)}
                                            </div>
                                        </div>
                                    </td>
                                    <td>{getValue(x.value)}</td>
                                </tr>
                            )}
                            <tr>
                                <th>Total</th>
                                <th>{getValue(payload.reduce((a, b) => ({value: a.value + b.value})).value)}</th>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )
        }
        return null
    }

    return (
        <div className="stacked-bar-graph">
            <ResponsiveContainer width='100%' height={300}>
                <BarChart width={400} height={300} data={chartData}>
                    <CartesianGrid strokeDasharray="3 3" stroke={getThemeColor('bs-gray-400')} />
                    <XAxis 
                        dataKey="name"
                        stroke={getThemeColor('bs-body-color')}
                        height={50}
                        scale="time"
                        type="number"
                        domain={['dataMin', 'dataMax']}
                        angle={-25}
                        textAnchor="end"
                        padding={{ left: 20, right: 20 }}
                        tickFormatter={unixTime => {
                            let date = new Date(unixTime)
                            return date.toLocaleString('en', {month: 'numeric', day: 'numeric'})
                        }}
                    />
                    <YAxis 
                        width={40}
                        domain={metadata && metadata.minY ? [metadata.minY, 'auto'] : [0, 'auto']} 
                        stroke={getThemeColor('bs-body-color')}
                    />
                    <Tooltip content={renderTooltip} />
                    {metadata && metadata.referenceValues ?
                        metadata.referenceValues.map((v, i) =>
                            <ReferenceLine
                                key={`ref-value-line-${i}`}
                                y={v.value}
                                stroke={v.color}
                                strokeDasharray="4 4"
                                label={{ value: v.label, fill: v.color }}
                                ifOverflow={v.alwaysShow ? "extendDomain" : null}
                            />
                        )
                        : null}
                    {metadata && metadata.referenceTimes ?
                        metadata.referenceTimes.map((v, i) =>
                            <ReferenceLine
                                key={`ref-time-line-${i}`}
                                x={new Date(v.value).getTime()}
                                stroke={v.color}
                                strokeDasharray="4 4"
                                label={{ value: v.label, fill: v.color }}
                                ifOverflow={v.alwaysShow ? "extendDomain" : null}
                            />
                        )
                        : null}
                    <Legend
                        iconType="square"
                        formatter={(value, entry, index) => `${value.substring(0,1).toUpperCase()}${value.substring(1)}`}
                    />
                    {groups.map((g, i) => 
                        <Bar
                            key={`bar-${i}`}
                            dataKey={g.nameJson}
                            stackId="a"
                            fill={colors[i]}
                            maxBarSize={20}
                        />
                    )}
                </BarChart>
            </ResponsiveContainer>
        </div>
    )
}