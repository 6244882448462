import React from 'react'
import { Link } from '@reach/router'

export default function Tutorials() {
    return (
        <div>
            <h1>Tutorials</h1>

            <h2 className="mt-3">First Things</h2>

            <Link className="btn btn-outline-primary" to="/cubetools/notation">Notation</Link>
            <Link className="btn btn-outline-primary ms-2" to="/cubetools/triggers">Common Triggers</Link>

            <h2 className="mt-3">3x3</h2>
            
            <Link className="btn btn-outline-primary" to="/cubetools/lbl">Beginner (Layer By Layer)</Link>
            <Link className="btn btn-outline-primary ms-2" to="/cubetools/cfop">Intermediate (CFOP Method)</Link>
            <Link className="btn btn-outline-primary ms-2" to="/cubetools/roux">Roux Method</Link>
            
            <h2 className="mt-3">2x2</h2>
        
            <Link className="btn btn-outline-primary" to="/cubetools/2x2">Beginner</Link>
            <Link className="btn btn-outline-primary ms-2" to="/cubetools/ortega">Intermediate (Ortega/Varasano Method)</Link>
        </div>
    )
}
