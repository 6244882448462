import React, { useState, useEffect, useCallback } from 'react'
import { navigate } from '@reach/router'
import { Loading, fetcher } from '../../common'
import { Edit, FileCopy, Launch } from '@material-ui/icons'
import { Tag } from '.'
import { useAuth } from '../../main/auth'
import { Button } from 'reactstrap'
import './recipe-view.scss'

export default function RecipeView({ recipeId }) {
    const { user } = useAuth()
    const [recipe, setRecipe] = useState(null)
    const [isLoading, setIsLoading] = useState(true) 
    const isOwner = recipe && user ? recipe.userId === user.userId : false
    const tagArray = recipe && recipe.tags ? recipe.tags.split(',') : []

    const openRecipeFile = async () => {
        await fetcher.open(recipe.location, recipe.name, 'recipes')
    }

    const getRecipe = useCallback(async () => {
        if (recipeId && recipeId !== 'new') {
            setIsLoading(true)
            const resp = await fetcher.get(`recipes/${recipeId}`)
            if (resp) {
                setRecipe(resp)
            }
        }
        setIsLoading(false)
    }, [recipeId])

    useEffect(() => {
        getRecipe()
    }, [getRecipe])

    if (isLoading || !recipe) return <Loading />

    return (
        <div className="recipe-view">
            <div className="name">
                <div className="me-auto">{recipe.name}</div>
                <div className="ms-auto">
                    {isOwner 
                        ? <Button onClick={() => navigate(`whisk/${recipe.recipeId}/edit`)} color="primary">
                            <span className="d-flex align-items-center"><Edit className="me-1" />Edit</span>
                        </Button>
                        : <Button onClick={() => navigate(`whisk/${recipe.recipeId}/copy`)} color="primary">
                            <span className="d-flex align-items-center"><FileCopy className="me-1" />Copy</span>
                        </Button>
                    }
                </div>
            </div>
            <div className="tag-list">
                {tagArray.map(x => 
                    <Tag key={x} text={x} />
                )}
            </div>
            {recipe.type === 'text' &&
                <div>
                    <textarea className="description" value={recipe.description} readOnly />
                </div>
            }
            {recipe.type === 'url' &&
                <div>
                    <a href={recipe.location} rel="noopener noreferrer" target="_blank">Click to see recipe</a>
                </div>
            }
            {recipe.type === 'file' &&
                <div>
                    <Button color="primary" onClick={openRecipeFile}>
                        <span className="d-flex align-items-center"><Launch className="me-1" />View Recipe</span>
                    </Button>
                </div>
            }
        </div>
    )
}