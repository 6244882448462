import React, { useState, useCallback, useMemo } from "react";
import { useAuth } from "../../main/auth";
import { fetcher } from "../../common";
import { navigate } from "@reach/router";

export const HoneyContext = React.createContext();

export default function HoneyProvider({ children }) {
    const { user, userSettings } = useAuth();
    const [lists, setLists] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [activeListId, setActiveListId] = useState(0);
    const activeList = useMemo(() => lists.find((l) => l.listId === activeListId), [lists, activeListId]);
    
    const isListCreator = useCallback((list) => (list && user ? list.userId === user.userId : false), [user]);
    
    const canEditList = useCallback(
        (list) => {
            const isCreator = isListCreator(list);
            let hasEditPermission =
            permissions.length && list && user
                    ? permissions.find((x) => x.listId === list.listId && x.userId === user.userId && x.canEdit)
                    : false;
                    return isCreator || hasEditPermission;
                },
                [user, isListCreator, permissions]
                );

                const isListOwner = isListCreator(activeList);
    const canEdit = canEditList(activeList);
    const getItems = useCallback((list) => (list && list.detailsJSON ? JSON.parse(list.detailsJSON).items : []), []);
    const items = getItems(activeList);

    const getLists = useCallback(async () => {
        setIsLoading(true);
        let lists = await fetcher.get("list");
        if (lists) {
            setLists(lists);
        }
        setIsLoading(false);
    }, [setIsLoading]);

    const getPermissions = useCallback(async () => {
        let response = await fetcher.get("listpermission");
        if (response) setPermissions(response);
    }, []);

    const addList = async (listName) => {
        setIsLoading(true);
        let detail = {
            items: [],
        };
        let body = {
            userId: user.userId,
            name: listName.trim(),
            detailsJSON: JSON.stringify(detail),
        };
        let response = await fetcher.post("list", body);
        await getLists();
        if (response && response.id) {
            navigate(`/honey/${response.id}`);
        } else {
            setIsLoading(false);
        }
    };

    const renameList = async (list, userInput) => {
        setIsLoading(true);
        if (userInput.trim() === "") return;
        let body = {
            listId: list.listId,
            name: userInput.trim(),
            detailsJSON: list.detailsJSON,
            concurrency: list.concurrency,
        };
        let response = await fetcher.put("list", body);
        if (response) {
            await getLists();
            navigate(`/honey/${list.listId}`);
        } else {
            setIsLoading(false);
        }
    };

    const deleteList = async (listId) => {
        setIsLoading(true);
        let response = await fetcher.del(`list/${listId}`);
        if (response) {
            await getLists();
            navigate("/honey");
        } else {
            setIsLoading(false);
        }
    };

    const editItem = async (itemIndex, newValue, date, description) => {
        setIsLoading(true);
        if (!activeList || !items.length) return;
        let updatedItems = items.map((item, index) => {
            if (index === itemIndex)
                return {
                    name: newValue,
                    date: date,
                    description: description,
                };
            else return item;
        });
        let details = {
            items: [...updatedItems],
        };
        let body = {
            listId: activeListId,
            name: activeList.name,
            detailsJSON: JSON.stringify(details),
            concurrency: activeList.concurrency,
        };
        let response = await fetcher.put("list", body);
        if (response) {
            await getLists();
        } else {
            setIsLoading(false);
        }
    };

    const changeItemDate = async (item, date) => {
        let itemList = lists.find((x) => x.listId === item.listId);
        let isCreator = itemList.userId === user.userId;
        let hasEditPermission = permissions.length
            ? permissions.find((x) => x.listId === itemList.listId && x.userId === user.userId && x.canEdit)
            : false;
        if (isCreator || hasEditPermission) {
            let storedItems = JSON.parse(itemList.detailsJSON).items;
            let itemIndex = storedItems.findIndex((x) => x.name === item.name);
            let updatedItems = storedItems.map((storedItem, index) => {
                if (index === itemIndex) {
                    return {
                        name: storedItem.name,
                        date: date,
                        description: storedItem.description,
                    };
                } else {
                    return storedItem;
                }
            });
            let details = {
                items: [...updatedItems],
            };
            let body = {
                listId: itemList.listId,
                name: itemList.name,
                detailsJSON: JSON.stringify(details),
                concurrency: itemList.concurrency,
            };
            let response = await fetcher.put("list", body);
            if (response) {
                await getLists();
            } else {
                setIsLoading(false);
            }
        }
    };

    const deleteItems = async (listId, itemsChecked) => {
        setIsLoading(true);
        const list = lists.find((l) => l.listId === listId);
        const items = getItems(list);
        let updatedItems = items.filter((item, index) => !itemsChecked.includes(index));
        let details = {
            items: [...updatedItems],
        };
        let body = {
            listId: list.listId,
            name: list.name,
            detailsJSON: JSON.stringify(details),
            concurrency: list.concurrency,
        };
        let response = await fetcher.put("list", body);
        if (response) {
            await getLists();
        } else {
            setIsLoading(false);
        }
    };

    const addItems = async (newItems, date, description) => {
        setIsLoading(true);
        if (!activeList) return;
        let newItemsArray = newItems.split(",").map((item) => {
            return {
                name: item.trim(),
                date: date,
                description: description,
            };
        });
        let details = {
            items: [...items, ...newItemsArray],
        };
        let body = {
            listId: activeListId,
            name: activeList.name,
            detailsJSON: JSON.stringify(details),
            concurrency: activeList.concurrency,
        };
        let response = await fetcher.put("list", body);
        if (response) {
            await getLists();
        } else {
            setIsLoading(false);
        }
    };

    const context = {
        lists,
        setLists,
        activeListId,
        setActiveListId,
        permissions,
        setPermissions,
        isLoading,
        setIsLoading,
        canEdit,
        isListOwner,
        activeList,
        items,
        getItems,
        getLists,
        getPermissions,
        addList,
        renameList,
        deleteList,
        editItem,
        deleteItems,
        addItems,
        changeItemDate,
        canEditList,
        isListCreator,
    };

    return <HoneyContext.Provider value={context}>{children}</HoneyContext.Provider>;
}

export function useHoney() {
    const context = React.useContext(HoneyContext);
    if (context === undefined) {
        throw new Error(`useHoney must be used within a HoneyProvider`);
    }
    return context;
}
