import React from 'react'
import { Link } from '@reach/router'
import { Table } from 'reactstrap'
import eo1 from './LL eo1.png'
import eo2 from './LL eo2.png'
import eo3 from './LL eo3.png'
import eo4 from './LL eo4.png'
import yellowCross from './LL yellow cross.png'

export default function LBL4() {
    return (
        <div>
            <h1>3x3 Beginner Tutorial: The Yellow Cross</h1>

            <ul>
                <li><Link to="/cubetools/lbl">Introduction</Link></li>
                <li><Link to="/cubetools/lbl/1">The White Cross</Link></li>
                <li><Link to="/cubetools/lbl/2">The White Corners</Link></li>
                <li><Link to="/cubetools/lbl/3">The Second Layer</Link></li>
                <li>The Yellow Cross</li>
                <li><Link to="/cubetools/lbl/5">The Yellow Top</Link></li>
                <li><Link to="/cubetools/lbl/6">The Final Corners</Link></li>
                <li><Link to="/cubetools/lbl/7">The Final Edges</Link></li>
            </ul>

            <p>The next step is to make a yellow cross on the top of the cube. The algorithm we use for this is (F RUR'U' F').</p>

            <p>After you finish the first two layers, there are four possible configurations of the top edges (ignoring the corners):</p>

            <Table>
                <tr>
                    <th>Yellow Edges</th>
                    <th>Top View</th>
                    <th>Do This</th>
                </tr>
                <tr>
                    <td>4</td>
                    <td><img src={eo1} alt="" /></td>
                    <td>You already have a yellow cross!<br />Skip to the next step.</td>
                </tr>
                <tr>
                    <td>2<br />opposite</td>
                    <td><img src={eo2} alt="" /></td>
                    <td>F (RUR'U') F'</td>
                </tr>
                <tr>
                    <td>2<br />adjacent</td>
                    <td><img src={eo3} alt="" /></td>
                    <td>F (RUR'U')*2 F'</td>
                </tr>
                <tr>
                    <td>None</td>
                    <td><img src={eo4} alt="" /></td>
                    <td>F (RUR'U') F'<br />This gives you two adjacent yellow edges.</td>
                </tr>
            </Table>

            <p>Note: Be sure to orient the top as shown above before using the algorithm.</p>

            <p>Once you have a yellow cross on top, you are ready to <Link to="/cubetools/lbl/5">orient the last layer corners</Link>.</p>

            <div className="text-center">
                <img src={yellowCross} alt="" />
            </div>
        </div>
    )
}
