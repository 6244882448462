import { Link } from '@reach/router'
import React from 'react'
import { armed, hole, kitty, user, monster, sword } from '.'
import './kitty-intro.scss'

export default function KittyIntro() {
    return (
        <div className="kitty-intro">
            <table>
                <tbody>
                    <tr>
                        <td className="text-end">
                            <img src={user} alt="Unarmed player" title="Hang in there, kitty!" />
                            <img src={sword} alt="Sword" title="*glistens*" />
                        </td>
                        <td className="text-center">
                            Your mission:
                        </td>
                        <td></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td className="text-center">
                            Get the weapon!
                        </td>
                        <td>
                            <img src={armed} alt="Armed player" title="You're going down, monster!" />
                            <img src={monster} alt="Monster" title="Rawr!" />
                        </td>
                    </tr>
                    <tr>
                        <td className="text-end">
                            <img src={kitty} alt="Kitty" title="Meow!" />
                        </td>
                        <td className="text-center">
                            Slay the monsters before they get to the kitten!
                        </td>
                        <td></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td className="text-center">
                            Watch out for holes!
                        </td>
                        <td>
                            <img src={hole} alt="Hole" title="Impenetrable darkness" />
                        </td>
                    </tr>
                </tbody>
            </table>

            <div className="instructions">
                <p>Use the arrows or WASD to move.</p>
                <p><strong>Press Spacebar to begin!</strong></p>
            </div>
            
            <table className="credits">
                <tbody>
                    <tr>
                        <td>
                            <div className="credit-category">Level Designs</div>
                            <div>John Culpepper &amp; Bradley Harris</div>
                        </td>
                        <td>
                            <div className="credit-category">Images</div>
                            <div>Marcos Duarte</div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div><Link to="/games/kitty/scores">View High Scores</Link></div>
        </div>
    )
}
