import React from 'react'
import { Link } from '@reach/router'

export default function Roux8() {
    return (
        <div>
            <h1>Roux Tutorial: Advanced Roux</h1>

            <ul>
                <li><Link to="/cubetools/roux">Introduction</Link></li>
                <li><Link to="/cubetools/roux/1">The First Block</Link></li>
                <li><Link to="/cubetools/roux/2">The Second Block</Link></li>
                <li><Link to="/cubetools/roux/3">CMLL (Top Corners)</Link></li>
                <li><Link to="/cubetools/roux/4">LSE: Edge Orientation</Link></li>
                <li><Link to="/cubetools/roux/5">LSE: Permute UL/UR</Link></li>
                <li><Link to="/cubetools/roux/6">LSE: Permute M Slice</Link></li>
                <li><Link to="/cubetools/roux/7">Example Solves</Link></li>
                <li>Advanced Roux</li>
            </ul>

            <p>I am not a Roux expert, but I can point you to those who are.</p>

            <p><a href="https://www.worldcubeassociation.org/persons/2015MANS03" target="_blank" rel="noopener noreferrer">Kian Mansour</a> has achieved sub-7 averages with the Roux method. His <a href="https://sites.google.com/view/kianroux" target="_blank" rel="noopener noreferrer">homepage</a> and <a href="https://www.youtube.com/channel/UCdEm9-DsuOzNoWmTAYNLRoQ" target="_blank" rel="noopener noreferrer">YouTube channel</a> have great resources to help you progress in Roux.</p>

            <p>Some advanced concepts in Roux include:
                <ul>
                    <li>Build the blocks in a different order. Sometimes the center is last!</li>
                    <li>Influencing the second block while building the first.</li>
                    <li>Build the two blocks simultaneously.</li>
                    <li>EOLR: solve edge orientation while setting up UL/UR. Kian has a nice <a href="https://sites.google.com/view/kianroux/eolr" target="_blank" rel="noopener noreferrer">tutorial</a> on this, and <a href="http://grrroux.free.fr/method/Step_4.html" target="_blank" rel="noopener noreferrer">Gilles Roux's tutorial</a> also deals with it.</li>
                </ul>
            </p>

            <p>Happy cubing!</p>
        </div>
    )
}
