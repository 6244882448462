import React, { useState, useEffect, useCallback, useReducer } from 'react'
import { levels } from './levels'
import { abyss, armed, catDies, happyKitty, hole, kitty, 
    monsterScared, userDies, victory, user, monster, sword, ScoreDialog, KittyIntro 
} from '.'
import { Favorite } from '@material-ui/icons'
import Loading from '../../common/Loading'
import './kitty-game.scss'

export default function KittyGame() {
    const gridMin = 0
    const [gridMax, setGridMax] = useState(0)
    const [message, setMessage] = useState('')
    const [gameActive, setGameActive] = useState(false)
    const [showScoreDialog, setShowScoreDialog] = useState(false)
    const [currentLevel, setCurrentLevel] = useState(0)
    const [loadingLevel, setLoadingLevel] = useState(false)
    const [levelComplete, setLevelComplete] = useState(true)
    const [gameStarted, setGameStarted] = useState(false)
    const level = levels.length && currentLevel ? levels[currentLevel - 1] : null
    
    const isHole = useCallback((x, y) => {
        return level && x !== undefined && y !== undefined && level[y][x] === '*'
    }, [level])

    const isWall = useCallback((x, y) => {
        return level && x !== undefined && y !== undefined && level[y][x] === '='
    }, [level])

    const comparePoints = (a, b) => {
        if (a === undefined || b === undefined || a[0] === undefined || b[0] === undefined) {
            return false
        }
        return a[0] === b[0] && a[1] === b[1]
    }

    const [kittyPoint, setKittyPoint] = useState([])
    const [userImg, setUserImg] = useState(user)
    const [monsterImg, setMonsterImg] = useState(monster)
    const [kittyImg, setKittyImg] = useState(kitty)

    const moveUserReducer = (state, { action, value }) => {
        if (action === 'set') return value
        if (!gameActive) return state
        if (userImg === victory) {
            setUserImg(armed)
        }
        let x = state[0]
        let y = state[1]

        if (x === undefined || y === undefined) return state

        switch (action) {
            case 'left':
                if (x > gridMin) x--
                break
            case 'up':
                if (y > gridMin) y--
                break
            case 'right':
                if (x < gridMax) x++
                break
            case 'down':
                if (y < gridMax) y++
                break
            default:
                return value
        }

        if (!isWall(x, y) && !comparePoints([x, y], kittyPoint)) {
            return ([x, y])
        } else {
            return state
        }
    }

    const [userPoint, moveUserDispatch] = useReducer(moveUserReducer, [])
    const [monsterPoints, setMonsterPoints] = useState([])
    const [weaponPoint, setWeaponPoint] = useState([])

    const [userArmed, setUserArmed] = useState(false)
    const [kittensSaved, setKittensSaved] = useState(0)
    const [monsterSpeed, setMonsterSpeed] = useState(500)
    const [userLives, setUserLives] = useState(2)

    const addLife = useCallback(() => {
        setUserLives(x => x + 1)
    }, [])

    const removeLife = useCallback(() => {
        setUserLives(x => x - 1)
    }, [])

    const findPoint = useCallback((char) => {
        const rowIndex = level.findIndex(row => row.includes(char))
        const columnIndex = level[rowIndex].indexOf(char)
        return [columnIndex, rowIndex]
    }, [level])

    const loadMonsterPoints = useCallback(() => {
        let updatedMonsterPoints = []
        level.forEach((row, rowIndex) => {
            row.split('').forEach((char, columnIndex) => {
                if (char === 'm') {
                    updatedMonsterPoints.push([columnIndex, rowIndex])
                }
            })
        })
        setMonsterPoints(updatedMonsterPoints)
    }, [level])

    const loadInitialPoints = useCallback(() => {
        if (level) {
            moveUserDispatch({ action: 'set', value: [] })
            loadMonsterPoints()
            moveUserDispatch({ action: 'set', value: findPoint('u') })
            setKittyPoint(findPoint('k'))
            setWeaponPoint(findPoint('w'))
        }
    }, [findPoint, loadMonsterPoints, level])

    const moveMonsters = useCallback(() => {
        let updatedMonsterPoints = []
        for (let i = 0; i < monsterPoints.length; i++) {
            updatedMonsterPoints.push(monsterPoints[i])
        }

        for (let i = 0; i < updatedMonsterPoints.length; i++) {
            let monsterPoint = [...updatedMonsterPoints[i]]
            let valid = true
            let moveMonster = true
            let newPoint = [-1, -1]
            do {
                valid = true
                const suggestedMove = getRandomMove()
                newPoint[0] = monsterPoint[0] + suggestedMove[0]
                newPoint[1] = monsterPoint[1] + suggestedMove[1]

                // monster cannot move off grid
                if (newPoint[0] < gridMin || 
                    newPoint[0] > gridMax ||
                    newPoint[1] < gridMin ||
                    newPoint[1] > gridMax) {
                    valid = false
                } 
                else { 
                    if (comparePoints(newPoint, weaponPoint)) {
                        // monster cannot move into the weapon
                        valid = false
                    } else if (isHole(newPoint[0], newPoint[1]) ||
                        isWall(newPoint[0], newPoint[1])) {
                        // monster cannot move into a hole or wall
                        valid = false
                    } else if (updatedMonsterPoints.length > 1) {
                        // monster cannot move into another monster
                        let noOverlap = true
                        updatedMonsterPoints.forEach(point => {
                            if (comparePoints(point, newPoint)) {
                                noOverlap = false
                            }
                        })
                        if (!noOverlap) {
                            moveMonster = false
                        }
                    }
                }

            } while (!valid)
            if (moveMonster) {
                updatedMonsterPoints[i] = newPoint
            }
        }
        setMonsterPoints(updatedMonsterPoints)
    }, [gridMax, isHole, isWall, weaponPoint, monsterPoints])

    const loadLevel = useCallback(async () => {
        setLoadingLevel(true)
        if (userLives < 1) { // new game
            setGameStarted(false)
            setUserLives(2)
            setCurrentLevel(1)
            setKittensSaved(0)
            setMonsterSpeed(500)
            setLoadingLevel(false)
            return
        } else if (currentLevel % 10 === 0) { // gain a life every 10 levels
            addLife()
        }
        loadInitialPoints()
        setUserArmed(false)
        setMessage('Get the weapon and slay the monsters before they get to the kitten!')
        setKittyImg(kitty)
        setMonsterImg(monster)
        setUserImg(user)
        setLoadingLevel(false)
        setGameActive(true)
    }, [userLives, currentLevel, loadInitialPoints, addLife])

    const endLevel = useCallback((newMessage, success = false) => {
        setGameActive(false)
        if (success) {
            setKittyImg(happyKitty)
            setMessage(newMessage)
            setKittensSaved(x => x + 1)
            setLevelComplete(true)
        } else {
            if (userLives === 1) {
                setMessage('Game over. Press Spacebar to play again!')
                removeLife()
                if (kittensSaved) {
                    setShowScoreDialog(true)
                }                    
            } else {
                setMessage(newMessage + ' Press Spacebar to start over.')
                removeLife()
            }
        }
    }, [userLives, removeLife, kittensSaved])

    const onSpacebar = useCallback(() => {
        setGameStarted(true)
        if (!gameActive) {
            if (levelComplete) {
                setLevelComplete(false)
                setCurrentLevel(x => x + 1)
            }
            setLoadingLevel(true)
        }
    }, [gameActive, levelComplete])

    const keyCheck = useCallback(e => {
        const event = e.keyCode || e.charCode

        if (event === 32) {
            if (!showScoreDialog) {
                onSpacebar()
            }
            return
        }

        if (!gameActive) return

        switch (event) {
            case 65: // a
            case 37: // left arrow
                moveUserDispatch({ action: 'left' })
                break
            case 87: // w
            case 38: // up arrow
                moveUserDispatch({ action: 'up' })
                break
            case 68: // d
            case 39: // right arrow
                moveUserDispatch({ action: 'right' })
                break
            case 83: // s
            case 40: // down
                moveUserDispatch({ action: 'down' })
                break
            // case 80: // [
            //     addLife()
            //     break
            default:
                return
        }
    }, [gameActive, onSpacebar, showScoreDialog])
    
    const pointHasAMonster = useCallback(point => {
        if (point === undefined)
            return false
        let result = false
        monsterPoints.forEach(monsterPoint => {
            if (comparePoints(point, monsterPoint)) {
                result = true
            }
        })
        return result
    }, [monsterPoints])

    const checkCollisions = useCallback(() => {
        if (!gameActive) return

        // user + hole
        const fellIntoTheAbyss = isHole(userPoint[0], userPoint[1])
        if (fellIntoTheAbyss) {
            setUserImg(abyss)
            endLevel('You fell into the abyss!')
        }

        // monsters + kitty
        if (pointHasAMonster(kittyPoint)) {
            setKittyImg(catDies)
            endLevel('A monster got the kitty!')
        }

        // user + weapon
        if (comparePoints(userPoint, weaponPoint)) {
            setUserArmed(true)
            setUserImg(armed)
            setMonsterImg(monsterScared)
            setMessage('You got the weapon! Kill the monsters!')
        }

        // user + monsters
        if (pointHasAMonster(userPoint)) {
            if (userArmed) {
                let updatedMonsterPoints = [...monsterPoints]
                let killedMonsterIndex = updatedMonsterPoints.findIndex(x => comparePoints(x, userPoint))
                if (killedMonsterIndex !== -1) {
                    updatedMonsterPoints.splice(killedMonsterIndex, 1)
                }
                setMonsterPoints(updatedMonsterPoints)
                setUserImg(victory)

                if (updatedMonsterPoints.length) {
                    setMessage('You got a monster!')
                } else { // there are no more monsters
                    endLevel('You killed all the monsters! Press Spacebar to continue.', true)
                }
            } else { // user is unarmed
                setUserImg(userDies)
                endLevel('A monster got you!')
            }
        }
    }, [endLevel, isHole, userPoint, weaponPoint, gameActive, kittyPoint, monsterPoints, pointHasAMonster, userArmed])

    const getRandomMove = () => {
        const xChange = Math.floor(Math.random() * 3) - 1
        let yChange = 0
        if (xChange === 0) {
            yChange = Math.floor(Math.random() * 3) - 1
        }
        return [xChange, yChange]
    }

    const getCellSize = difference => {
        const w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
        const smallerSide = w < h ? w : h;
        const base = Math.floor(smallerSide * .75)
        let cellSize = Math.floor(base / level.length)
        if (difference) {
            cellSize += difference;
        }
        return `${cellSize}px`
    }

    const renderCell = (rowIndex, cellIndex, char) => {
        if (comparePoints([cellIndex, rowIndex], userPoint)) {
            return (
                <td key={`row-${rowIndex}-cell-${cellIndex}-${char}`} style={{ height: getCellSize(), width: getCellSize() }}>
                    <img src={userImg} alt='Player' style={{ maxHeight: getCellSize(-2), maxWidth: getCellSize(-2) }} />
                </td>
            )   
        }
        if (pointHasAMonster([cellIndex, rowIndex])) {
            return (
                <td key={`row-${rowIndex}-cell-${cellIndex}-${char}`} style={{ height: getCellSize(), width: getCellSize() }}>
                    {levelComplete ? null : <img src={monsterImg} alt='Monster' title='Rawr!' style={{ maxHeight: getCellSize(-2), maxWidth: getCellSize(-2) }} />}
                </td>
            )
        }
        switch (char) {
            case 'k':
                return (
                    <td key={`row-${rowIndex}-cell-${cellIndex}-${char}`} style={{ height: getCellSize(), width: getCellSize() }}>
                        <img src={kittyImg} alt='Kitty' title='Meow!' style={{ maxHeight: getCellSize(-2), maxWidth: getCellSize(-2) }} />
                    </td>
                )
            case 'w':
                return (
                    <td key={`row-${rowIndex}-cell-${cellIndex}-${char}`} style={{ height: getCellSize(), width: getCellSize() }}>
                        {userArmed ? null : <img src={sword} alt='Sword' title='*glistens*' style={{ maxHeight: getCellSize(-2), maxWidth: getCellSize(-2) }} />}
                    </td>
                )
            case '=':
                return (
                    <td key={`row-${rowIndex}-cell-${cellIndex}-${char}`} className="wall" style={{ height: getCellSize(), width: getCellSize() }} />
                )
            case '*':
                return (
                    <td key={`row-${rowIndex}-cell-${cellIndex}-${char}`} style={{ height: getCellSize(), width: getCellSize() }}>
                        <img src={hole} alt='Hole' title='Impenetrable darkness' style={{ maxHeight: getCellSize(-2), maxWidth: getCellSize(-2) }} />
                    </td>
                )
            default:
                return (
                    <td key={`row-${rowIndex}-cell-${cellIndex}-${char}`} style={{ height: getCellSize(), width: getCellSize() }} />
                )
        }
    }

    const renderRow = (row, rowIndex) => 
        <>
            {row.split('').map((char, cellIndex) =>
                renderCell(rowIndex, cellIndex, char)
            )}
        </>

    const renderLives = useCallback(() => {
        let livesArray = []
        for (let i = 0; i < userLives; i++) {
            livesArray.push('')
        }
        return (
            livesArray.map((life, i) => <Favorite key={`life-${i}`} />)
        )
    }, [userLives])

    useEffect(() => {
        if (currentLevel > 0 && levels.length) {
            if (currentLevel > levels.length) {
                setCurrentLevel(1)
                setMonsterSpeed(x => x / 2)
                addLife()
            }
            if (level) {
                setGridMax(level.length - 1)
                if (loadingLevel) {
                    loadLevel()
                }
            }
        }
    }, [level, currentLevel, addLife, loadLevel, loadingLevel])

    useEffect(() => {
        document.addEventListener("keydown", keyCheck, false)
        return () => {
            document.removeEventListener("keydown", keyCheck, false)
        }
    }, [keyCheck])

    useEffect(() => {
        checkCollisions()
    }, [checkCollisions])

    useEffect(() => {
        let monsterMover 
        if (gameActive) {
            monsterMover = setInterval(moveMonsters, monsterSpeed)
        } else {
            clearInterval(monsterMover)
        }        
        return () => clearInterval(monsterMover)
    }, [gameActive, monsterSpeed, moveMonsters])

    if (!gameStarted) {
        return <KittyIntro />
    }

    return (
        <div className="kitty-game">
            {loadingLevel || !level ? <Loading /> : null}
            {level && !loadingLevel &&
                <table className={`grid ${showScoreDialog ? 'faded' : ''}`}>
                    <thead>
                        <tr>
                            <td colSpan={level.length}>
                                <div className="table-header">
                                    <div className="score">{kittensSaved}</div>
                                    <div className="info">
                                        Level {currentLevel} <span className="text-muted">(Speed {500/monsterSpeed})</span>
                                    </div>
                                    <div className="lives">{renderLives()}</div>
                                </div>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {level.map((row, i) => 
                            <tr key={`grid-row-${i}`}>
                                {renderRow(row, i)}
                            </tr>
                        )}
                    </tbody>
                </table>}
            <div className="message">{message}</div>
            <div className="loading-images">
                {[abyss, armed, catDies, happyKitty, hole, kitty, monsterScared, userDies, victory, user, monster, sword].map(i =>
                    <img src={i} alt='preload' key={i} />
                )}
            </div>
            {showScoreDialog && 
                <div className="score-dialog-container">
                    <ScoreDialog score={kittensSaved} onClose={() => setShowScoreDialog(false)} />
                </div>
            }
        </div>
    )
}
