const isLetter = str => {
    if (str == null || str.length < 1)
        return false;
    return str.length === 1 && str.match(/[a-z]/i);
}

// returns a given string with all instances of a given character removed
const removeFromString = (str, char) => {
	let newStr = '';
	for (let i = 0; i < str.length; i++) {
		if (str[i] !== char)
			newStr += str[i];
	}
	return newStr;
}

const getCoordsBySquareName = squareName => {
    const rank = Math.abs(parseInt(squareName[1], 10) - 8)
    const file = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h'].findIndex(x => x === squareName[0])
    return { rank: rank, file: file}
}

const getSquareContentBySquareName = (squareName, givenPosition) => {
    const { rank, file } = getCoordsBySquareName(squareName)
    return givenPosition[rank][file]
}

const getSquareContentByCoords = (rank, file, givenPosition) => {
    return givenPosition[rank][file]
}

const getPieceColorBySquareName = (squareName, givenPosition) => {
    const squareContent = getSquareContentBySquareName(squareName, givenPosition)
    if (!squareContent || squareContent === '-')
        return null
    if (squareContent === squareContent.toLowerCase())
        return 'b'
    return 'w'
}

const getPieceColorByCoords = (rank, file, givenPosition) => {
    const squareContent = getSquareContentByCoords(rank, file, givenPosition)
    if (!squareContent || squareContent === '-')
        return null
    if (squareContent === squareContent.toLowerCase())
        return 'b'
    return 'w'
}

const getKingSquare = (color, givenPosition) => {
    const king = color === 'w' ? 'K' : 'k'
    for (let key in coordMap) {
        if (getSquareContentBySquareName(key, givenPosition) === king) {
            return key
        }
    }
    return null
}

const getSquaresWithPieceType = (pieceType, givenPosition) => {
    const result = []
    for (let key in coordMap) {
        if (getSquareContentBySquareName(key, givenPosition) === pieceType) {
            result.push(key)
        }
    }
    return result
}

const getFenPiecesFromPieceArray = pieces => {
    let FEN = ''
    let emptySquares = 0
    let numberWritten = false

    pieces.forEach((rank, rankIndex) => {
        emptySquares = 0
        numberWritten = 0
        rank.forEach((square, fileIndex) => {
            if (isLetter(square)) {
                FEN += square
                emptySquares = 0
                numberWritten = false
            } else {
                emptySquares++
                if (fileIndex < 7 && isLetter(rank[fileIndex + 1])) {
                    FEN += emptySquares
                    numberWritten = true
                } else if (fileIndex === 7 && !numberWritten) {
                    FEN += emptySquares
                }
            }
        })
        if (rankIndex < 7) {
            FEN += '/'
        }
    })
    return FEN
}

const getPieceArrayFromFenPieces = fenPieces => {
    const result = []
    const piecesArray = fenPieces.split('/')
    piecesArray.forEach((rank, rankIndex) => {
        result[rankIndex] = []
        for (let i = 0; i < rank.length; i++) {
            if (!isNaN(rank[i])) { // a number x indicates a sequence of x empty squares
                const emptySquares = rank[i]
                for (let j = 0; j < emptySquares; j++) {
                    result[rankIndex].push('-')
                }
            } else if (isLetter(rank[i])) {
                const piece = rank[i]
                result[rankIndex].push(piece)
            }
        }
    })
    return result
}

const coordMap = {
    a8: '00',
    b8: '01',
    c8: '02',
    d8: '03',
    e8: '04',
    f8: '05',
    g8: '06',
    h8: '07',
    
    a7: '10',
    b7: '11',
    c7: '12',
    d7: '13',
    e7: '14',
    f7: '15',
    g7: '16',
    h7: '17',
    
    a6: '20',
    b6: '21',
    c6: '22',
    d6: '23',
    e6: '24',
    f6: '25',
    g6: '26',
    h6: '27',
    
    a5: '30',
    b5: '31',
    c5: '32',
    d5: '33',
    e5: '34',
    f5: '35',
    g5: '36',
    h5: '37',
    
    a4: '40',
    b4: '41',
    c4: '42',
    d4: '43',
    e4: '44',
    f4: '45',
    g4: '46',
    h4: '47',
    
    a3: '50',
    b3: '51',
    c3: '52',
    d3: '53',
    e3: '54',
    f3: '55',
    g3: '56',
    h3: '57',
    
    a2: '60',
    b2: '61',
    c2: '62',
    d2: '63',
    e2: '64',
    f2: '65',
    g2: '66',
    h2: '67',
    
    a1: '70',
    b1: '71',
    c1: '72',
    d1: '73',
    e1: '74',
    f1: '75',
    g1: '76',
    h1: '77'
}

const getPositionAfterMove = (position, piece, originSquare, destinationSquare, isEnPassantCapture) => {
    const newPosition = [
		[...position[0]],
		[...position[1]],
		[...position[2]],
		[...position[3]],
		[...position[4]],
		[...position[5]],
		[...position[6]],
		[...position[7]]
    ]    
    const originCoords = getCoordsBySquareName(originSquare)
    newPosition[originCoords.rank][originCoords.file] = '-' // origin square should be empty
    const destinationCoords = getCoordsBySquareName(destinationSquare)
    newPosition[destinationCoords.rank][destinationCoords.file] = piece // moved piece should go to destination square

    // if en passant, remove captured pawn
    if (isEnPassantCapture) {
        const targetRank = piece === 'P' ? destinationCoords.rank + 1 : destinationCoords.rank - 1
        newPosition[targetRank][destinationCoords.file] = '-'
    }

    // handle rook moves for castling
    if (piece.toLowerCase() === 'k') {
        if (originSquare === 'e8' && destinationSquare === 'g8') { // Black castles kingside
            newPosition[0][5] = 'r'
            newPosition[0][7] = '-'
        }
        else if (originSquare === 'e8' && destinationSquare === 'c8') { // Black castles queenside
            newPosition[0][3] = 'r'
            newPosition[0][0] = '-'
        }
        else if (originSquare === 'e1' && destinationSquare === 'g1') { // White castles kingside
            newPosition[7][5] = 'R'
            newPosition[7][7] = '-'
        }
        else if (originSquare === 'e1' && destinationSquare === 'c1') { // White castles queenside
            newPosition[7][3] = 'R'
            newPosition[7][0] = '-'
        }
    }

    return newPosition
}

const getPositionAfterPawnPromotion = (position, piece, square) => {
    const newPosition = [
		[...position[0]],
		[...position[1]],
		[...position[2]],
		[...position[3]],
		[...position[4]],
		[...position[5]],
		[...position[6]],
		[...position[7]]
    ]

    const coords = getCoordsBySquareName(square)
    newPosition[coords.rank][coords.file] = piece
    return newPosition    
}

const isCheckForColor = (kingColor, position, testKingSquare = null) => {
    const actualKingSquare = getKingSquare(kingColor, position)
    const targetSquare = testKingSquare || actualKingSquare
    if (!targetSquare) return false
    for (let key in coordMap) {
        const pieceColor = getPieceColorBySquareName(key, position)
        const isEnemyPiece = (kingColor === 'w' && pieceColor === 'b') || 
            (kingColor === 'b' && pieceColor === 'w')
        if (isEnemyPiece) {
            const pieceType = getSquareContentBySquareName(key, position)
            const isCheck = isValidMove(position, key, targetSquare, pieceType, true, '-')
            if (isCheck)
                return true
        }
    }
    return false
}

const canCastle = (position, castlingOptions, corner) => {
    const hasOption = castlingOptions.includes(corner)
    let isUnobstructed = false
    let isSafeFromChecks = true

    switch (corner) {
        case 'K':
            if (position[7][5] === '-' && position[7][6] === '-')
                isUnobstructed = true
            if (isCheckForColor('w', position, 'e1') || isCheckForColor('w', position, 'f1'))
                isSafeFromChecks = false
            break
        case 'k':
            if (position[0][5] === '-' && position[0][6] === '-')
                isUnobstructed = true
            if (isCheckForColor('b', position, 'e8') || isCheckForColor('b', position, 'f8'))
                isSafeFromChecks = false
            break
        case 'Q':
            if (position[7][1] === '-' && position[7][2] === '-' && position[7][3] === '-')
                isUnobstructed = true
            if (isCheckForColor('w', position, 'e1') || isCheckForColor('w', position, 'd1'))
                isSafeFromChecks = false
            break
        case 'q':
            if (position[0][1] === '-' && position[0][2] === '-' && position[0][3] === '-')
                isUnobstructed = true
            if (isCheckForColor('b', position, 'e8') || isCheckForColor('b', position, 'd8'))
                isSafeFromChecks = false
            break
        default:
    }

    return hasOption && isUnobstructed && isSafeFromChecks
}

const isValidWhitePawnMove = (position, originCoords, destinationCoords, isCapture) => {
    const rankChange = destinationCoords.rank - originCoords.rank
    const fileChange = destinationCoords.file - originCoords.file

    // move 1 square forward
    if (!isCapture && fileChange === 0 && rankChange === -1) {
        return true
    }

    // capture
    else if (isCapture && rankChange === -1 && Math.abs(fileChange) === 1) {
        return true
    }

    // initial 2-square move forward
    else if (!isCapture 
        && fileChange === 0 
        && originCoords.rank === 6 
        && destinationCoords.rank === 4
        && getSquareContentByCoords(originCoords.rank - 1, originCoords.file, position) === '-' ) {
        return true
    }

    return false
}

const isValidBlackPawnMove = (position, originCoords, destinationCoords, isCapture) => {
    const rankChange = destinationCoords.rank - originCoords.rank
    const fileChange = destinationCoords.file - originCoords.file

    // move 1 square forward
    if (!isCapture && fileChange === 0 && rankChange === 1) {
        return true
    }

    // capture
    else if (isCapture && rankChange === 1 && Math.abs(fileChange) === 1) {
        return true
    }

    // initial 2-square move forward
    else if (!isCapture 
        && fileChange === 0 
        && originCoords.rank === 1 
        && destinationCoords.rank === 3
        && getSquareContentByCoords(originCoords.rank + 1, originCoords.file, position) === '-' ) {
        return true
    }

    return false
}

const isValidRookMove = (position, originCoords, destinationCoords) => {
    const rankChange = destinationCoords.rank - originCoords.rank
    const fileChange = destinationCoords.file - originCoords.file

    if (fileChange === 0) { // Rook moves along a file
        if (rankChange === 1) {
            return true
        } else if (originCoords.rank < destinationCoords.rank) {
            for (let i = originCoords.rank + 1; i < destinationCoords.rank; i++) {
                if (getSquareContentByCoords(i, destinationCoords.file, position) !== '-')
                    return false
            }
        } else if (originCoords.rank > destinationCoords.rank) {
            for (let i = originCoords.rank - 1; i > destinationCoords.rank; i--) {
                if (getSquareContentByCoords(i, destinationCoords.file, position) !== '-')
                    return false
            }
        }
        return true
    }

    else if (rankChange === 0) { // Rook moves along a rank
        if (fileChange === 1) {
            return true
        } else if (originCoords.file < destinationCoords.file) {
            for (let i = originCoords.file + 1; i < destinationCoords.file; i++) {
                if (getSquareContentByCoords(destinationCoords.rank, i, position) !== '-')
                    return false
            }
        } else if (originCoords.file > destinationCoords.file) {
            for (let i = originCoords.file - 1; i > destinationCoords.file; i--) {
                if (getSquareContentByCoords(destinationCoords.rank, i, position) !== '-')
                    return false
            }
        }
        return true
    }

    return false
}

const isValidBishopMove = (position, originCoords, destinationCoords) => {
    const rankChange = destinationCoords.rank - originCoords.rank
    const fileChange = destinationCoords.file - originCoords.file

    if (Math.abs(rankChange) === Math.abs(fileChange)) {
        if (Math.abs(rankChange) === 1) {
            return true
        } else if (rankChange === fileChange && destinationCoords.rank > originCoords.rank) {
            for (let i = 1; i < destinationCoords.rank - originCoords.rank; i++) {
                if (getSquareContentByCoords(originCoords.rank + i, originCoords.file + i, position) !== '-')
                    return false;
            }
        } else if (rankChange === fileChange && destinationCoords.rank < originCoords.rank) {
            for (let i = 1; i < originCoords.rank - destinationCoords.rank; i++) {
                if (getSquareContentByCoords(originCoords.rank - i, originCoords.file - i, position) !== '-')
                    return false;
            }
        } else if (rankChange === -fileChange && destinationCoords.rank > originCoords.rank) {
            for (let i = 1; i < destinationCoords.rank - originCoords.rank; i++) {
                if (getSquareContentByCoords(originCoords.rank + i, originCoords.file - i, position) !== '-')
                    return false;
            }
        } else if (rankChange === -fileChange && destinationCoords.rank < originCoords.rank) {
            for (let i = 1; i < originCoords.rank - destinationCoords.rank; i++) {
                if (getSquareContentByCoords(originCoords.rank - i, originCoords.file + i, position) !== '-')
                    return false;
            }
        }
        return true
    } else {
        return false
    }
}

const isValidKnightMove = (originCoords, destinationCoords) => {
    const rankChange = destinationCoords.rank - originCoords.rank
    const fileChange = destinationCoords.file - originCoords.file

    return (Math.abs(rankChange) === 1 && Math.abs(fileChange) === 2) ||
        (Math.abs(rankChange) === 2 && Math.abs(fileChange) === 1)
    
}

const isValidQueenMove = (position, originCoords, destinationCoords) => {
    return isValidRookMove(position, originCoords, destinationCoords) ||
        isValidBishopMove(position, originCoords, destinationCoords)
}

const isValidWhiteKingMove = (position, originCoords, destinationCoords, castlingOptions) => {
    const rankChange = destinationCoords.rank - originCoords.rank
    const fileChange = destinationCoords.file - originCoords.file

    if (Math.abs(rankChange) < 2 && Math.abs(fileChange) < 2)
        return true
    
    // allow castling
    else if (originCoords.rank === 7 && originCoords.file === 4 && 
        destinationCoords.rank === 7 && destinationCoords.file === 6)
            return canCastle(position, castlingOptions, 'K')
    else if (originCoords.rank === 7 && originCoords.file === 4 && 
        destinationCoords.rank === 7 && destinationCoords.file === 2)
            return canCastle(position, castlingOptions, 'Q')

    return false
}

const isValidBlackKingMove = (position, originCoords, destinationCoords, castlingOptions) => {
    const rankChange = destinationCoords.rank - originCoords.rank
    const fileChange = destinationCoords.file - originCoords.file

    if (Math.abs(rankChange) < 2 && Math.abs(fileChange) < 2)
        return true
    
    // allow castling
    else if (originCoords.rank === 0 && originCoords.file === 4 && 
        destinationCoords.rank === 0 && destinationCoords.file === 6)
            return canCastle(position, castlingOptions, 'k')
    else if (originCoords.rank === 0 && originCoords.file === 4 && 
        destinationCoords.rank === 0 && destinationCoords.file === 2)
            return canCastle(position, castlingOptions, 'q')

    return false
}

const isValidMove = (position, origin, destination, piece, isCapture, castlingOptions) => {
    const originCoords = getCoordsBySquareName(origin)
    const destinationCoords = getCoordsBySquareName(destination)

    switch (piece) {
        case 'P':
            return isValidWhitePawnMove(position, originCoords, destinationCoords, isCapture)
        case 'p':
            return isValidBlackPawnMove(position, originCoords, destinationCoords, isCapture)
        case 'R':
        case 'r':
            return isValidRookMove(position, originCoords, destinationCoords)
        case 'B':
        case 'b':
            return isValidBishopMove(position, originCoords, destinationCoords)
        case 'N':
        case 'n':
            return isValidKnightMove(originCoords, destinationCoords)
        case 'Q':
        case 'q':
            return isValidQueenMove(position, originCoords, destinationCoords)
        case 'K':
            return isValidWhiteKingMove(position, originCoords, destinationCoords, castlingOptions)
        case 'k':
            return isValidBlackKingMove(position, originCoords, destinationCoords, castlingOptions)
        default:
            return true;
    }
}

const isLegalMove = (originSquare, destinationSquare, piece, isCapture, position, castlingOptions, isEnpassantCapture) => {
    const isValid = isValidMove(position, originSquare, destinationSquare, piece, isCapture, castlingOptions)
    if (!isValid)
        return false

    const turnColor = getPieceColorBySquareName(originSquare, position)
    const positionAfterMove = getPositionAfterMove(position, piece, originSquare, destinationSquare, isEnpassantCapture)
    const isMovingIntoCheck = isCheckForColor(turnColor, positionAfterMove)
    return !isMovingIntoCheck
}

const getPossibleMoves = fen => {
    if (!fen || !fen.pieces) return 0
    const squaresWithOwnPieces = []
    for (let key in coordMap) {
        const pieceArray = getPieceArrayFromFenPieces(fen.pieces)
        if (getPieceColorBySquareName(key, pieceArray) === fen.turn)
            squaresWithOwnPieces.push(key)
    }

    const possibleMoves = []
    squaresWithOwnPieces.forEach(square => {
        const pieceArray = getPieceArrayFromFenPieces(fen.pieces)
        const piece = getSquareContentBySquareName(square, pieceArray)
        for (let destination in coordMap) {
            const destinationPieceColor = getPieceColorBySquareName(destination, pieceArray)
            if (destinationPieceColor !== fen.turn && square !== destination) {
                const destinationPiece = getSquareContentBySquareName(destination, pieceArray)
                const isEnPassantCapture = piece.toLowerCase() === 'p' && square === fen.enPassantSquare
                const isCapture = (destinationPiece !== '-') || isEnPassantCapture
                const isLegal = isLegalMove(square, destination, piece, isCapture, pieceArray, fen.castlingOptions, isEnPassantCapture)
                if (isLegal) {
                    possibleMoves.push(`${square}-${destination}`)
                }
            }
        }
    })

    return possibleMoves
}

export {
    isLetter,
    removeFromString,
    getCoordsBySquareName,
    getSquareContentBySquareName,
    getSquareContentByCoords,
    getPieceColorBySquareName,
    getPieceColorByCoords,
    getKingSquare,
    getSquaresWithPieceType,
    getFenPiecesFromPieceArray,
    coordMap,
    isValidMove,
    isValidWhitePawnMove,
    isValidBlackPawnMove,
    isLegalMove,
    getPositionAfterMove,
    getPositionAfterPawnPromotion,
    isCheckForColor,
    getPieceArrayFromFenPieces,
    getPossibleMoves
}
