import React from 'react'
import { Toast, ToastHeader, ToastBody } from 'reactstrap'
import './music-entry.scss'

export default function MusicEntry({ title, pdf, youtube, setYouTube, description, ...otherProps }) {
    const view = link => {
        if (link && setYouTube) {
            setYouTube(link)
        }
    }

    return (
        <Toast className="me-2 mb-2 music-entry" {...otherProps}>
            <ToastHeader>{title}</ToastHeader>
            <ToastBody>
                {description 
                    ? <div className="music-description">{description}</div> 
                    : null
                }
                <div className="watch">
                    {pdf 
                        ? <a href={pdf} target="_blank" rel="noopener noreferrer">Sheet music (PDF)</a>
                        : null
                    }
                    {youtube && youtube.length > 0 &&
                        youtube.map((link, i) =>
                            <div 
                                key={`link-${youtube}-${i}`} 
                                className="clickable text-primary" 
                                onClick={() => view(link)}
                            >
                                YouTube Video {youtube.length > 1 && `${i + 1}`}
                            </div>  
                        )
                    }
                </div>                
            </ToastBody>
        </Toast>
    )
}