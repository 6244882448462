import React from 'react'
import { Router } from '@reach/router'
import { Compositions, Playlists, Pieces, PieceDialog, MusicProvider, DropboxRedirect, MusicBrowser, 
    PlaylistDialog, MusicMenu, Playlist, HarmonicProgressions } from '.'
import { useAuth } from '../../main/auth'
import Tuning from './ChordTools/Tuning'

export default function MusicModule() {
    const { user } = useAuth()
    const userHasAccess = user && user.userId < 3

    return (
        <MusicProvider>
            <div className="module-container">
                <MusicMenu />
                <div className="main-container with-side-menu">
                    <Router>
                        <Compositions path="compositions" default />
                        <Compositions path="pdf/:file" />
                        <HarmonicProgressions path="chords/progressions" />
                        <Tuning path="tuning" />
                        {userHasAccess && <> 
                            <Pieces path="pieces" />
                            <PieceDialog path="pieces/:pieceId" />
                            <Playlists path="playlists" />
                            <Playlist path="playlists/:playlistId" />
                            <PlaylistDialog path="playlists/new" />
                            <PlaylistDialog path="playlists/:playlistId/edit" />
                            <DropboxRedirect path="redirect" />
                            <MusicBrowser path="browse" />
                        </>}
                    </Router>
                </div>
            </div>
        </MusicProvider>
    )
}
