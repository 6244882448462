import React from 'react'
import { AudioPlayer } from '.'
import { navigate } from '@reach/router'
import { List, MusicNote, Folder, QueueMusic, AccountTree, Tune } from '@material-ui/icons'
import { Button, ButtonGroup } from 'reactstrap'
import { useAuth } from '../../main/auth'
import SideMenu from '../../main/nav/SideMenu'
import './music-menu.scss'

export default function MusicMenu() {
    const { user } = useAuth()
    const userHasAccess = user && user.userId < 3

    return (
        <SideMenu header="Music" headerLink="/music" className="music-menu">
            <ButtonGroup vertical className="w-100">
                {userHasAccess && <>
                    <Button
                        color="primary"
                        outline
                        onClick={() => navigate('/music/playlists')}
                    >
                        <span className="d-flex align-items-center"><QueueMusic className="me-1"/>Playlists</span>
                    </Button>                    
                    <Button
                        color="primary"
                        outline
                        onClick={() => navigate('/music/pieces')}
                    >
                        <span className="d-flex align-items-center"><List className="me-1"/>Pieces</span>
                    </Button>
                    <Button
                        color="primary"
                        outline
                        onClick={() => navigate('/music/browse')}    
                    >
                        <span className="d-flex align-items-center"><Folder className="me-1"/>Browse Dropbox</span>
                    </Button>
                </>}
                <Button
                    color="primary"
                    outline
                    onClick={() => navigate('/music/compositions')}
                >
                    <span className="d-flex align-items-center"><MusicNote className="me-1"/>Compositions</span>
                </Button>
                <Button color="secondary">Tools</Button>
                <Button 
                    color="primary" 
                    outline 
                    onClick={() => navigate('/music/chords/progressions')}
                >
                    <span className="d-flex align-items-center"><AccountTree className="me-1" />Chord Progressions</span>
                </Button>
                <Button
                    color="primary"
                    outline
                    onClick={() => navigate('/music/tuning')}
                >
                    <span className="d-flex align-items-center"><Tune className="me-1" />Tuning</span>
                </Button>
            </ButtonGroup>
            <AudioPlayer />
        </SideMenu>
    )
}
