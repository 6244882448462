import React from 'react'
import { ArrowBackIos, ArrowForwardIos, FirstPage, LastPage } from '@material-ui/icons'
import { Input } from 'reactstrap'

export default function DataTablePagination({ tableInstance }) {
    const {
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = tableInstance
    
    return (
        <div className="pagination">
            <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                <FirstPage />
            </button>
            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                <ArrowBackIos />
            </button>
            <button onClick={() => nextPage()} disabled={!canNextPage}>
                <ArrowForwardIos />
            </button>
            <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                <LastPage />
            </button>
            <span className="page-control">
                {pageIndex + 1}/{pageOptions.length}
            </span>
            {/* <span className="page-control">
                Go to page:
                <input
                    type="number"
                    defaultValue={pageIndex + 1}
                    onChange={e => {
                        const targetPage = e.target.value ? Number(e.target.value) - 1 : 0
                        gotoPage(targetPage)
                    }}
                    className="goto-page-input"
                />
            </span> */}
            <div className="d-flex">
                <Input type="select" value={pageSize} onChange={e => setPageSize(Number(e.target.value))} className="page-control">
                    {[10, 25, 50, 100].map(size =>
                        <option key={size} value={size}>
                            Show {size}
                        </option>
                    )}
                </Input>
            </div>
        </div>
    )
}
