import React, { useState, useEffect, useCallback } from 'react'
import { generateRandomString } from '../../utils'
import { useLocation, navigate } from '@reach/router'
import { useAuth } from '../../main/auth'
import { fetcher, Loading } from '../../common'
import { NotificationManager } from 'react-notifications'

export const MusicContext = React.createContext()

export default function MusicProvider({ children }) {
    const { user } = useAuth()
    const audioPlayer = React.createRef()
    const playBtn = React.createRef()
    const pauseBtn = React.createRef()
    const location = useLocation()
    const [mode, setMode] = useState('piece')
    const [isPlaying, setIsPlaying] = useState(false)
    const [isLoadingPiece, setIsLoadingPiece] = useState(false)
    const [isIntegrating, setIsIntegrating] = useState(false)
    const [activePieceIndex, setActivePieceIndex] = useState(0)
    const [activePieceCollection, setActivePieceCollection] = useState([])
    const [activePieceUrl, setActivePieceUrl] = useState('')
    const [selectedPiece, setSelectedPiece] = useState(null)
    const [activePlaylistTitle, setActivePlaylistTitle] = useState('')
    const [pieces, setPieces] = useState([])
    const [playlists, setPlaylists] = useState([])
    const [accessToken, setAccessToken] = useState(null)
    const [redirectUri, setRedirectUri] = useState('')
    const activePiece = mode === 'playlist' 
        ? activePieceCollection[activePieceIndex]
        : selectedPiece
    const hasMusicAccess = user && user.userId < 3
    const appKey = '3eadni68fp9lzd1'

    const isValidToken = useCallback(async (token) => {
        const path = 'https://api.dropboxapi.com/2/check/user'
        const body = {
            "query": "testing123"
        }
        const request = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(body)
        }
        const response = await fetch(path, request)
        return response.ok
    }, [])

    const getCode = useCallback(async () => {
        const challenge = generateRandomString(128)
        localStorage.setItem('dbChallenge', challenge)
        const url = `https://www.dropbox.com/oauth2/authorize?client_id=${appKey}&response_type=code&code_challenge=${challenge}&code_challenge_method=plain&redirect_uri=${redirectUri}`
        navigate(url)
    }, [redirectUri])

    const getToken = useCallback(async (dropboxCode, challenge) => {
        if (hasMusicAccess) {
            const path = 'https://api.dropboxapi.com/oauth2/token'
            const body = {
                code: dropboxCode,
                grant_type: 'authorization_code',
                redirect_uri: redirectUri,
                code_verifier: challenge,
                client_id: appKey
            }
    
            const formBody = Object.keys(body).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(body[key])).join('&');
    
            const request = {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: formBody
            }
    
            const response = await fetch(path, request)
            if (response.ok) {
                const jsonResponse = await response.json()
                if (jsonResponse.access_token) {
                    localStorage.removeItem('dbCode')
                    localStorage.removeItem('dbChallenge')
                    localStorage.removeItem('dbStatus')
                    localStorage.setItem('dbToken', jsonResponse.access_token)
                    setAccessToken(jsonResponse.access_token)
                }
            }
            setIsIntegrating(false)
        }
    }, [redirectUri, hasMusicAccess])

    const sayHiToDropbox = useCallback(async () => {
        if (hasMusicAccess && redirectUri) {
            setIsIntegrating(true)
            const storedToken = localStorage.getItem('dbToken')
            if (storedToken) {
                const isValid = await isValidToken(storedToken)
                if (isValid) {
                    setAccessToken(storedToken)
                    localStorage.removeItem('dbChallenge')
                    setIsIntegrating(false)
                    return
                } else {
                    localStorage.removeItem('dbToken')
                }
            }
            const dbStatus = localStorage.getItem('dbStatus')
            if (dbStatus === null) {
                localStorage.setItem('dbStatus', 'getting code')
                getCode()
            } else if (dbStatus === 'getting code') {
                localStorage.setItem('dbStatus', 'getting token')
                const storedCode = localStorage.getItem('dbCode')
                const challenge = localStorage.getItem('dbChallenge')
                if (storedCode && challenge) {
                    getToken(storedCode, challenge)
                }
            }
        }
    }, [isValidToken, hasMusicAccess, getCode, getToken, redirectUri])

    useEffect(() => {
       sayHiToDropbox()
    }, [sayHiToDropbox])

    const getRedirectUri = useCallback(async () => {
        if (redirectUri === '' && location) {
            setRedirectUri(`${location.origin}/music/redirect`)
        }
    }, [location, redirectUri])

    useEffect(() => {
        getRedirectUri()
    }, [getRedirectUri])

    const getPlaylists = useCallback(async () => {
        const resp = await fetcher.get('music/playlists')
        if (resp) {
            setPlaylists(resp)
        }
    }, [])

    const getPieces = useCallback(async () => {
        const resp = await fetcher.get('music/pieces')
        if (resp) {
            setPieces(resp)
        }
    }, [])

    const loadAndPlayFile = useCallback(async (filePath) => {
        if (accessToken) {
            setIsLoadingPiece(true)
            const path = `https://content.dropboxapi.com/2/files/download`
            const request = {
                method: 'POST',
                headers: {
                    'Content-Type': 'text/plain',
                    'Authorization': `Bearer ${accessToken}`
                }
            }
            request.headers[encodeURIComponent('Dropbox-API-Arg')] = JSON.stringify({ path: filePath })
            try {
                const response = await fetch(path, request)
                if (response.ok) {
                    const blob = await response.blob()
                    const url = URL.createObjectURL(blob)
                    setActivePieceUrl(url)
                    const player = document.getElementById("appAudioPlayer")
                    if (player) {
                        player.load()
                        setIsPlaying(true)
                        await player.play()
                    }
                }
                setIsLoadingPiece(false)
            } catch (err) {
                NotificationManager.error(err)
                setIsLoadingPiece(false)
            }
        }
    }, [accessToken])

    const startPlaylist = useCallback(async (playlist, startPlaying = true) => {        
        if (!playlist || !playlist.pieceIds) return

        let newCollection = []
        for (let i = 0; i < playlist.pieceIds.length; i++) {
            const piece = pieces.find(piece => piece.id === playlist.pieceIds[i])
            if (piece) {
                newCollection.push(piece)
            }
        }
        
        setActivePieceIndex(0)
        setActivePieceCollection(newCollection)
        setMode('playlist')
        setActivePlaylistTitle(playlist.name)
        navigate(`/music/playlists/${playlist.id}`)
        if (newCollection[0] && newCollection[0].path && startPlaying) {
            loadAndPlayFile(newCollection[0].path)
        }
    }, [pieces, loadAndPlayFile])

    useEffect(() => {
        if (hasMusicAccess) {
            getPieces()
        }
    }, [getPieces, hasMusicAccess])

    const nextPiece = useCallback(() => {
        if (!audioPlayer.current || activePieceCollection.length < 2) {
            return
        }
        let pieceToPlay = null
        if (activePieceIndex === activePieceCollection.length - 1) {
            setActivePieceIndex(0)
            pieceToPlay = [...activePieceCollection][0]
        } else {
            pieceToPlay = [...activePieceCollection][activePieceIndex + 1]
            setActivePieceIndex(x => x + 1)
        }
        if (pieceToPlay) {
            loadAndPlayFile(pieceToPlay.path)
        }
    }, [audioPlayer, activePieceCollection, activePieceIndex, loadAndPlayFile])

    const prevPiece = useCallback(() => {
        if (!audioPlayer.current || activePieceCollection.length < 2) {
            return
        }
        let pieceToPlay = null
        if (activePieceIndex === 0) {
            setActivePieceIndex(activePieceCollection.length - 1)
            pieceToPlay = [...activePieceCollection][activePieceCollection.length - 1]
        } else {
            pieceToPlay = [...activePieceCollection][activePieceIndex - 1]
            setActivePieceIndex(x => x - 1)
        }
        if (pieceToPlay) {
            loadAndPlayFile(pieceToPlay.path)
        }
    }, [audioPlayer, activePieceCollection, activePieceIndex, loadAndPlayFile])

    const context = {
        audioPlayer, playBtn, pauseBtn,
        mode, setMode,
        nextPiece, prevPiece,
        activePiece,
        selectedPiece, setSelectedPiece,
        activePieceIndex, setActivePieceIndex,
        activePieceCollection, setActivePieceCollection,
        activePieceUrl, setActivePieceUrl,
        isLoadingPiece, setIsLoadingPiece,
        isPlaying, setIsPlaying,
        pieces, setPieces,
        playlists, setPlaylists,
        accessToken,
        getToken,
        getPlaylists,
        getPieces,
        loadAndPlayFile,
        startPlaylist,
        activePlaylistTitle
    }

    if (isIntegrating) {
        return <Loading />
    }

    return (
        <MusicContext.Provider value={context}>
            {children}
        </MusicContext.Provider>
    )
}

export function useMusic() {
    const context = React.useContext(MusicContext)
    if (context === undefined) {
        throw new Error('useMusic must be used within a MusicProvider')
    }
    return context
}
