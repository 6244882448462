import React from 'react'
import qb from '../../files/chess/qb.png'
import qw from '../../files/chess/qw.png'
import bb from '../../files/chess/bb.png'
import bw from '../../files/chess/bw.png'
import nb from '../../files/chess/nb.png'
import nw from '../../files/chess/nw.png'
import rb from '../../files/chess/rb.png'
import rw from '../../files/chess/rw.png'
import { useChess } from '.'
import './pawn-promotion-dialog.scss'

export default function PawnPromotionDialog() {
    const { currentPosition, lightColor, promotePawn } = useChess()

    const onSelect = piece => {
        promotePawn(currentPosition.turn === 'b' ? piece.toUpperCase() : piece)
    }

    if (currentPosition) return (
        <div className="pawn-promotion-dialog">
            <div className="modal" style={{ backgroundColor: lightColor }}>
                <img src={currentPosition.turn === 'b' ? qw : qb} alt="Promote to Queen" onClick={() => onSelect('q')} />
                <img src={currentPosition.turn === 'b' ? rw : rb} alt="Promote to Rook" onClick={() => onSelect('r')} />
                <img src={currentPosition.turn === 'b' ? bw : bb} alt="Promote to Bishop" onClick={() => onSelect('b')} />
                <img src={currentPosition.turn === 'b' ? nw : nb} alt="Promote to Knight" onClick={() => onSelect('n')} />
            </div>
        </div>
    )

    return null
}
