import React, { useEffect, useState } from 'react'
import { useCalendar, Monthly, Weekly } from '.'
import { useAuth } from '../../main/auth'
import { Input, Col, Row, Container } from 'reactstrap'
import { Link } from '@reach/router'
import { Settings } from '@material-ui/icons'
import { ListSettings } from '../Honey'
import './calendar.scss'

export default function Calendar({ events }) {
    const { setEvents } = useCalendar()
    const { userSettings, updateUserSettings } = useAuth()
    const [showSettings, setShowSettings] = useState(false)
    const [view, setView] = useState('week')

    useEffect(() => {
        setEvents(events)
    }, [events, setEvents])

    useEffect(() => {
        if (userSettings && userSettings.calendarView) {
            setView(userSettings.calendarView)
        }
    }, [userSettings])
    
    const updateCalendarView = async (view) => {
        let body = {
            ...userSettings,
            calendarView: view
        }
        updateUserSettings(body)
    }
    
    return (
        <div className="calendar main-container">
            <Container fluid={true}>
                <Row>
                    <Col sm={2}>
                        <Input type="select" value={view} onChange={e => updateCalendarView(e.target.value)} className="mobile-hide">
                            <option value="week">Week</option>
                            <option value="month">Month</option>
                        </Input>
                    </Col>
                    <Col sm={2}>
                        <Link to="/honey" className="btn btn-outline-secondary">View Lists</Link>
                    </Col>
                    <Col className="d-flex justify-content-end">
                        <Settings className="clickable" onClick={() => setShowSettings(x => !x)} />
                        {showSettings && <ListSettings />}
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <div className="mobile-hide">
                            {view === 'month'
                                ? <Monthly />
                                : <Weekly />
                            }
                        </div>
                        <div className="mobile-only">
                            <Weekly />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}