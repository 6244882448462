import React, { useState, useEffect } from 'react'
import { useCalendar, WeeklyControls } from '.'
import { stringToDate } from '../../utils'
import { Link, navigate } from '@reach/router'
import { AddCircle } from '@material-ui/icons'
import { Loading } from '../../common'
import { useHoney } from '../Honey'
import './weekly.scss'

export default function Weekly() {
    const { monthArray, date, events, getDateKey, isToday } = useCalendar()
    const { activeListId } = useHoney()
    const [weekArray, setWeekArray] = useState([])

    useEffect(() => {
        if (monthArray.length) {
            setWeekArray(monthArray.find(x => x.includes(date.date)))
        }
    }, [monthArray, date, events])

    if (!weekArray || !weekArray.length ) {
        return <Loading />
    }

    return (
        <>
            <WeeklyControls />
            <div className="weekly">
                {weekArray.map(day => {
                    let dateKey = getDateKey(day)
                    let weekEvents = events.find(x => x[0] === dateKey)
                    let today = isToday(day)
                    return (
                        <div key={day}>
                            <div className={today ? 'date-header today' : 'date-header'}>
                                {stringToDate(dateKey)} 
                                {today && ' - TODAY!'} 
                                <AddCircle 
                                    onClick={() => navigate(`/calendar/${getDateKey(day)}/${activeListId}`)}
                                    className={`clickable ms-1 ${today ? 'text-primary' : 'text-muted'}`}
                                />
                            </div>
                            {weekEvents && weekEvents[1] 
                                ? <ul className="events">
                                    {weekEvents[1].map(x => 
                                        <li key={`${x.listId}-${x.index}`}>
                                            <Link to={`/honey/${x.listId}`}>{x.name}</Link>
                                        </li>
                                    )}
                                </ul> 
                                : <ul className="no-events">
                                    <li>(no events)</li>
                                </ul>
                            }
                        </div>
                    )
                })}
            </div>
        </>
    )
}
