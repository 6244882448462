import { Table } from "reactstrap";
import TuningSound from "./TuningSound";

export default function Tuning() {
    return (
        <div>
            <h1>Tuning</h1>
            <Table>
                <tbody>
                    <tr>
                        <th>Temperament</th>
                        <td>A</td>
                        <td>B</td>
                        <td>C#</td>
                        <td>D</td>
                        <td>E</td>
                        <td>F#</td>
                        <td>G#</td>
                        <td>A</td>
                    </tr>
                    <tr>
                        <th>Just/Pure (ratio)</th>
                        <td>1</td>
                        <td>9/8</td>
                        <td>5/4</td>
                        <td>4/3</td>
                        <td>3/2</td>
                        <td>5/3</td>
                        <td>15/8</td>
                        <td>2</td>
                    </tr>
                    <tr>
                        <th>Just/Pure (Hz)</th>
                        <td>440</td>
                        <td>495</td>
                        <td>550</td>
                        <td>586.67</td>
                        <td>660</td>
                        <td>733.33</td>
                        <td>825</td>
                        <td>880</td>
                    </tr>
                    <tr>
                        <th>Equal (Hz)</th>
                        <td>440</td>
                        <td>493.9</td>
                        <td>554.4</td>
                        <td>587.3</td>
                        <td>659.3</td>
                        <td>740</td>
                        <td>830.6</td>
                        <td>880</td>
                    </tr>
                </tbody>
            </Table>

            <h3>Perfect Fifth</h3>
            <div className="d-flex">
                <TuningSound label="Equal fifth" frequencies={[440,659.3]} />
                <TuningSound label="Pure fifth" frequencies={[440,660]} />
            </div>

            <h3>Major Triad</h3>
            <div className="d-flex">
                <TuningSound label="Equal major triad" frequencies={[440,554.4,659.3]} />
                <TuningSound label="Pure major triad" frequencies={[440,550,660]} />
            </div>
        </div>
    )
}
