import { useCallback, useEffect, useState, useMemo } from "react";
import { fetcher, Loading } from "../../common";
import { useAuth } from "../../main/auth";
import { numToCurrency } from "../../utils";
import { Donations } from ".";
import { Col, Container, Progress, Row } from "reactstrap";
import bekkiDallonDad from "./bekki-dallon-dad.jpg";
import bradleyBekki from "./bradley-bekki.jpg";
import dallonGuitar from "./dallon-guitar.jpg";
import dallonPuzzle from "./dallon-puzzle.jpg";
import dallon from "./dallon.jpg";
import dallon2 from "./dallon2.jpg";
import family from "./family.jpg";
import hollisBekkiDallon from "./hollis-bekki-dallon.jpg";
import jennifer from "./jennifer.jpg";
import joBekki from "./jo-bekki.jpg";
import kermit from "./kermit-bekki-dallon.jpg";
import kermit4 from "./kermit-4.jpg";
import nancyBekki from "./nancy-bekki.jpg";
import "./trust-fund.scss";

export default function TrustFundSummary() {
    const { user } = useAuth();
    const [summary, setSummary] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [activePhotoIndex, setActivePhotoIndex] = useState(0);
    const hasAuthAccess = user && user.userId === 1;

    const photos = useMemo(
        () => [
            bekkiDallonDad,
            bradleyBekki,
            dallonGuitar,
            dallonPuzzle,
            dallon,
            dallon2,
            family,
            hollisBekkiDallon,
            jennifer,
            joBekki,
            kermit,
            kermit4,
            nancyBekki,
        ],
        []
    );

    const activePhoto = photos[activePhotoIndex];

    const getSummary = useCallback(async () => {
        const resp = await fetcher.get("trustfund/summary");
        if (resp) {
            setSummary(resp);
        }
        setIsLoading(false);
    }, []);

    useEffect(() => {
        getSummary();
    }, [getSummary]);

    const getValue = () => {
        const goal = 10000;
        const result = Math.round((summary.amountDonated * 100) / goal);
        return result > 100 ? 100 : result;
    };

    const nextPhoto = useCallback(() => {
        setActivePhotoIndex((x) => (x < photos.length - 1 ? x + 1 : 0));
    }, [photos]);

    useEffect(() => {
        const photoTimer = setInterval(nextPhoto, 5000);
        return () => clearInterval(photoTimer);
    }, [nextPhoto]);

    if (!hasAuthAccess)
        return (
            <div className="main-container trust-fund">
                <Container fluid>
                    <h1>Dallon's Trust Fund</h1>
                    <p>Thank you to everyone for your generous donations. We were able to raise over $10,000 for my nephew Dallon!</p>
                </Container>
            </div>
        );

    return (
        <div className="main-container trust-fund">
            <Container fluid>
                <h1>Dallon's Trust Fund</h1>
                <Row>
                    <Col xl={5} lg={6} md={6} sm={12}>
                        {isLoading && <Loading />}
                        {!isLoading && summary && (
                            <>
                                <h2>{numToCurrency(summary.amountDonated)}</h2>
                                <Progress value={getValue()} />
                                <div className="text-muted mb-4 mt-1">
                                    {summary.numberOfDonors} {summary.numberOfDonors === 1 ? "donation" : "donations"}
                                </div>
                            </>
                        )}

                        <p>
                            On July 13, 2022,{" "}
                            <a
                                href="https://www.mlfordsons.com/obituary/RebekahBekki-Harris"
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                my sister Bekki
                            </a>{" "}
                            died unexpectedly. She left behind a 9-year-old son, my nephew Dallon. Dallon has a hard
                            path ahead, and our family is starting a trust fund for him.
                        </p>
                        <p>If you would like to contribute, you can donate to Laura Harris:</p>

                        <div>
                            <a
                                className="btn btn-outline-primary me-2 mb-3"
                                href="http://paypal.me/lmharris8"
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                Donate with PayPal
                            </a>
                            <a
                                className="btn btn-outline-primary mb-3"
                                href="https://www.venmo.com/u/lmharris8"
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                Donate with Venmo
                            </a>
                        </div>

                        <p>
                            If you would like to donate using Zelle or a check, please email Laura at{" "}
                            <a href="mailto:lmharris8@gmail.com">Lmharris8@gmail.com</a>.
                        </p>
                        <p>
                            Gratefully,
                            <br />
                            --Bradley Harris
                        </p>
                    </Col>
                    <Col xl={6} lg={6} md={6} sm={12}>
                        <img src={activePhoto} alt="Family pictures" className="mb-4 family" />
                    </Col>
                </Row>

                <Donations getSummary={getSummary} />
            </Container>
        </div>
    );
}
