import React, { useState } from 'react'
import { Help } from '@material-ui/icons'
import './tooltip.scss'

export default function Tooltip({ text }) {
    const [showText, setShowText] = useState(false)

    return (
        <div className="tooltip">
            <Help 
                className="icon" 
                onMouseOver={() => setShowText(true)}
                onMouseOut={() => setShowText(false)}
            />
            <div className={`text ${showText ? '' : 'hide'}`}>{text}</div>
        </div>
    )
}
