import React, { useState, useEffect, useCallback } from 'react'
import { Card } from '../../common'
import YouTubeEmbed from './YouTubeEmbed'
import MusicEntry from './MusicEntry'
import { spiritsong, ode, kitty, krampus, cloud, duets, poems, thaxted, elegy, 
    acrossTheStars, meadow, lydian } from '../../files/music/music-exports'

export default function Music({ file }) {
    const [embedURL, setEmbedURL] = useState(null)

    const getFile = useCallback(() => {
        switch (file) {
            case 'cloud':
                return cloud
            case 'spiritsong':
                return spiritsong
            case 'ode':
                return ode
            case 'kitty':
                return kitty
            case 'krampus':
                return krampus
            case 'poems':
                return poems
            case 'duets':
                return duets
            case 'thaxted':
                return thaxted
            case 'elegy':
                return elegy
            case 'acrossTheStars':
                return acrossTheStars
            case 'meadow':
                return meadow
            default:
                return null
        }
    }, [file])

    useEffect(() => {
        const getFileResult = getFile()
        if (getFileResult) {
            window.location.href = getFileResult
        }
    }, [getFile])

    const setYouTube = (url) => {
        setEmbedURL(`https://www.youtube.com/embed/${url}`)
        window.scrollTo(0,0)
    }

    if (file) {
        return <div>Loading PDF...</div>
    }

    return (
        <div className="music">
            <h1>Compositions</h1>
            <p>Sometimes I write music. Here's my <a href="https://www.youtube.com/channel/UCSS9zAR_6DbTAndCFfVZNfQ" target="_blank" rel="noopener noreferrer">YouTube channel</a>.</p>
            <YouTubeEmbed title='youtube embed' url={embedURL} />

            <Card header="Original Compositions">
                <div className="d-flex flex-wrap">
                    <MusicEntry 
                        title="Ode" 
                        pdf={ode} 
                        youtube={["u4uyR61sa0w"]} 
                        setYouTube={setYouTube} 
                        description="For solo piano" 
                    />
                    <MusicEntry 
                        title="Tune Which Is As Yet Untitled"
                        youtube={["sbgX5Ycc4VM"]}
                        setYouTube={setYouTube}
                        description="For solo piano"
                    />
                    <MusicEntry 
                        title="Elegy" 
                        pdf={elegy} 
                        youtube={["-z9sudqoyY0"]} 
                        setYouTube={setYouTube} 
                        description="For solo piano" 
                    />
                    <MusicEntry 
                        title="The Wide Meadow" 
                        pdf={meadow} 
                        youtube={["F8k0DDurWtk"]} 
                        setYouTube={setYouTube} 
                        description="For ocarina and piano" 
                    />
                    <MusicEntry 
                        title="Theme and Variations on &quot;Soft Kitty, Warm Kitty&quot;" 
                        pdf={kitty} 
                        youtube={["vmCutnhAhew"]} 
                        setYouTube={setYouTube} 
                        description="For solo piano" 
                    />
                    <MusicEntry 
                        title="Three Musical Poems" 
                        pdf={poems} 
                        youtube={["p7cNbONVrBI", "yRs-3ftvcFQ", "nVvnmBOJ3tM"]} 
                        setYouTube={setYouTube}
                        description="For solo piano" 
                    />
                    <MusicEntry 
                        title="Krampus Rumpus" 
                        pdf={krampus} 
                        youtube={["8xiLn1VfTco"]} 
                        setYouTube={setYouTube}
                    />
                    <MusicEntry 
                        title="Hugging a Cloud" 
                        pdf={cloud} 
                        youtube={["snMn5xpdCPk"]} 
                        setYouTube={setYouTube} 
                        description="For solo piano" 
                    />
                    <MusicEntry 
                        title="Hope" 
                        youtube={["oqHXeL8NvLI"]} 
                        setYouTube={setYouTube} 
                        description="For solo piano" 
                    />
                    <MusicEntry 
                        title="Five Duets for Horn" 
                        pdf={duets}
                        youtube={["Ivgpfscf_Dw"]}
                        setYouTube={setYouTube}
                    />
                    <MusicEntry
                        title="Lydian Litany" 
                        pdf={lydian}
                        description="For horn and piano"
                    />
                    <MusicEntry 
                        title="Hold On"
                        youtube={["jI4ILbM4sOs"]}
                        setYouTube={setYouTube}
                        description="Electronica"
                    />
                </div>
            </Card>

            <Card header="Arrangements">
                <div className="d-flex flex-wrap">
                    <MusicEntry 
                        title="Across the Stars, by John Williams" 
                        pdf={acrossTheStars} 
                        youtube={["-9nMxyRd7VA"]} 
                        setYouTube={setYouTube} 
                        description="For solo piano" 
                    />
                    <MusicEntry 
                        title="THAXTED from Jupiter, by Gustav Holst" 
                        pdf={thaxted} 
                        youtube={["JCyw1wWYaW8"]} 
                        setYouTube={setYouTube} 
                        description="For solo piano" 
                    />
                    <MusicEntry 
                        title="Spirit Song, by John Wimber" 
                        pdf={spiritsong} 
                        youtube={["H6-qrzHgTDs"]} 
                        setYouTube={setYouTube} 
                        description="For solo piano" 
                    />
                </div>
            </Card>
        </div>
    )
}
