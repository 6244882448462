import React, { useState, useEffect, useCallback } from 'react'
import { fetcher, Loading } from '../../common'
import { Link, navigate } from '@reach/router'
import { Table } from 'reactstrap'
import { AddCircle } from '@material-ui/icons'

export default function ManageBlogTags() {
    const [tags, setTags] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const getTags = useCallback(async () => {
        setIsLoading(true)
        const resp = await fetcher.get('blog/tags/mine')
        if (resp) {
            setTags(resp)
        }
        setIsLoading(false)
    }, [])

    useEffect(() => {
        getTags()
    }, [getTags])

    if (isLoading) return <Loading />

    return (
        <div>
            <h2>Manage Blog Tags</h2>
            <Link to="/blog/tags/new" className="btn btn-primary mb-2">
                <span className="d-flex align-items-center"><AddCircle className="me-1" />New Tag</span>
            </Link>
            {tags.length > 0 
                ? <Table>
                    <thead>
                        <tr>
                            <th>Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tags.map(tag =>
                            <tr key={`tag-${tag.id}`} onClick={() => navigate(`/blog/tags/edit/${tag.id}`)} className="clickable">
                                <td>{tag.name}</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
                : <p>No tags found.</p>
            }
        </div>
    )
}
