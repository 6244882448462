import React, { useState, useCallback } from 'react'
import { fetcher } from '../../common'

export const WhiskContext = React.createContext()

export default function WhiskProvider({ children }) {
    const [recipes, setRecipes] = useState([])
    const [tags, setTags] = useState([])

    const getRecipes = useCallback(async (id = 0) => {
        let recipes = await fetcher.get('recipes')
        if (recipes) {
            setRecipes(recipes)
        }
    }, [])

    const context = {
        recipes, setRecipes,
        tags, setTags,
        getRecipes,
    }

    return (
        <WhiskContext.Provider value={context}>
            {children}
        </WhiskContext.Provider>
    )
}

export function useWhisk() {
    const context = React.useContext(WhiskContext)
    if (context === undefined) {
        throw new Error(`useWhisk must be used within a WhiskProvider`)
    }
    return context
}