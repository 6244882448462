import React, { useEffect, useCallback, useReducer } from 'react'
import { fetcher } from '../common'

export const FileContext = React.createContext()

export default function FilesProvider({ children }){
    const blobReducer = (state, { action, folder, filePath, url}) => {
        let newState = {...state}
        switch (action) {
            case 'add': {
                newState[`${folder}/${filePath}`] = url
                return newState
            }
            default:
                return newState
        }
    }

    const queueReducer = (state, { action, index, payload }) => {
        switch (action) {
            case 'add': {
                let newState = [...state, ...payload]        
                return newState
            }
            case 'setStatus': {
                let newState = [...state]
                newState[index].status = payload
                return newState
            }
            default:
                return state
        }
    }

    const [blobs, blobDispatch] = useReducer(blobReducer, {})
    const [queue, queueDispatch] = useReducer(queueReducer, [])

    const filePathIsInQueue = useCallback((filePath, folder) => {
        for (let i = 0; i < queue.length; i++) {
            const entry = queue[i]
            if (entry.filePath === filePath && entry.folder === folder) {
                return true
            }
        }
        return false
    }, [queue])

    const addFilePathsToQueue = useCallback((filePaths, folder = 'blog') => {
        let filePathsToAdd = []
        for (let i = 0; i < filePaths.length; i++) {
            const filePath = filePaths[i]
            if (!filePath) continue
            const isInQueue = filePathIsInQueue(filePath, folder)
            if (!isInQueue) {
                filePathsToAdd.push({ filePath: filePath, status: 'waiting', folder: folder })
            }
        }
        if (filePathsToAdd.length) {
            queueDispatch({ action: 'add', payload: filePathsToAdd })
        }
    }, [filePathIsInQueue])

    const getBlob = async (filePath, folder) => {
        if (filePath) {
            const response = await fetcher.getFile(filePath, folder)
            if (response) {
                const blob = await response.blob()
                return blob
            }
        }
        return null
    }
    
    const getUrl = useCallback(async (filePath, folder = 'blog') => {
        if (filePath) {
            if (blobs[filePath]) {
                return blobs[filePath]
            } else {
                const blob = await getBlob(filePath, folder)
                const url = URL.createObjectURL(blob)
                blobDispatch({ action: 'add', filePath: filePath, folder: folder, url: url })
            }
        }
    }, [blobs])
    
    useEffect(() => {
        if (queue.length) {
            for (let i = 0; i < queue.length; i++) {
                const queuedFile = queue[i]
                if (queuedFile.status === 'waiting') {
                    getUrl(queuedFile.filePath, queuedFile.folder)
                    queueDispatch({ action: 'setStatus', index: i, payload: 'pending' })
                    break
                }
            }
        }
    }, [queue, getUrl])

    const context = {
        blobs,
        addFilePathsToQueue,
        getBlob,
        getUrl
    }

    return (
        <FileContext.Provider value={context}>
            {children}
        </FileContext.Provider>
    )    
}

export function useFiles() {
    const context = React.useContext(FileContext)
    if (context === undefined) {
        throw new Error(`useFiles must be used within a FilesProvider`)
    }
    return context
}
