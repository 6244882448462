import React from 'react'
import { Router } from '@reach/router'
import { Chessboard, ChessMenu, ChessProvider } from '.'

export default function ChessModule() {
    return (
        <ChessProvider>
            <div className="module-container">
                <ChessMenu />
                <div className="main-container with-side-menu">
                    <Router>
                        <Chessboard path="play" default />
                    </Router>
                </div>
            </div>
        </ChessProvider>
    )
}
