import React, { useState, useEffect, useCallback } from 'react'
import { TrackerLineGraph, TrackerDataTable, StackedBarGraph, TrackerAggregateDataTable, useTracker } from '.'
import { fetcher, Card } from '../../common'
import { useAuth } from '../../main/auth'
import { Input, Row, Col } from 'reactstrap'
import { localizeAggregateData } from '../../utils'
import './tracker-data.scss'

export default function TrackerData({ groupId }) {
    const { user } = useAuth()
    const { permissions, activeGroup, getGroup, maxDate, minDate } = useTracker()
    const [data, setData] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [aggregateData, setAggregateData] = useState(null)
    const [filteredAggregateData, setFilteredAggregateData] = useState(null)
    const [activeNote, setActiveNote] = useState(0)
    const isOwner = user && activeGroup && user.userId === activeGroup.userId
    const groupPermissions = permissions && activeGroup ? permissions.filter(x => x.groupId === activeGroup.id) : []
    const myPermission = groupPermissions.find(x => x.userId === user.userId)
    const hasEditPermission = myPermission ? myPermission.canEdit : false
    const canEdit = isOwner || hasEditPermission

    const getData = useCallback(async () => {
        if (activeGroup && !activeGroup.isAggregate) {
            setData(activeGroup.data)
        } else if (activeGroup && activeGroup.isAggregate && activeGroup.metadata && activeGroup.metadata.aggregateGroups) {
            const response = await fetcher.get(`tracker/aggregate/${activeGroup.id}`)
            if (response) {
                setAggregateData(response)
            }
            setActiveNote(activeGroup.id)
        }
    }, [activeGroup])

    const applyAggregateFilters = useCallback(() => {
        if (!aggregateData) return
        let updatedData = localizeAggregateData(aggregateData)
        if (minDate) {
            updatedData.data = updatedData.data.filter(x => x.date >= minDate)
        }
        if (maxDate) {
            updatedData.data = updatedData.data.filter(x => x.date <= maxDate)
        }
        setFilteredAggregateData(updatedData)
    }, [maxDate, minDate, aggregateData])

    const applyFilters = useCallback(() => {
        if (activeGroup && activeGroup.isAggregate) {
            applyAggregateFilters()
        } else if (activeGroup && !activeGroup.isAggregate) {
            let updatedData = [...data]
            if (minDate) {
                updatedData = updatedData.filter(x => {
                    const entryDate = new Date(x.timeUtc)
                    return entryDate >= minDate
                })
            }
            if (maxDate) {
                updatedData = updatedData.filter(x => {
                    const entryDate = new Date(x.timeUtc)
                    return entryDate <= maxDate
                })
            }
            setFilteredData(updatedData)
        }
    }, [data, activeGroup, minDate, maxDate, applyAggregateFilters])

    const getNotes = () => {
        if (activeNote && aggregateData) {
            if (activeNote === activeGroup.id) {
                return activeGroup.notes === null ? '' : activeGroup.notes
            }
            const g = aggregateData.groups.find(x => x.id === activeNote)
            if (g) {
                return g.notes === null ? '' : g.notes
            }
        }
        return ''
    }

    useEffect(() => {
        getGroup(groupId)
    }, [getGroup, groupId])

    useEffect(() => {
        getData()
    }, [getData])

    useEffect(() => {
        applyFilters()
    }, [applyFilters])

    if (activeGroup) return (
        <Card className="tracker-data" header={activeGroup.name}>
            <Row>
                {activeGroup.isAggregate
                    ? <Col sm={12}>
                        <StackedBarGraph 
                            data={filteredAggregateData} 
                            groups={aggregateData ? aggregateData.groups : []}
                            metadata={activeGroup.metadata}
                        /> 
                    </Col>
                    : <Col sm={12} md={12} lg={8} xl={8}>
                        <TrackerLineGraph 
                            data={filteredData} 
                            ylabel={activeGroup.unit}
                            metadata={activeGroup.metadata}
                        />
                    </Col>
                }
                {activeGroup.isAggregate
                    ? <Col sm={12}>
                        <TrackerAggregateDataTable data={filteredAggregateData} />
                    </Col>
                    : <Col sm={12} md={12} lg={4} xl={4}>
                        <TrackerDataTable 
                            data={filteredData}
                            canEdit={canEdit} 
                        />
                    </Col>
                }
            </Row>
            {activeGroup.notes && !activeGroup.isAggregate &&
                <Card header="Notes" className="notes">
                    <Input type="textarea" value={activeGroup.notes} readOnly />
                </Card>}
            {activeNote !== 0 && activeGroup.isAggregate && aggregateData &&
                <Card header="Notes" className="notes">
                    <Input type="select" value={activeNote} onChange={e => setActiveNote(parseInt(e.target.value, 10))} >
                        <option value={activeGroup ? activeGroup.id : 0}>{activeGroup ? activeGroup.name : ''}</option>
                        {aggregateData && aggregateData.groups.map((g, i) => 
                            <option key={`group-option-${g.id}`} value={g.id}>{g.name}</option>)}
                    </Input>
                    <Input type="textarea" value={getNotes()} readOnly />
                </Card>}
        </Card>
    )

    return null
}
