import React from 'react'
import { Link } from '@reach/router'
import { Row, Col, Card, CardBody } from 'reactstrap'
import layer1 from './layer1.png'
import layer2 from './layer2.png'
import layer3 from './layer3.png'
import layer4 from './layer4.png'

export default function _2x22() {
    return (
        <div>
            <h1>2x2 Beginner Tutorial: Bottom Layer</h1>

            <ul>
                <li><Link to="/cubetools/2x2">Introduction</Link></li>
                <li><Link to="/cubetools/2x2/1">Bottom Side</Link></li>
                <li>Bottom Layer</li>
                <li><Link to="/cubetools/2x2/3">Top Side</Link></li>
                <li><Link to="/cubetools/2x2/4">Top Layer</Link></li>
            </ul>

            <Row>
                <Col>
                    <p>Turn the cube so that white is on top. Next we will look along the sides of the top pieces for &quot;matching corners&quot; - adjacent corners that have the same color on the same side.</p>
                </Col>
                <Col>
                    <Card className="text-center">
                        <CardBody>
                            <img src={layer4} style={{ height: '100px' }} alt="" />
                        </CardBody>
                        <CardBody>
                            In this example, there are red matching corners.
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <h2>Matching Corners</h2>
            
            <p>If there are matching corners, turn the cube (or simply turn the top layer) so that the matching corners are on the left side. Then use this algorithm:</p>

            <p>(RU2R'U') (RU2L'U) (R'U'R)</p>
            
            <p>This algorithm makes the two right corners trade places, so now you should have a solved layer on top:</p>

            <Row className="mb-2">
                <Col className="text-center">
                    <img src={layer1} style={{ height: '100px' }} alt="" /><br />(front)
                </Col>
                <Col className="text-center">
                    <img src={layer2} style={{ height: '100px' }} alt="" /><br />(back)
                </Col>
            </Row>

            <h2>No Matching Corners</h2>
            
            <p>Sometimes there will be no matching corners.</p>
            
            <div className="text-center">
                <img src={layer3} style={{ height: '100px' }} alt="" />
            </div>

            <p>If there are no matching corners, simply use the above algorithm with white on top. This will give you matching corners. Do the algorithm once more as described above.</p>

            <p>Once the entire layer is solved, turn the cube so that white is once again on the bottom, and proceed to the <Link to="/cubetools/2x2/3">next step, the top side</Link>.</p>

        </div>
    )
}
