import React from 'react'
import SideMenu from '../../main/nav/SideMenu'
import { ButtonGroup, Button } from 'reactstrap'
import { navigate } from '@reach/router'
import { useAuth } from '../../main/auth'
import { AddCircle, Label, List, Home } from '@material-ui/icons'

export default function BlogMenu() {
    const { user } = useAuth()

    return (
        <SideMenu header="Blog" headerLink="/blog">
            <ButtonGroup vertical className="w-100">
                <Button
                    color="primary"
                    outline
                    onClick={() => navigate('/blog')}
                >
                    <span className="d-flex align-items-center"><Home className="me-1" />Blog</span>
                </Button>
                {user && user.isAdmin && <>
                    <Button
                        color="primary"
                        outline
                        onClick={() => navigate('/blog/posts')}
                    >
                        <span className="d-flex align-items-center"><List className="me-1" />My Posts</span>
                    </Button>
                    <Button
                        color="primary"
                        outline
                        onClick={() => navigate('/blog/posts/new')}
                    >
                        <span className="d-flex align-items-center"><AddCircle className="me-1" />Post</span>
                    </Button>
                    <Button
                        color="primary"
                        outline
                        onClick={() => navigate('/blog/tags')}
                    >
                        <span className="d-flex align-items-center"><Label className="me-1" />Manage Blog Tags</span>
                    </Button>
                </>}
            </ButtonGroup>
        </SideMenu>
    )
}
