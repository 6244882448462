import React, { useState, useEffect, useCallback } from 'react'
import { PieceDialog, useMusic } from '.'
import { Folder } from '@material-ui/icons'
import { compareStrings, displayFileSize } from '../../utils'
import { Link } from '@reach/router'
import { Modal, ModalHeader, ModalBody, Badge } from 'reactstrap'
import './music-browser.scss'

export default function MusicBrowser() {
    const { accessToken, setActivePieceCollection, setActivePieceUrl, setMode, setSelectedPiece, pieces, getPieces, loadAndPlayFile } = useMusic()
    const [folderPathArray, setFolderPathArray] = useState(['Family Room', 'Music'])
    const [folderContents, setFolderContents] = useState([])
    const [showAddPieceModal, setShowAddPieceModal] = useState(false)
    const [showEditPieceModal, setShowEditPieceModal] = useState(false)
    const [activeEntry, setActiveEntry] = useState(null)
    const [editingPiece, setEditingPiece] = useState(null)
    const folders = folderContents
        .filter(entry => entry[".tag"] === 'folder')
        .sort((a, b) => compareStrings(a.name, b.name))
    const files = folderContents
        .filter(entry => entry[".tag"] === 'file')
        .sort((a, b) => compareStrings(a.name, b.name))

    const openFolder = folder => {
        setFolderPathArray(x => [...x, folder])
    }

    const navigateToFolder = index => {
        const updatedArray = [...folderPathArray].slice(0, index + 1)
        setFolderPathArray(updatedArray)
    }

    const handleFileClick = async (entry, index) => {
        setActivePieceUrl('')
        setMode('piece')
        setActivePieceCollection([])
        const pieceOnTheFly = {
            id: 0,
            name: entry.name,
            album: '',
            artist: '',
            path: entry.path_display,
            tags: []
        }
        setSelectedPiece(pieceOnTheFly)
        loadAndPlayFile(pieceOnTheFly.path)
    }

    const listFolder = useCallback(async () => {
        if (accessToken) {
            const apiPath = 'https://api.dropboxapi.com/2/files/list_folder'
            const folderPath = `/${folderPathArray.join('/')}`
            const body = {
                "path": folderPath,
                "recursive": false,
                "include_deleted": false,
                "include_has_explicit_shared_members": false,
                "include_mounted_folders": true,
                "include_non_downloadable_files": false
            }
            const request = {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify(body)
            }
            const response = await fetch(apiPath, request)
            if (response.ok) {
                const jsonResponse = await response.json()
                setFolderContents(jsonResponse.entries)
            }
        }
    }, [accessToken, folderPathArray])

    useEffect(() => {
        listFolder()
    }, [listFolder])

    const handleAddBtnClick = entry => {
        setActiveEntry(entry)
        setShowAddPieceModal(true)
    }

    const handleEditBtnClick = piece => {
        setEditingPiece(piece)
        setShowEditPieceModal(true)
    }

    const renderPieceBtn = entry => {
        const entryPath = entry.path_lower
        const storedPiece = pieces.find(piece => piece.path.toLowerCase() === entryPath)
        if (storedPiece) {
            return (
                <Badge 
                    pill 
                    color="success" 
                    className="clickable me-1" 
                    onClick={() => handleEditBtnClick(storedPiece)} 
                    style={{ width: '60px' }}
                >
                    View
                </Badge>
            )
        }
        return (
            <Badge 
                pill 
                color="info" 
                className="clickable me-1" 
                onClick={() => handleAddBtnClick(entry)} 
                style={{ width: '60px' }}
            >
                Add
            </Badge>
        )
    }

    const handleCloseAddPieceModal = async () => {
        getPieces()
        listFolder()
        setShowAddPieceModal(false)
    }

    const handleCloseEditPieceModal = async () => {
        getPieces()
        listFolder()
        setShowEditPieceModal(false);
    }

    return (
        <div className="music-browser">
            <h1>Browse Dropbox</h1>
            <div className="mb-2">
                {folderPathArray.map((folder, index) => 
                    <React.Fragment key={`folder-path-${folder}-${index}`}>
                        {index < folderPathArray.length - 1
                            ? <span><Link to="" onClick={() => navigateToFolder(index)}>{folder}</Link> | </span>
                            : <span>{folder}</span>
                        }
                    </React.Fragment>
                )}
            </div>
            <ul className="folder-contents-list">
                {folders.map((entry, index) => 
                    <li key={`folder-${index}`} className="clickable" onClick={() => openFolder(entry.name)}>
                        <Folder className="me-1" />{entry.name}
                    </li>
                )}
                {files.map((entry, index) => 
                    <li key={`file-${index}`}>
                        {renderPieceBtn(entry)}
                        <span className="clickable" onClick={() => handleFileClick(entry, index)}>
                            {entry.name}{' '} 
                            <span className="text-muted">({displayFileSize(entry.size)})</span>
                        </span>
                    </li>
                )}
            </ul>
            <Modal isOpen={showAddPieceModal} toggle={() => setShowAddPieceModal(x => !x)} size="lg">
                <ModalHeader toggle={() => setShowAddPieceModal(x => !x)}>Add Piece Record</ModalHeader>
                <ModalBody>
                    <PieceDialog 
                        isModal 
                        entry={activeEntry} 
                        onClose={handleCloseAddPieceModal} 
                    />
                </ModalBody>
            </Modal>
            <Modal isOpen={showEditPieceModal} toggle={() => setShowEditPieceModal(x => !x)} size="lg">
                <ModalHeader toggle={() => setShowEditPieceModal(x => !x)}>Edit Piece Record</ModalHeader>
                <ModalBody>
                    <PieceDialog 
                        isModal
                        pieceId={editingPiece ? editingPiece.id : 0}
                        onClose={handleCloseEditPieceModal} 
                    />
                </ModalBody>
            </Modal>
        </div>
    )
}
