import React from 'react'
import { Redirect, Router } from '@reach/router'
import { ErrorBoundary } from '../common'
import { CubeToolsModule } from '../modules/CubeTools'
import { MusicModule } from '../modules/Music'
import Login from './auth/Login'
import Games from './Games'
import Home from './Home'
import Navigation from './nav/Navigation'
import Productivity from './Productivity'
import Signup from './auth/Signup'
import ProtectedRoutes from './ProtectedRoutes'
import { useAuth } from './auth/AuthProvider'
import { BlogModule } from '../modules/Blog'
import { TrustFundSummary } from '../modules/TrustFund'

export default function Main() {
    const { user } = useAuth()

    return (
        <>
            <Navigation />
            <main>
                <ErrorBoundary>
                    <Router>
                        <Home path="/home" />
                        {user 
                            ? <Redirect from="/" to="/dashboard" noThrow />
                            : <Home path="/" />
                        }
                        <CubeToolsModule path="/cubetools/*" />
                        <MusicModule path="/music/*" />
                        <Games path="/games/*" />
                        <BlogModule path="/blog/*" />
                        <Productivity path="/productivity" />
                        <Login path="/login" />
                        <Signup path="/signup" />
                        <TrustFundSummary path="/fund" />
                        <ProtectedRoutes path="/*" />
                    </Router>
                </ErrorBoundary>
            </main>
        </>
    )
}
