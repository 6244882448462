import { Link } from "@reach/router";
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Nav,
    NavItem,
    UncontrolledDropdown,
    Input,
    Button,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Form,
} from "reactstrap";
import { useLocalStorage } from "../../common";
import { AddCircle } from "@material-ui/icons";
import { useState } from "react";

export default function WorkStart() {
    const [notes, setNotes] = useLocalStorage("notes", []);
    const [showModal, setShowModal] = useState(false);
    const [activeNoteIndex, setActiveNoteIndex] = useLocalStorage("activeNoteIndex", -1);
    const activeNote = activeNoteIndex < 0 ? undefined : notes[activeNoteIndex];

    const handleAddNote = () => {
        setActiveNoteIndex(notes.length)
        setNotes((prev) => [...prev, { name: "Untitled", value: "" }]);
        setShowModal(true)
    };

    const handleDelete = () => {
        setShowModal(false)
        setNotes(prev => {
            let result = [...prev];
            result.splice(activeNoteIndex, 1);
            return result;
        })
    }

    return (
        <div className="start-page">
            <Nav className="d-flex align-items-center">
                <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret></DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem to="/music/playlists" tag={Link}>
                            Music
                        </DropdownItem>
                        <DropdownItem to="/calendar" tag={Link}>
                            Calendar
                        </DropdownItem>
                        <DropdownItem to="/admin/start" tag={Link}>
                            Home Start
                        </DropdownItem>
                        <DropdownItem
                            href="https://keysys.atlassian.net/jira/projects"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Jira
                        </DropdownItem>
                        <DropdownItem
                            href="https://keysys.atlassian.net/wiki/spaces/KKR/overview?homepageId=66194"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Knowledge Repo
                        </DropdownItem>
                        <DropdownItem href="http://bitbucket.org" target="_blank" rel="noopener noreferrer">
                            Bitbucket
                        </DropdownItem>
                        <DropdownItem href="https://keysys.bamboohr.com" target="_blank" rel="noopener noreferrer">
                            Bamboo
                        </DropdownItem>
                        <DropdownItem href="https://pal.keysyscloud.com/" target="_blank" rel="noopener noreferrer">
                            PAL
                        </DropdownItem>
                        <DropdownItem href="https://workforce.intuit.com/" target="_blank" rel="noopener noreferrer">
                            QuickBooks
                        </DropdownItem>
                        <DropdownItem href="https://keysys.harvestapp.com" target="_blank" rel="noopener noreferrer">
                            Harvest
                        </DropdownItem>
                        <DropdownItem
                            href="https://keysysconsulting.cultureamp.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Culture Amp
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
                <NavItem className="clickable" onClick={handleAddNote}>
                    <AddCircle />
                </NavItem>
                {notes.map((note, index) => (
                    <NavItem
                        className="clickable"
                        onClick={() => index === activeNoteIndex ? setShowModal(true) : setActiveNoteIndex(index)}
                        key={`note-${note.name}-${index}`}
                        style={{ color: activeNoteIndex === index ? "blue" : "#777" }}
                    >
                        {note.name}
                    </NavItem>
                ))}
            </Nav>
            <div>
                {activeNote ? (
                    <Input
                        name="text"
                        type="textarea"
                        style={{ color: "#8f8", backgroundColor: "#000", height: '85vh' }}
                        value={activeNote.value}
                        onChange={(e) =>
                            setNotes((prev) => {
                                let result = [...prev];
                                prev[activeNoteIndex].value = e.target.value;
                                return result;
                            })
                        }
                    />
                ) : null}
            </div>

            <Modal isOpen={showModal} toggle={() => setShowModal(x => !x)} centered>
                <Form onSubmit={(e) => { e.preventDefault(); setShowModal(false)}}>
                    <ModalHeader>Edit {activeNote?.name || ''}</ModalHeader>
                    <ModalBody>
                        <Input 
                            autoFocus
                            value={notes[activeNoteIndex]?.name || ''}
                            onChange={(e) =>
                                setNotes((prev) => {
                                    let result = [...prev];
                                    prev[activeNoteIndex].name = e.target.value;
                                    return result;
                                })
                            }
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button type="button" color="danger" onClick={handleDelete}>Delete</Button>
                        <Button type="submit" color="secondary" onClick={() => setShowModal(false)}>Close</Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </div>
    );
}
