import React, { useState, useEffect } from 'react'
import { fetcher, Loading } from '../../common'

export const AuthContext = React.createContext()

export default function AuthProvider({ children }) {
    const [appLoaded, setAppLoaded] = useState(false)
    const [user, setUser] = useState(null)
    const [isLoadingUser, setIsLoadingUser] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [userSettings, setUserSettings] = useState({})

    useEffect(() => {
        refresh()
    }, [])

    useEffect(() => {
        if (user) {
            setUserSettings(user.settings)
        } else {
            setUserSettings({})
        }
    }, [user])

    const getUpdatedUserSettings = async () => {
        let response = await fetcher.get('user/settings')
        setUserSettings(response)
    }

    const updateUserSettings = async updatedSettings => {
        const response = await fetcher.put('user/settings', updatedSettings)
        if (response.ok)
            getUpdatedUserSettings()
    }

    const refresh = async () => {
        setIsLoadingUser(true)
        const response = await fetcher.get('session')
        if (response.user)
            setUser(response.user)
        else
            setUser(null)
        setIsLoadingUser(false)
        setAppLoaded(true)
    }

    const login = async (email, password) => {
        setIsLoadingUser(true)
        const body = {
            email: email,
            password: password
        }
        const response = await fetcher.post('session', body)
        if (response.user)
            setUser(response.user)
        setIsLoadingUser(false)
        return response
    }

    const logout = async () => {
        setIsLoadingUser(true)
        await fetcher.del('session')
        setUser(null)
        setIsLoadingUser(false)
    }

    const context = {
        user,
        userSettings,
        getUpdatedUserSettings,
        updateUserSettings,
        refresh,
        login,
        logout,
        isLoadingUser,
        isLoading, setIsLoading
    }

    if (!appLoaded) {
        return <Loading />
    }
    
    return (
        <AuthContext.Provider value={context}>
            {children}
        </AuthContext.Provider>
    )
}

export function useAuth() {
    const context = React.useContext(AuthContext)
    if (context === undefined) {
        throw new Error(`useAuth must be used within an AuthProvider`)
    }
    return context
}
