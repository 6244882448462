import React, { useState, useEffect, useCallback } from 'react'
import { Close, ArrowBackIos, ArrowForwardIos } from '@material-ui/icons'
import { getExtension, getFileNameWithoutTimestamp } from '../utils'
import { useFiles } from '../main/FilesProvider'
import './file-viewer.scss'

export default function FileViewer({ files, initialFile, folder = 'blog', onClose = () => {} }) {
    const { blobs } = useFiles()
    const [activeFileIdx, setActiveFileIdx] = useState(0)
    const activeFile = files[activeFileIdx]

    const loadInitialFile = useCallback(() => {
        if (initialFile) {
            const fileIndex = files.findIndex(x => x.id === initialFile.id)
            if (fileIndex >= 0) {
                setActiveFileIdx(fileIndex)
            }
        }
    }, [initialFile, files])

    useEffect(() => {
        loadInitialFile()
    }, [loadInitialFile])

    const renderFile = () => {
        if (!activeFile) return null
        const imgExtensions = ['jpg', 'jpeg', 'gif', 'png']
        const url = blobs[`${folder}/${activeFile.path}`]
        const isImage = imgExtensions.includes(getExtension(activeFile.path))
        if (isImage) {
            return (
                <div className="rendered-image" style={{ backgroundImage: `url(${url})` }} />
            )
        } else {
            return (
                <iframe src={url} title="non-image file" className="embedded-file" />
            )
        }
    }

    const nextFile = () => {
        if (activeFileIdx === files.length - 1) {
            setActiveFileIdx(0)
        } else {
            setActiveFileIdx(x => x + 1)
        }
    }

    const previousFile = () => {
        if (activeFileIdx === 0) {
            setActiveFileIdx(files.length - 1)
        } else {
            setActiveFileIdx(x => x - 1)
        }
    }

    if (activeFile)
        return (
            <div className="file-viewer">
                <div className="file-viewer-header">
                    <div className="file-name">
                        {activeFile.name || getFileNameWithoutTimestamp(activeFile.path)}
                    </div>
                    <div className="controls">
                        {files.length > 1 && <> 
                            <ArrowBackIos className="control-btn" onClick={previousFile} />
                            <ArrowForwardIos className="control-btn" onClick={nextFile} />
                        </>}
                    </div>
                    <div className="close-btn">
                        <Close className="close-btn-icon" onClick={onClose} />
                    </div>
                </div>
                <div className="main">
                    {renderFile()}
                </div>
            </div>
        )

    return null
}
