import React from 'react'
import { navigate } from '@reach/router'
import { Card, Loading } from '../../common'
import { BarChart } from '@material-ui/icons'
import { useTracker } from '.'
import { useAuth } from '../../main/auth'
import { useFriends } from '../Friends/FriendsProvider'
import { Button } from 'reactstrap'
import './tracker-group-list.scss'

export default function TrackerGroupList() {
    const { user } = useAuth()
    const { friends } = useFriends()
    const { groups, isLoading } = useTracker()
    const myGroups = groups ? groups.filter(group => group.userId === user.userId) : []
    const friendGroups = groups ? groups.filter(group => group.userId !== user.userId) : []

    if (isLoading || !user) {
        return <Loading />
    }

    return (
        <div className="tracker-group-list">
            <Card header="My Data">
                {groups.length < 1 && <p className="text-muted">No data groups found. Add some!</p>}
                <div className="groups">
                    {myGroups.map((group, i) => 
                        <Button 
                            key={`my-group-${i}`} 
                            color="info" 
                            outline
                            onClick={() => navigate(`/tracker/groups/${group.id}`)} 
                            className="me-2 mb-2"
                        >
                            <div className="button-content">
                                {group.name} {group.isAggregate && <BarChart className="icon" />}
                            </div>
                        </Button>
                    )}
                </div>
            </Card>
            {friendGroups.length > 0 && friends.length > 0 && <> 
                <Card header="Friends' Data">
                    <div className="groups">
                        {friendGroups.map((group, i) => {
                            const friend = friends.find(f => f.userId === group.userId)
                            return (
                                <Button 
                                    key={`friend-group-${i}`} 
                                    color="info"
                                    outline
                                    onClick={() => navigate(`/tracker/groups/${group.id}`)}
                                    className="me-2 mb-2"
                                >
                                    <div className="text-center">
                                        <div className="button-content">
                                            {group.name} {group.isAggregate && <BarChart className="icon" />}
                                        </div>
                                        <div className="friend-name">({friend.firstName} {friend.lastName})</div>
                                    </div>
                                </Button>
                            )
                        })}
                    </div>
                </Card>
            </>}
        </div>
    )
}
