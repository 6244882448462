import React, { useState, useEffect, useCallback } from 'react'
import { dateFromInputs, dateToString } from '../../utils'
import { FormText, Input, FormGroup } from 'reactstrap'
import { useTracker } from '.'

export default function TrackerDataFilter() {
    const { setMinDate, setMaxDate } = useTracker()
    
    const getDefaultMin = () => {
        const d = new Date()
        const month = d.getMonth()
        const minMonth = month === 0 ? 11 : month - 1
        d.setMonth(minMonth)
        if (month === 0) {
            d.setFullYear(d.getFullYear() - 1)
        }
        return dateToString(d)
    }

    const [xMinInput, setXMinInput] = useState(getDefaultMin())
    const [xMaxInput, setXMaxInput] = useState(dateToString())
    const [xMin, setXMin] = useState('')
    const [xMax, setXMax] = useState('')

    const updateMinMax = useCallback((min, max) => {
        setMinDate(min)
        setMaxDate(max)
    }, [setMaxDate, setMinDate])

    useEffect(() => {
        if (xMinInput === '') {
            setXMin('')
        } else {
            setXMin(dateFromInputs(xMinInput))
        }
    }, [xMinInput])

    useEffect(() => {
        if (xMaxInput === '') {
            setXMax('')
        } else {
            setXMax(dateFromInputs(xMaxInput, '23:59'))
        }
    }, [xMaxInput])

    useEffect(() => {
        updateMinMax(xMin, xMax)
    }, [xMin, xMax, updateMinMax])

    return (
        <div className="mt-2">
            <p>Data Filter</p>
            <FormGroup>
                <Input type="date" value={xMinInput} onChange={e => setXMinInput(e.target.value)} />
                <FormText>Min</FormText>
            </FormGroup>
            <FormGroup>
                <Input type="date" value={xMaxInput} onChange={e => setXMaxInput(e.target.value)} />
                <FormText>Max</FormText>
            </FormGroup>
        </div>
    )    
}