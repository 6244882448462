import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { Chord } from '..'

export default function HarmonicProgressions() {
    return (
        <div>
            <h2>Chord Progressions</h2>
            <p>Click a chord to hear it in the key of C.</p>
            <h4>Major</h4>
            <Container>
                <Row className="mb-2 d-flex align-items-center">
                    <Col xs={5} sm={4} md={3} lg={2}>
                        <h6>Tonic</h6>
                    </Col>
                    <Col xs={7} sm={8} md={9} lg={10} className="d-flex">
                        <Chord symbol="I" bg='#FCC' notes='c4,e4,g4,c5' />
                    </Col>
                </Row>
                <Row className="mb-2 d-flex align-items-center">
                    <Col xs={5} sm={4} md={3} lg={2}>
                        <h6>Dominant</h6>
                    </Col>
                    <Col xs={7} sm={8} md={9} lg={10} className="d-flex">
                        <Chord symbol="V" bg='#FFC' notes='g3,d4,g4,b4' />
                        <Chord symbol={<span>V<sup>7</sup></span>} bg='gold' notes='g3,f4,g4,b4' />
                        <Chord symbol="vii&deg;" bg='#FEC' notes='b3,d4,f4' />
                    </Col>
                </Row>
                <Row className="mb-2 d-flex align-items-center">
                    <Col xs={5} sm={4} md={3} lg={2}>
                        <h6>Subdominant</h6>
                    </Col>
                    <Col xs={7} sm={8} md={9} lg={10} className="d-flex">
                        <Chord symbol="ii" bg='#8CF' notes='d4,f4,a4' />
                        <Chord symbol="IV" bg='#CFC' notes='f3,c4,f4,a4' />
                        <Chord symbol="V/V" bg='#91c791' notes='d4,fs4,a4' />
                        <Chord symbol={<span>It<sup>+6</sup></span>} bg='#CCF' notes='gs3,c4,fs4' />
                        <Chord symbol={<span>Fr<sup>+6</sup></span>} bg='#CCF' notes='gs3,c4,d4,fs4' />
                        <Chord symbol={<span>Ger<sup>+6</sup></span>} bg='#CCF' notes='gs3,c4,ds4,fs4' />
                    </Col>
                </Row>
                <Row className="mb-2 d-flex align-items-center">
                    <Col xs={5} sm={4} md={3} lg={2}>
                        <h6>Submediant</h6>
                    </Col>
                    <Col xs={7} sm={8} md={9} lg={10} className="d-flex">
                        <Chord symbol="vi" bg="#FAD" notes='a3,e4,a4,c5' />
                        <Chord symbol="V/ii" bg="#FBE" notes='a3,cs4,e4,a4' />
                        <Chord symbol={<span>V<sup>7</sup>/IV</span>} bg="#FBE" notes='c4,e4,g4,as4' />
                    </Col>
                </Row>
                <Row className="mb-2 d-flex align-items-center">
                    <Col xs={5} sm={4} md={3} lg={2}>
                        <h6>Mediant</h6>
                    </Col>
                    <Col xs={7} sm={8} md={9} lg={10} className="d-flex">
                        <Chord symbol="iii" bg='#cb9c83' notes='e4,g4,b4' />
                        <Chord symbol="V/vi" bg='#c28b6d' notes='e4,gs4,b4' />
                    </Col>
                </Row>
            </Container>

            <h4>Minor</h4>
            <Container>
                <Row className="mb-2 d-flex align-items-center">
                    <Col xs={5} sm={4} md={3} lg={2}>
                        <h6>Tonic</h6>
                    </Col>
                    <Col xs={7} sm={8} md={9} lg={10} className="d-flex">
                        <Chord symbol="i" bg='#FCC' notes='c4,ds4,g4,c5' />
                    </Col>
                </Row>
                <Row className="mb-2 d-flex align-items-center">
                    <Col xs={5} sm={4} md={3} lg={2}>
                        <h6>Dominant</h6>
                    </Col>
                    <Col xs={7} sm={8} md={9} lg={10} className="d-flex">
                        <Chord symbol="V" bg='#FFC' notes='g3,d4,g4,b4' />
                        <Chord symbol="vii&deg;" bg='#FEC' notes='b3,d4,f4' />
                    </Col>
                </Row>
                <Row className="mb-2 d-flex align-items-center">
                    <Col xs={5} sm={4} md={3} lg={2}>
                        <h6>Subdominant</h6>
                    </Col>
                    <Col xs={7} sm={8} md={9} lg={10} className="d-flex">
                        <Chord symbol="ii&deg;" bg='#8cf' notes='d4,f4,gs4' />
                        <Chord symbol="iv" bg='#CFC' notes='f3,c4,f4,gs4' />
                        <Chord symbol={<span>N<sup>6</sup></span>} bg='#91c791' />
                        <Chord symbol={<span>It<sup>+6</sup></span>} bg='#CCF' notes='gs3,c4,fs4' />
                        <Chord symbol={<span>Fr<sup>+6</sup></span>} bg='#CCF' notes='gs3,c4,d4,fs4' />
                        <Chord symbol={<span>Ger<sup>+6</sup></span>} bg='#CCF' notes='gs3,c4,ds4,fs4' />
                    </Col>
                </Row>
                <Row className="mb-2 d-flex align-items-center">
                    <Col xs={5} sm={4} md={3} lg={2}>
                        <h6>Submediant</h6>
                    </Col>
                    <Col xs={7} sm={8} md={9} lg={10} className="d-flex">
                        <Chord symbol="VI" bg='#FAD' notes='gs3,ds4,gs4,c5' />
                    </Col>
                </Row>
                <Row className="mb-2 d-flex align-items-center">
                    <Col xs={5} sm={4} md={3} lg={2}>
                        <h6>Mediant</h6>
                    </Col>
                    <Col xs={7} sm={8} md={9} lg={10} className="d-flex">
                        <Chord symbol="III" bg='#cb9c83' notes='ds4,g4,as4' />
                    </Col>
                </Row>
                <Row className="mb-2 d-flex align-items-center">
                    <Col xs={5} sm={4} md={3} lg={2}>
                        <h6>Subtonic</h6>
                    </Col>
                    <Col xs={7} sm={8} md={9} lg={10} className="d-flex">
                        <Chord symbol="VII" bg='#ccc' notes='as3,d4,f4,as4' />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
