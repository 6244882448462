import React, { useState, useEffect } from 'react'
import { Clear } from '@material-ui/icons'
import { Button, Input, Label, FormGroup, Col } from 'reactstrap'
import './reference-value-input.scss'

export default function ReferenceValueInput({ index, type, value, color, label, alwaysShow, onChange, onDelete }) {
    const [valueInput, setValueInput] = useState(value ? value : '')
    const [colorInput, setColorInput] = useState(color ? color : '#000000')
    const [labelInput, setLabelInput] = useState(label ? label : '')
    const [alwaysShowInput, setAlwaysShowInput] = useState(alwaysShow ? alwaysShow : false)

    useEffect(() => {
        if (onChange && (
            (type === 'value' ? parseFloat(valueInput) !== value : valueInput !== value) ||
            colorInput !== color || 
            labelInput !== label || 
            alwaysShowInput !== alwaysShow)) {
            let updatedFields = {
                value: type === 'value' ? parseFloat(valueInput) : valueInput,
                color: colorInput,
                label: labelInput,
                alwaysShow: alwaysShowInput
            }
            onChange({ action: 'update', index: index, updatedFields: updatedFields})
        }
    }, [valueInput, colorInput, labelInput, alwaysShowInput, type, index, onChange,
        alwaysShow, value, color, label])

    return (
        <FormGroup row>
            <Col sm={2}>
                <Input 
                    type="color"
                    value={colorInput}
                    className="color-input"
                    onChange={e => setColorInput(e.target.value)}
                />
            </Col>
            <Col sm={2}>
                <Input
                    type="text"
                    placeholder="Label"
                    className="label-input"
                    value={labelInput}
                    onChange={e => setLabelInput(e.target.value)}
                />
            </Col>
            <Col sm={3}>
                {type === 'value' && 
                    <Input 
                        type="number"
                        placeholder="Value"
                        className="value-input"
                        value={valueInput}
                        onChange={e => setValueInput(e.target.value)}
                    />}
                {type === 'time' && 
                    <Input 
                        type="date"
                        placeholder="Date"
                        className="time-input"
                        value={valueInput}
                        onChange={e => setValueInput(e.target.value)}
                    />}
            </Col>
            <FormGroup inline check>
                <Label check>
                    <Input 
                        type="checkbox" 
                        checked={alwaysShowInput} 
                        onChange={e => setAlwaysShowInput(e.target.checked)}    
                    />Always Show
                </Label>
            </FormGroup>
            <FormGroup inline>
                <Button
                    color="danger"
                    outline
                    type="button"
                    onClick={onDelete}
                >
                    <Clear />
                </Button>
            </FormGroup>
        </FormGroup>
    )
}