import React from 'react'
import { useAuth } from '../../main/auth'
import { useFriends } from '../Friends/FriendsProvider'
import { useHoney } from '.'
import { Input } from 'reactstrap'
import './list-selector.scss'

export default function ListSelector({ onChange }) {
    const { user } = useAuth()
    const { lists, activeListId, isLoading } = useHoney()
    const { friends } = useFriends()
    const userLists = user && lists.length ? lists.filter(x => x.userId === user.userId) : []
    const friendLists = user && lists.length ? lists.filter(x => x.userId !== user.userId) : []

    if (isLoading)
        return null
    
    if (lists && lists.length)
        return (
            <div className="list-selector">
                <Input type="select" value={activeListId} onChange={e => onChange(e)}>
                    <option value={0} disabled>Select a list...</option>
                    {userLists.length &&
                        <optgroup label="My Lists">
                            {userLists.map(list => <option key={list.listId} value={list.listId}>{list.name}</option>)}
                        </optgroup>
                    }
                    {friendLists.length &&
                        <optgroup label="Friends' Lists">
                            {friendLists.map(list => {
                                let friend = friends ? friends.find(x => x.userId === list.userId) : null
                                let friendName = friend ? friend.firstName : ''
                                return (
                                    <option key={list.listId} value={list.listId}>{list.name} ({friendName})</option>
                                )
                            })}
                        </optgroup>
                    }
                </Input>
            </div>
        )
    
    return null
}
