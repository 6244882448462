import React from 'react'
import { AddCircle, Settings, MultilineChart } from '@material-ui/icons'
import { navigate } from '@reach/router'
import { ButtonGroup, Button } from 'reactstrap'
import { TrackerDataFilter, TrackerGroupDropdown, useTracker } from '.'
import { useAuth } from '../../main/auth/AuthProvider'
import SideMenu from '../../main/nav/SideMenu'

export default function TrackerMenu() {
    const { activeGroup, permissions } = useTracker()
    const { user } = useAuth()
    const isOwner = user && activeGroup && user.userId === activeGroup.userId
    const groupPermissions = permissions && activeGroup ? permissions.filter(x => x.groupId === activeGroup.id) : []
    const myPermission = groupPermissions.find(x => x.userId === user.userId)
    const hasEditPermission = myPermission ? myPermission.canEdit : false
    const canEdit = isOwner || hasEditPermission

    return (
        <SideMenu header="Tracker" headerLink="/tracker">
            <TrackerGroupDropdown />
            {activeGroup !== null && <TrackerDataFilter />}
            <ButtonGroup className="mt-2 w-100" vertical>
                {activeGroup !== null && 
                    <Button
                        type="button"
                        color="primary"
                        outline
                        onClick={() => navigate(`/tracker/groups/${activeGroup.id}`)}
                    >
                        <span className="d-flex align-items-center"><MultilineChart className="me-1" />View Data</span>
                    </Button>}
                {activeGroup && canEdit && !activeGroup.isAggregate && 
                    <Button
                        type="button"
                        color="primary"
                        outline
                        onClick={() => navigate(`/tracker/groups/${activeGroup.id}/data/new`)}
                    >
                        <span className="d-flex align-items-center"><AddCircle className="me-1" />Data</span>
                    </Button>}
                <Button
                    type="button"
                    color="primary"
                    outline
                    onClick={() => navigate('/tracker/groups/create')}
                >
                    <span className="d-flex align-items-center"><AddCircle className="me-1" />Data Group</span>
                </Button>
                {activeGroup && isOwner && 
                    <Button 
                        type="button"
                        color="primary"
                        outline
                        onClick={() => navigate(`/tracker/groups/${activeGroup.id}/edit`)}
                    >
                        <span className="d-flex align-items-center"><Settings className="me-1" />Group Settings</span>
                    </Button>}
            </ButtonGroup>
        </SideMenu>
    )
}
