import React, { useState, useEffect } from 'react'
import { useAuth } from '../../main/auth'
import { fetcher } from '../../common'
import { Done, Clear } from '@material-ui/icons'
import { navigate } from '@reach/router'
import { convertToUtcString } from '../../utils'
import { Button } from 'reactstrap'
import './score-dialog.scss'

export default function ScoreDialog({ score, onClose }) {
    const { user } = useAuth() 
    const [nameInput, setNameInput] = useState('')

    useEffect(() => {
        if (user) {
            setNameInput(user.firstName)
        }
    }, [user])

    const handleSubmit = async e => {
        e.preventDefault()
        const body = {
            name: nameInput,
            userId: user ? user.userId : null,
            score: score,
            date: convertToUtcString(new Date())
        }
        await fetcher.post('kitty', body)
        navigate('/games/kitty/scores')
    }

    return (
        <div className="score-dialog">
            <form>
                <table className="score-input">
                    <tbody>
                        <tr>
                            <th>Score:</th>
                            <td>{score}</td>
                        </tr>
                        <tr>
                            <th>Name:</th>
                            <td>
                                <input 
                                    type="text"
                                    placeholder="Name"
                                    value={nameInput}
                                    onChange={e => setNameInput(e.target.value)}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="d-flex">
                    <Button 
                        color="success"
                        type="submit"
                        onClick={e => handleSubmit(e)}
                    >
                        <span className="d-flex align-items-center"><Done className="me-1" />Save</span>
                    </Button>
                    <Button
                        color="secondary"
                        type="button"
                        onClick={onClose}
                    >
                        <span className="d-flex align-items-center"><Clear className="me-1" />Cancel</span>
                    </Button>
                </div>
            </form>
        </div>
    )
}
