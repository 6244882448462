import React, { useCallback, useEffect } from 'react'
import { useChess } from '.'
import { ArrowBackIos, ArrowForwardIos, FirstPage, LastPage } from '@material-ui/icons'
import { analyzePosition, getAnalysisForPosition } from './analysis'
import { Button, ButtonGroup } from 'reactstrap'
import SideMenu from '../../main/nav/SideMenu'
import './chess-menu.scss'

export default function ChessMenu() {
    const { 
        showCoords, setShowCoords, flipView, resetBoard, 
        currentPosition, positions, moves, undoLastMove,
        darkColor, setDarkColor, lightColor, setLightColor, 
        currentHalfMove, setCurrentHalfMove, isViewingLatestPosition,
        gameResult
    } = useChess()

    // TODO: auto-flip, analysis mode, load PGN/FEN, play against basic AI, sounds, animations, blindfold mode
    
    const getFEN = useCallback(() => {
        if (currentPosition) {
            const { pieces, turn, castlingOptions, enPassantSquare, halfMoveClock, fullMoves } = currentPosition
            return `${pieces} ${turn} ${castlingOptions} ${enPassantSquare} ${halfMoveClock} ${fullMoves}`
        }
        return ''
    }, [currentPosition])

    const previousMove = () => {
        if (currentHalfMove > 0) {
            setCurrentHalfMove(x => x - 1)
        }
    }

    const nextMove = () => {
        if (!isViewingLatestPosition) {
            setCurrentHalfMove(x => x + 1)
        }
    }

    useEffect(() => {
        if (currentPosition) {
            analyzePosition(currentPosition)
        }
    }, [currentPosition])

    return (
        <SideMenu className="chess-menu" header="Chess" headerLink="/games/chess">
            <div className="d-flex flex-column">
                <ButtonGroup vertical className="mb-2">
                    <Button
                        type="button"
                        color="primary"
                        onClick={flipView}
                        outline
                    >
                        Flip Board
                    </Button>
                    <Button 
                        type="button"
                        color="primary"
                        onClick={resetBoard}
                        outline
                    >
                        Reset Board
                    </Button>
                    <Button 
                        type="button"
                        color="primary"
                        onClick={() => setShowCoords(!showCoords)}
                        outline
                    >
                        {`${showCoords ? 'Hide' : 'Show'} Coordinates`}
                    </Button>
                    {moves.length > 0 && 
                        <Button 
                            color="primary"
                            onClick={undoLastMove}
                            outline
                        >
                            Undo Last Move
                        </Button>}
                </ButtonGroup>
                {/* <Button 
                    text="View FEN"
                    className="txet-primary"
                    onClick={() => console.log(getFEN())}
                    bordered
                /> */}
                <div>
                    Dark squares: <input type="color" value={darkColor} onChange={e => setDarkColor(e.target.value)} />
                </div>
                <div>
                    Light squares: <input type="color" value={lightColor} onChange={e => setLightColor(e.target.value)} />
                </div>

                {currentPosition && <>
                    <div className="d-flex mt-2 mb-2">
                        <FirstPage className="clickable text-primary" onClick={() => setCurrentHalfMove(0)} />
                        <ArrowBackIos className="clickable text-primary ms-1" onClick={previousMove} />
                        <ArrowForwardIos className="clickable text-primary ms-1" onClick={nextMove} />
                        <LastPage className="clickable text-primary ms-1" onClick={() => setCurrentHalfMove(positions.length - 1)} />
                    </div>
                </>}
                {/* {currentPosition ? <div>Score: {getAnalysisForPosition(currentPosition)}</div> : null} */}
                {/* {currentPosition ? <div>Score: {analyzePosition(currentPosition)}</div> : null} */}
                <div>Moves:</div>
                <div className="move-container">
                    {moves.map((move, index) => {
                        let moveNumber = 1
                        if (index % 2 === 0) {
                            moveNumber += (index / 2)
                        } else {
                            moveNumber = Math.ceil(index / 2)
                        }
                        return (
                            <span 
                                className={`move ${currentHalfMove === index + 1 ? 'active' : ''}`} 
                                key={`move-${index}-${move}`} 
                                onClick={() => setCurrentHalfMove(index + 1)}
                            >
                                {index % 2 === 0 && `${moveNumber}. `} 
                                {`${move} `}
                            </span>
                        )
                    })}
                    {gameResult !== null && gameResult}
                </div>
            </div>
        </SideMenu>
    )
}
