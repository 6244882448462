import React, { useState } from 'react'
import { Tooltip, Loading, Card } from '../../common'
import { Clear, Done } from '@material-ui/icons'
import { Send } from '@material-ui/icons'
import { useFriends } from './FriendsProvider'
import { useAuth } from '../../main/auth/AuthProvider'
import { Toast, ToastHeader, ToastBody, Button, Input, Form } from 'reactstrap'
import './friends.scss'

export default function Friends() {
    const { isLoading } = useAuth()
    const { friends, invitations, invite, acceptInvitation, removeFriend } = useFriends()
    const [userInput, setUserInput] = useState('')
    
    const handleInvite = async (event) => {
        event.preventDefault()
        if (userInput.trim() === '') {
            return
        }
        const response = await invite(userInput)
        if (response) {
            setUserInput('')
        }
    }
    
    if (isLoading) return <Loading />

    return (
        <div className="main-container">
            {invitations.length > 0 && <>
                <h2>Invitations</h2>
                <ul className="friends-list">
                    {invitations.map(x => 
                        <li key={x.userId}>
                            {`${x.firstName} ${x.lastName} (${x.email})`}
                            <Button
                                color="success"
                                onClick={() => acceptInvitation(x.userId)}
                            >
                                <Done />
                            </Button>
                            <Button
                                color="danger"
                                onClick={() => removeFriend(x.userId)}
                            ><Clear /></Button>
                        </li>
                    )}
                </ul>
            </>}

            <Card header="My Friends">
                {friends.length
                    ? <div className="current-friends">
                        {friends.map(x =>
                            <Toast key={x.userId} isOpen={true} className="me-2 mb-2">
                                <ToastHeader toggle={() => removeFriend(x.userId)}>
                                    {x.firstName} {x.lastName}
                                </ToastHeader>
                                <ToastBody>
                                    {x.email}
                                </ToastBody>
                            </Toast>)}
                        </div>
                    : <p>Invite some friends!</p>
                }
            </Card>

            <Card header="Invite a Friend">
                <Form>
                    <Input
                        placeholder="Email address"
                        value={userInput}
                        type="email"
                        onChange={(event) => setUserInput(event.target.value)}
                    />
                    <Button
                        type="submit"
                        color="primary"
                        className="mt-2"
                        onClick={(event) => handleInvite(event)}
                        outline
                    >                        
                        <span className="d-flex align-items-center"><Send className="me-1" />Invite</span>
                    </Button>
                    <Tooltip text="When you invite a registered user, they will see your invitation when they log in next." />
                </Form>
            </Card>

            <p><strong>Who can be my friend?</strong></p>
            <p className="text-muted">You can add friends who have registered on this app.</p>
            
            <p><strong>What can I share with friends?</strong></p>
            <p className="text-muted">Your recipes in Whisk are automatically shared with friends.</p>
            <p className="text-muted">You can share your lists (Honey), budgets (Nickel &amp; Dime), and tracking data (Tracker) with friends, but these are not shared automatically.</p>
            <p className="text-muted">Manage your sharing within each app.</p>
        </div>
    );
}