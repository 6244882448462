import React, { useState } from 'react'
import { Row, Col, Container, Table } from 'reactstrap'
import './notation.scss'
import start from './start.png'
import l from './l.png'
import lp from './lp.png'
import l2 from './l2.png'
import lw from './lw.png'
import lwp from './lwp.png'
import lw2 from './lw2.png'
import b from './b.png'
import b2 from './b2.png'
import bp from './bp.png'
import bw from './bw.png'
import bw2 from './bw2.png'
import bwp from './bwp.png'
import d from './d.png'
import d2 from './d2.png'
import dp from './dp.png'
import dw from './dw.png'
import dw2 from './dw2.png'
import dwp from './dwp.png'
import e from './e.png'
import e2 from './e2.png'
import ep from './ep.png'
import f from './f.png'
import f2 from './f2.png'
import fp from './fp.png'
import fw from './fw.png'
import fw2 from './fw2.png'
import fwp from './fwp.png'
import m from './m.png'
import m2 from './m2.png'
import mp from './mp.png'
import r from './r.png'
import r2 from './r2.png'
import rp from './rp.png'
import rw from './rw.png'
import rw2 from './rw2.png'
import rwp from './rwp.png'
import s from './s.png'
import s2 from './s2.png'
import sp from './sp.png'
import u from './u.png'
import u2 from './u2.png'
import up from './up.png'
import uw from './uw.png'
import uw2 from './uw2.png'
import uwp from './uwp.png'
import x from './x.png'
import x2 from './x2.png'
import xp from './xp.png'
import y from './y.png'
import y2 from './y2.png'
import yp from './yp.png'
import z from './z.png'
import z2 from './z2.png'
import zp from './zp.png'

export default function Notation() {
    const singleImg = React.createRef()
    const doubleImg = React.createRef()
    const middleImg = React.createRef()
    const cubeImg = React.createRef()
    const [single, setSingle] = useState('')
    const [double, setDouble] = useState('')
    const [middle, setMiddle] = useState('')
    const [cube, setCube] = useState(' ')

    const turnSingle = (ref, img, display) => {
        if (ref && ref.current) {
            ref.current.src = img
            setSingle(display)
        }
    }

    const turnDouble = (ref, img, display) => {
        if (ref && ref.current) {
            ref.current.src = img
            setDouble(display)
        }
    }

    const turnMiddle = (ref, img, display) => {
        if (ref && ref.current) {
            ref.current.src = img
            setMiddle(display)
        }
    }

    const turnCube = (ref, img, display) => {
        if (ref && ref.current) {
            ref.current.src = img
            setCube(display)
        }
    }

    return (
        <div className="notation">
            <h1>Notation</h1>
            <p>Tap/hover over the table cells to see a given turn.</p>

            <Container fluid={true}>
                <Row>
                    <h2>Single face rotations</h2>
                </Row>
                <Row>
                    <Col>
                        <p>Capital letters show which side of the cube to turn:
                            <ul>
                                <li><u>F</u>ront, <u>B</u>ack, <u>R</u>ight, <u>L</u>eft, <u>U</u>p (top), or <u>D</u>own (bottom).</li>
                            </ul>
                        </p>
                        <p>A plain capital letter represents a single (90-degree) turn clockwise.
                            <ul>
                                <li>A prime mark (e.g. R') makes it counterclockwise.</li>
                                <li>A &quot;2&quot; (e.g. R2) makes it a double turn (180 degrees).</li>
                            </ul>
                        </p>
                    </Col>
                    <Col>
                        <Table onMouseOut={() => turnSingle(singleImg, start, '')} className="notation-table-by-cell">
                            <thead>
                                <tr>
                                    <th>90&deg; C</th>
                                    <th>90&deg; CC</th>
                                    <th>180&deg;</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td onMouseOver={() => turnSingle(singleImg, l, 'L')}>L</td>
                                    <td onMouseOver={() => turnSingle(singleImg, lp, `L'`)}>L'</td>
                                    <td onMouseOver={() => turnSingle(singleImg, l2, `L2`)}>L2</td>
                                </tr>
                                <tr>
                                    <td onMouseOver={() => turnSingle(singleImg, r, `R`)}>R</td>
                                    <td onMouseOver={() => turnSingle(singleImg, rp, `R'`)}>R'</td>
                                    <td onMouseOver={() => turnSingle(singleImg, r2, `R2`)}>R2</td>
                                </tr>
                                <tr>
                                    <td onMouseOver={() => turnSingle(singleImg, f, `F`)}>F</td>
                                    <td onMouseOver={() => turnSingle(singleImg, fp, `F'`)}>F'</td>
                                    <td onMouseOver={() => turnSingle(singleImg, f2, `F2`)}>F2</td>
                                </tr>
                                <tr>
                                    <td onMouseOver={() => turnSingle(singleImg, b, `B`)}>B</td>
                                    <td onMouseOver={() => turnSingle(singleImg, bp, `B'`)}>B'</td>
                                    <td onMouseOver={() => turnSingle(singleImg, b2, `B2`)}>B2</td>
                                </tr>
                                <tr>
                                    <td onMouseOver={() => turnSingle(singleImg, u, `U`)}>U</td>
                                    <td onMouseOver={() => turnSingle(singleImg, up, `U'`)}>U'</td>
                                    <td onMouseOver={() => turnSingle(singleImg, u2, `U2`)}>U2</td>
                                </tr>
                                <tr>
                                    <td onMouseOver={() => turnSingle(singleImg, d, `D`)}>D</td>
                                    <td onMouseOver={() => turnSingle(singleImg, dp, `D'`)}>D'</td>
                                    <td onMouseOver={() => turnSingle(singleImg, d2, `D2`)}>D2</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                    <Col>
                        <div className="text-center">
                            <img src={start} ref={singleImg} alt="" />
                            <div style={{ fontWeight: 'bold' }}>{single}</div>
                        </div>                                    
                    </Col>
                </Row>
                <Row>
                    <h2>Double face rotations</h2>
                </Row>
                <Row>
                    <Col>
                        <p>Lowercase letters indicate that you turn two layers instead of one.</p>
                    </Col>
                    <Col>
                        <Table onMouseOut={() => turnDouble(doubleImg, start, '')} className="notation-table-by-cell">
                            <thead>
                                <tr>
                                    <th>90&deg; C</th>
                                    <th>90&deg; CC</th>
                                    <th>180&deg;</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td onMouseOver={() => turnDouble(doubleImg, lw, 'l')}>l</td>
                                    <td onMouseOver={() => turnDouble(doubleImg, lwp, `l'`)}>l'</td>
                                    <td onMouseOver={() => turnDouble(doubleImg, lw2, `l2`)}>l2</td>
                                </tr>
                                <tr>
                                    <td onMouseOver={() => turnDouble(doubleImg, rw, `r`)}>r</td>
                                    <td onMouseOver={() => turnDouble(doubleImg, rwp, `r'`)}>r'</td>
                                    <td onMouseOver={() => turnDouble(doubleImg, rw2, `r2`)}>r2</td>
                                </tr>
                                <tr>
                                    <td onMouseOver={() => turnDouble(doubleImg, fw, `f`)}>f</td>
                                    <td onMouseOver={() => turnDouble(doubleImg, fwp, `f'`)}>f'</td>
                                    <td onMouseOver={() => turnDouble(doubleImg, fw2, `f2`)}>f2</td>
                                </tr>
                                <tr>
                                    <td onMouseOver={() => turnDouble(doubleImg, bw, `b`)}>b</td>
                                    <td onMouseOver={() => turnDouble(doubleImg, bwp, `b'`)}>b'</td>
                                    <td onMouseOver={() => turnDouble(doubleImg, bw2, `b2`)}>b2</td>
                                </tr>
                                <tr>
                                    <td onMouseOver={() => turnDouble(doubleImg, uw, `u`)}>u</td>
                                    <td onMouseOver={() => turnDouble(doubleImg, uwp, `u'`)}>u'</td>
                                    <td onMouseOver={() => turnDouble(doubleImg, uw2, `u2`)}>u2</td>
                                </tr>
                                <tr>
                                    <td onMouseOver={() => turnDouble(doubleImg, dw, `d`)}>d</td>
                                    <td onMouseOver={() => turnDouble(doubleImg, dwp, `d'`)}>d'</td>
                                    <td onMouseOver={() => turnDouble(doubleImg, dw2, `d2`)}>d2</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                    <Col>
                        <div className="text-center">
                            <img src={start} ref={doubleImg} alt="" />
                            <div style={{ fontWeight: 'bold' }}>{double}</div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <h2>Middle slice rotations</h2>
                </Row>
                <Row>
                    <Col>
                        <p>Some algorithms use the inner layers:
                            <ul>
                                <li><u>M</u>iddle, <u>E</u>quator, and <u>S</u>lice</li>
                            </ul>
                        </p>
                    </Col>
                    <Col>
                        <Table onMouseOut={() => turnMiddle(middleImg, start, '')} className="notation-table-by-row">
                            <thead>
                                <tr>
                                    <th>Symbol</th>
                                    <th>Equals</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr onMouseOver={() => turnMiddle(middleImg, m, 'M')}>
                                    <td>M</td>
                                    <td>r'R</td>
                                </tr>
                                <tr onMouseOver={() => turnMiddle(middleImg, mp, `M'`)}>
                                    <td>M'</td>
                                    <td>rR'</td>
                                </tr>
                                <tr onMouseOver={() => turnMiddle(middleImg, m2, `M2`)}>
                                    <td>M2</td>
                                    <td>r2R2</td>
                                </tr>
                                <tr onMouseOver={() => turnMiddle(middleImg, e, 'E')}>
                                    <td>E</td>
                                    <td>dD'</td>
                                </tr>
                                <tr onMouseOver={() => turnMiddle(middleImg, ep, `E'`)}>
                                    <td>E'</td>
                                    <td>d'D</td>
                                </tr>
                                <tr onMouseOver={() => turnMiddle(middleImg, e2, `E2`)}>
                                    <td>E2</td>
                                    <td>d2D2</td>
                                </tr>
                                <tr onMouseOver={() => turnMiddle(middleImg, s, 'S')}>
                                    <td>S</td>
                                    <td>fF'</td>
                                </tr>
                                <tr onMouseOver={() => turnMiddle(middleImg, sp, `S'`)}>
                                    <td>S'</td>
                                    <td>f'F</td>
                                </tr>
                                <tr onMouseOver={() => turnMiddle(middleImg, s2, `S2`)}>
                                    <td>S2</td>
                                    <td>f2F2</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                    <Col>
                        <div className="text-center">
                            <img src={start} ref={middleImg} alt="" />
                            <div style={{ fontWeight: 'bold' }}>{middle}</div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <h2>Cube rotations</h2>
                </Row>
                <Row>
                    <Col>
                        <p>Cube rotations are rare, but y and y' are the most common.</p>
                    </Col>
                    <Col>
                        <Table onMouseOut={() => turnCube(cubeImg, start, ' ')} className="notation-table-by-row">
                            <thead>
                                <tr>
                                    <th>Symbol</th>
                                    <th>Cube moves like</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr onMouseOver={() => turnCube(cubeImg, x, 'x')}>
                                    <td>x</td>
                                    <td>R</td>
                                </tr>
                                <tr onMouseOver={() => turnCube(cubeImg, xp, `x'`)}>
                                    <td>x'</td>
                                    <td>R'</td>
                                </tr>
                                <tr onMouseOver={() => turnCube(cubeImg, y, 'y')}>
                                    <td>y</td>
                                    <td>U</td>
                                </tr>
                                <tr onMouseOver={() => turnCube(cubeImg, yp, `y'`)}>
                                    <td>y'</td>
                                    <td>U'</td>
                                </tr>
                                <tr onMouseOver={() => turnCube(cubeImg, z, 'z')}>
                                    <td>z</td>
                                    <td>F</td>
                                </tr>
                                <tr onMouseOver={() => turnCube(cubeImg, zp, `z'`)}>
                                    <td>z'</td>
                                    <td>F'</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                    <Col>
                        <div className="text-center">
                            <img src={start} ref={cubeImg} alt="" />
                            <div style={{ fontWeight: 'bold' }}>{cube}</div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
