import React from 'react'
import { Link } from '@reach/router'

export default function BlogHome() {
    return (
        <div>
            <h2>Blog</h2>
            <p>Sporadic musings are now officially happening.</p>

            <h4>Chess Musings</h4>
            <ol>
                <li>
                    <Link to="/blog/post/chess-musings-1">The Center</Link>
                </li>
                <li>
                    <Link to="/blog/post/chess-musings-2">Time</Link>
                </li>
                <li>
                    <Link to="/blog/post/chess-musings-3">Five Golden Rules</Link>
                </li>
                <li>
                    <Link to="/blog/post/chess-musings-4">Exchanges</Link>
                </li>
                <li>
                    <Link to="/blog/post/chess-musings-5">Tactics</Link>
                </li>
                <li>
                    <Link to="/blog/post/chess-musings-6">Pawn Structure</Link>
                </li>
                <li>
                    <Link to="/blog/post/chess-musings-7">The Endgame</Link>
                </li>
                <li>Plans</li>
            </ol>

            <h4>Music Musings</h4>
            <ul>
                <li>
                    <Link to="/blog/post/why-do-scales-matter">Why Do Scales Matter?</Link>
                </li>
            </ul>
        </div>
    )
}
