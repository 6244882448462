import React, { useEffect } from 'react'
import { Link, navigate } from '@reach/router'
import { useAuth } from '../../main/auth';
import { gmail, calendar, drive, news, youtube, wrench, home, doc, github, grid } from './img'
import { CalendarToday, List, MusicNote, AttachMoney, ViewColumn } from '@material-ui/icons'
import './start.scss'

export default function HomeStart() {
    const { user, isLoadingUser } = useAuth()

    const hideLinks = () => {
        for (var i = 1; i < 6; i++)
            document.getElementById('link' + i).style.display='none';
    }
      
    const showLinks = number => {
        hideLinks();
        document.getElementById('link' + number).style.display='inline';
    }

    useEffect(() => {
        if (!isLoadingUser && user && !user.isAdmin)
            navigate('/dashboard')
    }, [user, isLoadingUser])

    if (!isLoadingUser && user && user.isAdmin) return (
        <div onClick={hideLinks} className="start-page">

            <table className="icons">
                <tbody>
                    <tr onMouseOver={hideLinks}>
                        <td>
                            <a href="http://www.gmail.com/" target="_blank" rel="noopener noreferrer">
                                <img src={gmail} alt="Gmail" />
                            </a>
                        </td>
                        <td>
                            <a href="http://www.google.com/calendar" target="_blank" rel="noopener noreferrer">
                                <img src={calendar} alt="Calendar" />
                            </a>
                        </td>
                        <td>
                            <a href="https://drive.google.com/drive/#recent" target="_blank" rel="noopener noreferrer">
                                <img src={drive} alt="Google Drive" />
                            </a>
                        </td>
                        <td>
                            <a href="http://news.google.com" target="_blank" rel="noopener noreferrer">
                                <img src={news} alt="Google News" />
                            </a>
                        </td>
                        <td>
                            <a href="http://www.youtube.com" target="_blank" rel="noopener noreferrer">
                                <img src={youtube} alt="YouTube" />
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td className="py-2">
                            <Link to="/calendar"><CalendarToday className="module-icon" /></Link>
                        </td>
                        <td>
                            <Link to="/music/playlists"><MusicNote className="module-icon" /></Link>
                        </td>
                        <td>
                            <Link to="/honey"><List className="module-icon" /></Link>
                        </td>
                        <td>
                            <Link to="/budget/dashboard"><AttachMoney className="module-icon" /></Link>
                        </td>
                        <td>
                            <a href="https://trello.com/b/ywh7PxNL/main-harris-board" target="_blank" rel="noopener noreferrer">
                                <ViewColumn className="module-icon" />
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <a href="https://squarenote.net/dashboard" target="_blank" rel="noopener noreferrer">
                                <img src={wrench} alt="Tools" onMouseOver={() => showLinks(1)} />
                            </a>
                        </td>
                        <td>
                            <img src={home} alt="Housey Things" onMouseOver={() => showLinks(2)} />
                        </td>
                        <td>
                            <a href="https://docs.google.com/spreadsheets/d/1fZQuEy_kNMFmgY_m80FgMhi9hiiu7JddhQWUa1IU5AY/edit" target="_blank" rel="noopener noreferrer">
                                <img src={doc} alt="Docs" onMouseOver={() => showLinks(4)} />
                            </a>
                        </td>
                        <td>
                            <a href="http://www.github.com" target="_blank" rel="noopener noreferrer">
                                <img src={github} alt="Programming" onMouseOver={() => showLinks(5)} />
                            </a>
                        </td>
                        <td>
                            <img src={grid} onMouseOver={() => showLinks(3)} alt="Games" />
                        </td>
                    </tr>
                </tbody>
            </table>

            <br /><br />

            {/* <!-- TOOLS --> */}
            <div className="links" id="link1">
                <ul>
                    <li><a href="https://www.linkedin.com/" target="_blank" rel="noopener noreferrer">LinkedIn</a></li>
                    <li><a href="https://ccec.clientsecure.me/sign-in" target="_blank" rel="noopener noreferrer">CCEC Login</a></li>
                    <li><a href="http://translate.google.com/" target="_blank" rel="noopener noreferrer">Google Translate</a></li>
                    <li><a href="http://fast.com" target="_blank" rel="noopener noreferrer">Fast.com</a></li>
                    <li><a href="http://www.wikipedia.org" target="_blank" rel="noopener noreferrer">Wikipedia</a></li>
                    <li><a href="http://www.amazon.com" target="_blank" rel="noopener noreferrer">Amazon</a></li>
                    <li><a href="http://www.alabamawx.com" target="_blank" rel="noopener noreferrer">AlabamaWX</a></li>
                </ul>
            </div>

            {/* <!-- HOME --> */}
            <div className="links" id="link2">
                <ul>
                    <li><a href="http://www.avadiancu.com" target="_blank" rel="noopener noreferrer">Avadian</a></li>
                    <li><a href="https://online.citi.com/" target="_blank" rel="noopener noreferrer">Citi</a></li>
                    <li><a href="http://www.bcbsal.com" target="_blank" rel="noopener noreferrer">BCBS AL</a></li>
                </ul>

                <ul>
                    <li><a href="https://docs.google.com/spreadsheets/d/1TEwgk4J1hKRs4zv0z8G7BcyLlrUwqeHiOIoZDX0ZBOE" target="_blank" rel="noopener noreferrer">Utilities Log</a></li>
                    <li><a href="https://docs.google.com/spreadsheets/d/1TTWMsqp7AO_c38mh2lFYHcivLaigUXnz4zE9Odus1k0" target="_blank" rel="noopener noreferrer">Water Usage</a></li>
                    <li><a href="https://www.alabamapower.com/" target="_blank" rel="noopener noreferrer">AL Power</a></li>
                    <li><a href="http://www.att.com" target="_blank" rel="noopener noreferrer">AT&amp;T</a></li>
                    <li><a href="https://web.bwwb.org/myaccount" target="_blank" rel="noopener noreferrer">BWWB</a></li>
                    <li><a href="http://mrcooper.com" target="_blank" rel="noopener noreferrer">Mr. Cooper</a></li>
                    <li><a href="https://www.spectrum.net/" target="_blank" rel="noopener noreferrer">Spectrum</a></li>
                    <li><a href="https://www.spireenergy.com/" target="_blank" rel="noopener noreferrer">Spire</a></li>
                    <li><a href="http://hooveralabama.gov/954/My-Hoover-Connect" target="_blank" rel="noopener noreferrer">My Hoover</a> [<a href="http://www.hooveralabama.gov/308/Garbage-Recycling" target="_blank" rel="noopener noreferrer">G+R</a>]</li>
                </ul>
            </div>

            {/* <!-- DOCS --> */}
            <div className="links" id="link4">
                <ul>
                    <li><a href="https://docs.google.com/spreadsheets/d/1y2ztFcs1KDJpqEts9GSG7ztDj5fJ0EjwYHUaOcXjPE0/edit" target="_blank" rel="noopener noreferrer">Contact Info</a></li>
                    <li><a href="https://docs.google.com/spreadsheets/d/1jr4WorzdqzUdKJjwfdEGudWaKecPV1M1x6lNmrriD48/edit" target="_blank" rel="noopener noreferrer">Food Journal</a></li>
                    <li><a href="https://docs.google.com/spreadsheets/d/1WemT1eDeIX7sWZd1o91N6EP5s7IRDwvYsQJlmCh0aTQ/edit" target="_blank" rel="noopener noreferrer">Home Care</a></li>
                    <li><a href="https://docs.google.com/spreadsheets/d/1fZQuEy_kNMFmgY_m80FgMhi9hiiu7JddhQWUa1IU5AY/edit" target="_blank" rel="noopener noreferrer">Logins</a></li>
                </ul>

                <ul>
                    <li>My Coding Guides:</li>
                    <li><a href="https://docs.google.com/document/d/1HJr2MAOQZF6W_EExLd0mVLhB8SOHBU_ysqnn1R3j32E/edit" target="_blank" rel="noopener noreferrer">Angular/MEAN</a></li>
                    <li><a href="https://docs.google.com/document/d/13A08hBuJjSYeKTBZ45V6kxKT1S3Gd_Fv0WOVc1DSUN0/edit" target="_blank" rel="noopener noreferrer">ASP.NET MVC</a></li>
                    <li><a href="https://docs.google.com/document/d/1KkVD4TCUqsB8Kfs0cPc-H9J_Dy4xKIX-UGZyG9BT3iA/edit" target="_blank" rel="noopener noreferrer">ES2015</a></li>
                    <li><a href="https://docs.google.com/document/d/1Y_JpvlxfCGE1xd4VBrvsQgXD8IXUrEoxTVyLN3kaRGo/edit" target="_blank" rel="noopener noreferrer">Git</a></li>
                    <li><a href="https://docs.google.com/document/d/15oOdAH2uGIKuIbplCrjjw4qIZSzKUH3Ko_dT2wYnSJQ/edit" target="_blank" rel="noopener noreferrer">Heroku/Node</a></li>
                    <li><a href="https://docs.google.com/document/d/1pjc8NQ6bnipP6J2D7aA3E-YDnK7WKTjzWpCKWhCwLO8/edit" target="_blank" rel="noopener noreferrer">MySQL</a></li>
                    <li><a href="https://docs.google.com/document/d/1ZiN7l-pF6ixESnQXrE9Xnt6CCsqFslrApp8Y0WY6228/edit" target="_blank" rel="noopener noreferrer">React/Redux</a></li>
                </ul>
            </div>

            {/* <!-- GITHUB --> */}
            <div className="links" id="link5">
                <ul>
                    <li><a href="https://github.com/BradleyKH" target="_blank" rel="noopener noreferrer">Profile</a> | <a href="https://github.com/BradleyKH?tab=repositories" target="_blank" rel="noopener noreferrer">Repos</a></li>
                    <li><a href="https://github.com/BradleyKH/lmh" target="_blank" rel="noopener noreferrer">lmh</a></li>
                    <li><a href="https://docs.google.com/spreadsheets/d/1uQ6EJ9m5VMhEcYQv0gl8H90Zk7NuPEKGsh2k1q6falo/edit#gid=0" target="_blank" rel="noopener noreferrer">My Coding Ideas</a></li>
                    <li><a href="https://portal.azure.com" target="_blank" rel="noopener noreferrer">Azure</a> | <a href="http://www.gearhost.com" target="_blank" rel="noopener noreferrer">GearHost</a></li>
                    <li></li>
                    <li>
                        <a href="https://keysys.atlassian.net/jira/projects" target="_blank" rel="noopener noreferrer">Jira</a> |{' '}
                        <a href="https://keysys.atlassian.net/wiki/spaces/KKR/overview?homepageId=66194" target="_blank" rel="noopener noreferrer">Knowledge Repo</a> |{' '}
                        <a href="http://bitbucket.org" target="_blank" rel="noopener noreferrer">Bitbucket</a> |{' '}
                        <a href="https://keysys.bamboohr.com" target="_blank" rel="noopener noreferrer">Bamboo</a> |{' '}
                        <Link to="/admin/start/work">Work Start</Link>
                    </li>
                    <li>
                        <a href="https://pal.keysyscloud.com/" target="_blank" rel="noopener noreferrer">PAL</a> |{' '}
                        <a href="https://workforce.intuit.com/" target="_blank" rel="noopener noreferrer">QuickBooks</a> |{' '}
                        <a href="https://keysys.harvestapp.com" target="_blank" rel="noopener noreferrer">Harvest</a> |{' '} 
                        <a href="https://keysysconsulting.cultureamp.com/" target="_blank" rel="noopener noreferrer">Culture Amp</a>
                    </li>
                </ul>
            </div>

            {/* <!-- GAMES --> */}
            <div className="links" id="link3">
                <ul>
                    <li><a href="http://online-go.com" target="_blank" rel="noopener noreferrer">OGS</a></li>
                    <li><a href="http://chess.com" target="_blank" rel="noopener noreferrer">Chess.com</a></li>
                    <li><a href="http://lichess.org" target="_blank" rel="noopener noreferrer">Lichess</a></li>
                    <li><a href="https://savethekitty.azurewebsites.net/" target="_blank" rel="noopener noreferrer">Save the Kitty</a></li>
                </ul>
            </div>

        </div>
    )

    return null
}
