import { navigate } from '@reach/router'
import React from 'react'
import { Input } from 'reactstrap'
import { useAuth } from '../../main/auth/AuthProvider'
import { useFriends } from '../Friends/FriendsProvider'
import { useTracker } from '.'

export default function TrackerGroupDropdown() {
    const { user } = useAuth()
    const { friends } = useFriends()
    const { groups, activeGroup } = useTracker()
    const myGroups = groups ? groups.filter(group => group.userId === user.userId) : []
    const friendGroups = groups ? groups.filter(group => group.userId !== user.userId) : []

    return (
        <Input 
            type="select" 
            value={activeGroup ? activeGroup.id : ''}
            onChange={e => navigate(`/tracker/groups/${e.target.value}`)}
        >
            <option disabled value=''>Select a group...</option>
            {myGroups.length &&
                <optgroup label="My Data Groups">
                    {myGroups.map(group => <option key={group.id} value={group.id}>{group.name}</option>)}
                </optgroup>
            }
            {friendGroups.length &&
                <optgroup label="Friends' Data Groups">
                    {friendGroups.map(group => {
                        let friend = friends ? friends.find(x => x.userId === group.userId) : null
                        let friendName = friend ? friend.firstName : ''
                        return (
                            <option key={group.id} value={group.id}>{group.name} ({friendName})</option>
                        )
                    })}
                </optgroup>
            }
        </Input>
    )
}
