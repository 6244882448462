import React, { useMemo, useCallback, useState, useEffect } from 'react'
import { useTable, useSortBy, useFilters, usePagination } from 'react-table'
import { ArrowDownward, ArrowUpward } from '@material-ui/icons'
import { TextColumnFilter } from './DataTableFilters'
import { DataTablePagination, fetcher } from '.'
import { useFriends } from '../modules/Friends/FriendsProvider'
import { useBudget } from '../modules/NickelAndDime/BudgetProvider'
import { Table } from 'reactstrap'
import { useAuth } from '../main/auth/AuthProvider'
import './data-table.scss'

export default function DataTable({ data, columns, sortable, filterable, paginated, onRowClick }) {
    const { friends } = useFriends()
    const { user } = useAuth()
    // const { activeBudgetId } = useBudget()
    const [budgetCategories, setBudgetCategories] = useState([])

    const getName = useCallback(value => {
        if (user && value === user.userId) {
            return 'Me'
        }
        const friend = friends.find(x => x.userId === value)
        if (friend) {
            return `${friend.firstName} ${friend.lastName}`
        }
        return '?'
    }, [friends, user])

    const getCategoryName = useCallback(id => {
        if (budgetCategories.length) {
            let category = budgetCategories.find(x => x.categoryId === parseInt(id, 10))
            if (category)
                return category.categoryName
        }
        return id
    }, [budgetCategories])

    const defaultColumn = useMemo(() => ({
        Filter: TextColumnFilter,
    }), [])

    const filterTypes = useMemo(() => ({
        boolean: (tableRows, id, filterValue) => {
            return tableRows.filter(row => {
                const rowValue = row.values[id]
                const filterBoolean = filterValue === 'Yes'
                return rowValue === "" || undefined
                    ? true
                    : rowValue === filterBoolean
            })
        },
        exact: (tableRows, id, filterValue) => {
            return tableRows.filter(row => {
                const rowValue = row.values[id]
                return rowValue === '' || undefined
                    ? true
                    : rowValue === filterValue || rowValue === parseInt(filterValue, 10)
            })
        },
        friends: (tableRows, id, filterValue) => {
            return tableRows.filter(row => {
                const rowValue = row.values[id]
                return rowValue === '' || undefined
                    ? true
                    : getName(rowValue) === filterValue
            })
        },
        months: (tableRows, id, filterValue) => {
            const months = [
                'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
            ]
            return tableRows.filter(row => {
                const rowValue = row.values[id]
                return rowValue === '' || undefined
                    ? true
                    : months[Number(rowValue) - 1] === filterValue
            })
        },
        budgetCategories: (tableRows, id, filterValue) => {
            return tableRows.filter(row => {
                const rowValue = row.values[id]
                return rowValue === '' || undefined
                    ? true
                    : getCategoryName(rowValue) === filterValue
            })
        }
    }), [getName, getCategoryName])

    // const getBudgetCategories = useCallback(async () => {
    //     if (activeBudgetId) {
    //         const response = await fetcher.get(`budget/category/${activeBudgetId}`)
    //         setBudgetCategories(response)
    //     } else {
    //         setBudgetCategories([])
    //     }
    // }, [activeBudgetId])

    // useEffect(() => {
    //     getBudgetCategories()
    // }, [getBudgetCategories])

    const tableInstance = useTable({ 
        columns, 
        data, 
        defaultColumn, 
        filterTypes, 
        initialState: { pageIndex: 0 } 
    }, useFilters, useSortBy, usePagination)

    const { 
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page 
    } = tableInstance

    const getTableContent = () => {
        const content = paginated ? page : rows
        return content.map((row, i) => {
            prepareRow(row)
            return (
                <tr 
                    key={`table-body-row-${i}`} 
                    {...row.getRowProps()} 
                    onClick={onRowClick ? () => onRowClick(row) : () => {}}
                    className={onRowClick ? 'clickable' : ''}
                >
                    {row.cells.map((cell, j) =>
                        <td key={`table-body-row-${i}-cell-${j}`} {...cell.getCellProps()}>
                            {cell.render('Cell')}
                        </td>
                    )}
                </tr>
            )
        })
    }

    return (
        <div className="data-table">
            <Table striped hover {...getTableProps()}>
                <thead>
                    {paginated && 
                        <tr className="pagination-row">
                            <td colSpan={columns.length}>
                                <DataTablePagination tableInstance={tableInstance} />
                            </td>
                        </tr>
                    }
                    {headerGroups.map((headerGroup, i) =>
                        <React.Fragment key={`table-head-row-set-${i}`}>
                            <tr className="header-row" {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column, j) => {
                                    if (sortable) {
                                        return (
                                            <th 
                                                key={`column-${j}-header`} 
                                                {...column.getHeaderProps(column.getSortByToggleProps())}
                                                className={column.canSort ? 'sortable' : ''}
                                            >
                                                {column.render('Header')}
                                                <span>
                                                    {column.isSorted
                                                        ? column.isSortedDesc
                                                            ? <ArrowDownward className="sort-icon" />
                                                            : <ArrowUpward className="sort-icon" />
                                                        : ''
                                                    }
                                                </span>
                                            </th>
                                        )
                                    } else {
                                        return (
                                            <th key={`column-${j}-header`} {...column.getHeaderProps()}>
                                                {column.render('Header')}
                                            </th>
                                        )
                                    }
                                })}
                            </tr>
                            {filterable && 
                                <tr className="filter-row">
                                    {headerGroup.headers.map((column, j) =>
                                        <td key={`column-${j}-filter`}>
                                            {column.canFilter ? column.render('Filter') : null}
                                        </td>
                                    )}
                                </tr>}
                        </React.Fragment>
                    )}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {getTableContent()}                        
                </tbody>
            </Table>
        </div>
    )
}
