import React from 'react'
import { Input } from 'reactstrap'

const dateToString = (value = new Date()) => {
    let year = value.getFullYear()
    let month = value.getMonth() + 1
    if (month < 10)
        month = `0${month}`
    let date = value.getDate()
    if (date < 10)
        date = `0${date}`
    return `${year}-${month}-${date}`
}

const timeToString = (value = new Date()) => {
    let hour = value.getHours()
    if (hour < 10)
        hour = `0${hour}`
    let minutes = value.getMinutes()
    if (minutes < 10)
        minutes = `0${minutes}`
    return `${hour}:${minutes}`
}

const dateToISOString = (value = new Date()) => {
    return value.toISOString()
}

const unixToTime = unix => {
    try {
        const date = new Date(unix * 1000)
        return date.toLocaleString('en', {hour: 'numeric', minute: '2-digit'})
    } catch (error) {
        return error.toString()
    }
}

const unixToDateTime = unix => {
    const date = new Date(unix * 1000)
    return date.toLocaleString('en', {month: 'numeric', day: 'numeric', hour: 'numeric'})
}

const unixToDate = unix => {
    const date = new Date(unix * 1000)
    return date.toLocaleString('en', {month: 'numeric', day: 'numeric'})
}

const formatDateString = (value) => {
    if (!value) {
        return ''
    }
    let valueDate = new Date(value)
    let year = valueDate.getFullYear()
    let month = valueDate.getMonth() + 1
    let date = valueDate.getDate()
    return `${month}.${date}.${year}`
}

const numToCurrency = (value) => {
    return value.toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2 })
}

const stringToDate = (input) => {
    let array = input.split('-')
    let year = parseInt(array[0], 10)
    let month = parseInt(array[1], 10)
    let date = parseInt(array[2], 10)
    return new Date(year, month - 1, date).toDateString()
}

const dateToUnixTime = (date = new Date()) => {
    return date.getTime();
}

const dateFromInputs = (dateInput, timeInput = '00:00') => {
    let dateInputParams = dateInput.split('-')
    let year = parseInt(dateInputParams[0], 10)
    let month = parseInt(dateInputParams[1], 10) - 1
    let date = parseInt(dateInputParams[2], 10)
    let timeInputParams = timeInput.split(':')
    let hour = parseInt(timeInputParams[0], 10)
    let minute = parseInt(timeInputParams[1], 10)
    return new Date(year, month, date, hour, minute)
}

const convertToUtcString = isoString => {
    let dt = new Date(isoString)
    let year = dt.getUTCFullYear()
    let month = dt.getUTCMonth()
    let date = dt.getUTCDate()
    let hour = dt.getUTCHours()
    let min = dt.getUTCMinutes()
    let dtUtc = new Date(Date.UTC(year, month, date, hour, min))
    return dtUtc.toISOString()
}

const dateStringToUtcDate = value => {
    let array = value.split('-')
    let year = parseInt(array[0], 10)
    let month = parseInt(array[1], 10) - 1
    let date = parseInt(array[2], 10)
    return new Date(Date.UTC(year, month, date))
}

const displayUtcDate = dateString => {
    const utcDate = new Date(dateString)
    let year = utcDate.getUTCFullYear()
    let month = utcDate.getUTCMonth() + 1
    let date = utcDate.getUTCDate()
    return `${month}/${date}/${year}`
}

const displayUtcDateWithoutYear = dateString => {
    const utcDate = new Date(dateString)
    let month = utcDate.getUTCMonth() + 1
    let date = utcDate.getUTCDate()
    return `${month}/${date}`
}

const getUtcDateStringFromInput = inputValue => {
    let utcDate = dateStringToUtcDate(inputValue)
    return utcDate.toISOString()
}

const getUtcDateStringForInput = dateString => {
    const utcDate = new Date(dateString)
    let year = utcDate.getUTCFullYear()
    let month = utcDate.getUTCMonth()
    let date = utcDate.getUTCDate()
    let dateObj = new Date(year, month, date)
    return dateToString(dateObj)
}

const sortListItemsByDate = (items) => {
    let obj = {
        none: []
    }

    for (let i = 0; i < items.length; i++) {
        let indexedItem = items[i]
        indexedItem.index = i

        if (indexedItem.date) {
            if (!obj[indexedItem.date])
                obj[indexedItem.date] = []
            obj[indexedItem.date].push(indexedItem)
        } else {
            obj.none.push(indexedItem)
        }
    }

    return Object.entries(obj).sort()
}

const compareStrings = (a, b) => {
    if (a < b) {
        return -1
    } else if (a > b) {
        return 1
    } else {
        return 0
    }
  }

const isEqualDate = (d1, d2) => {
    let sameYear = d1.getFullYear() === d2.getFullYear() 
    let sameMonth = d1.getMonth() === d2.getMonth()
    let sameDate = d1.getDate() === d2.getDate()
    return sameYear && sameMonth && sameDate
}

const getDistinctDates = dates => {
    let result = []
    for (let i = 0; i < dates.length; i++) {
        let storedDate = dates[i]
        let date = new Date(storedDate.getFullYear(), storedDate.getMonth(), storedDate.getDate())
        if (!result.length) {
            result.push(date)
        } else if (!isEqualDate(dates[i], result[result.length - 1])) {
            result.push(date)
        }
    }
    return result
}

const caseInsensitiveFilter = (filter, row) => row[filter.id].toLowerCase().startsWith(filter.value.toLowerCase())

const filterWithPlaceholder = (filter, onChange, placeholder) => 
    <Input 
        onChange={e => onChange(e.target.value)} 
        value={filter ? filter.value : ''}
        placeholder={placeholder}
        style={{ width: '100%' }}
    />

const compareValueToTarget = (value, operator, target) => {
    switch(operator) {
		case "all":
			return true;
		case "<":
			return value < target
		case ">":
			return value > target
		case "<=":
			return value <= target
		case ">=":
            return value >= target
        case "=":
			return value === target
		case "!=":
			return value !== target
		default:
			return true
	}
}

const renderCell = (value, condition, classIfTrue, classIfFalse = '') => {
    return <span className={condition ? classIfTrue : classIfFalse}>{value}</span>
}

const generateRandomString = (length = 128) => {
    let result = []
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    for (let i = 0; i < length; i++) {
        const char = chars.charAt(Math.floor(Math.random() * chars.length))
        result.push(char)
    }
    return result.join('')
}

const validUploadFileTypes = [
    'image/jpeg',
    'image/gif',
    'image/png',
    'text/plain',
    'text/csv',
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'image/svg+xml'
]

const validUploadImgFileTypes = [
    'image/jpeg',
    'image/gif',
    'image/png'
]

const displayFileSize = bytes => {
    var i = Math.floor( Math.log(bytes) / Math.log(1024) );
    return ( bytes / Math.pow(1024, i) ).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
}

const getExtension = fileName => {
    const array = fileName.split('.')
    return array[array.length - 1].toLowerCase()
}

const localizeAggregateData = aggregateData => {
    if (aggregateData) {
        let newLocalData = []
        let groupData = [...aggregateData.groups]
        let localDates = []
        for (let i = 0; i < groupData.length; i++) {
            for (let j = 0; j < groupData[i].data.length; j++) {
                localDates.push(new Date(groupData[i].data[j].timeUtc))
            }
        }
        localDates.sort((a, b) => a - b)
        let localDistinctDates = getDistinctDates(localDates)

        for (let i = 0; i < localDistinctDates.length; i++) {
            let data = {
                name: localDistinctDates[i].getTime(),
                date: localDistinctDates[i],
                properties: {}
            }
            for (let j = 0; j < groupData.length; j++) {
                let entries = []
                for (let k = 0; k < groupData[j].data.length; k++) {
                    let entryTime = new Date(groupData[j].data[k].timeUtc)
                    if (isEqualDate(entryTime, localDistinctDates[i])) {
                        entries.push(groupData[j].data[k].value)
                    }
                }
                if (entries.length) {
                    let sum = entries.reduce((a, b) => a + b)
                    let average = sum / entries.length
                    data.properties[groupData[j].nameJson] = average
                }
            }
            newLocalData.push(data)
        }
        let result = {
            dates: localDistinctDates,
            groups: groupData,
            data: newLocalData
        }
        return result
    }
}

const getExtensionFromMimeType = type => {
	switch (type){
		case 'application/pdf':
			return 'pdf'
		case 'image/jpeg':
			return 'jpg'
		case 'image/png':
			return 'png'
		case 'text/plain':
			return 'txt'
		case 'text/csv':
			return 'csv'
		case 'text/html':
			return 'html'
		default:
			return 'csv'
	}
}

const getFileNameWithoutTimestamp = fileName => {
    const array = fileName.split('-')
    array.splice(0, 1)
    return array.join('-')
}

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export {
    dateToString,
    timeToString,
    dateToISOString,
    formatDateString,
    unixToTime,
    unixToDateTime,
    unixToDate,
    stringToDate,
    dateToUnixTime,
    dateFromInputs,
    convertToUtcString,
    sortListItemsByDate,
    isEqualDate,
    getDistinctDates,
    caseInsensitiveFilter,
    filterWithPlaceholder,
    compareValueToTarget,
    renderCell,
    numToCurrency,
    validUploadFileTypes,
    validUploadImgFileTypes,
    displayFileSize,
    localizeAggregateData,
    generateRandomString,
    getExtension,
    compareStrings,
    getExtensionFromMimeType,    
    getUtcDateStringFromInput,
    displayUtcDate,
    getUtcDateStringForInput,
    displayUtcDateWithoutYear,
    sleep,
    getFileNameWithoutTimestamp
}
