import React from 'react'
import { Link } from '@reach/router'
import { Table } from 'reactstrap'
import midLayer1 from './second layer1.png'
import midLayer2 from './second layer2.png'
import midLayer3 from './second layer3.png'
import midLayer4 from './second layer4.png'
import midLayer5 from './second layer5.png'
import midLayer6 from './second layer6.png'
import midLayer7 from './second layer7.png'
import arrow from './arrow.png'
import midLayerComplete from './f2l complete.png'

export default function LBL3() {
    return (
        <div>
            <h1>3x3 Beginner Tutorial: The Second Layer</h1>

            <ul>
                <li><Link to="/cubetools/lbl">Introduction</Link></li>
                <li><Link to="/cubetools/lbl/1">The White Cross</Link></li>
                <li><Link to="/cubetools/lbl/2">The White Corners</Link></li>
                <li>The Second Layer</li>
                <li><Link to="/cubetools/lbl/4">The Yellow Cross</Link></li>
                <li><Link to="/cubetools/lbl/5">The Yellow Top</Link></li>
                <li><Link to="/cubetools/lbl/6">The Final Corners</Link></li>
                <li><Link to="/cubetools/lbl/7">The Final Edges</Link></li>
            </ul>

            <p>Solving an edge in the middle layer entails three steps: align the edge, setup the edge, and solve the white corner. Our second layer algorithm is (U + sexy move) and its mirror (U' + L'U'LU).</p>

            <h2>Align the Edge</h2>

            <p>Locate an edge piece on the top layer that has no yellow sticker. The edge piece has a top sticker and a side sticker, so be sure to check them both. (If there is no such piece, and the middle layer is not yet solved, skip below to Setup the Edge.)</p>

            <p>One you have found an edge piece without yellow, turn the top so that the side sticker of the edge piece is aligned with its center (e.g. red with red), and face that side.</p>

            <div className="text-center">
               <img src={midLayer1} alt="" />
               <img src={arrow} alt="" className="m-2" />
               <img src={midLayer2} alt="" />
            </div>

            <h2>Setup the Edge</h2>

            <p>Look at the top sticker of the edge piece and the center stickers of the left and right sides to determine where the edge belongs: to the front right slot or the front left slot.</p>

            <Table>
                <tbody>
                    <tr>
                        <td><img src={midLayer3} alt="" /></td>
                        <td>If the edge piece belongs in the front right slot, use (U RUR'U'), which is U + sexy move.</td>
                    </tr>
                    <tr>
                        <td><img src={midLayer2} alt="" /></td>
                        <td>If the edge piece belongs in the front left slot, use (U' L'U'LU), which is U' + sexy move mirrored.</td>
                    </tr>
                </tbody>
            </Table>

            <p>If all the top edges have a yellow sticker, but the middle layer is not solved, simply face a side that has an incorrect edge in the middle.</p>

            <Table>
                <tbody>
                    <tr>
                        <td><img src={midLayer4} alt="" /></td>
                        <td>If the incorrect edge piece is in the front right slot, use Sexy move (RUR’U’). This will bring the incorrect edge piece up to the top layer. Proceed to solving the white corner.</td>
                    </tr>
                    <tr>
                        <td><img src={midLayer5} alt="" /></td>
                        <td>If the incorrect edge piece is in the front left slot, use Sexy move mirrored (L’U’LU). This will bring the incorrect edge piece up to the top layer. Proceed to solving the white corner.</td>
                    </tr>
                </tbody>
            </Table>

            <p>Note: The arrangement of yellow stickers in the top layer will vary from the diagrams above.</p>

            <h2>Solve the White Corner</h2>

            <p>When we setup the edge, we put one of the white corners in the top layer. Solve the white corner normally (the step after the cross), and the middle edge will be solved along with it automatically.</p>

            <div className="d-flex justify-content-center align-items-center mb-2">
                <img src={midLayer6} alt="" />
                <div className="text-center"><img src={arrow} alt="" className="ms-2 me-2" /><br />(RUR')</div>
                <img src={midLayer7} alt="" />
            </div>

            <h2>Rinse &amp; Repeat</h2>

            <p>When you place all four middle edges correctly, the first two layers will be complete. We can then start solving the last layer, starting by <Link to="/cubetools/lbl/4">forming the yellow cross</Link>.</p>

            <div className="text-center">
                <img src={midLayerComplete} alt="" />
            </div>
        </div>
    )
}
