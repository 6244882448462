import React from 'react'
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons'
import { useFriends } from '../Friends/FriendsProvider'
import { useHoney } from '.'
import { fetcher, Card } from '../../common'

export default function ListSharing() {
    const { activeList, permissions, getPermissions } = useHoney()
    const { friends } = useFriends()
    const checkedBox = <CheckBox className='muted' />
    const uncheckedBox = <CheckBoxOutlineBlank className='muted' />

    const listPermissions = permissions ? permissions.filter(x => x.listId === activeList?.listId) : []
    const permissionsObj = friends.map(friend => { 
        let friendPermission = listPermissions.find(x => x.userId === friend.userId)
        return ({
            listPermissionId: friendPermission ? friendPermission.listPermissionId : 0,
            userId: friend.userId,
            firstName: friend.firstName,
            lastName: friend.lastName,
            email: friend.email,
            canRead: friendPermission && friendPermission.canRead,
            canEdit: friendPermission && friendPermission.canEdit
        })
    })

    const handleViewClick = async (listPermissionId, userId, allowed) => {
        if (allowed === false) {
            let response = await fetcher.del(`listpermission/${listPermissionId}`)
            if (response)
                getPermissions()
        } else if (activeList) {
            let body = {
                userId: userId,
                listId: activeList.listId,
                canRead: true,
                canEdit: false
            }
            let response = await fetcher.post('listpermission', body)
            if (response)
                getPermissions()
        }
    }

    const handleEditClick = async (listPermissionId, allowed) => {
        let body = {
            listPermissionId,
            canRead: true,
            canEdit: allowed
        }
        let response = await fetcher.put('listpermission', body)
        if (response)
            getPermissions()
    }
    
    const rows = permissionsObj.map(x => 
        <tr key={x.userId}>
            <td>{x.firstName} {x.lastName}<br />({x.email})</td>
            <td>
                <div className="clickable" onClick={() => handleViewClick(x.listPermissionId, x.userId, !x.canRead)}>
                    {x.canRead ? checkedBox : uncheckedBox}                    
                    View
                </div>
            </td>
            <td>
                {x.canRead 
                    ? <div className="clickable" onClick={() => handleEditClick(x.listPermissionId, !x.canEdit)}>
                        {x.canEdit 
                            ? checkedBox 
                            : uncheckedBox}
                        Edit
                    </div>
                    : null}
            </td>
        </tr>
    )
    
    return (
        <Card header="Share List with Friends">
            <table className="sharing-table">
                <tbody>
                    {rows}
                </tbody>
            </table>
        </Card>
    )
}
