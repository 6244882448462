import { useLayoutEffect } from 'react';
import { globals } from './themes';

export default function useTheme(theme) {
  useLayoutEffect(() => {
      for (const key in theme) {
        document.documentElement.style.setProperty(`--${key}`, theme[key]);
      }
      for (const key in globals){
        document.documentElement.style.setProperty(`--${key}`, globals[key]);
      }
    }, [theme]);
}
