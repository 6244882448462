import React from 'react'
import './youtube-embed.scss'

export default function YouTubeEmbed({ title, url }) {
    if (url) {
        return (
            <iframe 
                className="youtube-embed"
                title={title}
                src={url} 
                frameBorder="0" 
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen 
            />
        )
    } else {
        return (
            <div />
        )
    }
}
