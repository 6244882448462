import React from 'react'
import { useMusic } from '.'
import { Table } from 'reactstrap'
import { NotificationManager } from 'react-notifications'
import { Link } from '@reach/router'
import './playlist.scss'

export default function Playlist({ playlistId }) {
    const { setMode, loadAndPlayFile, activePlaylistTitle, activePieceCollection, activePieceIndex, setActivePieceIndex } = useMusic()

    const handlePieceClick = async (piece, index) => {
        setMode('playlist')    
        setActivePieceIndex(index)
        try {
            await loadAndPlayFile(piece.path)
        } catch (err) {
            NotificationManager.error(`Caught error on play(): ${err}`)
        }
    }

    if (activePlaylistTitle && activePieceCollection) return (
        <div className="playlist">
            <h2>Playlist: {activePlaylistTitle}</h2>
            <Table>
                <thead>
                    <tr>
                        <th>Title</th>
                        <th>Artist</th>
                        <th>Album</th>
                    </tr>
                </thead>
                <tbody>
                    {activePieceCollection.map((piece, index) =>
                        <tr 
                            key={`piece-${piece.id}`} 
                            onClick={() => handlePieceClick(piece, index)} 
                            className={`piece-row ${activePieceIndex === index ? 'active' : ''}`}
                        >
                            <td>{piece.name}</td>
                            <td>{piece.artist}</td>
                            <td>{piece.album}</td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </div>
    )

    return (
        <p>Maybe choose a <Link to='/music/playlists'>playlist</Link>?</p>
    )
}
