import React, { useState, useEffect, useCallback } from 'react'
import { 
    isLegalMove, 
    removeFromString, 
    getFenPiecesFromPieceArray, 
    getSquareContentBySquareName, 
    getPieceColorBySquareName, 
    getPositionAfterMove, 
    getPieceArrayFromFenPieces, 
    getPossibleMoves,
    isCheckForColor,
    getSquaresWithPieceType,
    getPositionAfterPawnPromotion
} from './helpers'

export const ChessContext = React.createContext()

const initialFEN = {
    pieces: 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR',
    turn: 'w',
    castlingOptions: 'KQkq',
    enPassantSquare: '-',
    halfMoveClock: 0, // number of halfmoves since the last capture or pawn advance
    fullMoves: 1
}

export default function ChessProvider({ children }) {
    const [mode, setMode] = useState('play')
    const [gameResult, setGameResult] = useState(null)
    const [lightColor, setLightColor] = useState('#FFFFFF')
    const [darkColor, setDarkColor] = useState('#619254')
    const [selectColor, setSelectColor] = useState('#FFFF00')
    const [view, setView] = useState('white')
    const [showCoords, setShowCoords] = useState(false)
    const [autoFlip, setAutoFlip] = useState(false)
    const [positions, setPositions] = useState([])
    const [moves, setMoves] = useState([])
    const [clickedSquare, setClickedSquare] = useState(null)
    const [currentHalfMove, setCurrentHalfMove] = useState(0)
    const [displayedPieces, setDisplayedPieces] = useState([ [], [], [], [], [], [], [], [] ])
    const [capturedBlackPieces, setCapturedBlackPieces] = useState([])
    const [capturedWhitePieces, setCapturedWhitePieces] = useState([])
    const currentPosition = positions[currentHalfMove]
    const [possibleMoves, setPossibleMoves] = useState([])
    const [pawnPromotionSquare, setPawnPromotionSquare] = useState(null)
    const isViewingLatestPosition = positions.length && currentHalfMove === positions.length - 1

    const updateDisplayedPieces = useCallback(pieces => {
        const updatedDisplayedPieces = getPieceArrayFromFenPieces(pieces)
        setDisplayedPieces(updatedDisplayedPieces)
    }, [])

    useEffect(() => {
        if (mode === 'play') {
            const updatedPositions = []
            updatedPositions.push(initialFEN)
            setPositions(updatedPositions)
        }
    }, [mode, updateDisplayedPieces])

    useEffect(() => {
        if (currentPosition) {
            updateDisplayedPieces(currentPosition.pieces)
            const updatedPossibleMoves = getPossibleMoves(currentPosition)
            setPossibleMoves(updatedPossibleMoves)
            if (updatedPossibleMoves.length === 0) {
                const sideWithNoMoves = currentPosition.turn
                const pieceArray = getPieceArrayFromFenPieces(currentPosition.pieces)
                if (isCheckForColor(sideWithNoMoves, pieceArray)) {
                    console.log('Checkmate!')
                    setGameResult(sideWithNoMoves === 'w' ? '0-1' : '1-0')
                } else {
                    console.log('Stalemate!')
                    setGameResult('1/2 - 1/2')
                }
            } else if (positions.halfMoveClock > 50) {
                console.log('Draw after 50 moves without a capture or pawn move!')
                setGameResult('1/2 - 1/2')
            } else if (positions.length > 6) {
                positions.forEach(p => {
                    let duplicateCount = 0
                    positions.forEach(other => {
                        if (p.pieces === other.pieces)
                            duplicateCount++
                    })
                    if (duplicateCount > 2) {
                        console.log('Draw by repetition!')
                        setGameResult('1/2 - 1/2')
                    }
                })
            } 
        }
    }, [currentPosition, updateDisplayedPieces, positions])

    const flipView = () => {
        setView(view === 'white' ? 'black' : 'white')
    }

    const resetBoard = () => {
        const updatedPositions = []
        updatedPositions.push(initialFEN)
        setPositions(updatedPositions)
        setCapturedWhitePieces([])
        setCapturedBlackPieces([])
        setCurrentHalfMove(0)
        setMoves([])
        setGameResult(null)
    }

    const addCapturedPiece = piece => {
        if (currentPosition.turn === 'w') {
            setCapturedBlackPieces([...capturedBlackPieces, piece])
        } else {
            setCapturedWhitePieces([...capturedWhitePieces, piece])
        }
    }

    const undoLastMove = () => {
        const updatedPositions = [...positions]
        updatedPositions.pop()
        const updatedMoves = [...moves]
        updatedMoves.pop()
        setCurrentHalfMove(x => x - 1)
        setPositions(updatedPositions)
        setMoves(updatedMoves)
        // TODO: update captured arrays
    }

    const addMove = (piece, originSquare, destinationSquare, isCapture, isCheck) => {
        let move = ''
        const oldPosition = [
            [...displayedPieces[0]],
            [...displayedPieces[1]],
            [...displayedPieces[2]],
            [...displayedPieces[3]],
            [...displayedPieces[4]],
            [...displayedPieces[5]],
            [...displayedPieces[6]],
            [...displayedPieces[7]]
        ]  

        if (piece.toLowerCase() === 'k' && originSquare[0] === 'e' && destinationSquare[0] === 'g')
            move += 'O-O'
        else if (piece.toLowerCase() === 'k' && originSquare[0] === 'e' && destinationSquare[0] === 'c')
            move += 'O-O-O'
        else {
            if (piece.toLowerCase() !== 'p')
            move += piece.toUpperCase();

            if (piece.toLowerCase() === 'n' || piece.toLowerCase() === 'r') {
                const allSquaresWithPiece = getSquaresWithPieceType(piece, oldPosition)
                if (allSquaresWithPiece.length > 1) {
                    const otherSquareWithPiece = allSquaresWithPiece.find(x => x !== originSquare)
                    const needsHint = isLegalMove(otherSquareWithPiece, destinationSquare, piece, isCapture, oldPosition, currentPosition.castlingOptions, false)
                    if (needsHint) {
                        const isSameFile = originSquare[0] === otherSquareWithPiece[0]
                        move += isSameFile ? originSquare[1] : originSquare[0]
                    }
                }
            }
            
            if (piece.toLowerCase() === 'p' && isCapture)
                move += originSquare[0];            
            
            if (isCapture)
                move += 'x';
            
            move += destinationSquare;
        }

        if (isCheck)
            move += '+'

        setMoves([...moves, move])
    }

    const move = (originSquare, destinationSquare, originPiece, destinationPiece, isCapture, isEnPassantCapture) => {
        if (isCapture) {
            addCapturedPiece(isEnPassantCapture ? 'p' : destinationPiece)
        }

        const newFEN = {...currentPosition}
        const currentPieceArray = getPieceArrayFromFenPieces(currentPosition.pieces)
        const newPosition = getPositionAfterMove(currentPieceArray, originPiece, originSquare, destinationSquare, isEnPassantCapture)
        newFEN.pieces = getFenPiecesFromPieceArray(newPosition)
        newFEN.turn = currentPosition.turn === 'w' ? 'b' : 'w'
        if (currentPosition.turn === 'b') {
            newFEN.fullMoves = currentPosition.fullMoves + 1
        }
        newFEN.halfMoveClock = (originPiece.toLowerCase() === 'p' || isCapture) ? 0 : currentPosition.halfMoveClock + 1
        
        if (currentPosition.castlingOptions !== '-') {
            let updatedCastlingOptions = ''

            // update castling options for king moves
            if (originPiece.toLowerCase() === 'k') {
                
                if (currentPosition.turn === 'w') {
                    updatedCastlingOptions = removeFromString(currentPosition.castlingOptions, 'K')
                    updatedCastlingOptions = removeFromString(updatedCastlingOptions, 'Q')
                } else if (currentPosition.turn === 'b') {
                    updatedCastlingOptions = removeFromString(currentPosition.castlingOptions, 'k')
                    updatedCastlingOptions = removeFromString(updatedCastlingOptions, 'q')
                }
                newFEN.castlingOptions = updatedCastlingOptions || '-'
            }
    
            // update castling options for rook moves
            else if (originPiece.toLowerCase() === 'r') {
                if (originSquare[0] === 'a') { // queen's rook moved
                    updatedCastlingOptions = removeFromString(currentPosition.castlingOptions, originPiece === 'R' ? 'Q' : 'q')
                    newFEN.castlingOptions = updatedCastlingOptions || '-'
                } else if (originSquare[0] === 'h') { // king's rook moved
                    updatedCastlingOptions = removeFromString(currentPosition.castlingOptions, originPiece === 'R' ? 'K' : 'k')
                    newFEN.castlingOptions = updatedCastlingOptions || '-'
                }
            }
        }
        
        // update en passant square
        if (originPiece === 'P' && originSquare[1] === '2' && destinationSquare[1] === '4') // White pawn moves up two squares
            newFEN.enPassantSquare = `${originSquare[0]}3`
        else if (originPiece === 'p' && originSquare[1] === '7' && destinationSquare[1] === '5') // Black pawn moves up two squares
            newFEN.enPassantSquare = `${originSquare[0]}6`
        else
            newFEN.enPassantSquare = '-'
        
        const isCheck = isCheckForColor(newFEN.turn, newPosition)
        addMove(originPiece, originSquare, destinationSquare, isCapture, isCheck)        

        const updatedPositions = [...positions]
        updatedPositions.push(newFEN)
        setPositions(updatedPositions)
        setCurrentHalfMove(x => x + 1)
    }

    const onSquareSelect = square => {
        if (mode === 'play' && gameResult) {
            console.log('Game is over!')
            return
        }
        if (clickedSquare && clickedSquare === square) {
            setClickedSquare(null)
        } else if (clickedSquare && clickedSquare !== square) {
            const currentPieceArray = getPieceArrayFromFenPieces(currentPosition.pieces)
            const clickedPieceColor = getPieceColorBySquareName(square, currentPieceArray)
            if (clickedPieceColor === currentPosition.turn) {
                console.log('You cannot capture your own piece!')
                setClickedSquare(null)
                return
            }
            if (!isViewingLatestPosition) {
                console.log('You are not viewing the latest position')
                return
            }
            const originPiece = getSquareContentBySquareName(clickedSquare, currentPieceArray)
            const destinationPiece = getSquareContentBySquareName(square, currentPieceArray)
            const isEnPassantCapture = originPiece.toLowerCase() === 'p' && square === currentPosition.enPassantSquare
            const isCapture = (destinationPiece !== '-') || isEnPassantCapture

            const isLegal = isLegalMove(clickedSquare, square, originPiece, isCapture, currentPieceArray, currentPosition.castlingOptions, isEnPassantCapture)
            if (isLegal) {
                if (originPiece.toLowerCase() === 'p' && ['1', '8'].includes(square[1])) {
                    setPawnPromotionSquare(square)
                }
                move(clickedSquare, square, originPiece, destinationPiece, isCapture, isEnPassantCapture)
            } else {
                console.log(`${clickedSquare}-${square} is an illegal move!`)
            }
            setClickedSquare(null)
        } else {
            const currentPieceArray = getPieceArrayFromFenPieces(currentPosition.pieces)
            const squareColor = getPieceColorBySquareName(square, currentPieceArray)
            if (squareColor !== currentPosition.turn) {
                console.log('Wrong color!')
            } else {
                setClickedSquare(square)
            }
        }
    }

    const promotePawn = piece => {
        const updatedPositionArray = getPositionAfterPawnPromotion([...displayedPieces], piece, pawnPromotionSquare)
        const updatedFenPieces = getFenPiecesFromPieceArray(updatedPositionArray)
        const updatedPositions = [...positions]
        const lastPosition = updatedPositions[updatedPositions.length - 1]
        lastPosition.pieces = updatedFenPieces
        updatedPositions[updatedPositions.length - 1] = lastPosition
        setPositions(updatedPositions)
        const updatedMoves = [...moves]
        const lastMove = updatedMoves[updatedMoves.length - 1]
        const isCheck = isCheckForColor(currentPosition.turn, updatedPositionArray)
        updatedMoves[updatedMoves.length - 1] = `${lastMove}=${piece.toUpperCase()}${isCheck ? '+' : ''}`
        setMoves(updatedMoves)
        setPawnPromotionSquare(null)
    }

    const context = {
        mode, setMode,
        gameResult, setGameResult,
        lightColor, setLightColor,
        darkColor, setDarkColor,
        selectColor, setSelectColor,
        clickedSquare, setClickedSquare,
        view, setView,
        showCoords, setShowCoords,
        autoFlip, setAutoFlip,
        positions, setPositions,
        moves, setMoves,
        currentHalfMove, setCurrentHalfMove,
        displayedPieces, setDisplayedPieces,
        capturedBlackPieces, setCapturedBlackPieces,
        capturedWhitePieces, setCapturedWhitePieces,
        possibleMoves, setPossibleMoves,
        pawnPromotionSquare, setPawnPromotionSquare,
        currentPosition,
        isViewingLatestPosition,
        flipView,
        resetBoard,
        onSquareSelect,
        undoLastMove,
        promotePawn
    }

    return (
        <ChessContext.Provider value={context}>
            {children}
        </ChessContext.Provider>
    )
}

export function useChess() {
    const context = React.useContext(ChessContext)
    if (context === undefined) {
        throw new Error(`useChess must be used within a ChessProvider`)
    }
    return context
}
