import React from 'react'
import { Link } from '@reach/router'
import firstBlock from './FB.png'

export default function Roux1() {
    return (
        <div>
            <h1>Roux Tutorial: The First Block</h1>

            <ul>
                <li><Link to="/cubetools/roux">Introduction</Link></li>
                <li>The First Block</li>
                <li><Link to="/cubetools/roux/2">The Second Block</Link></li>
                <li><Link to="/cubetools/roux/3">CMLL (Top Corners)</Link></li>
                <li><Link to="/cubetools/roux/4">LSE: Edge Orientation</Link></li>
                <li><Link to="/cubetools/roux/5">LSE: Permute UL/UR</Link></li>
                <li><Link to="/cubetools/roux/6">LSE: Permute M Slice</Link></li>
                <li><Link to="/cubetools/roux/7">Example Solves</Link></li>
                <li><Link to="/cubetools/roux/8">Advanced Roux</Link></li>
            </ul>

            <p>We can begin making a 1x2x3 block of any color, but this tutorial will start with the blue block shown below.</p>

            <div className="text-center"><img src={firstBlock} alt="" /><br />(red = front)</div>

            <p>There are no algorithms to make a 1x2x3 block, only ideas. One approach is to insert the white/blue edge followed by each of the two CE pairs around it. F2L patterns from CFOP can be useful, but there are often more efficient ways to manipulate the pieces since you have the whole cube at your disposal. Solving the block in 8-10 moves is a good goal.</p>

            <p>Blockbuilding is probably best learned by example, so be sure to check out my <Link to="/cubetools/roux/7">example Roux solves</Link> toward the end of the tutorial.</p>

            <p>The first block will remain on the lower left of the cube for the remainder of the solve. Left-handed solvers may prefer to keep the first block on the lower right.</p>

            <p>Once the first block is complete, we are ready to make the <Link to="/cubetools/roux/2">second block</Link>.</p>
        </div>
    )
}
