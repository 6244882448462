import React from 'react'
import { Link } from '@reach/router'
import { Table } from 'reactstrap'
import f2l1 from './f2l1.png'
import f2l2 from './f2l2.png'
import f2l3 from './f2l3.png'
import f2l4 from './f2l4.png'
import f2l5 from './f2l5.png'
import f2l6 from './f2l6.png'
import f2l7 from './f2l7.png'
import f2l8 from './f2l8.png'
import f2l from './f2l.pdf'

export default function CFOP2() {
    return (
        <div>
            <h1>CFOP Tutorial: First Two Layers</h1>

            <ul>
                <li><Link to="/cubetools/cfop">Introduction</Link></li>
                <li><Link to="/cubetools/cfop/1">Cross</Link></li>
                <li>First Two Layers (F2L)</li>
                <li><Link to="/cubetools/cfop/3">Orient Last Layer (OLL)</Link></li>
                <li><Link to="/cubetools/cfop/4">Permute Last Layer (PLL)</Link></li>
                <li><Link to="/cubetools/cfop/5">Beyond CFOP</Link></li>
            </ul>

            <p>In CFOP, solving the first two layers entails pairing up each of the four corner-edge (CE) pairs and inserting them around the cross. This is best learned intuitively, but <a href={f2l} target="_blank" rel="noreferrer">learning algorithms</a> will increase efficiency later on.</p>

            <h2>Setup and Insert</h2>

            <p>There are two basic steps for solving a CE pair: setup the CE pair, and insert the CE pair. There are two main setups to keep in mind:</p>

            <Table>
                <tbody>
                    <tr>
                        <td>
                            <img src={f2l1} alt="" />
                        </td>
                        <td>
                            The corner and edge are positioned a knight's move apart. This is common when the top colors of the two pieces are different. They are inserted with (RUR').
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <img src={f2l2} alt="" />
                        </td>
                        <td>
                            The corner and edge are combined in a block. This is common when the top colors of the two pieces are the same. They are inserted with (U RU'R') or (R'FRF').
                        </td>
                    </tr>
                </tbody>
            </Table>

            <p>So what's the big deal with F2L? Several things.</p>

            <h2>The Big Deal, Part I</h2>

            <p>First, the process of setting up the CE pair varies depending on where the pieces happen to be. Ideally, the pieces are either in the top layer or already in the correct slot. There are 41 of these standard cases. Most of these cases are very short and intuitive, and many are mirrors of another case. Here are some examples:</p>

            <Table>
                <tbody>
                    <tr>
                        <td>
                            <img src={f2l3} alt="" />
                        </td>
                        <td>
                            d (R'U'R) U2 (R'UR)<br />The top colors are the same, so we make a block and insert it in the back.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <img src={f2l4} alt="" />
                        </td>
                        <td>
                            U2 (RUR') U (RU'R')<br />Here we can make the block on the side and insert.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <img src={f2l5} alt="" />
                        </td>
                        <td>
                            y (L'U'LU)(L'U'L)<br />Here we position the pieces a knight's move apart, then insert.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <img src={f2l6} alt="" />
                        </td>
                        <td>
                            (RUR'U')(RU2R'U')(RUR')<br />This time it takes 8 moves to setup the knight's move before inserting.
                        </td>
                    </tr>
                </tbody>
            </Table>

            <h2>The Big Deal, Part II</h2>

            <p>Second, sometimes an edge or corner (or both) will be in an incorrect slot. The basic way to deal with this situation is to bring the wayward piece to the top layer with something like (RU'R') or (LUL'). Keep an eye out for which slots are still empty and use those to your advantage.</p>

            <Table>
                <tbody>
                    <tr>
                        <td>
                            <img src={f2l7} alt="" />
                        </td>
                        <td>
                            (R'U'R2UR')<br />The edge is in the wrong slot, so we bring it to the top layer, setup a knight's move, and insert.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <img src={f2l8} alt="" />
                        </td>
                        <td>
                            U (RU'R')(L'UL)<br />The corner is in the wrong slot. We first move the top to prepare a block, then insert as usual.
                        </td>
                    </tr>
                </tbody>
            </Table>

            <h2>The Big Deal, Part III</h2>

            <p>Third, slow down and look ahead. This is the golden rule of speedcubing. It is better to move fluidly without pausing, even if that means moving more slowly at first. Then you can gradually increase your speed.</p>

            <p>Once you get comfortable with solving CE pairs, you will be able to execute each one automatically. This means that you do not need to look at the CE pair you are currently solving. Instead, you are free to look elsewhere on the cube for the next pair. Looking ahead is how you can reduce pauses between CE pairs and dramatically improve your solve time.</p>

            <p>When convenient, insert pairs into the back slots; this gives you a better view of the other pieces around the cube.</p>

            <p>Increasing your speed will feel uncomfortable at first, but your brain will adapt to tracking the CE pairs at a faster speed. Once you get to 4 turns per second, the cross and F2L can be done in 10-12 seconds.</p>

            <p>Next comes <Link to="/cubetools/cfop/3">OLL</Link>.</p>
        </div>
    )
}
