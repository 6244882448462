import React from 'react'
import { AddCircle, Settings, List, Event } from '@material-ui/icons'
import { useAuth } from '../../main/auth'
import { ListSelector, useHoney } from '.'
import { navigate } from '@reach/router'
import { Button, ButtonGroup } from 'reactstrap'
import SideMenu from '../../main/nav/SideMenu'

export default function HoneyMenu() {
    const { activeList, lists, canEdit, isListOwner } = useHoney()
    const { user } = useAuth()

    if (user) return (
        <SideMenu className="honey-menu" header={activeList ? `Honey: ${activeList.name}` : `Honey`} headerLink="/honey">
            {lists.length > 0 && 
                <ListSelector onChange={e => navigate(`/honey/${e.target.value}`)} />}
                <ButtonGroup vertical className="w-100">
                    {activeList && canEdit && 
                        <Button 
                            type="button"
                            color="primary"
                            outline
                            onClick={() => navigate(`/honey/${activeList.listId}/item/new`)}
                        >
                            <span className="d-flex align-items-center"><AddCircle className="me-1" />Items</span>
                        </Button>}
                    {activeList && isListOwner &&
                        <Button 
                            type="button"
                            color="primary"
                            outline
                            onClick={() => navigate(`/honey/${activeList.listId}/edit`)}
                        >
                            <span className="d-flex align-items-center"><Settings className="me-1" />List Settings</span>
                        </Button>}
                    {lists.length > 0 &&
                        <Button 
                            type="button"
                            color="primary"
                            outline
                            onClick={() => navigate('/honey/due')}
                        >
                            <span className="d-flex align-items-center"><List className="me-1" />List Items Due</span>
                        </Button>}
                    <Button
                        type="button"
                        color="primary"
                        outline
                        onClick={() => navigate('/calendar')}
                    >
                        <span className="d-flex align-items-center"><Event className="me-1" />View Calendar</span>
                    </Button>
                    <Button 
                        type="button"
                        color="primary"
                        outline
                        onClick={() => navigate(`/honey/new`)}
                    >
                        <span className="d-flex align-items-center"><AddCircle className="me-1" />List</span>
                    </Button>
                </ButtonGroup>
        </SideMenu>
    )

    return null
}
