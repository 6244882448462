import React from 'react'
import { Link } from '@reach/router'
import edge1 from './LL edge1.png'
import edge2 from './LL edge2.png'
import epll1 from './epll1.png'
import epll2 from './epll2.png'
import solved from './solved.png'

export default function LBL7() {
    return (
        <div>
            <h1>3x3 Beginner Tutorial: The Final Edges</h1>

            <ul>
                <li><Link to="/cubetools/lbl">Introduction</Link></li>
                <li><Link to="/cubetools/lbl/1">The White Cross</Link></li>
                <li><Link to="/cubetools/lbl/2">The White Corners</Link></li>
                <li><Link to="/cubetools/lbl/3">The Second Layer</Link></li>
                <li><Link to="/cubetools/lbl/4">The Yellow Cross</Link></li>
                <li><Link to="/cubetools/lbl/5">The Yellow Top</Link></li>
                <li><Link to="/cubetools/lbl/6">The Final Corners</Link></li>
                <li>The Final Edges</li>
            </ul>

            <p>This is the last step! Turn the cube so that yellow is once again on top.</p>

            <p>Look to see how many of the four sides of the cube (other than yellow and white) are completely solved. If you are very lucky, all four will be solved, and you're finished! Usually there will be 1 or 0 completed sides. (In other words, there will be 1 or 0 correct edges in the top layer.)</p>

            <h2>One Correct Side</h2>

            <p>If there is 1 correct edge, face the completed side and use this algorithm: (M2U')(M'U2M)(U'M2)</p>

            <div className="text-center">
                <img src={epll1} alt="" />
                <img src={edge1} alt="" />
            </div>

            <p>The algorithm shuffles the 3 incorrect edges clockwise. Note that you can change the direction of the shuffle by turning the top clockwise instead of counterclockwise: (M2U)(M'U2M)(UM2)</p>

            <div className="text-center">
                <img src={epll2} alt="" />
                <img src={edge2} alt="" />
            </div>

            <h2>No Correct Sides</h2>

            <p>If there are no correct edges, do the 3-edge shuffle facing any side, and after that you should have one correct edge. Now you can solve the cube using one more 3-edge shuffle.</p>

            <p>Congratulations, you have solved the Rubik's Cube! If you memorize these steps, you can solve it consistently with a little practice.</p>

            <div className="text-center">
                <img src={solved} alt="" />
            </div>
        </div>
    )
}
