import React, { useState, useEffect, useCallback } from 'react'
import { fetcher, Card, Tooltip, Loading } from '../../common'
import { Done, AddCircle, Clear } from '@material-ui/icons'
import { NotificationManager } from 'react-notifications'
import EditableDashboardElement from './EditableDashboardElement'
import { useAuth } from '../../main/auth/AuthProvider'
import { useTheming } from '../../main/theme/ThemeProvider'
import { useFriends } from '../Friends/FriendsProvider'
import { useHoney } from '../Honey'
import { useWhisk } from '../Whisk'
import { useBudget } from '../NickelAndDime'
import { useTracker } from '../Tracker'
import { Input, FormFeedback, Label, FormGroup, Button, Form, Alert } from 'reactstrap'
import './settings.scss'

export default function Settings() {
    const { userSettings, updateUserSettings, user } = useAuth()
    const { theme } = useTheming()
    const { lists, getLists, } = useHoney()
    const { friends } = useFriends()
    const { getGroups } = useTracker()
    const { getRecipes } = useWhisk()
    // const { getBudgets } = useBudget()
    const [hasSubmittedPassword, setHasSubmittedPassword] = useState(false)
    const [oldPasswordInput, setOldPasswordInput] = useState('')
    const [newPasswordInput, setNewPasswordInput] = useState('')
    const [confirmNewPasswordInput, setConfirmNewPasswordInput] = useState('')
    const [dashboardInput, setDashboardInput] = useState([])
    const [loading, setLoading] = useState(false)
    const userLists = user && lists.length ? lists.filter(x => x.userId === user.userId) : []
    const friendLists = user && lists.length ? lists.filter(x => x.userId !== user.userId) : []

    const onLoad = useCallback(async () => {
        setLoading(true)
        await Promise.all([
            getLists(),
            getRecipes(),
            getGroups(),
            // getBudgets()
        ])
        setLoading(false)
    }, [getLists, getRecipes, getGroups])

    useEffect(() => {
        onLoad()
    }, [onLoad])

    useEffect(() => {
        if (userSettings && userSettings.dashboard) {
            setDashboardInput(userSettings.dashboard)
        }
    }, [userSettings])

    const defaultContent = {
        module: '',
        type: '',
        id: 0
    }

    const addDashboardContent = (content = defaultContent) => {
        let updatedDashboard = [...dashboardInput]
        updatedDashboard.push(content)
        setDashboardInput(updatedDashboard)
    }

    const updateDashboardElement = useCallback((element, index) => {
        let updatedDashboard = [...dashboardInput]
        updatedDashboard[index] = element
        setDashboardInput(updatedDashboard)
    }, [dashboardInput])

    const deleteDashboardElement = index => {
        let updatedDashboard = [...dashboardInput]
        updatedDashboard.splice(index, 1)
        setDashboardInput(updatedDashboard)
    }

    const moveDashboardElementUp = index => {
        let updatedDashboard = [...dashboardInput]
        const swap = updatedDashboard[index]
        updatedDashboard[index] = updatedDashboard[index - 1]
        updatedDashboard[index - 1] = swap
        setDashboardInput(updatedDashboard)
    }

    const moveDashboardElementDown = index => {
        let updatedDashboard = [...dashboardInput]
        const swap = updatedDashboard[index]
        updatedDashboard[index] = updatedDashboard[index + 1]
        updatedDashboard[index + 1] = swap
        setDashboardInput(updatedDashboard)
    }

    const updateFirstList = async (firstList) => {
        let body = {
            ...userSettings,
            firstList: firstList
        }
        await updateUserSettings(body)
    }

    const updateTheme = async (theme) => {
        let body = {
            ...userSettings,
            theme: theme
        }
        await updateUserSettings(body)
    }

    const changePassword = async (event) => {
        event.preventDefault()
        setHasSubmittedPassword(true)
        if (oldPasswordInput.trim() === '' || newPasswordInput.trim() === '' || newPasswordInput !== confirmNewPasswordInput) {
            return
        }       
        const body = {
            oldPassword: oldPasswordInput,
            newPassword: newPasswordInput
        }
        const response = await fetcher.put('user/password', body)
        if (response && response.message) {
            NotificationManager.warning(response.message)
        } else if (response) {
            NotificationManager.success('Password successfully changed!')
            setOldPasswordInput('')
            setNewPasswordInput('')
            setConfirmNewPasswordInput('')
            setHasSubmittedPassword(false)
        }
    }

    const saveDashboard = async () => {
        let body = {
            ...userSettings,
            dashboard: dashboardInput
        }
        await updateUserSettings(body)
    }

    if (!user) return null

    return (
        <div className="main-container settings">
            <h1>Settings</h1>
            <p className="text-muted">Customize your experience, {user.firstName}!</p>
            <div className="settings-sections">
                <div className="first-column">
                    {/* <Section titled>
                        <h2>Theme</h2>
                        <label>
                            <input 
                                type="radio" 
                                name="theme" 
                                value="light" 
                                checked={theme === 'light'}
                                onChange={e => updateTheme(e.target.value)}
                            />Light
                        </label>
                        <label>
                            <input 
                                type="radio" 
                                name="theme" 
                                value="dark" 
                                checked={theme === 'dark'}
                                onChange={e => updateTheme(e.target.value)}
                            />Dark
                        </label>
                    </Section> */}

                    {/* <Section titled>
                        <h2>Default List</h2>
                        {loading
                            ? <Loading />
                            : <div className="flex vcenter">
                                <select
                                    value={userSettings && userSettings.firstList ? userSettings.firstList : 0}
                                    onChange={e => updateFirstList(e.target.value)}
                                >
                                    <option disabled value={0}>Select a list...</option>
                                    {userLists.length &&
                                        <optgroup label="My Lists">
                                            {userLists.map(list => <option key={list.listId} value={list.listId}>{list.name}</option>)}
                                        </optgroup>
                                    }
                                    {friendLists.length &&
                                        <optgroup label="Friends' Lists">
                                            {friendLists.map(list => {
                                                let friend = friends ? friends.find(x => x.userId === list.userId) : null
                                                let friendName = friend ? friend.firstName : ''
                                                return (
                                                    <option key={list.listId} value={list.listId}>{list.name} ({friendName})</option>
                                                )
                                            })}
                                        </optgroup>
                                    }
                                </select>
                                <Tooltip text="This list will be selected when you first open Honey." />
                            </div>
                        }
                    </Section> */}

                    <Card header="Change Password">
                        <Form>
                            <FormGroup>
                                <Label for="oldPw">Old Password</Label>
                                <Input
                                    type="password"
                                    name="oldPassword"
                                    id="oldPw"
                                    onChange={e => setOldPasswordInput(e.target.value)}
                                    value={oldPasswordInput}
                                    placeholder="Old Password"
                                    invalid={hasSubmittedPassword && oldPasswordInput.trim() === ''}
                                />
                                <FormFeedback>Please enter your old password.</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label for="newPw">New Password</Label>
                                <Input
                                    type="password"
                                    name="newPassword"
                                    id="newPw"
                                    onChange={e => setNewPasswordInput(e.target.value)}
                                    value={newPasswordInput}
                                    placeholder="New Password"
                                    invalid={hasSubmittedPassword && newPasswordInput.trim() === ''}
                                />
                                <FormFeedback>Please enter a new password</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label for="confirmPw">Confirm New Password</Label>
                                <Input
                                    type="password"
                                    name="confirmNewPassword"
                                    id="confirmPw"
                                    onChange={e => setConfirmNewPasswordInput(e.target.value)}
                                    value={confirmNewPasswordInput}
                                    placeholder="Confirm New Password"
                                    invalid={newPasswordInput !== confirmNewPasswordInput}
                                    valid={newPasswordInput.length > 0 && newPasswordInput === confirmNewPasswordInput}
                                />
                                <FormFeedback>Your new password fields must match.</FormFeedback>
                                <FormFeedback valid>Your passwords match! :)</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Button
                                    type="submit"
                                    color="success"
                                    onClick={e => changePassword(e)}
                                >
                                    <span className="d-flex align-items-center"><Done className="me-1" />Save New Password</span>
                                </Button>
                            </FormGroup>
                        </Form>
                    </Card>
                </div>

                <Card header="Dashboard Content">
                    <div className="dashboard-content">
                        {loading 
                            ? <Loading />
                            : dashboardInput.map((element, i) => 
                                <EditableDashboardElement 
                                    key={`element-${element.module}-${element.type}-${element.id}-${i}`}
                                    element={element}
                                    elements={dashboardInput} 
                                    index={i}
                                    moveUp={moveDashboardElementUp}
                                    moveDown={moveDashboardElementDown}
                                    onChange={updateDashboardElement}
                                    onDelete={deleteDashboardElement}
                                />
                            )
                        }
                    </div>
                    <Button 
                        type="button"
                        color="primary"
                        onClick={() => addDashboardContent()}
                    >
                        <span className="d-flex align-items-center"><AddCircle className="me-1" />Content</span>
                    </Button>
                    {dashboardInput !== userSettings.dashboard && <>
                        {userSettings && userSettings.dashboard && 
                            <Alert color="warning" className="mt-2">You have unsaved changes.</Alert>}
                        <Button 
                            type="button"
                            color="success"
                            onClick={saveDashboard}
                        >
                            <span className="d-flex align-items-center"><Done className="me-1" />Save Dashboard</span>
                        </Button>
                        <Button 
                            type="button"
                            color="secondary"
                            onClick={() => setDashboardInput(userSettings.dashboard || [])}
                            className="ms-1"
                        >
                            <span className="d-flex align-items-center"><Clear className="me-1" />Cancel Changes</span>
                        </Button>
                    </>}
                </Card>              
            </div>
        </div>
    )
}
