import React, { useEffect } from 'react'
import { Router } from '@reach/router'
import { TrackerGroupList, TrackerData, TrackerGroupDialog, useTracker, TrackerMenu, TrackerDataDialog } from '.'

export default function TrackerModule() {
    const { getGroups, getPermissions } = useTracker()

    useEffect(() => {
        getGroups()
        getPermissions()
    }, [getGroups, getPermissions])

    return (
        <div className="module-container">
            <TrackerMenu />
            <div className="main-container with-side-menu">
                <Router>
                    <TrackerGroupList path="groups" default />
                    <TrackerGroupDialog path="groups/create" />
                    <TrackerData path="groups/:groupId" />
                    <TrackerGroupDialog path="groups/:groupId/edit" />
                    <TrackerDataDialog path="groups/:groupId/data/:dataId" />
                </Router>
            </div>
        </div>
    )
}
