import React from 'react'
import { Link } from '@reach/router'
import { useAuth } from './auth'

export default function Productivity() {
    const { user } = useAuth()

    return (
        <div className="main-container">
            <h1>Productivity</h1>
            <p>These apps help me function. <Link to="/login">Sign in</Link> or <Link to="/signup">sign up</Link> to get access.</p>

            {/* {user 
                ? <Link to="/brisk"><h2>Brisk</h2></Link>
                : <h2>Brisk</h2>
            }            
            <p>Get current weather and forecasts.</p> */}

            {user 
                ? <Link to="/calendar"><h2>Calendar</h2></Link>
                : <h2>Calendar</h2>
            }
            <p>It's a calendar that interfaces with your lists in Honey.</p>

            {user 
                ? <Link to="/honey"><h2>Honey</h2></Link>
                : <h2>Honey</h2>
            }
            <p>Create and share lists.</p>

            {/* {user 
                ? <Link to="/budget/dashboard"><h2>Nickel &amp; Dime</h2></Link>
                : <h2>Nickel &amp; Dime</h2>
            }
            <p>Manage your budget.</p> */}

            {user 
                ? <Link to="/tracker"><h2>Tracker</h2></Link>
                : <h2>Tracker</h2>
            }    
            <p>Save and graph quantitative data.</p>

            {user 
                ? <Link to="/whisk"><h2>Whisk</h2></Link>
                : <h2>Whisk</h2>
            }
            <p>Save and share recipes.</p>
        </div>
    )
}
