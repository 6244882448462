import React from 'react'
import { Link } from '@reach/router'
import example1 from './rouxexample1.png'
import example2 from './rouxexample2.png'
import example3 from './rouxexample3.png'

export default function Roux7() {
    return (
        <div>
            <h1>Roux Tutorial: Example Solves</h1>

            <ul>
                <li><Link to="/cubetools/roux">Introduction</Link></li>
                <li><Link to="/cubetools/roux/1">The First Block</Link></li>
                <li><Link to="/cubetools/roux/2">The Second Block</Link></li>
                <li><Link to="/cubetools/roux/3">CMLL (Top Corners)</Link></li>
                <li><Link to="/cubetools/roux/4">LSE: Edge Orientation</Link></li>
                <li><Link to="/cubetools/roux/5">LSE: Permute UL/UR</Link></li>
                <li><Link to="/cubetools/roux/6">LSE: Permute M Slice</Link></li>
                <li>Example Solves</li>
                <li><Link to="/cubetools/roux/8">Advanced Roux</Link></li>
            </ul>

            <h4>Example 1</h4>

            <p>Face green with white on top, and do this scramble:<br />
            F R L' F R' U L U2 D' B' U F L' U' D L F U2 D' R U2 B' U' L' B</p>

            <div className="text-center">
                <img src={example1} alt="" />
            </div>

            <p>Now face the blue center with the orange center on top. This puts the blue/white edge in its DL spot, so we already have a head start on the first block.</p>

            <p>1st block: RuF' r'DB'D'B
                <ul>
                    <li>R gets the blue/red pieces out of the way so we can move the blue center without separating them</li>
                    <li>uF' corrects the blue center and inserts the blue/red pieces</li>
                    <li>r'D aligns the blue/orange pieces. We do r' instead of R' to preserve the connected green/red pieces on top</li>
                    <li>B' makes room for the whole block to come together</li>
                    <li>D'B finishes the 1st block</li>
                </ul>
            </p>

            <p>2nd block: rUr' U' r'U'r
                <ul>
                    <li>The green/white edge is already in place at DR</li>
                    <li>rUr' is a standard insert for the red/green pieces, and it also paired up the green orange pieces</li>
                    <li>U' r'U'r inserts the green/orange pieces</li>
                    <li>This was an unusually easy 2nd block</li>
                </ul>
            </p>

            <p>CMLL: U R'U' RUR'F' RUR'U' R'FR2</p>

            <p>EO: U MU'M
                <ul>
                    <li>Back Arrow case. The last turn is M rather than M' because we want the UL/UR edges on the bottom.</li>
                </ul>
            </p>

            <p>UL/UR: U M2 U'
                <ul>
                    <li>Since UL/UR were on the bottom, this is a quick solve. Note that the BU color is orange.</li>
                </ul>
            </p>

            <p>M Slice: M' U2 M U2 M2
                <ul>
                    <li>This is a 3-edge cycle. Since FU &amp; BU are the same, we know we'll start with M or M'. Here it is M' because it keeps the unique edge on top.</li>
                </ul>

            (41 moves total)</p>

            <h4>Example 2</h4>
            <p>Face green with white on top, and do this scramble:<br />
            L B' R' L B F' U L D' L2 R2 U2 L D2 B R2 D2 F2 B U B L R' U R</p>

            <div className="text-center">
                <img src={example2} alt="" />
            </div>

            <p>Now face the red center with the yellow center on top. This puts blue center in place, and the blue/white edge is only a D2 away from its DL spot.</p>

            <p>1st block: D'B D'rF U'r U2rB'
                <ul>
                    <li>D'B pairs up the blue/red pieces</li>
                    <li>D' inserts the blue/white edge</li>
                    <li>rF inserts the blue/red pieces</li>
                    <li>U'r pairs up the blue/orange pieces</li>
                    <li>U2rB' inserts the blue/orange pieces, completing the 1st block</li>
                </ul>
            </p>

            <p>2nd block: R2U'r'U'r2 R'U'R2U'r' U2rUr'
                <ul>
                    <li>R2U'r'U'r2 inserts the green/white edge</li>
                    <li>R'U' inserts the green/orange pieces</li>
                    <li>R2U'r' pairs up the green/red pieces</li>
                    <li>U2 rUr' inserts the green/red pieces, completing the 2nd block</li>
                </ul>
            </p>

            <p>CMLL: U' R'F2RU2 rU'r' F</p>

            <p>EO: M'U M'U'M U' M'U'M'
                <ul>
                    <li>Front Diagonal case. We end with UL/UR on the bottom.</li>
                </ul>
            </p>

            <p>UL/UR: U M2 U'</p>

            <p>M Slice: skip</p>

            <p>(45 moves total)</p>

            <h4>Example 3</h4>
            <p>Face green with white on top, and do this scramble:<br />
            L' U L R2 F2 U' F' U' F B' L' R' U2 R2 U' B' L B2 F2 R' D2 L' R2 U2 F</p>

            <div className="text-center">
                <img src={example3} alt="" />
            </div>

            <p>Now face the green center with the yellow center on top. This puts the blue/white edge in its DL spot.</p>

            <p>1st block: r'D2 r'R'U FUr'f
                <ul>
                    <li>r'D2 pairs up the blue/orange pieces while shuffling the blue/red pieces around</li>
                    <li>r' pairs up the blue/red pieces. I don't normally recommend pairing up both pairs before inserting either of them.</li>
                    <li>R'U re-inserts the blue/white edge next to the blue center</li>
                    <li>FU inserts the blue/red pieces</li>
                    <li>r'f inserts the blue/orange pieces and anchors the block in the lower left</li>
                </ul>
            </p>

            <p>2nd block: U'r'U' rU'r RUR2U'R UrU'r'
                <ul>
                    <li>U'r'U' prepares to insert the green/white edge</li>
                    <li>rU'r inserts the green/white edge, and the U' in the middle prevents the red/green corner from being stuck in the BR slot</li>
                    <li>RUR2 pairs up the green/orange pair</li>
                    <li>U'R inserts the green/orange pair</li>
                    <li>U prepares the green/red pair for a standard insertion</li>
                    <li>rU'r' inserts the green/red pair</li>
                </ul>
            </p>

            <p>CMLL: U' RUR'U' R'FR2U'R'U RUR'F'</p>

            <p>EO: M'U'M' U2 M'UM'
                <ul>
                    <li>Neighbors case. M'U'M' gives us an arrow. U2 points the arrow toward the bad D edge.</li>
                    <li>We end with M'UM' instead of M'U'M' so that the UL/UD edges end up on the bottom.</li>
                </ul>
            </p>

            <p>UL/UR: U M2 U</p>

            <p>M slice: U2 M2 U2 M2</p>

            <p>(53 moves total)</p>

            <p>I've covered the basics of the Roux method, but this rabbit hole goes <Link to="/cubetools/roux/8">deeper</Link>.</p>
        </div>
    )
}
