import React from 'react'
import { Link } from '@reach/router'
import { Row, Col } from 'reactstrap'
import lblPdf from './lbl.pdf'
import core from './core.png'
import corners from './corners.png'
import edges from './edges.png'

export default function LayerByLayer() {
    return (
        <div>
            <h1>3x3 Beginner Tutorial</h1>

            <ul>
                <li>Introduction</li>
                <li><Link to="/cubetools/lbl/1">The White Cross</Link></li>
                <li><Link to="/cubetools/lbl/2">The White Corners</Link></li>
                <li><Link to="/cubetools/lbl/3">The Second Layer</Link></li>
                <li><Link to="/cubetools/lbl/4">The Yellow Cross</Link></li>
                <li><Link to="/cubetools/lbl/5">The Yellow Top</Link></li>
                <li><Link to="/cubetools/lbl/6">The Final Corners</Link></li>
                <li><Link to="/cubetools/lbl/7">The Final Edges</Link></li>
            </ul>

            <p>You can solve the Rubik's Cube. It is simply a matter of breaking the puzzle down into smaller steps and understanding how to approach each step.</p>

            <p>This tutorial is for beginners. You can <a href="https://youtu.be/craX3LSi6Zc" target="_blank" rel="noopener noreferrer">watch a video of this tutorial on YouTube</a> or <a href={lblPdf} target="_blank" rel="noopener noreferrer">download this tutorial as a PDF</a>. More experienced cubers may be interested in the <Link to="/cubetools/cfop">CFOP tutorial</Link> and <Link to="/cubetools/roux">Roux tutorial</Link>.</p>

            <h2>Cube Anatomy</h2>

            <p>The first task in solving the cube is understanding what it is. Inside the cube is an unmoving structure that holds six pieces in place. These six pieces are the center squares on each of the six sides of the cube. Take your cube apart to see for yourself! Around this structure, there are 20 movable pieces: 8 corners and 12 edges. Each corner piece has 3 colors, and each edge piece has 2 colors. Solving the cube entails arranging these 20 pieces correctly around the central axes.</p>

            <Row className="mb-3">
                <Col className="text-center"><img src={core} height="175" alt='6 centers' /><br />6 centers</Col>
                <Col className="text-center"><img src={corners} height="175" alt='8 corners' /><br />8 corners</Col>
                <Col className="text-center"><img src={edges} height="175" alt='12 edges' /><br />12 edges</Col>
            </Row>

            <p>Once you understand how the cube is made, you can see why solving the cube one side at a time would be inefficient. You could solve a side alright, but the relative positions of those 8 pieces (around the unmoving center) could be completely incorrect. It is better to solve the cube layer by layer. This means that as you solve one side, you are simultaneously beginning to solve other sides.</p>

            <h2>Algorithms</h2>

            <p>The &quot;trick&quot; to solving the cube is the use of algorithms, or specific sequences of turns. Algorithms do certain things to the pieces of the cube without disrupting other parts of the cube. The more algorithms you memorize, the more tools you have to solve the cube efficiently.</p>

            <p>A layer-by-layer approach uses only 6 algorithms (1 for the first layer, 1 for the second layer, 2 for orienting last layer, 2 for positioning last layer) plus their mirrored equivalents on occasion. This method can solve the cube using roughly 110 turns on average.</p>

            <h2>Onward!</h2>

            <p>Before moving on to the first step, be sure to learn the <Link to="/cubetools/notation">cube notation</Link> for single-face rotations. Once you feel comfortable with that, then let's tackle <Link to="/cubetools/lbl/1">the white cross</Link>!</p>

        </div>
    )
}
