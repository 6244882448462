import React from 'react'
import { Link } from '@reach/router'

export default function Roux() {
    return (
        <div>
            <h1>Roux Tutorial</h1>

            <ul>
                <li>Introduction</li>
                <li><Link to="/cubetools/roux/1">The First Block</Link></li>
                <li><Link to="/cubetools/roux/2">The Second Block</Link></li>
                <li><Link to="/cubetools/roux/3">CMLL (Top Corners)</Link></li>
                <li><Link to="/cubetools/roux/4">LSE: Edge Orientation</Link></li>
                <li><Link to="/cubetools/roux/5">LSE: Permute UL/UR</Link></li>
                <li><Link to="/cubetools/roux/6">LSE: Permute M Slice</Link></li>
                <li><Link to="/cubetools/roux/7">Example Solves</Link></li>
                <li><Link to="/cubetools/roux/8">Advanced Roux</Link></li>
            </ul>

            <p>The Roux method, invented by Gilles Roux, is an alternative to the CFOP method. It begins by building two 1x2x3 blocks on opposite sides of the cube. This leaves the U and M slices free to turn. Next, the top layer corners are solved. The remaining six edges are oriented and positioned using U and M turns.</p>

            <p>Roux is an efficient, intuitive method. It requires fewer turns than CFOP (48 vs. 55) because almost all the moves are constructive rather than destructive. Once the blocks are built they are only broken once to solve the top corners. In contrast, CFOP solves the last layer by breaking up the F2L twice and putting it back together a different way, which increases the move count.</p>

            <p>Roux also uses fewer algorithms than CFOP (42 vs. 78), and it requires no cube rotations after the first step. It's also fast. Its inventor has achieved sub-15 times with it, and its top practitioners have achieved sub-6 averages.</p>

            <p>So why doesn't everybody use Roux? Beginners may struggle with the block building involved in Roux, and the heavy reliance on M turns may be unappealing to some, but I think Roux is an elegant way to solve the cube.</p>

            <p>If needed, go ahead and brush up on <Link to="/cubetools/notation">cube notation</Link> before we dive into the <Link to="/cubetools/roux/1">first block</Link>.</p>
        </div>
    )
}
