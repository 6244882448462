import React from 'react'
import { Link, navigate } from '@reach/router'
import { Card } from '../../common'
import { ItemDialog, ListSelector, useHoney } from '../Honey'

export default function CalendarItemDialog({ listId, calendarDate }) {
    const { activeListId, lists } = useHoney()
    
    const showDialog = activeListId || lists.length

    if (activeListId === 0 && lists.length) {
        navigate(`/calendar/${calendarDate}/${lists[0].listId}`)
    }
    
    return (
        <div className="d-flex flex-wrap">
            {showDialog 
                ? <> 
                    <Card>
                        <ListSelector onChange={e => navigate(`/calendar/${calendarDate}/${e.target.value}`)} />
                    </Card>
                    <ItemDialog listId={listId} calendarDate={calendarDate} />
                </>
                : <Card><p>No lists found. <Link to="/honey/new">Make one!</Link></p></Card>
            }
        </div>
    )
}
