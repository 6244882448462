import React from 'react'
import { Link } from '@reach/router'
import pll1 from './pll1.png'
import pll2 from './pll2.png'
import pll3 from './pll3.png'
import arrow from './arrow.png'
import solved from './solved.png'

export default function _2x24() {
    return (
        <div>
            <h1>2x2 Beginner Tutorial: Top Layer</h1>

            <ul>
                <li><Link to="/cubetools/2x2">Introduction</Link></li>
                <li><Link to="/cubetools/2x2/1">Bottom Side</Link></li>
                <li><Link to="/cubetools/2x2/2">Bottom Layer</Link></li>
                <li><Link to="/cubetools/2x2/3">Top Side</Link></li>
                <li>Top Layer</li>
            </ul>

            <p>With the top all yellow, we will look along the sides of the top pieces for matching corners, just like we did in the second step.</p>

            <p>If you have matching corners, turn the top so that they align with their color on the bottom layer. Put the matching corners (now a whole side) on the left side of the cube. </p>

            <div className="d-flex align-items-center justify-content-center">
                <img src={pll1} style={{ height: '100px' }} alt="" />
                <div className="text-center m-2">
                    <img src={arrow} alt="arrow" /><br />(turn top)
                </div>
                <img src={pll2} style={{ height: '100px' }} alt="" />
            </div>

            <p>Now we use our old friend, the corner-switching algorithm:</p>

            <p>(RU2R'U') (RU2L'U) (R'U'R)</p>

            <div className="d-flex align-items-center justify-content-center">
                <img src={pll3} style={{ height: '100px' }} alt="" />
                <div className="text-center m-2">
                    <img src={arrow} alt="arrow" /><br />(algorithm)
                </div>
                <img src={solved} style={{ height: '100px' }} alt="" />
            </div>

            <p>If there are no matching corners, simply use the algorithm to make some as before.</p>

            <p>Congratulations! You are finished!</p>

            <p>Want to get faster? My <Link to="/cubetools/ortega">2x2 intermediate tutorial</Link> shows you how to solve the cube more efficiently.</p>
        </div>
    )
}
