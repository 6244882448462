import React from 'react'
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons'
import { Button } from 'reactstrap'
import { useCalendar } from '.'

export default function MonthlyControls() {
    const { date, prevMonth, resetDate, nextMonth } = useCalendar()
    const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUNE', 'JULY', 'AUG', 'SEPT', 'OCT', 'NOV', 'DEC']

    return (
        <div className="header">
            <div style={{ width: '30%' }}>
                {`${months[date.month]} ${date.year}`}
            </div>
            <div className="control-buttons">
                <ArrowBackIos onClick={prevMonth} className="clickable text-muted" />
                <Button onClick={resetDate} outline>Today</Button>
                <ArrowForwardIos onClick={nextMonth} className="clickable text-muted" style={{ marginLeft: '3px' }} />
            </div>
            <div style={{ width: '30%'}} />
        </div>
    )
}
