import React from 'react'
import { useHoney } from '.'
import { useAuth } from '../../main/auth'
import { FormGroup, Input, Label } from 'reactstrap'
import './list-settings.scss'

export default function ListSettings() {
    const { userSettings, updateUserSettings } = useAuth()
    const { lists } = useHoney()

    const toggleList = async (listId) => {
        const isHidden = userSettings.hideLists.find(id => id === listId) ? true : false
        const updatedHideLists = isHidden 
            ? [...userSettings.hideLists].filter(id => id !== listId)
            : [...userSettings.hideLists, listId]
        const body = {
            ...userSettings,
            hideLists: updatedHideLists
        }
        await updateUserSettings(body)
    }

    if (userSettings) return (
        <div className="list-settings text-start">
            <div className="mb-2"><strong>Display Lists</strong></div>
            {lists.map(list =>
                <div key={`list-${list.listId}`}> 
                    <FormGroup check>
                        <Label check className="d-flex align-items-center">
                            <Input 
                                type="checkbox" 
                                checked={userSettings.hideLists.find(id => id === list.listId) ? false : true} 
                                onChange={() => toggleList(list.listId)}
                            />{list.name}
                        </Label>
                    </FormGroup>    
                </div>
            )}
        </div>
    )

    return (
        <div>User settings not found.</div>
    )
}
