import React from 'react'
import { navigate } from '@reach/router'
import './sunflower.css'
import './grass.css'
import Flower from './Flower'

export default function Garden() {
    return (
        <div>
            <div className="cloud-container">
                <div id="cloud-intro"></div>
            </div>

            <div className="grass"></div>
            
            <div id="position" className="sunflower" title="Dashboard" onClick={() => navigate('/dashboard')}>
                <div className="head">
                    <div id="eye-1" className="eye"></div>
                    <div id="eye-2" className="eye"></div>
                    <div className="mouth"></div>
                </div>
                <div className="petals"></div>
                <div className="trunk">
                    <div className="left-branch"></div>
                    <div className="right-branch"></div>
                </div>
                <div className="vase"></div>
            </div>

            <Flower 
                url={'/honey'} 
                name='Honey' 
                color='red' 
                fromLeft='200px'
                fromBottom='260px'
            />
            <Flower 
                url={'/whisk'} 
                name='Whisk' 
                color='#517bd6' 
                fromLeft='350px' 
                fromBottom='200px'
            />
            {/* <Flower 
                url={'/budget/dashboard'} 
                name='N&amp;D' 
                color='gold' 
                fromLeft='500px' 
                fromBottom='260px'
            /> */}
            <Flower 
                url={'/music'} 
                name='Music' 
                color='violet' 
                fromLeft='650px' 
                fromBottom='200px'
            />
            <Flower 
                url={'/calendar'} 
                name='Calendar' 
                color='gold' 
                fromLeft='500px' 
                fromBottom='260px'
            />
            {/* <Flower 
                url={'/brisk'} 
                name='Brisk' 
                color='gold' 
                fromLeft='950px' 
                fromBottom='200px'
            /> */}
        </div>
    )
}
