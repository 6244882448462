import React, { useState, useEffect, useCallback } from 'react'
import { fetcher, Card } from '../../common'
import { Done, Clear, Delete } from '@material-ui/icons'
import { dateToString, timeToString, convertToUtcString, dateFromInputs } from '../../utils'
import { useTracker } from '.'
import { navigate } from '@reach/router'
import { Form, FormGroup, Input, Button, Label, FormFeedback } from 'reactstrap'

export default function TrackerDataDialog({ groupId, dataId }) {
    const { activeGroup, getGroup, getGroups } = useTracker()
    const [data, setData] = useState(null)
    const [dateInput, setDateInput] = useState(dateToString())
    const [timeInput, setTimeInput] = useState(timeToString())
    const [valueInput, setValueInput] = useState('')
    const [hasSubmitted, setHasSubmitted] = useState(false)
    const mode = dataId === 'new' ? 'create' : 'edit'
    const returnUrl = `/tracker/groups/${groupId}`

    const getData = useCallback(async () => {
        if (activeGroup && dataId && dataId !== 'new') {
            const dataPoint = activeGroup.data.find(x => x.id === parseInt(dataId, 10))
            if (dataPoint) {
                setData(dataPoint)
            }
        } else (
            setData(null)
        )
    }, [activeGroup, dataId])

    useEffect(() => {
        getData()
    }, [getData])

    useEffect(() => {
        if (mode === 'edit' && data !== null) {
            let date = new Date(data.timeUtc)
            setDateInput(dateToString(date))
            setTimeInput(timeToString(date))
            setValueInput(data.value)
        }
    }, [mode, data])

    const handleSubmit = async (e) => {
        e.preventDefault()
        setHasSubmitted(true)
        if (valueInput === '') {
            return
        } else if (mode === 'create') {
            let body = {
                groupId: activeGroup.id,
                timeUtc: convertToUtcString(dateFromInputs(dateInput, timeInput)),
                value: parseFloat(valueInput)
            }
            let response = await fetcher.post('tracker/data', body)
            if (response) {
                getGroup(groupId)
                navigate(returnUrl)
            }
        } else if (mode === 'edit') {
            let body = {
                timeUtc: convertToUtcString(dateFromInputs(dateInput, timeInput)),
                value: parseFloat(valueInput),
                groupId: activeGroup.id,
                id: data.id
            }
            let response = await fetcher.put('tracker/data', body)
            if (response) {
                getGroup(groupId)
                navigate(returnUrl)
            }
        }
    }

    const handleDelete = async () => {
        let response = await fetcher.del(`tracker/data/${data.id}`)
        if (response) {
            getGroups()
            navigate(returnUrl)
        }
    }
    
    if (activeGroup) return (
        <Card header={`${mode === 'create' ? 'Add Data to' : 'Edit Data for'} ${activeGroup.name}`}>
            <Form>
                <FormGroup>
                    <Label for="date">Date</Label>
                    <Input 
                        type="date"
                        id="date"
                        value={dateInput}
                        onChange={e => setDateInput(e.target.value)}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="time">Time</Label>
                    <Input 
                        type="time"
                        id="time"
                        value={timeInput}
                        onChange={e => setTimeInput(e.target.value)}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="value">{activeGroup && activeGroup.unit ? activeGroup.unit : 'Value'}</Label>
                    <Input 
                        type="number"
                        id="value"
                        value={valueInput}
                        onChange={e => setValueInput(e.target.value)}
                        invalid={hasSubmitted && valueInput === ''}
                    />
                    <FormFeedback>Please enter a value.</FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Button
                        type="submit"
                        color="success"
                        onClick={async (e) => await handleSubmit(e)}
                    >
                        <span className="d-flex align-items-center"><Done className="me-1" />Save</span>
                    </Button>
                    <Button
                        type="button"
                        color="secondary"
                        onClick={() => navigate(returnUrl)}
                        className="ms-1"
                    >
                        <span className="d-flex align-items-center"><Clear className="me-1" />Cancel</span>
                    </Button>
                    {mode === 'edit' &&
                        <Button
                            type="button"
                            color="danger"
                            onClick={handleDelete}
                            className="ms-1"
                        >
                            <span className="d-flex align-items-center"><Delete className="me-1" />Delete</span>
                        </Button>}
                </FormGroup>
            </Form>
        </Card>
    )

    return null
}