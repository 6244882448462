import React from 'react'
import { Card } from '../../common'
import { formatDateString } from '../../utils'
import './tracker-aggregate-data-table.scss'

export default function TrackerAggregateDataTable({ data }) {
    if (!data || !data.groups) {
        return null
    }

    const getValue = value => {
        if (!value) {
            return null
        }
        const hasRemainder = value % Math.round(value) > 0
        return hasRemainder ? value.toFixed(2) : value
    }

    const getTotal = values => {
        const result = values.reduce((a, b) => a + b)
        const hasRemainder = result % Math.round(result) > 0
        return hasRemainder ? result.toFixed(2) : result
    }

    return (
        <Card className="tracker-aggregate-data-table">
            <table>
                <tbody>
                    <tr>
                        <th className="text-center">Date</th>
                        {data.groups.map((g, i) => <th key={`group-header-${g.id}`} className="text-center">{g.name}</th>)}
                        {data.groups.length > 1 && <th className="text-center">Total</th>}
                    </tr>
                    {data.data.map((d, i) => {
                        return (
                            <tr key={`row-${d.date}`}>
                                <td className="text-end">{formatDateString(d.date)}</td>
                                {data.groups.map((g, index) => 
                                    <td key={`data-row-${i}-cell-${index}`} className="text-center">{getValue(d.properties[g.nameJson])}</td>
                                )}
                                {data.groups.length > 1 && 
                                    <td className="text-center">{getTotal(Object.values(d.properties))}</td>}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </Card>
    )
}