import React from 'react'
import { Link } from '@reach/router'
import { Row, Col } from 'reactstrap'
import LR1 from './LR1.png'
import LR2 from './LR2.png'
import LR3 from './LR3.png'
import LR4 from './LR4.png'
import LR5 from './LR5.png'
import LR6 from './LR6.png'
import LR7 from './LR7.png'

export default function Roux5() {
    return (
        <div>
            <h1>Roux Tutorial: Permute UL/UR</h1>

            <ul>
                <li><Link to="/cubetools/roux">Introduction</Link></li>
                <li><Link to="/cubetools/roux/1">The First Block</Link></li>
                <li><Link to="/cubetools/roux/2">The Second Block</Link></li>
                <li><Link to="/cubetools/roux/3">CMLL (Top Corners)</Link></li>
                <li><Link to="/cubetools/roux/4">LSE: Edge Orientation</Link></li>
                <li>LSE: Permute UL/UR</li>
                <li><Link to="/cubetools/roux/6">LSE: Permute M Slice</Link></li>
                <li><Link to="/cubetools/roux/7">Example Solves</Link></li>
                <li><Link to="/cubetools/roux/8">Advanced Roux</Link></li>
            </ul>

            <h2>What Goes Down...</h2>

            <p>In our color scheme, the UL and UR edges are the blue/yellow and green/yellow edges, respectively. To solve these two edges, we first move them both to the bottom layer. As we do this, we must make moves that preserve the edge orientation we just fixed. Permitted moves are:
            <ul>
            <li>U, U', U2, and M2</li>
            <li>M and M' only if they are followed with U2M or U2M'</li>
            </ul>

            There are not many cases in this step. We will use moves like M'U2M to move edges between layers. The basic idea is to get one edge on the bottom, then insert the other edge across from it.</p>

            <Row className="mb-2">
                <Col className="text-center">
                    <img src={LR1} alt="" /><br />1 on top, 1 on DF<br />(U' MU2M')
                </Col>
                <Col className="text-center">
                    <img src={LR4} alt="" /><br />1 on top, 1 on DB<br />(U M'U2M)
                </Col>
                <Col className="text-center">
                    <img src={LR3} alt="" /><br />2 on top, opposite<br />(U M2)
                </Col>
                <Col className="text-center">
                    <img src={LR2} alt="" /><br />2 on top, adjacent<br />(M2 U M'U2M)
                </Col>
            </Row>

            <h2>...Must Come Up</h2>

            <p>Once the UL and UR edges are in the bottom layer, you will be able to see one of them on the front face. Note the color of this edge (in our case, blue or green), then turn the top layer so that the corner stickers facing you are the opposite color from the edge. In other words, if you can see a blue edge, you want to see green corners, and vice versa.</p>

            <div className="d-flex align-items-center justify-content-center">
                <img src={LR5} alt="" />
                <span className="m-2">OR</span>
                <img src={LR6} alt="" />
            </div>

            <p>Now simply use M2 to bring the UL and UR edges to the top layer, then use U or U' to solve the L and R sides. As you make the final U turn, note the color of the sticker that will be in the BU position when UL/UR are solved. (BU is the back sticker in the middle of the top layer.) Knowing that color will probably come in handy for the final step.</p>

            <h2>Almost There!</h2>

            <p>You are only 3-5 turns away! All that remains is to solve the last 4 edges around their centers in the <Link to="/cubetools/roux/6">middle slice</Link>.</p>

            <div className="text-center">
                <img src={LR7} alt="" />
            </div>
        </div>
    )
}
