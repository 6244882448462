import React, { useState, useEffect, useCallback } from 'react'
import { useChess } from '.'
import pb from '../../files/chess/pb.png'
import pw from '../../files/chess/pw.png'
import kb from '../../files/chess/kb.png'
import kw from '../../files/chess/kw.png'
import qb from '../../files/chess/qb.png'
import qw from '../../files/chess/qw.png'
import bb from '../../files/chess/bb.png'
import bw from '../../files/chess/bw.png'
import nb from '../../files/chess/nb.png'
import nw from '../../files/chess/nw.png'
import rb from '../../files/chess/rb.png'
import rw from '../../files/chess/rw.png'
import { getSquareContentBySquareName } from './helpers'

export default function ChessboardSquare({ name }) {
    const { 
        clickedSquare, setClickedSquare, 
        darkColor, lightColor, selectColor, 
        onSquareSelect, displayedPieces } = useChess()
    const [squareContent, setSquareContent] = useState(null)
    const hasPiece = squareContent !== null && squareContent !== '-'
    const isDarkSquare = 
        (['a', 'c', 'e', 'g'].includes(name[0]) && ['1', '3', '5', '7'].includes(name[1])) ||
        (['b', 'd', 'f', 'h'].includes(name[0]) && ['2', '4', '6', '8'].includes(name[1]))
    const bgColor = clickedSquare === name ? selectColor : isDarkSquare ? darkColor : lightColor
    
    const getSquareContent = useCallback(() => {
        const content = getSquareContentBySquareName(name, [...displayedPieces])
        if (content) {
            setSquareContent(content)
        }
    }, [displayedPieces, name])

    useEffect(() => {
        getSquareContent()
    }, [getSquareContent])

    const onDragStart = e => {
        e.dataTransfer.setData("originId", e.target.id)
    }

    const handleDrop = e => {
        e.preventDefault()
        if (clickedSquare) {
            if (e.target.id.substr(0, 2) === 'sq') {
                onSquareSelect(e.target.id.substr(3,2))
            } else if (e.target.id.substr(0, 5) === 'piece') {
                onSquareSelect(e.target.id.substr(8,2))
            } else if (e.target.id.substr(0,7) === 'wrapper') {
                onSquareSelect(e.target.id.substr(8,2))
            }
        }
    }

    const handleSquareClick = () => {
        if (hasPiece || clickedSquare) {
            onSquareSelect(name)
        } else {
            setClickedSquare(null)
        }
    }

    const onDragOver = e => {
        e.preventDefault()
    }

    const renderSquare = () => {
        let altText = ''
        let source = null

        switch (squareContent) {
            case 'p':
                altText = 'Black pawn'
                source = pb
                break
            case 'P':
                altText = 'White pawn'
                source = pw
                break
            case 'k':
                altText = 'Black King'
                source = kb
                break
            case 'K':
                altText = 'White King'
                source = kw
                break
            case 'q':
                altText = 'Black Queen'
                source = qb
                break
            case 'Q':
                altText = 'White Queen'
                source = qw
                break
            case 'B':
                altText = 'White Bishop'
                source = bw
                break
            case 'b':
                altText = 'Black Bishop'
                source = bb
                break
            case 'N':
                altText = 'White Knight'
                source = nw
                break
            case 'n':
                altText = 'Dark Knight'
                source = nb
                break
            case 'R':
                altText = 'White Rook'
                source = rw
                break
            case 'r':
                altText = 'Black Rook'
                source = rb
                break
            default:
                break
        }

        if (hasPiece) {
            return (
                <div className="content" id={`wrapper-${name}`}>
                    <img src={source} alt={altText} draggable onDragStart={onDragStart} id={`piece-${squareContent}-${name}`} />
                </div>
            )
        } else {
            return null
        }
    }

    return (
        <>
            <td 
                className="square" 
                style={{ backgroundColor: bgColor }}
                onMouseDown={handleSquareClick}
                onDrop={handleDrop}
                onDragOver={onDragOver}
                id={`sq-${name}`}
            >
                {renderSquare()}
            </td>
        </>
    )
}
