import React, { useEffect } from 'react'
import { navigate } from '@reach/router'
import './flower.scss'

export default function Flower({ color, height, width, fromLeft, fromBottom, url, name }) {

    useEffect(() => {
        document.documentElement.style.setProperty(`--main-flower`, `red`);
    })

    return (
        <div className="flower-container" style={{ left: fromLeft, bottom: fromBottom }}>
            <div className="flower" title={name} onClick={() => navigate(url)}>
                <div className="flower__head" style={{ background: color }}>
                    <div className="flower__eye left">
                        <span></span>
                    </div>
                    <div className="flower__nose"></div>
                    <div className="flower__eye right">
                        <span></span>
                    </div>
                </div>
            
                <div className="flower__leaf"></div>
                <div className="flower__leaf--shadow"></div>
                <div className="flower__leaf oposite"></div>
                <div className="flower__leaf--shadow oposite"></div>
            
                <div className="flower__stem"></div>
            
                <div className="flower__pot" style={{ color: 'rgb(47, 175, 47)' }}>
                    {name}
                    <div className="flower__pot--shadow"></div>
                    <div className="flower__pot--bottom"></div>
                </div>
            </div>
        </div>
    )
}