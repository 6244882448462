import React, { useEffect, useCallback, useState } from 'react'
import { Link } from '@reach/router'
import { TrackerLineGraph, StackedBarGraph, TrackerDataTable, TrackerAggregateDataTable, useTracker } from '../Tracker'
import { Card, fetcher } from '../../common'
import { localizeAggregateData } from '../../utils'
import { List, ListItemsDue } from '../Honey'
import { Row, Col } from 'reactstrap'

export default function RenderedDashboardElement({ element }) {
    const { groups, setActiveGroup } = useTracker()
    const [renderedElement, setRenderedElement] = useState(null)

    const getMinDate = () => {
        let d = new Date()
        const month = d.getMonth()
        const minMonth = month === 0 ? 11 : month - 1
        d.setMonth(minMonth)
        if (month === 0) {
            d.setFullYear(d.getFullYear() - 1)
        }
        return d
    }

    const getFilteredData = useCallback(data => {
        if (!data) return []
        const minDate = getMinDate()
        const filteredData = [...data].filter(x => {
            const entryDate = new Date(x.timeUtc)
            return entryDate >= minDate
        })
        return filteredData
    }, [])

    const getFilteredAggregateData = useCallback(data => {
        const minDate = getMinDate()
        let result = localizeAggregateData(data)
        result.data = result.data.filter(x => x.date >= minDate)
        return result
    }, [])

    const renderElement = useCallback(async () => {
        switch (element.type) {
            case 'listItemsDue':
                {
                    setRenderedElement(
                        <ListItemsDue />
                    )
                    break
                }
            case 'list':
                {
                    setRenderedElement(
                        <List listId={element.id} isDashboardElement />
                    )
                    break
                }
            // case 'monthlySummary':
            //     {
            //         setRenderedElement(
            //             <MonthlySummary />
            //         )
            //         break
            //     }
            // case 'yearlySummary':
            //     {
            //         setRenderedElement(
            //             <YearlySummary />
            //         )
            //         break
            //     }
            // case 'weather':
            //     {
            //         setRenderedElement(
            //             <Card header="Weather" headerLink="/brisk">
            //                 <Brisk isDashboard={true} />
            //             </Card>
            //         )
            //         break
            //     }
            case 'chart':
                {
                    const group = groups.find(x => x.id === element.id)
                    if (!group) return null
                    let chart = null
                    if (!group.isAggregate) {
                        const groupWithData = await fetcher.get(`tracker/groups/${group.id}`)
                        if (groupWithData) {
                            setActiveGroup(groupWithData)
                        }
                        const filteredData = getFilteredData(groupWithData.data)
                        chart = 
                            <TrackerLineGraph 
                                data={filteredData} 
                                ylabel={group.unit}
                                metadata={group.metadata}
                            />
                    } else if (group.isAggregate && group.metadata && group.metadata.aggregateGroups) {
                        const data = await fetcher.get(`tracker/aggregate/${group.id}`)
                        const filteredData = getFilteredAggregateData(data)
                        chart = 
                            <StackedBarGraph 
                                data={filteredData} 
                                groups={data ? data.groups : []}
                                metadata={group.metadata}
                            /> 
                    }
                    setRenderedElement(
                        <Card className="dashboard-section chart" header={<Link to={`/tracker/groups/${group.id}`}>{group.name}</Link>}>
                            <Row>
                                <Col sm={12}>
                                    {chart}
                                </Col>
                            </Row>
                        </Card>
                    )
                    break
                }
            case 'dataTable':
                {
                    const group = groups.find(x => x.id === element.id)
                    if (!group) return null
                    let table = null
                    if (!group.isAggregate) {
                        const data = await fetcher.get(`tracker/groups/${group.id}`)
                        const filteredData = getFilteredData(data)
                        table = 
                            <TrackerDataTable 
                                data={filteredData}
                                group={group}
                                canEdit={false}
                            />
                    } else if (group.isAggregate && group.metadata && group.metadata.aggregateGroups) {
                        const data = await fetcher.get(`tracker/aggregate/${group.id}`)
                        const filteredData = getFilteredAggregateData(data)
                        table = 
                            <TrackerAggregateDataTable 
                                data={filteredData}
                            /> 
                    }
                    setRenderedElement(
                        <Card className="dashboard-section" header={<Link to={`/tracker/groups/${group.id}`}>{group.name}</Link>}>
                            {table}
                        </Card>
                    )
                    break
                }
            default:
                {
                    setRenderedElement(
                        <Card className="dashboard-section" header="Work in progress">
                            <p>{element.module} - {element.type} - {element.id}</p>
                        </Card>
                    )
                }
        }
    }, [element, groups, getFilteredData, getFilteredAggregateData, setActiveGroup])    

    useEffect(() => {
        renderElement()
    }, [renderElement])

    return (
        renderedElement
    )
}
