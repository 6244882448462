import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { Link, navigate } from '@reach/router'
import { useAuth } from '../../main/auth'
import { fetcher, DataTable } from '../../common'
import { SelectColumnFilter } from '../../common/DataTableFilters'

export default function Admin() {
    const { user, isLoadingUser } = useAuth()
    const [users, setUsers] = useState([])

    const getUsers = useCallback(async () => {
        const response = await fetcher.get('user')
        if (response) {
            setUsers(response)
        }
    }, [])

    useEffect(() => {
        getUsers()
    }, [getUsers])

    useEffect(() => {
        if (!isLoadingUser && user && !user.isAdmin)
            navigate('/dashboard')
    }, [user, isLoadingUser])

    const columns = useMemo(() => [
        {
            Header: 'User ID',
            accessor: 'userId'
        },
        {
            Header: 'First Name',
            accessor: 'firstName'
        },
        {
            Header: 'Last Name',
            accessor: 'lastName'
        },
        {
            Header: 'Email',
            accessor: 'email'
        },
        {
            Header: 'Admin',
            accessor: 'isAdmin',
            Filter: SelectColumnFilter,
            filter: 'boolean',
            Cell: ({ value }) => value ? <span className="success">Yes</span> : 'No'
        }
    ], [])

    if (!isLoadingUser && user && user.isAdmin)
        return (
            <div className="main-container">
                <h2>Users</h2>
                <DataTable
                    data={users}
                    columns={columns}
                    filterable
                    sortable
                    paginated={users.length > 10}
                />
            </div>
        )
    
    return null
}
