import React, { useMemo } from 'react'
import { Input } from 'reactstrap'

function TextColumnFilter({ column: { filterValue, setFilter } }) {
    return (
        <Input
            value={filterValue || ''}
            onChange={e => setFilter(e.target.value || undefined)}
            placeholder={`Search...`}
        />
    )
}

function SelectColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
    const renderOption = value => {
        if (typeof(value) === 'boolean') {
            return value ? 'Yes' : 'No'
        }
        return value
    }  

    const options = useMemo(() => {
        const opts = new Set()
        preFilteredRows.forEach(row => opts.add(renderOption(row.values[id])))
        return [...opts.values()].sort()
    }, [id, preFilteredRows])
 
    return (
        <Input type="select" value={filterValue} onChange={e => setFilter(e.target.value || undefined)}>
            <option value="">All</option>
            {options.map((option, i) =>
                <option key={`option-${i}-${option}`} value={option}>
                    {option}
                </option>
            )}
        </Input>
    )
}

export {
    TextColumnFilter,
    SelectColumnFilter
}
