import React, { useEffect } from 'react'
import { Router } from '@reach/router'
import { HoneyMenu, ItemDialog, List, ListDialog, ListItemsDue, useHoney } from '.'
import { useFriends } from '../Friends/FriendsProvider'
import { useAuth } from '../../main/auth'

export default function HoneyModule() {
    const { friends } = useFriends()
    const { getLists, getPermissions } = useHoney()
    const { user } = useAuth();
    const isHarris = user && user.userId < 3;

    useEffect(() => {
        getLists()
    }, [getLists])

    useEffect(() => {
        getPermissions()
    }, [friends, getPermissions])

    useEffect(() => {
        if (isHarris) {
            window.location.href='https://squarenote.azurewebsites.net/honey';
        }
    }, [isHarris])

    return (
        <div className="module-container">
            <HoneyMenu />
            <div className="main-container with-side-menu">
                <Router>
                    <List path=":listId" />
                    <ListItemsDue path="due" />
                    <ListDialog path="new" />
                    <ListDialog path=":listId/edit" />
                    <ItemDialog path=":listId/item/:index" />
                    <List default />
                </Router>
            </div>
        </div>
    )
}
