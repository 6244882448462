import { Link } from '@reach/router'
import React from 'react'

export default class ErrorBoundary extends React.Component {
    state = {
        hasError: false,
        error: null
    }
    
    static getDerivedStateFromError(error) {
        return {
            hasError: true,
            error: error
        }
    }

    componentDidCatch(error, info) {
        // log error here if desired
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.component !== this.props.component) {
            this.setState({ hasError: false, error: null })
        }
    }

    render() {
        if (this.state.hasError) {
            return (
                <div>
                    <p>Oops! An error has occurred.</p>
                    <p>{this.state.error.toString()}</p>
                    <p><Link to='/'>Click here to go back Home</Link>, or refresh the page.</p>
                </div>
            )
        } else {
            return <>{this.props.children}</>
        }
    }
}
