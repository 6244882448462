import React from 'react'
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons'
import { Button } from 'reactstrap'
import { useCalendar } from '.'

export default function WeeklyControls() {
    const { prevWeek, resetDate, nextWeek } = useCalendar()

    return (
        <div className="header">
            <div />
            <div className="control-buttons">
                <ArrowBackIos onClick={prevWeek} className="clickable text-muted" />
                <Button onClick={resetDate} outline>Today</Button>
                <ArrowForwardIos onClick={nextWeek} className="clickable text-muted" style={{ marginLeft: '3px' }} />
            </div>
            <div />
        </div>
    )
}
