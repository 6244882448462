import React from 'react'
import { Link } from '@reach/router'
import { Table, Row, Col } from 'reactstrap'
import yellowTop from './LL yellow top.png'
import antiSune from './coll Anti-Sune.png'
import blinkers from './coll blinkers.png'
import bowtie from './coll bowtie.png'
import doubleHeadlights from './coll double headlights.png'
import headlights from './coll headlights.png'
import pi from './coll pi.png'
import sune from './coll Sune.png'

export default function LBL5() {
    return (
        <div>
            <h1>3x3 Beginner Tutorial: The Yellow Top</h1>

            <ul>
                <li><Link to="/cubetools/lbl">Introduction</Link></li>
                <li><Link to="/cubetools/lbl/1">The White Cross</Link></li>
                <li><Link to="/cubetools/lbl/2">The White Corners</Link></li>
                <li><Link to="/cubetools/lbl/3">The Second Layer</Link></li>
                <li><Link to="/cubetools/lbl/4">The Yellow Cross</Link></li>
                <li>The Yellow Top</li>
                <li><Link to="/cubetools/lbl/6">The Final Corners</Link></li>
                <li><Link to="/cubetools/lbl/7">The Final Edges</Link></li>
            </ul>

            <p>The next step is to make the top of the cube solid yellow. We will use an algorithm called the Sune (&quot;soo-nay&quot;): (RUR'U)(RU2R'). We will also use its mirror, the Anti-Sune: (L'U'LU')(L'U2L). You can think of these as Right Sune and Left Sune. Here is how they work:</p>

            <Table>
                <tr>
                    <td className="text-end">Right Sune:<br />(RUR'U)(RU2R')</td>
                    <td className="text-start"><img src={sune} alt="" /></td>
                </tr>
                <tr>
                    <td className="text-end">Left Sune:<br />(L'U'LU')(L'U2L)</td>
                    <td className="text-start"><img src={antiSune} alt="" /></td>
                </tr>
            </Table>

            <p>The bold edges represent yellow stickers on the side. If the above scenarios occur, the Sune will orient all the corners, making the top solid yellow.</p>

            <p>There are five other possible scenarios you may encounter:</p>

            <Row className="mb-3">
                <Col className="text-center"><img src={bowtie} alt="" /><br />Bowtie</Col>
                <Col className="text-center"><img src={blinkers} alt="" /><br />Blinkers</Col>
                <Col className="text-center"><img src={headlights} alt="" /><br />Headlights</Col>
                <Col className="text-center"><img src={pi} alt="" /><br />Pi</Col>
                <Col className="text-center"><img src={doubleHeadlights} alt="" /><br />Double Headlights</Col>
            </Row>

            <p>If one of the above five scenarios occurs, do the Right Sune. (Make sure you are facing the side shown above.) This should give you one correctly oriented corner. You can then finish orienting the third layer using either the Right Sune or Left Sune.</p>

            <p>Once the top is solid yellow, you are ready to <Link to="/cubetools/lbl/6">position the last layer corners</Link>.</p>

            <div className="text-center">
                <img src={yellowTop} alt="" />
            </div>

        </div>
    )
}
