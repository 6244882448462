import React from 'react'
import { Link } from '@reach/router'
import { Card, CardHeader, CardBody } from 'reactstrap'

export default function RoundedCard({ children, header, headerLink, className, ...otherProps }) {
    return (
        <Card className={`me-2 mb-2 ${className ? className : ''}`} {...otherProps} style={{ borderRadius: '10px' }}>
            {header
                ? <CardHeader>
                    <strong>
                        {headerLink 
                            ? <Link to={headerLink}>{header}</Link>
                            : header
                        }
                    </strong>
                </CardHeader>
                : null}
            <CardBody>
                {children}
            </CardBody>
        </Card>
    )
}
