import React, { useState, useEffect, useCallback } from 'react'
import { fetcher } from '../../common'
import { useAuth } from '../../main/auth/AuthProvider'
import { NotificationManager } from 'react-notifications'

export const FriendsContext = React.createContext()

export default function FriendsProvider({ children }) {
    const { user, setIsLoading } = useAuth()
    const [friends, setFriends] = useState([])
    const [invitations, setInvitations] = useState([])

    const getFriends = useCallback(async () => {
        setIsLoading(true)
        let response = await fetcher.get(`user/friends`)
        if (response)
            setFriends(response)
        setIsLoading(false)
    }, [setIsLoading])

    const getInvitations = useCallback(async () => {
        setIsLoading(true)
        let response = await fetcher.get('friendship/invitations')
        if (response)
            setInvitations(response)
        setIsLoading(false)
    }, [setIsLoading])

    const refresh = useCallback(() => {
        if (user) {
            getFriends()
            getInvitations()
        }
    }, [getFriends, getInvitations, user])

    useEffect(() => {
        refresh()
    }, [refresh])

    const invite = async (userInput) => {
        const body = {
            email: userInput.trim()
        }
        const response = await fetcher.post(`friendship`, body)
        if (response) {
            NotificationManager.success('Invitation sent!')
        }
    }

    const acceptInvitation = async (id) => {
        setIsLoading(true)
        let response = await fetcher.get(`friendship/confirm/${id}`)
        if (response.ok) {
            refresh()
        }
        setIsLoading(false)
    }

    const removeFriend = async (userId) => {
        setIsLoading(true)
        let response = await fetcher.del(`friendship/${userId}`)
        if (response.ok) {
            refresh()
        }
    }

    const context = {
        friends, setFriends,
        getFriends,
        invitations, setInvitations,
        invite,
        acceptInvitation,
        removeFriend
    }

    return (
        <FriendsContext.Provider value={context}>
            {children}
        </FriendsContext.Provider>
    )
}

export function useFriends() {
    const context = React.useContext(FriendsContext)
    if (context === undefined) {
        throw new Error('useFriends must be used within a FriendsProvider')
    }
    return context
}