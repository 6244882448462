import React from 'react'
import { Link } from '@reach/router'
import fourLook from './4lookll.pdf'
import threeLook from './3lookll.pdf'

export default function CFOP4() {
    return (
        <div>
            <h1>CFOP Tutorial: Permute Last Layer</h1>

            <ul>
                <li><Link to="/cubetools/cfop">Introduction</Link></li>
                <li><Link to="/cubetools/cfop/1">Cross</Link></li>
                <li><Link to="/cubetools/cfop/2">First Two Layers (F2L)</Link></li>
                <li><Link to="/cubetools/cfop/3">Orient Last Layer (OLL)</Link></li>
                <li>Permute Last Layer (PLL)</li>
                <li><Link to="/cubetools/cfop/5">Beyond CFOP</Link></li>
            </ul>

            <p>PLL permutes the last layer corners and edges in one algorithm. There is nothing intuitive about PLL. You recognize which case you have, and you execute the algorithm for it as fluidly and quickly as you can. Sound familiar?</p>

            <p>2-look PLL has only 6 algorithms. I have <Link to="/cubetools/flashcards">flashcards</Link> for them. My guide to the <a href={fourLook} target="_blank" rel="noreferrer">4-look last layer</a> also includes 2-look PLL.</p>

            <p>Full PLL has 21 cases. I have <Link to="/cubetools/flashcards">flashcards</Link> for all them, and my <a href={threeLook} target="_blank" rel="noreferrer">3-look last layer guide</a> has all of them on one page.</p>

            <h4>Tips</h4>

            <p>The tips I gave for <Link to="/cubetools/cfop/3">OLL</Link> also apply to PLL. Learn to recognize the case immediately, but AUF if needed.</p>

            <p>There are many possible algorithms for each case, so please explore to find the ones you like best. There are good lists of them at <a href="https://www.speedsolving.com/wiki/index.php/PLL" target="_blank" rel="noopener noreferrer">speedsolving.com</a> and <a href="http://algdb.net/puzzle/333/pll" target="_blank" rel="noopener noreferrer">algdb.net</a>.</p>

            <p>Once you become familiar with CFOP, your journey is far from over. There are many <Link to="/cubetools/cfop/5">supplements, variants, and alternate methods</Link> which await.</p>
        </div>
    )
}
