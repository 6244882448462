import React from 'react'
import { Loading, Card } from '../../common'
import { useTracker } from '.'
import { useAuth } from '../../main/auth'
import { formatDateString } from '../../utils'
import { navigate } from '@reach/router'
import './tracker-data-table.scss'

export default function TrackerDataTable({ data, canEdit }) {
    const { user } = useAuth()
    const { activeGroup } = useTracker()

    const handleEdit = id => {
        if (canEdit) {
            navigate(`/tracker/groups/${activeGroup.id}/data/${id}`)
        }
    }

    if (!activeGroup || !user) {
        return <Loading />
    }

    return (
        <Card className="tracker-data-table">
            <table>
                <tbody>
                    <tr>
                        <th className="text-center">Date</th>
                        <th className="text-center">Time</th>
                        <th className="text-center">{activeGroup.unit}</th>
                    </tr>
                    {data.map((row, i) => {
                        let dateUtc = new Date(row.timeUtc)
                        return (
                            <tr key={`row-${i}`} onClick={() => handleEdit(row.id)}>
                                <td className="text-end">{formatDateString(dateUtc)}</td>
                                <td className="text-end">{dateUtc.toLocaleString('en', {hour: 'numeric', minute: '2-digit'})}</td>                                  
                                <td className="text-center">{row.value}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </Card>
    )
}
