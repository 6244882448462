import React from 'react'
import './calendar-event.scss'

export default function CalendarEvent({ text, data}) {
    
    function drag(ev) {
        //ev.currentTarget.style.opacity = '.5'
        ev.dataTransfer.setData("text", ev.target.id)
    }

    return (
        <div 
            className="calendar-event" 
            id={JSON.stringify(data)} 
            draggable 
            onDragStart={e => drag(e)}
        >
            {text}
        </div>
    )
}