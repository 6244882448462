import React, { useState } from 'react';
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons'
import './list-item.scss';

export default function ListItem({ listName, itemName, itemIndex, onCheckItem, onUncheckItem, date, description, onEdit, canEdit }) {
    const [isChecked, setIsChecked] = useState(false)
    const checkedBox = <CheckBox className='muted' onClick={() => { setIsChecked(false); onUncheckItem(itemIndex)}} />
    const uncheckedBox = <CheckBoxOutlineBlank className='muted' onClick={() => { setIsChecked(true); onCheckItem(itemIndex)}} />

    return (
        <li key={listName + '-' + itemName}>
            <div>
                <div className="list-item">
                    {canEdit ? 
                        isChecked ? checkedBox : uncheckedBox
                        : null
                    }
                    <div onClick={() => onEdit(itemIndex)} style={{ marginLeft: '3px' }}>
                        {itemName}
                    </div>
                </div>
                {description 
                    ? <div onClick={() => onEdit(itemIndex)} className="description">
                        {description}
                    </div>
                    : null
                }
            </div>            
        </li>
    )
}
