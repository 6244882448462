import React, { useEffect, useMemo } from 'react'
import { Link } from '@reach/router'
import { useMusic } from '.'
import { DataTable } from '../../common'
import { PlayCircleFilled, PlaylistAdd } from '@material-ui/icons'
import { Button } from 'reactstrap'
import './playlists.scss'

export default function Playlists() {
    const { playlists, startPlaylist, getPlaylists } = useMusic()

    useEffect(() => {
        getPlaylists()
    }, [getPlaylists])

    const columns = useMemo(() => [
        {
            Header: 'Name',
            accessor: 'name'
        },
        {
            Header: 'Owner',
            accessor: 'userId',
            Cell: ({ value }) => value === 1 ? 'Hubby' : value === 2 ? 'Wifey' : '?'
        },
        {
            Header: '# Pieces',
            accessor: 'pieceIds',
            disableFilters: true,
            Cell: ({ row }) => row.original.pieceIds.length
        },
        {
            Header: '',
            accessor: 'nothingReally',
            disableFilters: true,
            disableSortBy: true,
            Cell: ({ row }) => 
                <div className="text-end">
                    <Button onClick={() => startPlaylist(row.original, true)} color="success">
                        <span className="d-flex align-items-center"><PlayCircleFilled className="me-1" />Play</span>
                    </Button>
                    <Button onClick={() => startPlaylist(row.original, false)} color="primary" className="ms-1">
                        View
                    </Button>
                    <Link to={`/music/playlists/${row.original.id}/edit`} className="btn btn-info ms-1">Edit</Link>
                </div>
        }
    ], [startPlaylist])

    return (
        <div className="playlists">
            <h1>Playlists</h1>
            <Link to="/music/playlists/new" className="btn btn-primary mb-2">
                <span className="d-flex align-items-center"><PlaylistAdd className="me-1"/>New Playlist</span>
            </Link>
            {playlists.length > 0 && 
                <DataTable
                    data={playlists}
                    columns={columns}
                    sortable
                    filterable
                    paginated={playlists.length > 10}
                />}
        </div>
    )
}
