import React from 'react'
import { render } from 'react-dom'
import App from './App'
import { unregister } from './registerServiceWorker'
//import 'bootstrap/dist/css/bootstrap.min.css'
import './bootstrap-custom.css'
import './style.scss'

render (
    <App />,
    document.getElementById('root')
);

unregister()
