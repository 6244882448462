import React, { useState, useEffect, useMemo } from 'react'
import { Clear, ArrowUpward, ArrowDownward } from '@material-ui/icons'
import { useFriends } from '../Friends/FriendsProvider'
import { useHoney } from '../Honey'
import { useTracker } from '../Tracker'
import { Input } from 'reactstrap'
import './editable-dashboard-element.scss'

export default function EditableDashboardElement({ element, elements, index, moveUp, moveDown, onChange, onDelete, ...otherProps }) {
    const { friends } = useFriends()
    const { lists } = useHoney()
    // const { budgets } = useBudget()
    const { groups } = useTracker()
    const [moduleInput, setModuleInput] = useState(element.module || '')
    const [typeInput, setTypeInput] = useState(element.type || '')
    const [idInput, setIdInput] = useState(element.id || 0)

    useEffect(() => {
        const updatedElement = {
            module: moduleInput,
            type: typeInput,
            id: parseInt(idInput, 10)
        }
        if (moduleInput !== element.module || 
            typeInput !== element.type || 
            idInput !== element.id) {
                onChange(updatedElement, index)
            }
    }, [moduleInput, typeInput, idInput, index, onChange, element])

    const handleModuleChange = value => {
        setModuleInput(value)
        setTypeInput('')
        setIdInput(0)
    }

    const handleTypeChange = value => {
        setTypeInput(value)
        setIdInput(0)
    }

    const typeOptions = useMemo(() => {
        switch (moduleInput) {
            case 'Honey':
                return <>
                    <option value="listItemsDue">List Items Due</option>
                    {lists.length > 0 && <option value="list">List</option>}
                </>
            case 'Tracker':
                if (groups.length) {
                    return <>
                        <option value="chart">Chart</option>
                        <option value="dataTable">Data Table</option>
                    </>
                }
                return null
            // case 'Nickel & Dime':
            //     if (budgets.length) {
            //         return <>
            //             <option value="monthlySummary">Current Monthly Summary</option>
            //             <option value="yearlySummary">Current Yearly Summary</option>
            //         </>
            //     }
            //     return null
            // case 'Brisk':
            //     return (
            //         <option value="weather">Current Weather</option>
            //     )
            default:
                return null
        }
    }, [groups, lists, moduleInput])

    const idOptions = useMemo(() => {
        switch (typeInput) {
            case 'list':
                return <>
                    {lists.map(list => 
                        <option key={`list-${list.listId}`} value={list.listId}>{list.name}</option>
                    )}
                </>
            case 'chart':
            case 'dataTable':
                return <>
                    {groups.map(group => {
                        const friend = friends.find(x => x.userId === group.userId)
                        return (
                            <option key={`group-${group.id}`} value={group.id}>{group.name} {friend ? `(${friend.firstName})` : ''}</option>
                        )
                    })}
                </>
            // case 'monthlySummary':
            // case 'yearlySummary':
            //     return <>
            //         {budgets.map(budget => {
            //             const friend = friends.find(x => x.userId === budget.userId)
            //             return (
            //                 <option key={`budget-${budget.budgetId}`} value={budget.budgetId}>{budget.name} {friend ? `(${friend.firstName})` : ''}</option>
            //             )
            //         })}
            //     </>
            default:
                return null
        }
    }, [friends, groups, lists, typeInput])

    return (
        <div className="editable-dashboard-element" {...otherProps}>
            <div className="controls">
                {index < elements.length - 1 && 
                    <ArrowDownward className="text-secondary clickable me-1" onClick={() => moveDown(index)} />}
                {index > 0 && 
                    <ArrowUpward className="text-secondary clickable me-1" onClick={() => moveUp(index)} />}
                <Clear className="text-danger clickable" onClick={() => onDelete(index)} />
            </div>
            <div>
                <Input type="select" value={moduleInput} onChange={e => handleModuleChange(e.target.value)}>
                    <option value='' disabled>Select a module...</option>
                    {['Honey', 'Tracker'].map(module => 
                        <option key={`option-${module}`} value={module}>{module}</option>    
                    )}
                </Input>
            </div>
            {moduleInput !== '' && 
                <div>
                    <Input type="select" value={typeInput} onChange={e => handleTypeChange(e.target.value)}>
                        <option value='' disabled>Select content...</option>
                        {typeOptions}
                    </Input>
                </div>
            }
            {!['', 'listItemsDue', 'weather'].includes(typeInput) && 
                <div>
                    <Input type="select" value={idInput} onChange={e => setIdInput(e.target.value)}>
                        <option value={0} disabled>Select one...</option>
                        {idOptions}
                    </Input>
                </div>
            }
        </div>
    )
}
