import React, { useEffect, useCallback, useReducer } from 'react'
import { validUploadImgFileTypes } from '../utils'
import { Close } from '@material-ui/icons'
import { Input } from 'reactstrap'
import { FileLink, Thumbnail } from '.'
import './multiple-file-upload-data.scss'

export default function MultipleFileUploadData({ files, onFileRemove, names, descriptions, onNameChange, onDescriptionChange }) {
    
    const urlReducer = (state, { action, filename, payload }) => {
        switch (action) {
            case 'set': {
                let newState = {...state}
                newState[filename] = payload
                return newState
            }
            default:
                return state
        }
    }

    const [urls, urlDispatch] = useReducer(urlReducer, {})

    const loadUrls = useCallback(() => {
        for (let i = 0; i < files.length; i++) {
            const file = files[i]
            if (file && validUploadImgFileTypes.includes(file.type)) {
                const reader = new FileReader()
                reader.onload = () => {
                    urlDispatch({ action: 'set', filename: file.name, payload: reader.result})
                }
                reader.readAsDataURL(file)
            }
        }
    }, [files])

    useEffect(() => {
        loadUrls()
    }, [loadUrls])

    return (
        <div className="multiple-file-upload-data">
            {files.map((file, index) => {
                if (urls[file.name]) {
                    return (
                        <div className="thumbnail-container" key={`filePreview-${file.name}`}>
                            <Thumbnail src={urls[file.name]} />
                            <Input 
                                type="text" 
                                placeholder="Name" 
                                value={names[index] || ''} 
                                onChange={e => onNameChange(e.target.value, index)}
                            />
                            <Input 
                                type="text"
                                placeholder="Description"
                                value={descriptions[index] || ''}
                                onChange={e => onDescriptionChange(e.target.value, index)}
                            />
                            <Close className="close-btn" onClick={() => onFileRemove(index)} />
                        </div>
                    )
                } else {
                    return (
                        <div className="thumbnail-container" key={`filePreview-${file.name}`}>
                            <FileLink filePath={file.name} isPreview />
                            <Input 
                                type="text" 
                                placeholder="Name" 
                                value={names[index] || ''} 
                                onChange={e => onNameChange(e.target.value, index)}
                            />
                            <Input 
                                type="text"
                                placeholder="Description"
                                value={descriptions[index] || ''}
                                onChange={e => onDescriptionChange(e.target.value, index)}
                            />
                            <Close className="close-btn" onClick={() => onFileRemove(index)} />
                        </div>
                    )
                }
            })}
        </div>
    )
}
