import React, { useState, useEffect } from 'react'
import { Link } from '@reach/router'
import { Loading, Card } from '../../common'
import { dateToString, sortListItemsByDate } from '../../utils'
import { useHoney, ListSettings } from '.'
import { useAuth } from '../../main/auth'
import { Settings } from '@material-ui/icons'
import './list-items-due.scss'

export default function ListItemsDue() {
    const { userSettings } = useAuth()
    const { lists, getLists, getPermissions, isLoading } = useHoney()
    const [overdueItems, setOverdueItems] = useState([])
    const [todayItems, setTodayItems] = useState([])
    const [tomorrowItems, setTomorrowItems] = useState([])
    const [showSettings, setShowSettings] = useState(false)

    useEffect(() => {
        getLists()
        getPermissions()
    }, [getLists, getPermissions])

    useEffect(() => {
        if (lists.length && userSettings && userSettings.hideLists) {
            let allLists = lists.filter(list => !userSettings.hideLists.includes(list.listId)).map(list => {
                return {
                    listId: list.listId,
                    name: list.name,
                    details: JSON.parse(list.detailsJSON)
                }
            })
            allLists.forEach(list => list.details.items.forEach(item => item.listId = list.listId))
            let allItems = allLists.map(list => list.details.items).flat()
            let sortedItems = sortListItemsByDate(allItems)
            getOverdueItems(sortedItems)
            getTodayItems(sortedItems)
            getTomorrowItems(sortedItems)
        } 
    }, [lists, userSettings])

    const getOverdueItems = (sortedItems) => {
        let overdueArrays = sortedItems.filter(arr => arr[0] < dateToString())
        let overdue = ['oops', []]
        overdueArrays.forEach(arr => arr[1].forEach(item => overdue[1].push(item)))
        setOverdueItems(overdue)
    }

    const getTodayItems = (sortedItems) => {
        let today = sortedItems.find(arr => arr[0] === dateToString())
        setTodayItems(today)
    }

    const getTomorrowItems = (sortedItems) => {
        let todayDate = new Date()
        let tomorrowDate = new Date()
        tomorrowDate.setDate(todayDate.getDate() + 1)
        let tomorrow = sortedItems.find(arr => arr[0] === dateToString(tomorrowDate))        
        setTomorrowItems(tomorrow)
    }

    const displayItems = (items, msg = null) => {
        return (
            <div>
                <ul className="list-ul">
                    {items && items.length
                        ? items[1].map(item =>
                            <li key={item.name}>
                                <Link to={`/honey/${item.listId}`}>{item.name}</Link>
                            </li>)
                        : <li>{msg}</li>
                    }
                </ul>
            </div>
        )
    }

    const cardHeader = 
        <div className="d-flex justify-content-between">
            <div>List Items Due</div>
            <Settings onClick={() => setShowSettings(x => !x)} />
        </div>

    if (isLoading) return <Loading />
    
    return (
        <Card className="list-items-due" header={cardHeader} headerLink="/honey/due">
            {showSettings && <ListSettings />}
            {overdueItems[1] && overdueItems[1].length
                ? <div>
                    <strong>Overdue</strong>
                    {displayItems(overdueItems)}
                </div>
                : null
            }
            <strong>Due Today</strong>
            {displayItems(todayItems, 'No items due today! Take a well-deserved break.')}
            <strong>Due Tomorrow</strong>
            {displayItems(tomorrowItems, 'No items due tomorrow!')}
        </Card>
    )
}
