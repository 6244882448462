import React, { useEffect } from 'react'
import { navigate, Router } from '@reach/router'
import Dashboard from '../modules/Dashboard/Dashboard'
import Garden from '../modules/Dashboard/Garden'
import { NotFound } from '../common'
import { useAuth } from './auth/AuthProvider'
import Settings from '../modules/Settings/Settings'
import Friends from '../modules/Friends/Friends'
import { HoneyModule, HoneyProvider } from '../modules/Honey'
import { CalendarModule } from '../modules/Calendar'
import { WhiskModule, WhiskProvider } from '../modules/Whisk'
import { TrackerModule, TrackerProvider } from '../modules/Tracker'
import Admin from '../modules/Admin/Admin'
import HomeStart from '../modules/Admin/HomeStart'
import { MusicModule } from '../modules/Music'
import WorkStart from '../modules/Admin/WorkStart'

export default function ProtectedRoutes() {
    const { user, isLoadingUser } = useAuth()

    useEffect(() => {
        if (!isLoadingUser && !user) {
            navigate('login')
        }
    }, [isLoadingUser, user])

    return (
        <HoneyProvider>            
            <WhiskProvider>
                <TrackerProvider>
                    <Router>
                        <Dashboard path="dashboard" />
                        <Garden path="dashboard/garden" />
                        <Settings path="settings" />
                        <Friends path="friends" />
                        {/* <BudgetModule path="budget/*" /> */}
                        <HoneyModule path="honey/*" />
                        <CalendarModule path="calendar/*" />
                        <WhiskModule path="whisk/*" />
                        <TrackerModule path="tracker/*" />
                        <MusicModule path="music/*" />
                        {/* <LessonModule path="lessons/*" /> */}
                        {/* <RoutinesModule path="routines/*" /> */}
                        {/* <Brisk path="brisk" /> */}
                        <HomeStart path="admin/start" />
                        <WorkStart path="admin/start/work" />
                        <Admin path="admin" />
                        <NotFound default />
                    </Router>
                </TrackerProvider>
            </WhiskProvider>
        </HoneyProvider>
    )
}
