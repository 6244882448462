import React from 'react'
import { Close } from '@material-ui/icons'
import { Badge } from 'reactstrap'

export default function BlogTag({ tag, onClick, showDelete }) {
    if (tag) return (
        <Badge 
            className={`m-1 ${onClick ? 'clickable' : ''}`} 
            color="secondary"
            pill 
            onClick={onClick ? onClick : () => {}}
        >
            <span className="d-flex align-items-center">
                {tag.name} {showDelete ? <Close style={{ fontSize: '12px' }} className="ms-1" /> : null}
            </span>
        </Badge>
    )

    return null
}
