import React, { useState, useEffect, useMemo } from 'react'
import { navigate } from '@reach/router'
import { ListItem, useHoney } from '.'
import { Loading, Card } from '../../common'
import { sortListItemsByDate, stringToDate } from '../../utils'
import { RemoveCircle } from '@material-ui/icons'
import { Button } from 'reactstrap'
import './list.scss'

export default function List({ listId, isDashboardElement }) {
    const { lists, isLoading, setActiveListId, deleteItems, getItems, canEditList } = useHoney()
    const [itemsChecked, setItemsChecked] = useState([]);
    const [datedItems, setDatedItems] = useState([])
    const [currentList, setCurrentList] = useState(undefined);
    
    useEffect(() => {
        if (listId) {
            let activeList = lists.find(list => list.listId === parseInt(listId, 10))
            if (activeList) {
                setActiveListId(parseInt(listId, 10))
                setCurrentList(activeList)
            }
        }
    }, [listId, lists, setActiveListId])

    const items = useMemo(() => {
        return getItems(currentList)
    }, [currentList, getItems])

    const canEditCurrentList = useMemo(() => {
        return canEditList(currentList)
    }, [canEditList, currentList])

    useEffect(() => {
        setDatedItems([])
        if (currentList && items.length) {
            setDatedItems(sortListItemsByDate(items))
        }
    }, [currentList, items])

    const handleCheckItem = (index) => {
        let updatedChecks = [...itemsChecked]
        updatedChecks.push(index)
        updatedChecks = updatedChecks.sort((a, b) => a - b)
        setItemsChecked(updatedChecks)
    }

    const handleUncheckItem = (index) => {
        let updatedChecks = [...itemsChecked]
        updatedChecks = updatedChecks.filter(x => x !== index)
        setItemsChecked(updatedChecks)
    }

    const handleEditItem = index => {
        if (canEditCurrentList) {
            navigate(`/honey/${listId}/item/${index}`)
        }
    }

    const handleDeleteItems = () => {
        deleteItems(+listId, itemsChecked)
        setItemsChecked([])
    }

    if (isLoading) return <Loading />

    if (currentList) return (
            <Card className="list" header={currentList.name} headerLink={isDashboardElement ? `/honey/${listId}` : ""}>
                {canEditCurrentList && 
                    <Button 
                        type="button"
                        color="danger"
                        className="mb-2"
                        onClick={handleDeleteItems}
                        disabled={itemsChecked.length < 1}
                        style={itemsChecked.length < 1 ? { visibility: 'hidden'} : {}}
                    >
                        <span className="d-flex align-items-center"><RemoveCircle className="me-1"/>
                            Selected ({itemsChecked.length})
                        </span>
                    </Button>}
                <div className="list-items">
                    {datedItems.map(groupedItems => {
                        return (
                            <div key={groupedItems[0]}>
                                <div className="text-primary">
                                    {groupedItems[0] !== 'none' 
                                        ? stringToDate(groupedItems[0])
                                        : groupedItems[1].length && datedItems.length > 1
                                            ? 'No Due Date'
                                            : null
                                    }
                                </div>
                                <ul className="list-ul">
                                    {groupedItems[1].map(item =>
                                        <ListItem
                                            listName={currentList.name}
                                            itemName={item.name}
                                            itemIndex={item.index}
                                            key={item.name}
                                            onCheckItem={handleCheckItem}
                                            onUncheckItem={handleUncheckItem}
                                            date={item.date}
                                            description={item.description}
                                            onEdit={handleEditItem}
                                            canEdit={canEditCurrentList}
                                        />
                                    )}
                                </ul>
                            </div>
                        )
                    })}
                </div>
            </Card>
    )

    return null
}
