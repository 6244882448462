import React from 'react'
import { Link } from '@reach/router'
import { Card, CardBody, Row, Col } from 'reactstrap'
import arrow from './arrow.png'
import corners1 from './LL matching corners1.png'
import corners2 from './LL matching corners2.png'
import corners3 from './LL matching corners3.png'
import corners4 from './LL matching corners4.png'

export default function LBL6() {
    return (
        <div>
            <h1>3x3 Beginner Tutorial: The Final Corners</h1>

            <ul>
                <li><Link to="/cubetools/lbl">Introduction</Link></li>
                <li><Link to="/cubetools/lbl/1">The White Cross</Link></li>
                <li><Link to="/cubetools/lbl/2">The White Corners</Link></li>
                <li><Link to="/cubetools/lbl/3">The Second Layer</Link></li>
                <li><Link to="/cubetools/lbl/4">The Yellow Cross</Link></li>
                <li><Link to="/cubetools/lbl/5">The Yellow Top</Link></li>
                <li>The Final Corners</li>
                <li><Link to="/cubetools/lbl/7">The Final Edges</Link></li>
            </ul>

            <p>In this step, you will look for sides which have matching corners in the last layer. Matching corners have the same color sticker on one side.</p>
            
            <div className="d-flex justify-content-center">
                <Card>
                    <CardBody className="text-center">
                        <img src={corners1} alt="" />
                    </CardBody>
                    <CardBody className="text-center">
                        In this diagram, the right side has matching red corners in the top layer.
                    </CardBody>
                </Card>
            </div>

            <p>If there is a side with two matching corners, turn the cube so that side is on the bottom, and face the white side.</p>

            <Row className="mb-3">
                <Col className="text-end"></Col>
                <Col className="text-center"></Col>
                <Col className="text-start"></Col>
            </Row>

            <div className="d-flex justify-content-center align-items-center">
                <img src={corners2} alt="" />
                <div className="text-center me-2 ms-2"><img src={arrow} alt="" /><br />(rotate cube)</div>
                <img src={corners3} alt="" />
            </div>
            
            <p>While facing the white side, execute this algorithm:</p>

            <p>(R'UR') D2 (RU'R') (D2R2)</p>

            <p>If there is not a side with two matching corners, execute the above algorithm while facing white. This should create a side with two matching corners. Put that side on the bottom, and follow the instructions above.</p>

            <p>Once all sides have matching corners, turn the last layer so that the corners line up with the correct sides of the cube. You are now ready to solve the cube by <Link to="/cubetools/lbl/7">positioning the last layer edges</Link>!</p>

            <div className="text-center">
                <img src={corners4} alt="" />
            </div>
        </div>
    )
}
