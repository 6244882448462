import React, { useState, useEffect, useCallback } from 'react'
import { Card, fetcher, Loading, TextEditor, FileLink, FileViewer, MultipleFileUploadData } from '../../common'
import { Form, FormGroup, FormFeedback, Col, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Link, navigate } from '@reach/router'
import { useAuth } from '../../main/auth/AuthProvider'
import { BlogTag } from '.'
import { validUploadFileTypes } from '../../utils'
import { AddCircle } from '@material-ui/icons'
import { useFiles } from '../../main/FilesProvider'
import './blog-post-dialog.scss'

export default function BlogPostDialog({ postId }) {
    const uploadBtn = React.createRef()
    const { user } = useAuth()
    const { addFilePathsToQueue } = useFiles()
    const [titleInput, setTitleInput] = useState('')
    const [contentInput, setContentInput] = useState('')    
    const [keyInput, setKeyInput] = useState('')
    const [isPublicInput, setIsPublicInput] = useState(true)
    const [tagsInput, setTagsInput] = useState([])
    const [filesInput, setFilesInput] = useState([])
    const [fileNamesInput, setFileNamesInput] = useState([])
    const [fileDescriptionsInput, setFileDescriptionsInput] = useState([])
    const [filesToBeUploaded, setFilesToBeUploaded] = useState([])
    const [filesToBeRemoved, setFilesToBeRemoved] = useState([])
    const [activeFile, setActiveFile] = useState(null)
    const [showFileViewer, setShowFileViewer] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [post, setPost] = useState(null)
    const [tags, setTags] = useState([])
    const [hasSubmitted, setHasSubmitted] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const unusedTags = tags.filter(tag => !tagsInput.includes(tag))
    const returnUrl = '/blog/posts'

    const getPost = useCallback(async () => {
        if (postId && postId !== 'new') {
            setIsLoading(true)
            const resp = await fetcher.get(`blog/posts/${postId}`)
            if (resp) {
                setPost(resp)
                addFilePathsToQueue(resp.files.map(file => `${file.path}`), 'blog')
                setTitleInput(resp.title)
                setIsPublicInput(resp.isPublic)
                setKeyInput(resp.key)
                setTagsInput(resp.tags)
                setContentInput(resp.content)
                setFilesInput(resp.files)
                setFileNamesInput(resp.files.map(file => file.name))
                setFileDescriptionsInput(resp.files.map(file => file.description))
            }
        }
        setIsLoading(false)
    }, [postId, addFilePathsToQueue])

    useEffect(() => {
        getPost()
    }, [getPost])

    const getTags = useCallback(async () => {
        const resp = await fetcher.get('blog/tags/mine')
        if (resp) {
            setTags(resp)
        }
    }, [])

    useEffect(() => {
        getTags()
    }, [getTags])

    const addTag = id => {
        const newTag = tags.find(tag => tag.id === parseInt(id, 10))
        if (newTag) {
            setTagsInput([...tagsInput, newTag])
        }
    }

    const removeTag = id => {
        setTagsInput([...tagsInput].filter(tag => tag.id !== id))
    }

    const removeFileToBeUploaded = index => {
        let updatedFilesToBeUploaded = [...filesToBeUploaded]
        updatedFilesToBeUploaded.splice(index, 1)
        setFilesToBeUploaded(updatedFilesToBeUploaded)
    }

    const handleFileClick = file => {
        setActiveFile(file)
        setShowFileViewer(true)
    }

    const handleFileRemove = filePath => {
        let updatedFilesToBeRemoved = [...filesToBeRemoved]
        updatedFilesToBeRemoved.push(filePath)
        setFilesToBeRemoved(updatedFilesToBeRemoved)
    }

    const handleFileRestore = filePath => {
        let updatedFilesToBeRemoved = [...filesToBeRemoved]
        const index = updatedFilesToBeRemoved.findIndex(x => x === filePath)
        if (index > -1) {
            updatedFilesToBeRemoved.splice(index, 1)
            setFilesToBeRemoved(updatedFilesToBeRemoved)
        }
    }

    const handleFileNameChange = (value, index) => {
        let updatedFileNames = [...fileNamesInput]
        updatedFileNames[index] = value
        setFileNamesInput(updatedFileNames)
    }

    const handleFileDescriptionChange = (value, index) => {
        let updatedFileDescriptions = [...fileDescriptionsInput]
        updatedFileDescriptions[index] = value
        setFileDescriptionsInput(updatedFileDescriptions)
    }

    const handleSubmit = async () => {
        setHasSubmitted(true)
        if (titleInput.trim() === '' || keyInput.trim() === '') {
            return
        }
        setIsLoading(true)
        if (post) {
            edit()
        } else {
            create()
        }
    }

    const edit = async () => {
        let filePaths = []
        if (filesToBeUploaded.length > 0) {
            let formData = new FormData()
            for (let i = 0; i < filesToBeUploaded.length; i++) {
                formData.append("file[]", filesToBeUploaded[i])
            }
            const fileResponse = await fetcher.postFile('files/blog/file', formData)
            filePaths = fileResponse.paths
        }
        
        let fileContent = [...filesInput]
        fileContent.forEach((file, index) => {
            file.name = fileNamesInput[index]
            file.description = fileDescriptionsInput[index]
        })
        fileContent = fileContent.filter(file => ![...filesToBeRemoved].includes(file.path))
        
        for (let i = 0; i < filePaths.length; i++) {
            const file = {
                id: 0,
                name: fileNamesInput[i + filesInput.length] || '',
                description: fileDescriptionsInput[i + filesInput.length] || '',
                path: filePaths[i]
            }
            fileContent.push(file)
        }

        const body = {
            id: post.id,
            title: titleInput.trim(),
            content: contentInput,
            isPublic: isPublicInput,
            key: keyInput.trim(),
            tags: tagsInput,
            files: fileContent
        }
        const resp = await fetcher.put(`blog/posts/${post.id}`, body)
        if (resp) {
            navigate(returnUrl)
        } else {
            setIsLoading(false)
        }
    }

    const create = async () => {
        let filePaths = []
        if (filesToBeUploaded.length > 0) {
            let formData = new FormData()
            for (let i = 0; i < filesToBeUploaded.length; i++) {
                formData.append("file[]", filesToBeUploaded[i])
            }
            const fileResponse = await fetcher.postFile('files/blog/file', formData)
            filePaths = fileResponse.paths
        }

        let fileContent = []
        for (let i = 0; i < filePaths.length; i++) {
            const file = {
                id: 0,
                name: fileNamesInput[i] || '',
                description: fileDescriptionsInput[i] || '',
                path: filePaths[i]
            }
            fileContent.push(file)
        }

        const body = {
            title: titleInput.trim(),
            content: contentInput,
            isPublic: isPublicInput,
            key: keyInput.trim(),
            tags: tagsInput,
            files: fileContent
        }
        const resp = await fetcher.post(`blog/posts`, body)
        if (resp) {
            navigate(returnUrl)
        } else {
            setIsLoading(false)
        }
    }

    const handleDelete = async () => {
        setIsLoading(true)
        const resp = await fetcher.del(`blog/posts/${post.id}`)
        if (resp) {
            navigate(returnUrl)
        } else {
            setIsLoading(false)
        }
    }

    if (isLoading) return <Loading />

    if (showFileViewer) return (
        <FileViewer files={filesInput} initialFile={activeFile} onClose={() => setShowFileViewer(false)} />
    )

    if (user) return (
        <Card header={`${postId ? 'Edit' : 'New'} Blog Post`} className="blog-post-dialog">
            <Form>
                <FormGroup row>
                    <Col sm={6}>
                        <Label for="title">Title</Label>
                        <Input
                            type="text"
                            id="title"
                            value={titleInput}
                            onChange={e => setTitleInput(e.target.value)}
                            invalid={hasSubmitted && titleInput.trim() === ''}
                        />
                        <FormFeedback>Please enter a title.</FormFeedback>
                    </Col>
                    <Col>
                        <Label for="key">URL Key</Label>
                        <Input 
                            type="text"
                            id="key"
                            placeholder="for-the-link"
                            value={keyInput}
                            onChange={e => setKeyInput(e.target.value)}
                            invalid={hasSubmitted && keyInput.trim() === ''}
                        />
                        <FormFeedback>Please enter a URL key.</FormFeedback>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col sm={6}>
                        <FormGroup check>
                            <Label check>
                                <Input
                                    type="checkbox"
                                    checked={isPublicInput}
                                    onChange={() => setIsPublicInput(x => !x)}
                                />Public?
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col sm={6}>
                        <Label>Tags</Label>
                        <Input 
                            type="select"
                            value={''}
                            onChange={e => addTag(e.target.value)}
                        >
                            <option disabled value=''>Add a tag...</option>
                            {unusedTags.map(tag => 
                                <option key={`tag-option-${tag.id}`} value={tag.id}>{tag.name}</option>
                            )}
                        </Input>
                        <div>
                            {tagsInput.map(tag => 
                                <BlogTag tag={tag} showDelete onClick={() => removeTag(tag.id)} key={`tag-${tag.id}`} />
                            )}
                        </div>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col sm={12}>
                        <Label>Content</Label>
                        <TextEditor 
                            data={contentInput} 
                            onChange={content => setContentInput(content)} 
                            placeholder='Post stuff here!' 
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col sm={12}>
                        <Label>Files</Label>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col sm={12}>
                        <input 
                            ref={uploadBtn}
                            className="d-none"
                            type="file" 
                            onChange={() => {
                                if (uploadBtn.current.files.length) {
                                    setFilesToBeUploaded(x => [...x, ...Array.from(uploadBtn.current.files)])
                                }
                            }}
                            accept={validUploadFileTypes.join(', ')}
                            multiple
                        />
                        <Button 
                            type="button"
                            color="info"
                            className="mb-2"
                            onClick={() => uploadBtn.current.click()}
                        >
                            <span className="d-flex align-items-center">
                                <AddCircle className="me-1" /> File(s)
                            </span>
                        </Button>
                        <div className="file-thumbnails">
                            {filesInput.map((file, index) => 
                                <div key={`attached-file-${file.id}`} className="attached-file">
                                    <div className="attached-file-link">
                                        <FileLink 
                                            key={`fileLink-${file.id}`}
                                            filePath={file.path}
                                            file={file}
                                            onClick={() => handleFileClick(file)}
                                            onRemove={() => handleFileRemove(file.path)}
                                            onRestore={() => handleFileRestore(file.path)}
                                            removed={filesToBeRemoved.includes(file.path)}
                                        />
                                    </div>
                                    <div className="attached-file-inputs">
                                        <Input 
                                            type="text" 
                                            placeholder="Name" 
                                            value={fileNamesInput[index] || ''} 
                                            onChange={e => handleFileNameChange(e.target.value, index)}
                                        />
                                        <Input 
                                            type="text"
                                            placeholder="Description"
                                            value={fileDescriptionsInput[index] || ''}
                                            onChange={e => handleFileDescriptionChange(e.target.value, index)}
                                        />
                                    </div>
                                </div>
                            )}

                            {filesToBeUploaded.length > 0 &&
                                <MultipleFileUploadData
                                    files={filesToBeUploaded}
                                    onFileRemove={index => removeFileToBeUploaded(index)}
                                    names={fileNamesInput.slice(filesInput.length)}
                                    descriptions={fileDescriptionsInput.slice(filesInput.length)}
                                    onNameChange={(value, index) => handleFileNameChange(value, index + filesInput.length)}
                                    onDescriptionChange={(value, index) => handleFileDescriptionChange(value, index + filesInput.length)}
                                />}
                        </div>
                    </Col>
                </FormGroup>
                <FormGroup>
                    <Button type="button" color="primary" onClick={handleSubmit}>Save</Button>
                    <Link className="btn btn-secondary ms-1" to={returnUrl}>Cancel</Link>
                    {post !== null && 
                        <Button 
                            type="button" 
                            color="danger" 
                            onClick={() => setShowDeleteModal(true)} 
                            className="ms-1"
                        >
                            Delete
                        </Button>}
                </FormGroup>
            </Form>

            <Modal isOpen={showDeleteModal} toggle={() => setShowDeleteModal(x => !x)}>
                <ModalHeader toggle={() => setShowDeleteModal(x => !x)}>Delete Blog Post</ModalHeader>
                <ModalBody>
                    Are you sure you want to delete this blog post?
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={handleDelete}>Yes, Kill It!</Button>
                    <Button color="secondary" onClick={() => setShowDeleteModal(false)}>No, I Like It</Button>
                </ModalFooter>
            </Modal>
        </Card>
    )

    return null
}
