import React from 'react'
import { Link } from '@reach/router'
import { Table } from 'reactstrap'
import whiteCorner1 from './white corner1.png'
import whiteCorner2 from './white corner2.png'
import whiteCorner3 from './white corner3.png'
import whiteCorner4 from './white corner4.png'
import whiteCorner5 from './white corner5.png'
import whiteCorner6 from './white corner6.png'
import firstComplete from './first layer complete.png'

export default function LBL2() {
    return (
        <div>
            <h1>3x3 Beginner Tutorial: The White Corners</h1>

            <ul>
                <li><Link to="/cubetools/lbl">Introduction</Link></li>
                <li><Link to="/cubetools/lbl/1">The White Cross</Link></li>
                <li>The White Corners</li>
                <li><Link to="/cubetools/lbl/3">The Second Layer</Link></li>
                <li><Link to="/cubetools/lbl/4">The Yellow Cross</Link></li>
                <li><Link to="/cubetools/lbl/5">The Yellow Top</Link></li>
                <li><Link to="/cubetools/lbl/6">The Final Corners</Link></li>
                <li><Link to="/cubetools/lbl/7">The Final Edges</Link></li>
            </ul>

            <p>In this step, we will move the four corner pieces with white stickers down to their proper place in the bottom layer. There is a short algorithm that helps us do this, and it's called Sexy move: (RUR'U') and its mirror (L'U'LU). <span className="text-muted">(Sometimes we will use only the first three turns of Sexy move.)</span></p>

            <p>Identify a corner in the top layer with a white sticker, preferably one with the white sticker on the side rather than on the very top of the cube.</p>

            <h2>Corner on Top, White Sticker on Side</h2>

            <p>If the white sticker is on the side, turn the top to align the other side color with its center (e.g. red with red), then face that side color. This puts the corner above the slot where it belongs, in this case between the red, green, and white centers.</p>

            <Table>
                <tbody>
                    <tr>
                        <td><img src={whiteCorner1} alt="" /></td>
                        <td>If the white corner is on the right side of the cube, use (RUR').</td>
                    </tr>
                    <tr>
                        <td><img src={whiteCorner2} alt="" /></td>
                        <td>If the white corner is on the left side of the cube, use (L'U'L).</td>
                    </tr>
                </tbody>
            </Table>

            <h2>Corner on Top, White Sticker on Top</h2>

            <p>If the white sticker is on the very top of the cube, turn the top until the white corner is over the spot where it belongs, in this case between the red, green, and white centers. Then use Sexy move until it is solved.</p>

            <Table>
                <tbody>
                    <tr>
                        <td><img src={whiteCorner3} alt="" /></td>
                        <td>If the white corner is on the right side of the cube, use (RUR'U') three times.</td>
                    </tr>
                    <tr>
                        <td><img src={whiteCorner4} alt="" /></td>
                        <td>If the white corner is on the left side of the cube, use (L'U'LU) three times.</td>
                    </tr>
                </tbody>
            </Table>

            <h2>Corner on Bottom</h2>

            <p>Sometimes there will be no white corners in the top layer, but the bottom layer is not solved. When this happens, turn the cube so that an incorrect white corner is on the bottom front (preferably with the white sticker facing sideways).</p>

            <Table>
                <tbody>
                    <tr>
                        <td><img src={whiteCorner5} alt="" /></td>
                        <td>If the white corner is on the right side of the cube, use (RUR'). Now the white sticker should be on the side in the top layer, so you can follow the instructions above.</td>
                    </tr>
                    <tr>
                        <td><img src={whiteCorner6} alt="" /></td>
                        <td>If the white corner is on the left side of the cube, use (L'U'L). Now the white sticker should be on the side in the top layer, so you can follow the instructions above.</td>
                    </tr>
                </tbody>
            </Table>

            <h2>Yay Corners!</h2>

            <p>After all corners are placed correctly around the white cross, the first layer will be complete. Next we will solve the <Link to="/cubetools/lbl/3">second layer</Link>.</p>
            
            <div className="text-center">
                <img src={firstComplete} class="auto" alt="" />
            </div>
        </div>
    )
}
