import { useCallback, useMemo, useEffect, useState } from "react";
import { DataTable, fetcher, Loading, BooleanIcon } from "../../common";
import { useAuth } from "../../main/auth/AuthProvider"
import { Button } from 'reactstrap'
import { DonationDialog } from '.'
import { AddCircle, Note, CloudDownload } from '@material-ui/icons'
import { SelectColumnFilter } from "../../common/DataTableFilters";
import { formatDateString, numToCurrency } from "../../utils";

export default function Donations({ getSummary }) {
    const { user } = useAuth()
    const authorizedUserIds = [1, 2]
    const hasAuthAccess = user && authorizedUserIds.includes(user.userId);
    const [donations, setDonations] = useState([]);
    const [activeDonationId, setActiveDonationId] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [isExporting, setIsExporting] = useState(false);
    const [showModal, setShowModal] = useState(false)
    const activeDonation = donations.find(d => d.id === activeDonationId)

    const getDonations = useCallback(async () => {
        const resp = await fetcher.get('trustfund');
        if (resp) {
            setDonations(resp);
        }
        setIsLoading(false);
    }, [])

    useEffect(() => {
        getDonations()
    }, [getDonations])

    const getDonationsExport = async () => {
        setIsExporting(true);
        await fetcher.openDirect('trustfund/export', 'donations');
        setIsExporting(false);
    }

    const handleCreate = () => {
        setActiveDonationId(0)
        setShowModal(true)
    }

    const handleRowClick = donation => {
        setActiveDonationId(donation.id)
        setShowModal(true)
    }

    const handleModalClose = (triggerRefresh = false) => {
        setShowModal(false)
        setActiveDonationId(0)
        if (triggerRefresh) {
            getSummary()
            getDonations()
        }
    }

    const columns = useMemo(() => [
        {
            Header: "Date Donated",
            accessor: 'dateDonated',
            Cell: ({ value }) => formatDateString(value),
            disableFilters: true,
            maxWidth: 150
        },
        {
            Header: "Donor",
            accessor: "donor"
        },
        {
            Header: "Amount",
            accessor: "amount",
            Cell: ({ value }) => <div className="text-end">{numToCurrency(value)}</div>,
            maxWidth: 150,
            disableFilters: true
        },
        {
            Header: "Method",
            accessor: "method",
            Filter: SelectColumnFilter
        },
        {
            Header: "Thanked?",
            accessor: "hasThankYou",
            Filter: SelectColumnFilter,
            filter: 'boolean',
            Cell: ({ value }) => <BooleanIcon value={value} />
        },
        {
            Header: "Transferred?",
            accessor: "dateTransferred",
            Cell: ({ value }) => <BooleanIcon value={!!value} title={formatDateString(value)} />,
            disableFilters: true,
        },
        {
            Header: "Connection",
            accessor: "connection",
            Filter: SelectColumnFilter
        },
        {
            Header: "Notes",
            accessor: "notes",
            disableFilters: true,
            sortable: false,
            Cell: ({ value }) => value ? <div className="d-flex justify-content-center"><Note /></div> : null
        }
    ], [])

    if (!hasAuthAccess) return null;

    if (isLoading) return <Loading />

    return (
        <>
            <div className="d-flex justify-content-between">
                <Button 
                    color="primary" 
                    onClick={handleCreate}
                    className="mb-2"
                >
                    <span className="d-flex align-items-center"><AddCircle className="me-1" />Donation</span>
                </Button>
                <Button
                    color="info"
                    className="mb-2"
                    onClick={getDonationsExport}
                    disabled={isExporting}
                >
                    <span className="d-flex align-items-center"><CloudDownload className="me-2" />{isExporting ? 'Exporting...' : "Export"}</span>
                </Button>
            </div>
            {donations.length 
                ? <DataTable 
                    data={donations}
                    columns={columns}
                    filterable
                    sortable
                    paginated={donations.length > 10}
                    onRowClick={e => handleRowClick(e.original)}
                /> 
                : <p>No donations found.</p>
            }
            <DonationDialog 
                show={showModal} 
                onClose={handleModalClose}
                donation={activeDonation}
            />
        </>
    )
}
