import React from 'react'
import { navigate } from '@reach/router'
import { ButtonGroup, Button } from 'reactstrap'
import SideMenu from '../../main/nav/SideMenu'
import { Style, Timer, GridOn, Public, FormatListNumbered } from '@material-ui/icons'

export default function CubeToolsMenu() {
    return (
        <SideMenu header="CubeTools" headerLink="/cubetools" className="cubetools-menu">
            <ButtonGroup vertical className="w-100">
                <Button 
                    color="primary" 
                    outline
                    onClick={() => navigate('/cubetools/tutorials')}
                >
                    <span className="d-flex align-items-center"><FormatListNumbered className="me-1"/>Tutorials</span>
                </Button>
                {/* <Button 
                    color="primary" 
                    outline
                    onClick={() => navigate('/cubetools/timer')}
                >
                    <span className="d-flex align-items-center"><Timer className="me-1"/>Timer</span>
                </Button>
                <Button 
                    color="primary" 
                    outline
                    onClick={() => navigate('/cubetools/flashcards')}
                >
                    <span className="d-flex align-items-center"><Style className="me-1"/>Flashcards</span>
                </Button>
                <Button 
                    color="primary" 
                    outline
                    onClick={() => navigate('/cubetools/scrambler')}
                >
                    <span className="d-flex align-items-center"><GridOn className="me-1"/>Scrambler</span>
                </Button> */}
                <Button 
                    color="primary" 
                    outline
                    onClick={() => navigate('/cubetools/resources')}
                >
                    <span className="d-flex align-items-center"><Public className="me-1"/>Resources</span>
                </Button>
            </ButtonGroup>
        </SideMenu>
    )
}
