import { Router } from '@reach/router'
import React from 'react'
import AuthProvider from './main/auth/AuthProvider'
import Main from './main/Main'
import ThemeProvider from './main/theme/ThemeProvider'
import { NotificationContainer } from 'react-notifications'
import FilesProvider from './main/FilesProvider'
import FriendsProvider from './modules/Friends/FriendsProvider'
import BudgetProvider from './modules/NickelAndDime/BudgetProvider'
import 'react-notifications/lib/notifications.css'

export default function App() {
    return (
        <AuthProvider>
            <ThemeProvider>
                <FilesProvider>
                    <FriendsProvider>
                        {/* <BudgetProvider> */}
                            <NotificationContainer />
                            <Router>
                                <Main default />
                            </Router>
                        {/* </BudgetProvider> */}
                    </FriendsProvider>
                </FilesProvider>
            </ThemeProvider>
        </AuthProvider>
    )
}
