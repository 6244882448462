import React from 'react'
import { Link } from '@reach/router'
import fourLook from './4lookll.pdf'
import oll from './oll.pdf'

export default function CFOP3() {
    return (
        <div>
            <h1>CFOP Tutorial: Orient Last Layer</h1>

            <ul>
                <li><Link to="/cubetools/cfop">Introduction</Link></li>
                <li><Link to="/cubetools/cfop/1">Cross</Link></li>
                <li><Link to="/cubetools/cfop/2">First Two Layers (F2L)</Link></li>
                <li>Orient Last Layer (OLL)</li>
                <li><Link to="/cubetools/cfop/4">Permute Last Layer (PLL)</Link></li>
                <li><Link to="/cubetools/cfop/5">Beyond CFOP</Link></li>
            </ul>

            <p>OLL orients the last layer corners and edges in one algorithm. There is nothing intuitive about OLL. You recognize which case you have, and you execute the algorithm for it as fluidly and quickly as you can. OLL has 57 cases, which means 57 algorithms to memorize.</p>

            <h2>Journey of a Thousand Miles...</h2>

            <p>...begins with 2-look OLL. I have <Link to="/cubetools/flashcards">flashcards for the 10 algorithms of 2-look OLL</Link>. See also my PDF guide to the <a href={fourLook} target="_blank" rel="noreferrer">4-look last layer</a>, which begins with 2-look OLL.</p>

            <p>When you are ready for full OLL, check out my <a href={oll} target="_blank" rel="noreferrer">PDF guide</a> and my <Link to="/cubetools/flashcards">flashcards</Link>.</p>

            <h2>Tips</h2>

            <p>When figuring out which case you have for OLL (or PLL), do not rotate the entire cube. Instead, AUF (adjust the U face) as needed to identify the case. With practice, you can identify last layer cases by looking only at two sides plus the top.</p>

            <p>There are often multiple algorithms that accomplish the same thing. Try different ones to see which of them fit your style best. There are good lists of them at <a href="https://www.speedsolving.com/wiki/index.php/OLL" target="_blank" rel="noreferrer">speedsolving.com</a> and <a href="http://algdb.net/puzzle/333/oll" target="_blank" rel="noreferrer">algdb.net</a>. Practice them slowly to find the most efficient way to execute them, and then gradually increase your speed. Focus on practicing difficult ones until they become second nature / muscle memory.</p>

            <p>The final step of CFOP is <Link to="/cubetools/cfop/4">PLL</Link>.</p>
        </div>
    )
}
